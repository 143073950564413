import React from "react";
import { Check } from "@mui/icons-material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { formStyles } from "~/presentation/views/Reinf/components/Styles/FormStyles";
import { Title } from "~/presentation/views/Reinf/components/Layouts/styles";

function Nota({
  values,
  errors,
  businessPartnerOptions,
  isUnconditionalDiscountWrong,
  inputDisabled,
  handleChangeFieldValue,
}) {
  //filtrando parceiros de negócio CNPJ
  const cnpjPartners = businessPartnerOptions.filter((partner) => partner.personType === 1);
  const disabledInput = inputDisabled === "PRINCIPAL";
  const disabledInputAll = inputDisabled === "ALL";

  //ERROR: O desconto incondicional não pode ser maior do que o valor bruto da nota!
  const isUnconditionalDiscountWrongText =
    isUnconditionalDiscountWrong &&
    "O desconto incondicional não pode ser maior do que o valor bruto da nota!";

  //ERROR: só para mostrar se por engano escreverem o desconto maior do que o valor bruto
  const isAmountOperationNegative = values?.amountBC < 0;
  const isAmountOperationNegativeText = isAmountOperationNegative && "Valor não pode ser negativo!";

  return (
    <>
      <Title>Dados Principais</Title>
      <Box sx={formStyles.fieldsWrapper}>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 3, md: 2 }}>
            <Input
              type="text"
              title="Nº Doc. Fiscal"
              value={values?.nFseNumber}
              onChange={(e) => {
                const value = e.target.value;
                handleChangeFieldValue({
                  newItem: { nFseNumber: value },
                  field: "nFseNumber",
                });
              }}
              disabled={disabledInput}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 3, md: 2 }}>
            <Input
              type="text"
              title="Série NF"
              placeholder="Nº série NF"
              value={values?.serie}
              onChange={(e) => {
                const value = e.target.value;
                handleChangeFieldValue({
                  newItem: { serie: value },
                  field: "serie",
                });
              }}
              error={errors[`[${values?.id}].serie`]}
              disabled={disabledInputAll}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Input
              type="date"
              title="Data de emissão"
              value={values?.dateOfIssue}
              onChange={(date) => {
                handleChangeFieldValue({
                  newItem: { dateOfIssue: date },
                  field: "dateOfIssue",
                });
              }}
              error={errors[`[${values?.id}].dateOfIssue`]}
              disabled={disabledInput}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 5 }}>
            <Input
              type="select"
              title="CNPJ do Contratado"
              options={cnpjPartners}
              getOptionLabel={(option) => `${formatCpfCnpj(option.cpfCnpj)} - ${option.legalName}`}
              placeholder="Selecione um Contratado"
              value={cnpjPartners?.find((item) => item.id == values?.businessPartnerId)}
              onChange={(option) => {
                const { id } = option;
                handleChangeFieldValue({
                  newItem: { businessPartnerId: id },
                  field: "businessPartnerId",
                });
              }}
              disabled={disabledInput}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor Bruto do Doc. Fiscal"
              value={values?.amountService}
              onChange={(value) =>
                handleChangeFieldValue({
                  newItem: { amountService: value },
                  field: "amountService",
                })
              }
              error={errors[`[${values?.id}].amountService`]}
              disabled={disabledInputAll}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
            <Input
              type="amount"
              title="(-) Desconto Condicional"
              value={values?.conditionalDiscount}
              onChange={(value) => {
                handleChangeFieldValue({
                  newItem: { conditionalDiscount: value },
                  field: "conditionalDiscount",
                });
              }}
              error={errors[`[${values?.id}].conditionalDiscount`]}
              disabled={disabledInputAll}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2.5, xl: 3 }}>
            <Input
              type="amount"
              title="(-) Desconto Incondicional"
              value={values?.unconditionalDiscount}
              onChange={(value) => {
                handleChangeFieldValue({
                  newItem: { unconditionalDiscount: value },
                  field: "unconditionalDiscount",
                });
              }}
              error={
                errors[`[${values?.id}].unconditionalDiscount`] || isUnconditionalDiscountWrongText
              }
              disabled={disabledInputAll}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2.5 }}>
            <Input
              type="amount"
              title="Valor Total da Operação"
              allowNegative
              value={values?.amountBC}
              onChange={(value) => {
                handleChangeFieldValue({
                  newItem: { amountBC: value },
                  field: "amountBC",
                });
              }}
              error={errors[`[${values?.id}].amountBC`] || isAmountOperationNegativeText}
              disabled={disabledInputAll}
            />
          </Grid>
          <Grid size="auto">
            {/*Esse campo se refere a um percentual de 11% ou 3,5%. O mais comum é de 11%. Se não há marcação sub entende-se ser 11% */}
            <Typography sx={style.Label}>CPRB?</Typography>
            <Box
              sx={style.checkBox}
              onClick={() => {
                if (disabledInputAll) return;
                const lastValue = values?.isCPRB;
                handleChangeFieldValue({
                  newItem: { isCPRB: lastValue ? false : true },
                });
              }}
            >
              {values?.isCPRB && <Check fontSize="large" />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Nota;

const style = {
  checkBox: {
    width: "38px",
    height: "38px",
    background: "#FBFBFB",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  Label: {
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "700",
    fontSize: 12,
    textAlign: "left",
    maxWidth: "unset",
    paddingBottom: "3px",
  },
};
