import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, clientPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiUserUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getUsers({ checkPermission = true, companyId }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeUsers, clientPerms.seeUsers])) {
        throw new Error(securityErrorMessage("visualizar usuários"));
      }
    }

    const url = companyId ? `${apiURL}/User?companyID=${companyId}` : `${apiURL}/User`;

    try {
      const response = await this.httpClient.request({
        url: url,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getUserById({ id, checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeUsers, clientPerms.seeUsers])) {
        throw new Error(securityErrorMessage("visualizar usuário"));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/User/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteUser(id) {
    if (!hasPermission([adminPerms.deleteUsers, clientPerms.deleteUsers])) {
      throw new Error(securityErrorMessage("excluir usuários"));
    }

    const response = await this.httpClient.request({
      url: `${apiURL}/User/${id}`,
      method: "DELETE",
    });
    return response.data;
  }

  async deleteUserOfCompany(id, companyId) {
    if (!hasPermission([adminPerms.deleteUsers, clientPerms.deleteUsers])) {
      throw new Error(securityErrorMessage("excluir usuários"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/${id}/${companyId}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async register(data) {
    if (!hasPermission([adminPerms.addUsers, clientPerms.addUsers])) {
      throw new Error(securityErrorMessage("registrar usuários"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/User`,
        data,
        method: "POST",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateUser({ id, data, checkPermission = true }) {
    if (checkPermission && !hasPermission([adminPerms.editUsers, clientPerms.editUsers])) {
      throw new Error(securityErrorMessage("editar usuários"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/User/${id}`,
        data,
        method: "PUT",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async generatePassword(id) {
    if (!hasPermission([adminPerms.generateUsersPassword])) {
      throw new Error(securityErrorMessage("gerar senhas."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/User/GeneratePassword/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getTaxData() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/taxinformation/balance/`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
