import React, { useContext, useEffect, useState } from "react";
import TableActions from "~/presentation/components/TableActions";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import PageListHeader from "../components/PageListHeader";
import RegisterEdit from "./RegisterEdit/RegisterEdit";
import AdminTable from "../components/AdminTable/AdminTable";

function SecurityPermission({ securityPermissionUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //Data
  const [securityPermissions, setSecurityPermissions] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <RegisterEdit data={data} onCloseForm={closeDialog} refreshData={fetchData} />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const response = await securityPermissionUseCases.getSecurityPermissions();
      setSecurityPermissions(response);
    } catch (error) {
      showError(error, "Erro ao carregar permissões de segurança.");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "permission", headerName: "Permissão", flex: 1 },
    { field: "description", headerName: "Descrição", flex: 1, hide: "md" },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => <TableActions handleEdit={() => handleOpenForm(row)} />,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageListHeader
        title="Permissões de Segurança"
        titleAccess="Nova permissão de segurança"
        handleAdd={() => handleOpenForm()}
      />
      <AdminTable
        tableExport
        columns={columns}
        data={securityPermissions}
        exportFileName="permissoes"
      />
    </>
  );
}

export default SecurityPermission;
