export const formatCpfCnpj = (value) => {
  const cleanValue = value?.replace(/\D/g, "");
  if (cleanValue?.length <= 11) {
    // CPF
    return cleanValue?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else {
    // CNPJ
    return cleanValue?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
};

export const getMonths = (locale = "pt-BR") => {
  return Array.from({ length: 12 }, (v, i) => {
    return {
      value: i + 1, // Mês começando de 1 para 12
      label: new Date(0, i).toLocaleString(locale, { month: "long" }),
    };
  });
};

export const getYears = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push({ value: year, label: year.toString() });
  }
  return years;
};

export const getEFDReinfEvents = (user) => {
  return [
    { value: "r2010", label: "R-2010 - Retenção Contribuição Previdenciária - Serviços Tomados" },
    { value: "r2098", label: "R-2098 - Reabertura dos Eventos Periódicos" },
    { value: "r2099", label: "R-2099 - Fechamento dos Eventos Periódicos" },
    ...// //condicional até o desenv. completo da funcionalidade
    (["admin", "superAdmin"].includes(user?.role)
      ? [{ value: "r4010", label: "R-4010 - Pagamentos/créditos a beneficiário pessoa física" }]
      : []),
    { value: "r4020", label: "R-4020 - Pagamentos/créditos a beneficiário pessoa jurídica" },
    { value: "r4099", label: "R-4099 - Fechamento/reabertura dos eventos da série R-4000" },
    { value: "r9000", label: "R-9000 - Exclusão de eventos das séries R-2000 e R-4000" },
  ];
};

export const getEventType = (query) => {
  if (typeof query === "string") {
    const newquery = query.startsWith("R-")
      ? query.split("-").join("").toLowerCase()
      : query.toLowerCase();
    const num = {
      r1000: 0,
      r1050: 1,
      r1070: 2,
      r2010: 3,
      r2020: 4,
      r2070: 5,
      r2098: 6,
      r2099: 7,
      r3010: 8,
      r4010: 9,
      r4020: 10,
      r4040: 11,
      r4080: 12,
      r4099: 13,
      r9000: 14,
      r9001: 15,
      r9005: 16,
      r9011: 17,
      r9015: 18,
    };
    return num[newquery] || null;
  }

  if (typeof query === "number") {
    const event = {
      0: "R-1000",
      1: "R-1050",
      2: "R-1070",
      3: "R-2010",
      4: "R-2020",
      5: "R-2070",
      6: "R-2098",
      7: "R-2099",
      8: "R-3010",
      9: "R-4010",
      10: "R-4020",
      11: "R-4040",
      12: "R-4080",
      13: "R-4099",
      14: "R-9000",
      15: "R-9001",
      16: "R-9005",
      17: "R-9011",
      18: "R-9015",
    };
    return event[query] || null;
  }
};

// para ter o campo de acordo ao int dado pelo back
export const getDocType = (int) => {
  const type = {
    0: "NFSe",
    1: "NFe",
    2: "SIAFI",
    3: "Planilha",
    4: "Recibo",
    98: "Pgto pessoa física",
    99: "Outros",
  };
  return type[int];
};

export const validateDocumentsBasedOnEFDReinfEvent = (event, nfseData) => {
  const requiredFields = {
    r2010: ["amountBC", "amountOperation", "bcINSS"],
    itemsNFse: ["serviceClassificationId", "bc", "amount"],
    r4010: ["dateRegister", "datePayment", "totalAmount", "incomeNatureId", "businessPartnerId"],
    r4020A: ["amountBC", "amountOperation", "dateOfPayment", "incomeNatureId"],
    r4020B: ["amountBC", "amountOperation", "dateOfPayment", "incomeNatureId"],
    r4020C: ["amountBC", "amountOperation", "dateOfPayment", "incomeNatureId", "bcIR"],
    r4020D: ["amountBC", "amountOperation", "dateOfPayment", "incomeNatureId", "bcIR", "bcCSRF"],
  };

  // Função para verificar a presença de campos em objetos aninhados
  const hasRequiredFieldsInItems = (items, requiredFields) => {
    return items.every((item) => requiredFields.every((field) => item[field]));
  };

  const newNfseData = nfseData.map((nfse) => {
    let newNfse = { ...nfse };
    // Verifica os campos de nível superior
    const hasAllRequiredFields = requiredFields[event].every((field) => nfse[field]);

    if (nfse.incomeNatureId === 15768 && nfse.dateOfPayment) {
      return { ...newNfse, isReady: true };
    }

    if (event === "r2010") {
      // Se for o evento r2010, também verifica os campos dentro de itemsNFse
      const itemsNfseValid =
        nfse.itemsNFse && nfse.itemsNFse.length > 0
          ? hasRequiredFieldsInItems(nfse.itemsNFse, requiredFields["itemsNFse"])
          : false;

      if (hasAllRequiredFields && itemsNfseValid) {
        newNfse = { ...newNfse, isReady: true };
      }
    } else if (hasAllRequiredFields) {
      newNfse = { ...newNfse, isReady: true };
    }
    return newNfse;
  });

  return newNfseData;
};

// para calcular retenções em automatico
export const getRetentionTable = () => {
  return [
    {
      code: 6147,
      percentage: 5.85,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 1.2,
    },
    {
      code: 9060,
      percentage: 4.89,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 0.24,
    },
    {
      code: 8739,
      percentage: 1.24,
      PIS: 0.0,
      COFINS: 0.0,
      CSLL: 1.0,
      IR: 0.24,
    },
    {
      code: 8767,
      percentage: 2.2,
      PIS: 0.0,
      COFINS: 0.0,
      CSLL: 1.0,
      IR: 1.2,
    },
    {
      code: 6175,
      percentage: 7.05,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 2.4,
    },
    {
      code: 8850,
      percentage: 3.4,
      PIS: 0.0,
      COFINS: 0.0,
      CSLL: 1.0,
      IR: 2.4,
    },
    {
      code: 8863,
      percentage: 4.65,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 0,
    },
    {
      code: 6188,
      percentage: 7.05,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 2.4,
    },
    {
      code: 6190,
      percentage: 9.45,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 4.8,
    },
  ];
};
