import { styled } from "@mui/material";
import React from "react";

const bgGradientByWindowSizeBlue = {
  1365: "linear-gradient(89.69deg, rgba(27, 42, 74, 0.8) 37.74%, rgba(27, 42, 74, 0) 99.73%);",
  1080: "linear-gradient(89.69deg, rgba(27, 42, 74, 0.8) 42.74%, rgba(27, 42, 74, 0.2) 99.73%);",
  768: "linear-gradient(89.69deg, rgba(27, 42, 74, 0.8) 37.74%, rgba(27, 42, 74, 0.4) 99.73%);",
  default: "rgba(27, 42, 74, 0.8)",
};

export const handleColorByWindowSize = (windowWidth, colorsObj = bgGradientByWindowSizeBlue) => {
  let key = "default";
  if (windowWidth > 768 && windowWidth < 1080) key = 768;
  if (windowWidth > 1080 && windowWidth < 1365) key = 1080;
  if (windowWidth > 1365) key = 1365;
  return colorsObj[key];
};

const Bg = styled("div")(({ color, opacity = 1, mixBlendMode = "normal" }) => ({
  position: "absolute",
  zIndex: 1,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  background: color,
  opacity: opacity,
  mixBlendMode: mixBlendMode,
}));

const ModalBackground = ({ color, opacity, mixBlendMode }) => (
  <Bg color={color} opacity={opacity} mixBlendMode={mixBlendMode} />
);

export default ModalBackground;
