import React, { useEffect, useState } from "react";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import {
  formatDateToBrazilian,
  getDateEnd,
  getDateStart,
} from "~/presentation/views/Reinf/Utils/utilsDate";
import Table from "~/presentation/views/Reinf/components/Table/ReinfTable";
import {
  makeDigitalCertUseCases,
  makeReinfUseCases,
} from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import { reinfPerms } from "~/presentation/security/SecurePaths";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { useDialog } from "~/presentation/providers/DialogProvider";
import { sixMonthsAgo, today } from "~/presentation/utils/constants";
import Filters from "./Filters";
import { Box, IconButton, Tooltip } from "@mui/material";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { Quiz } from "@mui/icons-material";
import { commonColors } from "~/presentation/themes/styles.App";
import { EventDetailsModal } from "../SendEDF/components/SendEvents/components/EventDetailsModal";

function EventsHistory() {
  const apiReinf = makeReinfUseCases();
  const apiDigitalCert = makeDigitalCertUseCases();
  const { showError } = useSnackbar();
  const { openBackdrop } = useBackdrop();
  const { openDialogBox, closeDialog } = useDialog();
  const [eventsData, setEventsData] = useState([]);
  const [certificates, setCertificates] = useState([]);

  const initalFilters = {
    start: sixMonthsAgo,
    end: today,
    certificate: "",
  };

  // Filters
  const [filter, setFilter] = useState(initalFilters);
  const [activeFilters, setActiveFilters] = useState({});

  const handleChangeFilter = (newValues) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newValues,
    }));
  };

  const handleClearFilters = () => setActiveFilters({ start: null, end: null, certificate: "" });

  useEffect(() => {
    const handleSetCertificate = async () => {
      const certs = await getCertificates();
      setCertificates(certs);
      const firstCert = certs[0]?.id || "";
      setActiveFilters({ ...initalFilters, certificate: firstCert });
      handleChangeFilter({ certificate: firstCert });
    };

    handleSetCertificate();
    handleFilters();
  }, []);

  const getCertificates = async () => {
    const CertsData = await apiDigitalCert.getDigitalCerts();
    return CertsData;
  };

  const handleFilters = () => {
    openBackdrop(true, "Aplicando filtros");

    const start = getDateStart(filter?.start);
    const end = getDateEnd(filter?.end);

    const params = {
      start,
      end,
    };

    setActiveFilters(filter);
    setDataIntoState(params);
  };

  const setDataIntoState = async (params) => {
    try {
      openBackdrop(true, "Carregando informação dos eventos");
      const response = await apiReinf.getProtocolHistory(params);
      if (response) {
        const data = response?.reverse(); //para o mais recente ficar em cima na tabela
        setEventsData(data);
      }
    } catch (error) {
      showError(error, "Erro ao carregar eventos");
    } finally {
      openBackdrop(false);
    }
  };

  const handleProtocolConsult = async (protocol) => {
    try {
      openBackdrop(true, "Consultando protocolo");
      const isProduction = protocol.startsWith("1");

      const params = {
        DigitalCertId: filter.certificate,
        ProtocolNumber: protocol,
        IsProduction: isProduction,
      };

      const result = await apiReinf.getReinfConsult(params);
      openSituationModal([result]);
    } catch (error) {
      showError(error, "Erro ao fazer consultar sitaução");
    } finally {
      openBackdrop(false);
    }
  };

  const handleVerifyEvent = ({ reinfEvent, protocol, receiptNumber }) => {
    // R2098, R2099 e R4099 tem protocolo, porém sem recibo -> botão para fazer a consulta da situação do protocolo.
    if (!["R-2098", "R-2099", "R-4099"].includes(reinfEvent)) return "-";
    if (protocol && !receiptNumber) {
      return (
        <SecurityPath securePaths={[reinfPerms.checkProtocol]}>
          <Tooltip
            title={
              !activeFilters.certificate
                ? "Selecione e/ou ative um certificado"
                : "Consultar Situação"
            }
          >
            <Box component="span" sx={{ display: { xs: "none", md: "block" } }}>
              <RButton
                onClick={() => handleProtocolConsult(protocol)}
                disabled={!activeFilters.certificate}
              >
                Consultar situação
              </RButton>
            </Box>
            <Box component="span" sx={{ display: { md: "none" } }}>
              <IconButton
                sx={{ color: commonColors.reinf.primary }}
                onClick={() => handleProtocolConsult(protocol)}
                disabled={!activeFilters.certificate}
              >
                <Quiz />
              </IconButton>
            </Box>
          </Tooltip>
        </SecurityPath>
      );
    } else {
      return "-";
    }
  };

  const openSituationModal = (details) => {
    openDialogBox({
      customComponent: () => <EventDetailsModal onClose={closeDialog} eventDetails={details} />,
    });
  };

  const columns = [
    {
      field: "protocol",
      headerName: "Protocolo",
      align: "center",
      renderCell: ({ value }) => value || "-",
    },
    {
      field: "receiptNumber",
      headerName: "Nº Recibo",
      align: "center",
      renderCell: ({ value }) => value || "-",
      hide: "md",
    },

    {
      field: "sendDate",
      headerName: "Data do envio",
      align: "center",
      renderCell: ({ value }) => formatDateToBrazilian(value) || "-",
      hide: "xs",
    },
    {
      field: "userSend",
      headerName: "Email do usuário",
      align: "center",
      renderCell: ({ value }) => value || "-",
      hide: "lg",
    },
    {
      field: "reinfEvent",
      headerName: "Evento",
      align: "center",
      renderCell: ({ value }) => value || "-",
      hide: "md",
    },
    {
      field: "situation",
      headerName: "Situação",
      align: "center",
      renderCell: ({ value }) => value || "-",
    },
    {
      headerName: "Ações",
      align: "center",
      renderCell: ({ row }) => handleVerifyEvent(row),
    },
  ];

  return (
    <>
      <HeaderSectionPage title="Histórico de Eventos" />
      <Filters
        onClearFilters={handleClearFilters}
        filter={filter}
        onChangeFilter={handleChangeFilter}
        onFilters={handleFilters}
        certificates={certificates}
        activeFilters={activeFilters}
      />
      <Table data={eventsData} columns={columns} />
    </>
  );
}

export default EventsHistory;
