import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, CircularProgress, Container } from "@mui/material";
import BlogLayout from "~/presentation/layouts/BlogLayout";
import MoreContentSection from "~/presentation/views/Blog/BlogHome/Sections/MoreContent";
import blogNavbarItems from "~/presentation/views/Blog/data/blogNavbarItems";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { usePaginatedState } from "~/presentation/hooks/usePaginatedState";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
import Categories from "../components/Categories/Categories";

const DEFAULT_IMAGE = "/assets/bg-contentblog.png";

const BlogHome = ({ blogUseCases, blogCategoryUseCases }) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const categoryId = query.get("categoryId");
  const [blogCategories, setBlogCategories] = useState();

  const getBlogCategories = async () => {
    setBlogCategories(await blogCategoryUseCases.getBlogCategories({ checkPermission: false }));
  };

  const handleNavigateToPost = (id) => {
    navigate("/cliente/blog/post/" + id);
  };

  const handleSelectCategory = (categoryId) => {
    if (categoryId) {
      handleFilterBycategory(categoryId);
    } else {
      handleSearch();
    }
  };

  useEffect(() => {
    getBlogCategories();
  }, []);

  const getContent = (queryParams) => blogUseCases.getBlogPosts(queryParams);

  const {
    handleSearch,
    handlePage,
    numberOfPages,
    isLoading,
    content,
    page,
    handleFilterBycategory,
    clearCategory,
  } = usePaginatedState({
    getData: getContent,
    itemsPerPage: 9,
  });

  return (
    <BlogLayout
      className="blog"
      navbarItems={blogNavbarItems}
      loggedNavbarItems={clientNavbarItems()}
    >
      {() => (
        <>
          <SectionTitlePost
            redirectURL="/cliente/blog"
            title="BLOG"
            onSearch={handleSearch}
            image={"/assets/multimedia-banner.png"}
            searchButtonBg="#ff6829"
            blur={0.1}
          />
          {isLoading ? (
            <Box sx={styles.loading}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <Container maxWidth="lg">
              <Categories
                blogCategories={blogCategories}
                categoryId={categoryId}
                handleSelectCategory={handleSelectCategory}
                clearCategory={clearCategory}
              />
              <Box sx={styles.content}>
                {isLoading ? (
                  <Box sx={styles.loading}>
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  <MoreContentSection
                    blogPosts={content ? content : []}
                    goToPost={(id) => handleNavigateToPost(id)}
                    defaultImage={DEFAULT_IMAGE}
                    numberOfPages={numberOfPages}
                    goToPage={(value) => handlePage(value, categoryId)}
                    page={page}
                  />
                )}
              </Box>
            </Container>
          )}
        </>
      )}
    </BlogLayout>
  );
};

export default BlogHome;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "auto",
  },
  loading: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
  },
};
