import * as Yup from 'yup';
import { validarEmail } from '~/presentation/functions/emailValidator';
import { validarTelefone } from '~/presentation/functions/phoneValidator';
import removeMask from '~/presentation/functions/removeMask';
import {validarCPF} from "~/presentation/functions/cpfValidator";

export default Yup.object({
    zipCode: Yup.string()
        .transform((value, originalValue) => originalValue && removeMask(value))
        .min(8, "Digite um CEP válido!")
        .required("Campo obrigatório!"),
    complement: Yup.string().required('Campo Obrigatório!'),
    contactName: Yup.string().typeError('Campo obrigatório!')
        .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,"O nome só pode contar letras.")
        .required("Campo obrigatório"),
    contactCpf: Yup.string()
      .typeError('Campo obrigatório!')
      .test("contactCpf", "CPF inválido!", value => value && validarCPF(value))
      .required("Campo obrigatório!"),
    email: Yup.string()
        .test("email", "Email inválido!", value => validarEmail(value))
        .required("Campo obrigatório!"),
    address: Yup.string("Campo obrigatório!").required('Campo Obrigatório!'),
    homePhone: Yup.string("Campo obrigatório!")
        .transform((value, originalValue) => originalValue && removeMask(value))
        .min(10, "Digite um número válido").test("phone", "Telefone inválido!", value => value && validarTelefone(value))
        .required("Campo obrigatório!"),
    mobilePhone: Yup.string("Campo obrigatório!")
        .transform((value, originalValue) => originalValue && removeMask(value))
        .min(11, "Digite um número válido"),
    isECD: Yup.boolean().required('Campo Obrigatório!'),
    isExemptionFrom: Yup.boolean().required('Campo Obrigatório!'),
    isExemptionAgreement: Yup.boolean().required('Campo Obrigatório!'),
    isUnion: Yup.boolean().required('Campo Obrigatório!'),
    isEFR: Yup.boolean().required('Campo Obrigatório!'),
    //cnpjEFR: Yup.string().typeError('Campo obrigatório!')
        // .test("cnpjEFR", "CNPJ inválido!", value => validarCNPJ(value))
        //.required("Campo obrigatório!"),
});