import React from "react";
import { Box, Typography } from "@mui/material";
import { useSize } from "~/presentation/hooks/useSize";
import Slide from "~/presentation/components/Animation/Slide/Slide";

const About = ({ id }) => {
  const { isUpBreakPoint } = useSize();
  const up960 = isUpBreakPoint("960px");

  return (
    <Box sx={styles.outerContainer} id={id}>
      <Box sx={styles.container} id={id}>
        <Box sx={styles.content}>
          <Slide
            direction="up"
            style={{
              flex: 1,
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={styles.videoContainer}
              component="iframe"
              src="https://player.vimeo.com/video/478875163?title=0&byline=0&portrait=0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></Box>
          </Slide>
          {up960 && (
            <Slide
              direction="up"
              delay="0.2s"
              style={{
                flex: 1,
              }}
            >
              <Box sx={styles.textWrapper}>
                <Typography sx={styles.subtitle}>APRESENTAÇÃO</Typography>
                <Typography sx={styles.title}>Gestão Tributária</Typography>
                <Typography sx={styles.text}>
                  O Gestão Tributária é um sistema <i>web</i> que surgiu no intuito de auxiliar
                  entidades públicas e privadas na apuração dos principais tributos incidentes sobre
                  a contratação de terceiros (pessoas físicas e jurídicas). Nossa solução é focada
                  nas retenções e encargos tributários relativos ao INSS, IRRF, CSLL, PIS/Pasep,
                  Cofins e ISS.
                </Typography>
              </Box>
            </Slide>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default About;

const styles = {
  outerContainer: {
    position: "relative",
    backgroundImage: 'url("/assets/maze5.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    padding: "0 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "-100px",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  container: {
    width: "100%",
    maxWidth: "1400px",
    p: 0,
    background: "white",
    margin: "0 auto",
    zIndex: 1,
  },
  content: {
    boxShadow: "-20px -19px 0px 0px #14213D",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    p: 3,
    height: "400px",
  },
  videoContainer: {
    flex: "1",
    height: "100%",
    border: 0,
    width: "100%",
  },
  textWrapper: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 30px",
  },
  subtitle: {
    fontFamily: "Bebas Neue",
    fontSize: 27,
    color: "#052a48",
    lineHeight: 1,
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 38,
    fontWeight: 700,
    color: "#4c4c4c",
    lineHeight: 1.6,
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 18,
    lineHeight: 1.5,
  },
};
