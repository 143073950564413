import React, { useEffect } from "react";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import CalculateRetentions from "~/presentation/views/Reinf/components/Layouts/nfseLayout/CalculateRetentions/CalculateRetentions";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Nota from "~/presentation/views/Reinf/components/Layouts/nfseLayout/Fields/Nota/Nota";
import INSS from "~/presentation/views/Reinf/components/Layouts/nfseLayout/Fields/INSS/INSS";
import IR from "~/presentation/views/Reinf/components/Layouts/nfseLayout/Fields/IR/IR";
import CSRF from "~/presentation/views/Reinf/components/Layouts/nfseLayout/Fields/CSRF/CSRF";
import IRPJ from "~/presentation/views/Reinf/components/Layouts/nfseLayout/Fields/IRPJ/IRPJ";
import { Box, Typography } from "@mui/material";
import {
  validationSendR2010,
  validationSendR4020AB,
  validationSendR4020C,
  validationSendR4020D,
} from "~/presentation/views/Reinf/components/Layouts/nfseLayout/LogicAndValidation/validationSchema";
import useHandleDocFiscais from "~/presentation/views/Reinf/Hooks/useHandleDocFiscais";
import { reinfPerms } from "~/presentation/security/SecurePaths";
import { useDialog } from "~/presentation/providers/DialogProvider";

function LogicAndValidation({
  values,
  onSubmit,
  serviceClassificationOptions,
  incomeNatureOptions,
  businessPartnerOptions,
  handleChangeFieldValue,
  handleChangeINSSfield,
  inputDisabled,
  selectedEvent,
  errors,
  setErrors,
  isEventClosed,
  isProtocolsTable,
}) {
  const { showError } = useSnackbar();
  const { openBackdrop } = useBackdrop();
  const { openDialogBox, closeDialog } = useDialog();
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const QSOAB = QSO === "A" || QSO === "B";
  const QSOC = QSO === "C";
  const QSOD = QSO === "D";
  const R2010 = selectedEvent === "r2010";
  const R4020 = selectedEvent === "r4020";
  const R4020AB = selectedEvent === "r4020" && QSOAB;
  const R4020C = selectedEvent === "r4020" && QSOC;
  const R4020D = selectedEvent === "r4020" && QSOD;

  const {
    newItemsNFseObject,
    calculateAliquotIR,
    calculateAliquotCSRF,
    calculateAmoutCSRF,
    calculateNewBcINSS,
    calculateNewAmountINSS,
  } = useHandleDocFiscais();

  //se é nota de produto NFe
  const isNFe = values?.typeDoc === 1;

  const onChangeById = ({ newItem, byPass = false, field = null }) => {
    handleChangeFieldValue({
      newItem: newItem,
      id: values.id,
      field: field,
      byPass: byPass,
    });
  };

  const onChangeINSSById = ({ newItem, nestedIndex, byPass = false, field = null }) => {
    handleChangeINSSfield({
      newItem: newItem,
      id: values.id,
      nestedIndex: nestedIndex,
      field: field,
      byPass: byPass,
    });
  };

  //Valores totais de itemsNFse.bc
  const allBcTypeService = values?.itemsNFse?.reduce((total, curr) => total + curr.bc, 0);

  //Valores totais de itemsNFse.amout
  const allAmountTypeService = values?.itemsNFse?.reduce((total, curr) => total + curr.amount, 0);

  useEffect(() => {
    onChangeById({
      newItem: {
        bcINSS: calculateNewBcINSS(allBcTypeService, values.bcINSS),
        amountINSS: calculateNewAmountINSS(allAmountTypeService, values.amountINSS),
        aliquotINSS: values?.isCPRB ? 3.5 : 11,
        amountCSRF: calculateAmoutCSRF(values.amountCSLL, values.amountPIS, values.amountCOFINS),
        aliquotCSRF: calculateAliquotCSRF(values.bcCSRF, values.amountCSRF, values.aliquotCSRF),
        aliquotIR: calculateAliquotIR(
          values.amountIR,
          values.bcIR,
          values.amountAggregate,
          values.amountBC,
          values.aliquotIR
        ),
      },
      byPass: true, //para não contar como interação na nota
    });
  }, [
    allBcTypeService,
    allAmountTypeService,
    values?.amountCSLL,
    values?.amountPIS,
    values?.amountCOFINS,
    values?.amountCSRF,
    values?.bcCSRF,
    values?.isCPRB,
    values?.amountIR,
    values?.bcIR,
    values?.amountAggregate,
    values?.amountBC,
  ]);

  //ERRO: Data no futuro
  const isDateInTheFuture = new Date(values?.dateOfPayment) > new Date();

  //ERRO: desconto incondiconal > do que valor bruto
  const isUnconditionalDiscountWrong = values?.unconditionalDiscount > values?.amountService;

  //ERRO: amountBC tem de ser > do que qualquer base de cálculo
  const isBcINSSWrong = values?.bcINSS > values?.amountBC;
  const isBcIRWrong = values?.bcIR > values?.amountBC;
  const isBcSCRFWrong = values?.bcCSRF > values?.amountBC;

  const throwError = (message) => {
    showError({}, message);
  };

  const handleValidationErrors = (errors) => {
    setErrors(
      errors.inner.reduce((acc, error) => {
        acc[`[${values?.id}].${error.path}`] = error.message;
        return acc;
      }, {})
    );
    throwError("Erros nos campos, corrija para poder enviar!");
    openBackdrop(false);
  };

  const processSubmit = async (values, validationSchema) => {
    openBackdrop(true, "Verificando dados");
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        const isItemsNFseNull =
          values?.itemsNFse.length == 1 &&
          JSON.stringify(values?.itemsNFse) === JSON.stringify([newItemsNFseObject]);

        const data = isItemsNFseNull
          ? {
              ...values,
              itemsNFse: null,
            }
          : values;
        setErrors({});
        onSubmit(data);
      })
      .catch((errors) => {
        handleValidationErrors(errors);
      });
  };

  const handleSubmit = async (values) => {
    //TRATAMENTO DE ERROS:

    if (isUnconditionalDiscountWrong) {
      return throwError("O Desc. Incondicional não pode ser maior do que o Valor Bruto da Nota!");
    }

    if (!R2010) {
      if (isDateInTheFuture) {
        return throwError("Escolha uma data no presente");
      }
    }

    if (!R4020) {
      if (isBcINSSWrong) {
        return throwError(
          "Valor principal da base INSS não pode ser maior do que o Valor de Operação!"
        );
      }
    }

    if (QSOC || QSOD) {
      if (isBcIRWrong) {
        return throwError(
          "Valor da base IR não pode ser maior do que o Valor de Operação do documento!"
        );
      }
    }

    if (QSOD) {
      if (isBcSCRFWrong) {
        return throwError(
          "Valor da base CSRF não pode ser maior do que o Valor de Operação do documento!"
        );
      }
    }

    //Submeter nfse -> tabela Corrigir e Enviar SendReinf
    if (R2010) {
      return processSubmit(values, validationSendR2010);
    }

    if (R4020AB) {
      return processSubmit(values, validationSendR4020AB);
    }

    if (R4020C) {
      return processSubmit(values, validationSendR4020C);
    }

    if (R4020D) {
      return processSubmit(values, validationSendR4020D);
    }

    //Caso itemsNFse não tenha nenhum campo preenchido tem de ir null para o back
    const isItemsNFseNull =
      values?.itemsNFse.length == 1 &&
      JSON.stringify(values?.itemsNFse) === JSON.stringify([newItemsNFseObject]);

    const data = isItemsNFseNull ? { ...values, itemsNFse: null } : values;

    //Submeter nfse -> tabela Doc. Fiscais
    onSubmit(data);
    setErrors({});
  };

  const handleOpenDialog = () => {
    if (!values.bcIR > 0) {
      return throwError("Precisa da atribuir um valor à Base de Cálculo para efetuar o cálculo.");
    }
    if (!values?.amountBC > 0) {
      return throwError(
        "Precisa da atribuir um valor ao Valor Total da Operação para efetuar o cálculo."
      );
    }
    openDialogBox({
      customComponent: () => (
        <CalculateRetentions
          onClose={closeDialog}
          handleChangeFieldValue={onChangeById}
          values={values}
        />
      ),
    });
  };

  const modalCalculateRetention = (
    <Box sx={{ marginBottom: 2 }}>
      <RButton
        sx={{ boxShadow: 0, borderRadius: "8px", width: "fit-content" }}
        onClick={handleOpenDialog}
      >
        Calcular Retenções
      </RButton>
    </Box>
  );

  const docOriginAndSaveButton = (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "start", sm: "center" },
        justifyContent: { sm: "space-between" },
        marginTop: { sm: 2 },
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
        {[
          { label: "Origem", value: values?.origin },
          { label: "Situação", value: values?.situation },
        ].map((i, index) => (
          <Typography key={index} sx={{ fontSize: 14, fontFamily: "Montserrat", color: "#1341A1" }}>
            <strong>{i.label}: </strong>
            {i.value}
          </Typography>
        ))}
      </Box>
      {!isProtocolsTable && (
        <RButton
          sx={{ width: { xs: "100%", sm: "180px" }, boxShadow: 0 }}
          onClick={() => {
            handleSubmit(values, selectedEvent);
          }}
          disabled={isEventClosed}
          securePaths={[reinfPerms.editFiscalDocument]}
        >
          SALVAR
        </RButton>
      )}
    </Box>
  );

  const compNota = (
    <Nota
      values={values}
      businessPartnerOptions={businessPartnerOptions}
      errors={errors}
      handleChangeFieldValue={onChangeById}
      inputDisabled={inputDisabled}
      isUnconditionalDiscountWrong={isUnconditionalDiscountWrong}
    />
  );

  const compINSS = (
    <INSS
      values={values}
      serviceClassificationOptions={serviceClassificationOptions}
      errors={errors}
      handleChangeFieldValue={onChangeById}
      handleChangeINSSfield={onChangeINSSById}
      inputDisabled={inputDisabled}
      isBcINSSWrong={isBcINSSWrong}
    />
  );

  const compIR = (
    <IR
      values={values}
      incomeNatureOptions={incomeNatureOptions}
      errors={errors}
      handleChangeFieldValue={onChangeById}
      isBcIRWrong={isBcIRWrong}
      inputDisabled={inputDisabled}
      modalCalculateRetention={modalCalculateRetention}
    />
  );

  const compCSRF = (
    <CSRF
      values={values}
      handleChangeFieldValue={onChangeById}
      incomeNatureOptions={incomeNatureOptions}
      errors={errors}
      isBcSCRFWrong={isBcSCRFWrong}
      inputDisabled={inputDisabled}
    />
  );

  const compIRPJ = (
    <IRPJ
      values={values}
      handleChangeFieldValue={onChangeById}
      incomeNatureOptions={incomeNatureOptions}
      errors={errors}
      inputDisabled={inputDisabled}
      modalCalculateRetention={modalCalculateRetention}
    />
  );

  //Tela envio evento R2010

  if (R2010) {
    return (
      <>
        {compNota}
        {compINSS}
        {docOriginAndSaveButton}
      </>
    );
  }

  //Tela envio evento R4020

  if (R4020AB) {
    return (
      <>
        {compNota}
        {compIRPJ}
        {docOriginAndSaveButton}
      </>
    );
  }

  if (R4020C) {
    return (
      <>
        {compNota}
        {compIR}
        {docOriginAndSaveButton}
      </>
    );
  }

  if (R4020D) {
    return (
      <>
        {compNota}
        {compIR}
        {compCSRF}
        {docOriginAndSaveButton}
      </>
    );
  }

  //Tabela Importação Documentos Fiscais

  if (QSOAB) {
    return (
      <>
        {compNota}
        {!isNFe && compINSS}
        {compIRPJ}
        {docOriginAndSaveButton}
      </>
    );
  }

  if (QSOC) {
    return (
      <>
        {compNota}
        {!isNFe && compINSS}
        {compIR}
        {docOriginAndSaveButton}
      </>
    );
  }

  if (QSOD) {
    return (
      <>
        {compNota}
        {compINSS}
        {compIR}
        {compCSRF}
        {docOriginAndSaveButton}
      </>
    );
  }

  return (
    <Typography sx={{ fontSize: 14, fontFamily: "Montserrat", color: "#d32f2f", fontWeight: 700 }}>
      Erro ao carregar a linha QSO. Recarregue a página!
    </Typography>
  );
}

export default LogicAndValidation;
