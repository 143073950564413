import React from "react";
import { useDialog } from "~/presentation/providers/DialogProvider";
import { formatDateToISO } from "~/presentation/views/Reinf/Utils/utilsDate";
import { EventDetailsModal } from "./components/EventDetailsModal";
import { Box } from "@mui/material";
import SendNfseEvents from "~/presentation/views/Reinf/SendEDF/components/SendEvents/EventsForm/SendNfseEvents";
import SendOpenCloseEvents from "~/presentation/views/Reinf/SendEDF/components/SendEvents/EventsForm/SendOpenCloseEvents";
import SendR9000 from "~/presentation/views/Reinf/SendEDF/components/SendEvents/EventsForm/SendR9000";
import SendR2099 from "./EventsForm/SendR2099";

function SendEvents({
  data = [],
  operation = 1,
  filter = {},
  certificates = [],
  receiptNumber = "",
  refreshData = () => {},
  closeModal = () => {},
  handleVerifyEventStatus = () => {},
}) {
  const { companyId, companyInfor } = JSON.parse(localStorage.getItem("GT_user"));
  const { openSecondDialog, closeSecondDialog } = useDialog();

  const { event, certificate, month, year } = filter;
  const eventName = `R-${event?.split("r")[1]}`;

  const certificateObj = certificates.find((cert) => cert.id === certificate);
  const competence = formatDateToISO(year, month);

  const isProduction = true;

  const openDetailsModal = (responseDetails) => {
    openSecondDialog({
      fullWidth: true,
      maxWidth: "sm",
      customComponent: () => (
        <EventDetailsModal
          onClose={() => {
            closeSecondDialog();
            closeModal();
            if (["r2098", "r4099"].includes(event)) return;
            refreshData();
          }}
          eventDetails={responseDetails}
        />
      ),
    });
  };

  const renderModalByEvent = () => {
    if (["r2010", "r4020", "r4010"].includes(event)) {
      return (
        <SendNfseEvents
          event={event}
          companyId={companyId}
          companyInfor={companyInfor}
          data={data}
          certificateObj={certificateObj}
          operation={operation}
          receiptNumber={receiptNumber}
          isProduction={isProduction}
          openDetailsModal={openDetailsModal}
          closeModal={closeModal}
          competence={competence}
          month={month}
          year={year}
          eventName={eventName}
        />
      );
    }
    if (["r2098", "r4099"].includes(event)) {
      return (
        <SendOpenCloseEvents
          certificateObj={certificateObj}
          companyId={companyId}
          companyInfor={companyInfor}
          competence={competence}
          closeModal={closeModal}
          openDetailsModal={openDetailsModal}
          isProduction={isProduction}
          event={event}
          eventName={eventName}
        />
      );
    }
    if (event === "r9000") {
      return (
        <SendR9000
          docs={data}
          certificateObj={certificateObj}
          companyId={companyId}
          companyInfor={companyInfor}
          competence={competence}
          year={year}
          month={month}
          closeModal={closeModal}
          openDetailsModal={openDetailsModal}
          isProduction={isProduction}
        />
      );
    }
    if (event === "r2099") {
      return (
        <SendR2099
          data={data}
          competence={competence}
          companyId={companyId}
          companyInfor={companyInfor}
          certificateObj={certificateObj}
          isProduction={isProduction}
          closeModal={closeModal}
          openDetailsModal={openDetailsModal}
          handleVerifyEventStatus={handleVerifyEventStatus}
        />
      );
    }
    return <p>Nenhum evento selecionado.</p>;
  };

  return <Box sx={styles.Modal}>{renderModalByEvent()}</Box>;
}

export default SendEvents;

const styles = {
  Modal: {
    p: 4,
    backgroundColor: "#e6e6e6",
  },
};
