import React from "react";
import { format } from "date-fns";
import { Box, Typography as Text } from "@mui/material";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";

const CommentsSection = ({ handleComment, comments = [] }) => {
  const { setFeedbackSnackbar } = React.useContext(SnackbarContext);
  const [message, setMessage] = React.useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSubmit = () => {
    if (message.trim() !== "") {
      handleComment(message);
      setMessage("");
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Ops, você esqueceu de escrever seu comentário!",
        type: "error",
      });
    }
  };

  const formatDate = (date) => {
    let formattedDate = new Date(date);
    formattedDate = format(formattedDate, "dd/MM/yyyy");
    return formattedDate;
  };

  return (
    <Box>
      <Box sx={{ width: "100%", p: 0, m: 0 }}>
        <Input
          type="textarea"
          minRows={4}
          value={message}
          styles={{ border: "none", backgroundColor: "#f1f1f1" }}
          onChange={handleChange}
          placeholder="Deixe seu comentário aqui..."
          required
        />
        <ButtonCommon sx={styles.button} onClick={handleSubmit}>
          ENVIAR COMENTÁRIO
        </ButtonCommon>
      </Box>
      <Box sx={{ display: "grid", gap: "20px" }}>
        {comments ?? <Text sx={styles.title}>Comentários</Text>}
        {!!comments && <pre>{JSON.stringify(comments, undefined, 2)}</pre> &&
          comments?.map((thread) => (
            <>
              <Box key={"thread-" + thread.id}>
                <Box
                  sx={{ background: "#E1E1E1", padding: 2, borderRadius: 2 }}
                  index={thread.id}
                  key={"message-" + thread.id}
                >
                  <Text sx={[styles.commentTitle, styles.montserrat]}>
                    {thread.userComment.email}
                  </Text>
                  <Text sx={[styles.commentText, styles.montserrat]}>{thread.commentText}</Text>
                  <Text sx={[styles.commentDate, styles.montserrat]}>
                    {thread?.publishDate && `Comentário feito em ${formatDate(thread.publishDate)}`}
                  </Text>
                </Box>
              </Box>
            </>
          ))}
      </Box>
    </Box>
  );
};
const styles = {
  montserrat: { fontFamily: "Montserrat" },
  title: {
    color: "#1F1F1F",
    fontSize: 30,
    fontFamily: "Montserrat",
    fontWeight: 500,
    textTransform: "uppercase",
    marginTop: 4,
    marginBottom: 0,
  },
  subtitle: {
    color: "#000",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "Montserrat",
    marginTop: 2,
    marginBottom: 1,
  },
  contentText: {
    fontFamily: "Montserrat",
    fontSize: 16,
    lineHeight: 1.6,
  },
  button: {
    backgroundColor: "#031a6d",
    width: "min(300px, 90%)",
    fontFamily: "Montserrat",
    fontSize: 16,
    color: "#fff",
    padding: 2,
    marginBlock: 2,
    "&:hover": {
      backgroundColor: "#081445",
    },
  },
  commentTitle: {
    color: "1F1F1F",
    fontWeight: 500,
    fontSize: 18,
    mb: 0.5,
  },
  commentText: {
    color: "#787878",
    fontSize: 16,
  },
  commentDate: {
    fontSize: 14,
    color: "#787878",
    mt: 1,
  },
};

export default CommentsSection;
