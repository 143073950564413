import { Company } from "~/domain/entities/company";
import { CompanyCategory } from "~/domain/entities/company-categoriy";
import { apiURL } from "../../usecases/api";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { adminPerms, reinfPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";

export class ApiCompanyUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getCompanies({ isSummary = false, checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeCompanies])) {
        return new Error(securityErrorMessage("visualizar empresas."));
      }
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Company?isSummary=${isSummary}`,
        method: "GET",
      });
      response.data.forEach((company) => {
        company.categoryName = company.category ? company.category.name : "sem categoria";
        company.planName = company.plan ? company.plan.name : "Sem plano";
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteCompany(id) {
    if (!hasPermission([adminPerms.deleteCompanies])) {
      throw new Error(securityErrorMessage("excluir empresa"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Company/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getCompanyById({ id = id, checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeCompanies, reinfPerms.seeCompany])) {
        throw new Error(securityErrorMessage("visualizar empresa"));
      }
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Company/${id}`,
        method: "GET",
      });
      let { data } = response;
      return new Company({
        id: data.id,
        active: data.active,
        address: data.address,
        category: new CompanyCategory(data.category.id, data.category.name),
        zipCode: data.zipCode,
        city: data.city,
        cnpj: data.cnpj,
        complement: data.complement,
        email: data.email,
        endDate: new Date(data.dateEnd),
        homePhone: data.homePhone,
        mobilePhone: data.mobilePhone,
        name: data.name,
        plan: data.plan,
        startDate: new Date(data.dateStart),
        dateCompanyRegistered: data.dateCompanyRegistered,
        state: data.state,
        status: data.status,
        contactName: data.contactName,
        contactCpf: data.contactCpf,
        //reinf
        totalSubstituteOrDismissed: data.totalSubstituteOrDismissed,
        legalNature: data.legalNature,
        isECD: data.isECD,
        isExemptionFrom: data.isExemptionFrom,
        isExemptionAgreement: data.isExemptionAgreement,
        isUnion: data.isUnion,
        efr: data.efr,
        cnpjEFR: data.cnpjEFR,
        taxClassification: data.taxClassification,
        codeUG: data.codeUG,
      });
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async register(data) {
    if (!hasPermission([adminPerms.addCompanies])) {
      throw new Error(securityErrorMessage("criar empresa"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Company/`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async update({ data, checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.editCompanies, reinfPerms.editCompany])) {
        throw new Error(securityErrorMessage("editar empresa"));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Company/`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getPlanDetails(companyId) {
    try {
      let company = await this.getCompanyById({ id: companyId, checkPermission: true });
      return company.plan;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getCompanyCategories({ checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeCompanyCategory])) {
        throw new Error(securityErrorMessage("visualizar categorias de empresa"));
      }
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Category`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getCompanyCategoryById({ id, checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeCompanyCategory])) {
        throw new Error(securityErrorMessage("visualizar categoria de empresa"));
      }
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Category/${id}`,
        method: "GET",
      });
      return new CompanyCategory(response.data.id, response.data.name);
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async registerCompanyCategory(data) {
    if (!hasPermission([adminPerms.addCompanyCategory])) {
      throw new Error(securityErrorMessage("adicionar categoria de empresa"));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Category/`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateCompanyCategory(data) {
    if (!hasPermission([adminPerms.editCompanyCategory])) {
      throw new Error(securityErrorMessage("editar categoria de empresa"));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Category/`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteCompanyCategoryById(id) {
    if (!hasPermission([adminPerms.deleteCompanyCategory])) {
      throw new Error(securityErrorMessage("excluir categoria de empresa"));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Category/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
