import { useContext, useState } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";

export const useUploadXml = () => {
  const apiNfseUpload = new ApiNfseUseCases(makeHttpClient());
  const [files, setFiles] = useState([]);
  const { openBackdrop } = useContext(BackdropContext);

  const handleNOTAS = async (event) => {
    const fileList = event.target.files;
    const filesWithStatus = Array.from(fileList).map((file) => ({
      file,
      name: file.name,
      loading: true,
      success: undefined,
      description: "",
      errorMsg: "",
      message: "",
    }));
    setFiles(filesWithStatus);

    for (let fileWithStatus of filesWithStatus) {
      try {
        const result = await apiNfseUpload.uploadNfse(fileWithStatus.file);
        fileWithStatus.message = result;
        fileWithStatus.success = true;
      } catch (error) {
        fileWithStatus.success = false;
        fileWithStatus.errorMsg = error.response?.data || "Erro na importação";
      } finally {
        fileWithStatus.loading = false;
        setFiles([...filesWithStatus]);
      }
    }
  };

  const handleSIAFI = async ({ xml, xlsx, dateI, dateF, isDDF025, isDDF021 }) => {
    openBackdrop(true, "Importando ficheiros");
    let upload = {
      fileXML: xml,
      fileXLSL: xlsx,
      initialDate: dateI,
      finalDate: dateF,
      message: "",
      loading: true,
    };

    try {
      const result = await apiNfseUpload.uploadSIAFI({
        fileXML: xml,
        file: xlsx,
        dateI: dateI,
        dateF: dateF,
        isDDF025: isDDF025,
        isDDF021: isDDF021,
      });
      upload.message = result;
    } catch (error) {
      upload.message =
        error.response?.data ||
        "Erro na importação: Desculpe, mas não conseguimos fazer a leitura dos arquivos informados.";
    } finally {
      upload.loading = false;
      setFiles([{ ...upload }]);
      openBackdrop(false);
    }
  };

  const handleSIAFIOB = async (xlsx) => {
    openBackdrop(true, "Importando ficheiros");
    let upload = {
      fileXLSX: xlsx,
    };
    try {
      await apiNfseUpload.uploadSIAFIOB(xlsx);
      upload.message = "Arquivo importado com sucesso!";
    } catch (error) {
      upload.message = error.response?.data || "Erro na importação";
    } finally {
      setFiles([{ ...upload }]);
      openBackdrop(false);
    }
  };

  const handleSHEET = async (importedFile, isReplace) => {
    const file = {
      importedFile,
      name: importedFile.name,
      loading: true,
      success: undefined,
      description: null,
      errorMsg: "",
    };
    setFiles([file]);
    try {
      const result = await apiNfseUpload.uploadSheet(importedFile, isReplace);
      file.description = result;
      file.success = true;
    } catch (error) {
      file.success = false;
      file.errorMsg = error.response?.data || "Erro na importação";
    } finally {
      file.loading = false;
      setFiles([file]);
    }
  };

  return {
    handleNOTAS,
    handleSHEET,
    handleSIAFI,
    handleSIAFIOB,
    files,
    setFiles,
  };
};
