import * as Yup from "yup";
import {
  validationSendR2010,
  validationSendR4020AB,
  validationSendR4020C,
  validationSendR4020D,
} from "~/presentation/views/Reinf/components/Layouts/nfseLayout/LogicAndValidation/validationSchema";

export const validationR2010 = Yup.array().of(validationSendR2010);
export const validationR4020AB = Yup.array().of(validationSendR4020AB);
export const validationR4020C = Yup.array().of(validationSendR4020C);
export const validationR4020D = Yup.array().of(validationSendR4020D);
