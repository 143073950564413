import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import ItemAnswerForm from "./Form";
import { makeItemAnswerUseCases } from "~/main/factories/use-cases/item-answer";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";

function CreateEditType({
  data,
  onCloseForm,
  refreshData,
  fetchOptionsData = () => {},
  typesAnswer,
}) {
  const itemAnswerUseCases = makeItemAnswerUseCases();
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const isCreate = !data;

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    id: 0,
    title: "",
    text: "",
    gtAnswerItemType: {},
  });

  useEffect(() => {
    if (typesAnswer.length === 0) {
      fetchOptionsData();
    }
  }, []);

  useEffect(() => {
    if (data?.id) {
      setValues(data);
    }
  }, [data]);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} serviço`);

    try {
      if (isCreate) {
        await itemAnswerUseCases.create(values);
      } else {
        await itemAnswerUseCases.update(values);
      }
      showSuccess(`Item de resposta ${isCreate ? "criada" : "atualizada"} com sucesso!`);
      onCloseForm();
      await refreshData();
    } catch (error) {
      showError(error, `Erro ao ${isCreate ? "criar" : "editar"} item de resposta`);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} item de resposta`}
        handleBack={onCloseForm}
      />
      {values && (
        <ItemAnswerForm
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
          typesAnswer={typesAnswer}
          getTypesAnswer={fetchOptionsData}
        />
      )}
    </>
  );
}

export default CreateEditType;
