import React, { useContext, useEffect, useState } from "react";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import PageListHeader from "~/presentation/views/Admin/components/PageListHeader";
import validationSchema from "./validationSchema";
import TextEditor from "~/presentation/components/TextEditor";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import TableActions from "~/presentation/components/TableActions";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import CreateEdit from "~/presentation/views/Admin/TypeAnswer/CreateEdit/CreateEdit";
import Grid from "@mui/material/Grid2";
import { randomNineDigits } from "~/presentation/utils/randomNumber";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import AdminTable from "~/presentation/views/Admin/components/AdminTable/AdminTable";

function Item({ onClose, item, itemAnswers, handleAddNewItem, groups, fetchTypeAnswer }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { closeSecondDialog, openSecondDialog } = useContext(DialogContext);

  const [values, setValues] = useState({
    id: randomNineDigits(), //precisa de criar um random Id para a tabela mostrar os items corretamente
    isDefault: false,
    blockCode: "",
    groups: [],
    servicesCode: "",
    itemTypeId: 0,
    itemTypeName: "",
    text: "",
  });

  const [errors, setErrors] = useState({});

  const isCreate = !item;

  useEffect(() => {
    if (item?.id >= 0) {
      setValues(item);
    }
  }, [item]);

  const handleChangeItemValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = () => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        handleAddNewItem(values, isCreate);
        setErrors({});
        onClose();
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleDelete = (id) => {
    if (values?.groups?.length === 1) {
      handleChangeItemValue("groups", []);
    } else {
      const filtedValue = values?.groups?.filter((item) => item.id !== id);
      handleChangeItemValue("groups", filtedValue);
    }
  };

  const handleOpenModal = (type) => {
    openSecondDialog({
      maxWidth: "xs",
      fullWidth: true,
      customComponent: () => {
        switch (type) {
          case "typeAnswer":
            return (
              <CreateEdit
                data={null}
                onCloseForm={closeSecondDialog}
                refreshData={fetchTypeAnswer}
              />
            );
          case "group":
            return (
              <ListComponent
                data={groups}
                checkedData={values?.groups}
                title="Grupos"
                onClose={closeSecondDialog}
                handleAdd={(values) => handleChangeItemValue("groups", values)}
                setHeightAuto
              />
            );
          default:
            return null;
        }
      },
    });
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} Item de Resposta`}
        handleBack={onClose}
      />
      {values && (
        <>
          <Box sx={formStyles.container}>
            <Grid container spacing={1}>
              <Grid size={{ xs: 12, md: 3 }} sx={formStyles.radioContainer}>
                <Typography sx={formStyles.subtitle}>Padrão</Typography>
                <FormControl sx={formStyles.controlLabel}>
                  <RadioGroup
                    name="isDefault"
                    value={values.isDefault}
                    row
                    onChange={(event) => {
                      const value = event.target.value === "true";
                      handleChangeItemValue("isDefault", value);
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio sx={formStyles.icon} />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio sx={formStyles.icon} />}
                      label="Não"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, md: 9 }}>
                <Input
                  type="select"
                  name="itemTypeId"
                  title="Tipo de resposta"
                  value={itemAnswers.find((item) => item.id === values.itemTypeId)}
                  onChange={(option) => {
                    handleChangeItemValue("itemTypeId", option.id);
                    handleChangeItemValue("itemTypeName", option.title);
                  }}
                  error={errors.itemTypeId}
                  options={itemAnswers}
                  getOptionLabel={(option) => option.title}
                  icon={{
                    title: "Criar tipo de resposta",
                    func: () => handleOpenModal("typeAnswer"),
                  }}
                />
              </Grid>
              <Grid size={12}>
                <Input
                  type="text"
                  name="servicesCode"
                  title="Código de serviço"
                  value={values.servicesCode}
                  onChange={(event) => handleChangeItemValue("servicesCode", event.target.value)}
                  error={errors.servicesCode}
                />
              </Grid>
              <Grid size={12} sx={{ height: "100%" }}>
                <PageListHeader
                  title="Grupo"
                  titleAdd="Adicionar grupo"
                  handleAdd={() => handleOpenModal("group")}
                  fontSize={16}
                />
                <AdminTable
                  columns={[
                    {
                      field: "value",
                      headerName: "Título",
                      flex: 1,
                      align: "center",
                    },
                    {
                      field: "actions",
                      headerName: "Ações",
                      sortable: false,
                      filterable: false,
                      align: "center",
                      renderCell: ({ row }) => (
                        <TableActions handleDelete={() => handleDelete(row.id)} />
                      ),
                    },
                  ]}
                  data={values?.groups}
                />
              </Grid>
              <Grid size={12}>
                <Input
                  type="text"
                  name="blockCode"
                  title="Bloco"
                  value={values.blockCode}
                  onChange={(event) => handleChangeItemValue("blockCode", event.target.value)}
                  error={errors.blockCode}
                />
              </Grid>
              <Grid size={12}>
                <Typography sx={{ ...formStyles.subtitle, color: errors.text && "#d32f2f" }}>
                  Texto
                </Typography>
                <TextEditor
                  initialValue={values.text}
                  name="text"
                  height={400}
                  handleEditorChange={(content) => {
                    handleChangeItemValue("text", content);
                  }}
                  error={errors.text}
                />
                {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
              </Grid>
            </Grid>
          </Box>
          <Box sx={formStyles.buttonContainer}>
            <ButtonCommon sx={formStyles.button} onClick={handleValidation}>
              Salvar
            </ButtonCommon>
          </Box>
        </>
      )}
    </>
  );
}

export default Item;
