import React from "react";
import { reinfPerms } from "~/presentation/security/SecurePaths";
import { Box, Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { commonColors } from "~/presentation/themes/styles.App";

function TopTableButtons({
  selectedEvent,
  currentEvents,
  eventsWithDocsToAdd,
  disabledAddDocs,
  handleAddCNPJDocsToEvents,
  handleProtocolConsult,
  anyStatus1,
  handleCheckAll,
  selectedRows,
}) {
  const eventsCheckBoxArray = ["R-2010", "R-4010", "R-4020"];
  const isR9000 = selectedEvent === "r9000";

  const hasNotDocsToAdd = eventsWithDocsToAdd?.length === 0;
  const hasNotEvents = currentEvents?.length === 0;

  const disabledAdd = hasNotEvents || hasNotDocsToAdd || disabledAddDocs;

  const addDocsTooltip = disabledAddDocs
    ? "Já foram adicionados os documentos aos eventos. Pode proceder para a retificação."
    : hasNotDocsToAdd
      ? "Não existem documentos aptos para adicionar aos eventos enviados atuais."
      : "Adiciona aos eventos enviados os documentos aptos que tem o mesmo par de CNPJ (Contratado e Contratante) do respetivo evento.";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: isR9000 ? "row" : "column", md: "row" },
        gap: isR9000 ? 0 : 1,
      }}
    >
      {!isR9000 ? (
        <>
          <Tooltip title={addDocsTooltip}>
            <span>
              <RButton
                disabled={disabledAdd}
                onClick={handleAddCNPJDocsToEvents}
                sx={{ width: 300 }}
              >
                Adicionar Documentos
              </RButton>
            </span>
          </Tooltip>
          {anyStatus1 && (
            <SecurityPath securePaths={[reinfPerms.checkProtocol]}>
              <Tooltip title="Consulta a situação dos protocolos enviados que ainda não possuem confirmação / recibo.">
                <span>
                  <RButton sx={{ width: 300 }} onClick={handleProtocolConsult}>
                    Consultar protocolos
                  </RButton>
                </span>
              </Tooltip>
            </SecurityPath>
          )}
        </>
      ) : (
        eventsCheckBoxArray.map((event) => {
          const eventProtocols = currentEvents.filter((i) => i.reinfEvent === event);
          if (eventProtocols.length === 0) return;
          const selected = selectedRows.map((i) => i.protocol);
          const checked = eventProtocols.every((i) => selected.includes(i.protocol));
          return (
            <FormControlLabel
              key={event}
              sx={styles.controlLabel}
              control={
                <Checkbox
                  sx={styles.icon}
                  checked={checked}
                  onChange={() => handleCheckAll(event, checked)}
                  name={event}
                />
              }
              label={event}
            />
          );
        })
      )}
    </Box>
  );
}

export default TopTableButtons;

const styles = {
  controlLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      textAlign: "left",
      fontFamily: "Montserrat",
    },
    "&.Mui-disabled": {
      cursor: "not-allowed",
    },
  },
  icon: {
    p: 0.4,
    color: commonColors.reinf.primary,
    "&.Mui-checked": {
      color: commonColors.reinf.primary,
    },
    "&:first-of-type": {
      ml: 3,
    },
  },
};
