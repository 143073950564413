import { Box, Grow } from "@mui/material";
import { ClickAwayListener, Paper, Popper } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

const Menu = ({ anchorRef, anchorButton: AnchorButton, children: Children }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  //   return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return [
    <Box
      aria-controls={open ? "menu-list-grow" : undefined}
      aria-haspopup="true"
      ref={anchorRef}
      key={1}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AnchorButton onClick={handleToggle} />
    </Box>,
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-end"
      transition
      disablePortal
      modifiers={{
        arrow: {
          enabled: true,
        },
      }}
      key={2}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
          <div style={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                marginTop: "-6px",
                right: "10%",
                width: 0,
                height: 0,
                borderLeft: "7px solid transparent",
                borderRight: "7px solid transparent",
                borderBottom: "7px solid #fefefe",
              }}
            />
            <Paper
              sx={{
                color: "#222222",
                backgroundColor: "#FEFEFE",
                boxShadow: "#CDCDCD",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <Children
                    open={open}
                    handleClose={handleClose}
                    handleListKeyDown={handleListKeyDown}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </div>
        </Grow>
      )}
    </Popper>,
  ];
};

export default Menu;
