import React, { useEffect, useMemo, useRef, useState } from "react";
import { DeleteForever } from "@mui/icons-material";
import { Box, Button, Chip } from "@mui/material";
import { useLocation } from "react-router-dom";

export const Filters = ({ onFilter, getFilters }) => {
  const [filters, setFilters] = useState();
  const [activeFilter, setActiveFilter] = useState("");

  const query = new URLSearchParams(useLocation().search);
  const categoryId = query.get("categoryId");
  const categoriesContainerRef = useRef(null);
  const [isCategoriesContainerAtStart, setCategoriesContainerIsAtStart] = useState(true);
  const [isCategoriesContainerAtEnd, setIsCategoriesContainerAtEnd] = useState(false);

  const saveFilters = async () => {
    const result = await getFilters();
    setFilters(result);
  };

  const handleFilter = (value) => {
    setActiveFilter(value);
    onFilter(value);
  };

  const handleClearFilter = () => {
    return handleFilter("");
  };

  const memoFilters = useMemo(() => filters, [filters]);

  useEffect(() => {
    saveFilters();
  }, []);

  const handleCategoriesContainerScroll = (direction) => {
    const scrollAmount = 200;

    if (direction === "right") {
      categoriesContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    } else {
      categoriesContainerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
    }

    const currentScrollLeft =
      categoriesContainerRef.current.scrollLeft +
      (direction === "right" ? scrollAmount : -scrollAmount);
    const maxScrollLeft =
      categoriesContainerRef.current?.scrollWidth - categoriesContainerRef.current?.clientWidth;

    setCategoriesContainerIsAtStart(currentScrollLeft <= 0);
    setIsCategoriesContainerAtEnd(currentScrollLeft >= maxScrollLeft);
  };

  useEffect(() => {
    if (isCategoriesContainerAtEnd && categoryId && categoriesContainerRef.current) {
      categoriesContainerRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  }, [categoryId, isCategoriesContainerAtEnd]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: 2,
        marginBlock: 1,
        marginInline: "auto",
        maxWidth: { xs: 300, sm: 550, md: 860, lg: 1200 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          rotate: "180deg",
          cursor: "pointer",
          userSelect: "none",
        }}
        onClick={() => handleCategoriesContainerScroll("left")}
        disabled={isCategoriesContainerAtStart}
      >
        <img src="/assets/icons/arrow-left-rounded.svg" alt="arrow-left" />
      </Box>
      <Box
        ref={categoriesContainerRef}
        sx={{
          maxWidth: activeFilter ? "80%" : "100%",
          width: "100%",
          display: "flex",
          gap: 4,
          overflowX: "auto",
          alignItems: "center",
          transition: "scrollLeft 0.5s ease-in-out",
          paddingBlock: 2,
          paddingInline: 0,
          "&::-webkit-scrollbar": {
            width: "12px",
            height: "2px",
            background: "transparent",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "transparent",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "transparent",
          },
        }}
      >
        {memoFilters?.map((f) => (
          <Box
            key={"legislation-filter-" + f.id}
            sx={{
              minWidth: "fit-content",
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Chip
              sx={{
                fontFamily: "Montserrat",
                color: "#000", // Cor do texto do Chip ativo
                fontWeight: 600,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                fontSize: 17,
                backgroundColor: activeFilter === f.id ? "#e6e6e6" : "transparent", // Cor de fundo do Chip ativo
                "& .MuiChip-label": {
                  fontSize: 17,
                },
                "&:hover": {
                  transform: "scale(1.03)",
                  backgroundColor: "#e6e6e6",
                },
                "&:active": {
                  transform: "scale(1.03)",
                  backgroundColor: "#e6e6e6",
                },
              }}
              onClick={() => handleFilter(f.id)}
              label={f?.name || f?.title}
              clickable
            />
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          userSelect: "none",
        }}
        onClick={() => handleCategoriesContainerScroll("right")}
        disabled={isCategoriesContainerAtEnd}
      >
        <img src="/assets/icons/arrow-left-rounded.svg" alt="arrow-left" />
      </Box>

      {activeFilter && (
        <Box
          sx={{
            minWidth: "200px",
            whiteSpace: "nowrap",
            display: "flex",
            justifyContent: "center",
            scrollBehavior: "smooth",
            overflowX: "scroll",
          }}
        >
          <Button
            sx={{
              fontFamily: "Montserrat",
              color: "#6D6D6D",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            onClick={handleClearFilter}
          >
            <DeleteForever />
            Limpar Filtro
          </Button>
        </Box>
      )}
    </Box>
  );
};
