import { apiURL } from "~/data/usecases/api";

export class ApiStatsUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getStatsSearch({ companyId, userId, DateStart, DateEnd }) {
    const isUser = userId ? `&userId=${userId}` : "";
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/stats/Search?companyId=${companyId}&DateStart=${DateStart}&DateEnd=${DateEnd}${isUser}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getStatsSearchSummary({ companyId, dataStart, dateEnd }) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/stats/Search-summary?companyId=${companyId}&dataStart=${dataStart}&dateEnd=${dateEnd}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getStatsContentAccess({ companyId, isDetail = false, dataStart, dateEnd }) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/stats/Content-access?companyId=${companyId}&isDetail=${isDetail}&dataStart=${dataStart}&dateEnd=${dateEnd}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
