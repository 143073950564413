import { floatToReais } from "~/presentation/utils/reais-parser";
import { TransportTypes } from ".";
import { getBaseCalculus } from "./calculator";

export const reportMapper = (values, result, simulatorValue, retentionAliquot) => {
  const items = [];

  items.push({
    descriptionItem: "O rendimento é decorrente de serviço de transporte?",
    value: values.isTransportService === "true" ? "Sim" : "Não",
  });

  if (values.isTransportService === "true") {
    items.push({
      descriptionItem: "O transporte é de:",
      value: values.transportType === TransportTypes.cargo ? "Carga" : "Passageiros",
    });
  }

  items.push({
    descriptionItem: "Valor da exclusão de materiais/equipamentos",
    value: floatToReais(values.materialExclusionValue),
  });

  items.push({
    descriptionItem: "Base de cálculo da retenção do IR",
    value: floatToReais(getBaseCalculus(values, simulatorValue)),
  });

  items.push({
    descriptionItem: "Alíquota da retenção",
    // value: `${formatPercentageReport(data.aliquotRetention * 100)}%`
    value: retentionAliquot?.label,
  });

  items.push({
    descriptionItem: "Valor da retenção do IR",
    value: floatToReais(result),
  });

  const report = {
    title: "IRRF",
    tax: 1,
    retentionValue: result,
    items,
  };
  return report;
};
