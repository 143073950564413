import React from "react";
import Select from "react-select";
import DateInput from "../DateInput";
import { MoneyField } from "../MoneyField";
import { IMaskInput } from "react-imask";
import {
  TextField,
  InputLabel,
  Box,
  TextareaAutosize,
  Typography,
  IconButton,
  Tooltip,
  Input as MuiInput,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { commonColors } from "~/presentation/themes/styles.App";

export const errorColor = "#d32f2f";
export const warningColor = "#d67919";

function renderInputComponent(type, props) {
  const label = (
    <InputLabel
      sx={{
        ...styles.title,
        color: props?.error ? errorColor : props?.warning ? warningColor : "#222222",
      }}
    >
      {props.title}
    </InputLabel>
  );
  const errorMessage = (
    <Typography sx={{ ...styles.ErrorText, color: errorColor }}>{props?.error}</Typography>
  );
  const warningMessage = (
    <Typography sx={{ ...styles.ErrorText, color: warningColor }}>{props?.warning}</Typography>
  );

  const placeholder = props?.placeholder || props?.title || "";

  switch (type) {
    case "text":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="text"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            placeholder={placeholder}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "search":
      return (
        <Box sx={styles.content}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {label}
            {props.value && props.deleteFilter && (
              <Tooltip title="Apagar filtro">
                <Close
                  fontSize="small"
                  sx={{ color: commonColors.reinf.primary, cursor: "pointer" }}
                  onClick={props.deleteFilter}
                />
              </Tooltip>
            )}
          </Box>
          <MuiInput
            type="text"
            sx={searchInput(props?.styles)}
            variant="outlined"
            placeholder="Pesquisar"
            disableUnderline
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "email":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="email"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            placeholder={placeholder}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "textarea":
      return (
        <Box sx={styles.content}>
          {label}
          <TextareaAutosize
            minRows={props?.minRows}
            maxlength={props?.maxlength}
            style={TextareaStyles(props?.styles, props?.error)}
            placeholder={placeholder}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "password":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="password"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            placeholder={placeholder}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "number":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="number"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            placeholder={props?.placeholder || 0}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "mask": {
      return (
        <Box sx={styles.content}>
          {label}
          <IMaskInput
            mask={props?.mask}
            value={props?.value ? props?.value : ""}
            unmask={true}
            onAccept={(value) => {
              if (props.onChange && value !== props.value) {
                props.onChange(value);
              }
            }}
            overwrite={true}
            disabled={props?.disabled}
            placeholder={props?.placeholder || props?.mask}
            style={MaskStyles(props?.styles, props?.error)}
          />
          {props?.error && errorMessage}
        </Box>
      );
    }

    case "select":
      return (
        <Box sx={styles.content}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {label}
            {props.value && props.deleteFilter && (
              <Tooltip title="Apagar filtro">
                <Close
                  fontSize="small"
                  sx={{ color: commonColors.reinf.primary, cursor: "pointer" }}
                  onClick={props.deleteFilter}
                />
              </Tooltip>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Box sx={{ width: "100%" }}>
              <Select
                options={props.options}
                onChange={props.onChange}
                value={props.value}
                styles={styleSelectInput(props.style, props.error)}
                noOptionsMessage={() => "Não há opções"}
                isDisabled={props.disabled || props.options.length === 0}
                placeholder={props?.placeholder || "Selecione " + props?.title.toLowerCase()}
                maxMenuHeight={props?.height || 250}
                {...props}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              {props?.icon && (
                <Tooltip
                  title={props?.icon?.title || `Adicionar ${props?.title}`}
                  sx={styles.addIcon}
                >
                  <IconButton
                    disabled={props?.icon?.disabled || props.options.length === 0}
                    onClick={props?.icon?.func}
                  >
                    {props?.icon.component}
                  </IconButton>
                </Tooltip>
              )}
              {props?.icon2 && (
                <Tooltip
                  title={props?.icon2?.title || `Adicionar ${props?.title}`}
                  sx={styles.addIcon}
                >
                  <IconButton
                    disabled={props?.icon2?.disabled || props.options.length === 0}
                    onClick={props?.icon2?.func}
                  >
                    {props?.icon2.component}
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          {props?.error && errorMessage}
        </Box>
      );

    case "amount":
      return (
        <Box sx={styles.content}>
          {label}
          <MoneyField {...props} />
          {props?.error && errorMessage}
          {props?.warning && warningMessage}
        </Box>
      );

    case "date":
      return (
        <Box sx={styles.content}>
          {label}
          <DateInput {...props} />
          {props?.error && errorMessage}
        </Box>
      );

    default:
      return (
        <Box component="form" sx={styles.content}>
          <InputLabel sx={{ ...styles.title, color: props.error ? errorColor : "#222222" }}>
            {"Defina o Type Input: select, text, number, amount, mask, aliquot"}
          </InputLabel>
          <TextField
            sx={InputStyles(props.styles, props?.error, props?.warning)}
            variant="outlined"
            placeholder={props.placeholder}
            {...props}
          />
        </Box>
      );
  }
}

// ⇣⇣⇣⇣⇣⇣⇣⇣ SOBRE A ESTILIZAÇÃO DOS INPUTS: ⇣⇣⇣⇣⇣⇣⇣⇣
// styles={{passar styles necessários}}

function Input({ type, ...props }) {
  return <>{renderInputComponent(type, props)}</>;
}

export default Input;

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  title: {
    fontSize: 13,
    fontWeight: 700,
    textAlign: "left",
    fontFamily: "Montserrat",
    color: "#000000",
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingLeft: "2px",
  },
  ErrorText: {
    fontSize: 12,
    fontFamily: "Montserrat",
    paddingLeft: 1,
  },
  addIcon: {
    backgroundColor: "#1341A1",
    borderRadius: "3px",
    width: "38px",
    height: "38px",
    "&:hover": {
      backgroundColor: "#10337b",
      transform: "scale(1.01)",
    },
    "&:disabled": {
      backgroundColor: "#666666",
    },
  },
  inputAndIconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
};

const searchInput = (styles) => ({
  width: "100%",
  fontSize: 14,
  fontFamily: "Montserrat",
  height: "38px !important",
  backgroundColor: "#FFFFFF",
  borderRadius: "3px",
  px: 1,
  "& .MuiInputBase-input::placeholder": {
    opacity: 0.9,
    color: "gray",
  },
  ...styles,
});

const InputStyles = (styles, error) => ({
  "input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 1000px transparent inset",
    fontFamily: "Montserrat",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: error ? `1px solid ${errorColor}` : "none",
    boxShadow: 0,
  },
  "& .MuiInputBase-input::placeholder": {
    opacity: 0.7,
    color: "gray",
  },
  ".MuiOutlinedInput-root": {
    width: "100%",
    fontSize: 14,
    fontFamily: "Montserrat",
    height: "38px !important",
    backgroundColor: "#FFFFFF",
    borderRadius: "3px",
    ...styles,
  },
});

const TextareaStyles = (styles, error) => ({
  fontFamily: "Montserrat",
  fontSize: 14,
  width: "100%",
  backgroundColor: "#FBFBFB",
  boxShadow: 0,
  color: "#000",
  border: error ? `1px solid ${errorColor}` : 0,
  borderRadius: "3px",
  padding: "5px 10px",
  "& .MuiInputBase-root.Mui-disabled": {
    color: "#000",
  },
  ...styles,
});

const styleSelectInput = (style, error) => ({
  control: () => ({
    backgroundColor: "#FBFBFB",
    borderRadius: "3px",
    border: error ? `1px solid ${errorColor}` : "none",
    height: "38px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: 14,
    ":focus-within": {
      border: error ? `1px solid ${errorColor}` : style?.borderOnHover || "unset",
    },
    ":hover": {
      borderColor: "unset",
      outline: "none",
      border: error ? `1px solid ${errorColor}` : style?.borderOnHover || "unset",
    },
    ...style,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 99999,
    background: style?.backgroundColor || "#FBFBFB",
    borderRadius: "3px",
    fontSize: 14,
    fontFamily: "Montserrat",
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "12px",
    },
    "::-webkit-scrollbar-track": {
      background: "#c1c1c1",
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: style?.color || "black",
  }),
  option: (provided) => ({
    ...provided,
    background: "transparent",
    color: style?.color || "black",
    textAlign: "left",
    ":hover": {
      background: style?.hoverColor || "#CDCDCD",
      cursor: "pointer",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: style?.color || "black",
    padding: "6px 10px",
  }),
  dropdownIndicator: (style) => ({
    color: style?.color || "#504B4B",
    cursor: "pointer",
    padding: "6px 10px 0px 6px",
  }),
});

const MaskStyles = (style, error) => ({
  width: "100%",
  fontSize: 14,
  fontFamily: "Montserrat",
  height: "38px",
  backgroundColor: "#FFFFFF",
  borderRadius: "3px",
  border: error ? `1px solid ${errorColor}` : "none",
  boxShadow: 0,
  outline: 0,
  padding: "0px 10px",
  ...style,
});
