import React, { useContext, useState } from "react";
import ButtonCommon from "~/presentation/components/Common/Button";
import { Box, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";
import PageListHeader from "../../../components/PageListHeader";
import TableActions from "~/presentation/components/TableActions";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import CreateEdit from "../../../Service/CreateEdit/CreateEdit";
import { Tab, TabPanel, Tabs, TabsList } from "~/presentation/components/Tabs/Tabs";
import Grid from "@mui/material/Grid2";
import { adminPerms } from "~/presentation/security/SecurePaths";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import InBulkHandler, {
  onInBulkDelete,
} from "~/presentation/components/ListComponent/components/InBulkHandler";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import AdminTable from "../../../components/AdminTable/AdminTable";

const CnaeForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  services,
  cnaes,
  fetchServices,
}) => {
  const { openDialogBox, closeDialog, closeSecondDialog, openSecondDialog } =
    useContext(DialogContext);
  const { showInfo } = useContext(SnackbarContext);

  // Controlar tabs para não alterar o estado quando existem alterações
  const [openTabs, setOpenTabs] = useState(1);
  const handleChangeTabs = (_, newValue) => setOpenTabs(newValue);

  const handleServiceList = () => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          disablePadding={false}
          data={services}
          checkedData={values?.serviceCodes}
          title="Serviços"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("serviceCodes", values)}
          handleCreate={handleServiceModal}
          titleAdd="Criar novo serviço"
          itemsPerPage={5}
          securePaths={[adminPerms.addServiceCodes]}
          costumFilter={{
            title: "InBulk",
            field: "code",
            nameField: "Serviços",
          }}
        />
      ),
    });
  };

  const handleServiceModal = () => {
    openSecondDialog({
      customComponent: () => (
        <CreateEdit
          data={null}
          onCloseForm={closeSecondDialog}
          refreshData={fetchServices}
          cnaes={cnaes}
        />
      ),
    });
  };

  const handleCnaeList = () => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          disablePadding={false}
          data={cnaes}
          checkedData={values?.cnaeAnswersLinked}
          title="Cnaes"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("cnaeAnswersLinked", values)}
          itemsPerPage={5}
          costumFilter={{
            title: "InBulk",
            field: "code",
            nameField: "CNAES",
          }}
        />
      ),
    });
  };

  const handleDeleteInBulk = (codes = [], field = "") => {
    onInBulkDelete({
      codes: codes,
      data: values[field],
      onChange: (values) => onChangeValue([field], values),
      showUpdateCount: (items) =>
        showInfo(items === 1 ? "Foi removido 1 item." : `Foram removidos ${items} itens.`),
    });
  };

  const handleDeleteServices = (params) => {
    if (values.serviceCodes.length === 1) {
      onChangeValue("serviceCodes", []);
    } else {
      const filtedValue = values?.serviceCodes?.filter((item) => item.id !== params.row.id);
      onChangeValue("serviceCodes", filtedValue);
    }
  };

  const handleDeleteCnae = (params) => {
    if (values.cnaeAnswersLinked.length === 1) {
      onChangeValue("cnaeAnswersLinked", []);
    } else {
      const filtedValue = values?.cnaeAnswersLinked?.filter((item) => item.id !== params.row.id);
      onChangeValue("cnaeAnswersLinked", filtedValue);
    }
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>CNAE</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: !2, sm: 6, md: 3 }}>
            <Input
              type="mask"
              mask="0000-0/00"
              name="code"
              title="Código"
              value={values.code}
              onChange={(e) => onChangeValue("code", e)}
              error={errors.code}
            />
          </Grid>
          <Grid size={{ xs: !2, sm: 6, md: 9 }}>
            <Input
              type="text"
              name="detailCode"
              title="Detalhamento"
              value={values.detailCode}
              onChange={(e) => onChangeValue("detailCode", e.target.value)}
              error={errors.detailCode}
            />
          </Grid>
          <Grid size={{ xs: !2, sm: 6 }}>
            <Input
              type="text"
              name="description"
              title="Descrição"
              value={values.description}
              onChange={(e) => onChangeValue("description", e.target.value)}
              error={errors.description}
            />
          </Grid>

          <Grid size={{ xs: !2, sm: 6 }}>
            <Input
              type="text"
              name="synonym"
              title="Sinônimo"
              value={values.synonym}
              onChange={(e) => onChangeValue("synonym", e.target.value)}
              error={errors.synonym}
            />
          </Grid>
          <Grid size={12}>
            <Input
              type="text"
              name="comment"
              title="Comentário"
              value={values.comment}
              onChange={(e) => onChangeValue("comment", e.target.value)}
              error={errors.comment}
            />
          </Grid>
        </Grid>
      </Box>

      <Tabs value={openTabs} onChange={handleChangeTabs}>
        <TabsList>
          <Tab value={1} error={errors.serviceCodes}>
            Serviços
          </Tab>
          <Tab value={2} error={errors.cnaeAnswersLinked}>
            CNAES
          </Tab>
        </TabsList>
        <TabPanel value={1} error={errors.serviceCodes}>
          <PageListHeader
            title="Serviços"
            titleAdd="Adicionar Serviços"
            handleAdd={handleServiceList}
          />
          <AdminTable
            columns={[
              { field: "code", headerName: "Código", flex: 1, align: "center" },
              { field: "group", headerName: "Grupo", flex: 1, align: "center" },
              { field: "description", headerName: "Descrição", flex: 6, hide: "md" },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDeleteServices(params)} />
                ),
              },
            ]}
            costumFilter={
              <Box sx={{ minWidth: { sm: 360 } }}>
                <InBulkHandler
                  onChange={(values) => handleDeleteInBulk(values, "serviceCodes")}
                  data={values?.serviceCodes}
                  action="delete"
                  field="code"
                  nameField="Serviços"
                />
              </Box>
            }
            data={values?.serviceCodes}
          />
        </TabPanel>
        <TabPanel value={2} error={errors.cnaeAnswersLinked}>
          <PageListHeader title="Cnaes" titleAdd="Adicionar Cnaes" handleAdd={handleCnaeList} />
          <AdminTable
            columns={[
              { field: "code", headerName: "Código", flex: 1, align: "center" },
              { field: "detailCode", headerName: "Detalhe", flex: 1, align: "center" },
              { field: "description", headerName: "Descrição", flex: 6 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDeleteCnae(params)} />
                ),
              },
            ]}
            costumFilter={
              <Box sx={{ minWidth: { sm: 360 } }}>
                <InBulkHandler
                  onChange={(values) => handleDeleteInBulk(values, "cnaeAnswersLinked")}
                  data={values?.cnaeAnswersLinked}
                  action="delete"
                  field="code"
                  nameField="CNAES"
                />
              </Box>
            }
            data={values?.cnaeAnswersLinked}
          />
        </TabPanel>
      </Tabs>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default CnaeForm;
