import React from "react";
import { SectionHeader } from "~/presentation/views/Client/ClientHome/components/SectionHeader";
import { Card } from "~/presentation/views/Client/ClientHome/components/Card";
import { CardsWrapper } from "~/presentation/views/Client/ClientHome/components/CardsWrapper";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { clientPerms } from "~/presentation/security/SecurePaths";

const contentFeatures = [
  {
    id: 1,
    icon: "/assets/icons/notepad.png",
    title: "Artigos",
    text: "Leia artigos exclusivos e tire dúvidas que muita gente também tem.",
    link: "/cliente/conteudo-selecionado/artigo",
    securityPath: [clientPerms.seeArticles],
  },
  {
    id: 2,
    icon: "/assets/icons/file_stack.png",
    title: "Legislação",
    text: "Existem milhões de normas tributárias, mas nós separamos as que lhe interessam.",
    link: "/cliente/conteudo-selecionado/legislacao",
    securityPath: [clientPerms.seeLegislation],
  },
  {
    id: 3,
    icon: "/assets/icons/laptop.png",
    title: "Multimídia",
    text: "Assista vídeos e entenda de forma fácil e didática o que parece complicado.",
    link: "/cliente/conteudo-selecionado/multimidia",
    securityPath: [clientPerms.seeMultimedia],
  },
];

export const SelectedContent = ({ componentRef }) => {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <Box sx={styles.container} ref={componentRef}>
      <Box sx={{ ...styles.container.line, top: 2, height: "2px" }} />
      <Box sx={{ ...styles.container.line, top: 6, height: "1px" }} />
      <SectionHeader
        text={`CONTEÚDO SELECIONADO`}
        subTitle="Leia artigos, assista vídeos e veja a legislação específica sobre a tributação dos seus contratos."
      />
      <CardsWrapper>
        {contentFeatures.map((card) => {
          const permission = hasPermission(card.securityPath);
          return (
            <Card
              key={card.id}
              icon={card.icon}
              title={card.title}
              text={card.text}
              onClick={() => handleClick(card.link)}
              buttonVariant="outlined"
              showButton={permission}
            />
          );
        })}
      </CardsWrapper>
    </Box>
  );
};

const styles = {
  container: {
    height: "100%",
    minHeight: "100vh",
    backgroundImage: 'url("/assets/client-home-1.jpg")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    gap: "16px",
    line: {
      position: "absolute",
      left: 0,
      width: "100%",
      backgroundColor: "#fff",
      boxShadow: "1px 1px 10px #000",
    },
    "> h1, h2, h3, p, span": {
      color: "#fafafa",
    },
    "> h2, h3, p": {
      textShadow: "2px 2px 4px #272c33",
    },
    "@media (min-width: 768px)": {
      height: "100vh",
      padding: "80px",
      gap: "32px",
    },
    "@media (max-width: 768px)": {
      backgroundImage: "none",
      backgroundColor: "#fff",
      "> h1, h2, h3, p, span": {
        color: "#000",
        textShadow: "none",
      },
      marginBottom: "15rem",
    },
  },
};
