import { Formik, Form } from "formik";
import React, {useContext, useEffect, useRef, useState} from "react";
import { floatToReais } from "~/presentation/utils/reais-parser";
import {
  Button,
  FormWrapper,
  SimulatorResultCard,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { INSSCalculator } from "~/presentation/views/GTFacil/Simulator/entities/inss-calculator";
import {
  additionalAliquotRetentionOptions,
  aliquotRetentionOptions,
} from "../../common/inss/formOptions";
import {
  HasMaterialOrEquipmentExclusion,
  MaterialOrEquipmentExclusionValue,
  HasTransportationOrAlimentationBenefitsDeduction,
  TransportationOrAlimentationBenefitsDeductionValue,
  BaseRetentionCalcValue,
  AliquotRetention,
  HasAdditionalAliquotRetention,
  AdditionalAliquotRetention,
} from "~/presentation/views/GTFacil/Simulator/forms/common/inss/questions";
import { reportMapper } from "./reportMapper";

const initialValues = {
  baseRetentionCalcValue: 0.0,
  hasMaterialOrEquipmentExclusion: "false",
  materialOrEquipmentExclusionValue: 0.0,
  hasTransportationOrAlimentationBenefitsDeduction: "false",
  transportationOrAlimentationBenefitsDeductionValue: 0.0,
  aliquotRetention: aliquotRetentionOptions[0],
  hasAdditionalAliquotRetention: "false",
  additionalAliquotRetention: additionalAliquotRetentionOptions[0],
};

export const InssPJSimples = () => {
  const resultCardRef = useRef();
  const [result, setResult] = useState();
  const [lastSubmitValues, setLastSubmitValues] = useState("");
  const { simulatorValue, setInvalidSimulatorValue, handleCacheReport } = useContext(SimulatorContext);

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  const inssCalculatorFactory = (values) => new INSSCalculator(mapValuesToCalculatorProps(values));

  const mapValuesToCalculatorProps = (values) => ({
    materialOrEquipmentExclusionValue: values.materialOrEquipmentExclusionValue,
    transportationOrAlimentationBenefitsDeductionValue:
      values.transportationOrAlimentationBenefitsDeductionValue,
    simulatorValue: simulatorValue,
    aliquotRetention: values.aliquotRetention.value,
    additionalAliquotRetention:
      values.hasAdditionalAliquotRetention === "true" ? values.additionalAliquotRetention.value : 0,
  });

  useEffect(() => {
    if(result && resultCardRef.current) {
      resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [result, resultCardRef]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }
        action.setSubmitting(true);
        setLastSubmitValues(values);

        const inssCalculator = inssCalculatorFactory(values);

        setResult(inssCalculator.retentionValue);

        action.setSubmitting(false);

        const response = reportMapper(inssCalculator, inssCalculator.retentionValue);

        handleCacheReport(response);         
      }}
    >
      {({ values }) => {
        const inssCalculator = inssCalculatorFactory(values);

        return (
          <Form>
            <FormWrapper>
              <HasMaterialOrEquipmentExclusion />

              {values.hasMaterialOrEquipmentExclusion === "true" ? (
                <MaterialOrEquipmentExclusionValue />
              ) : null}

              <HasTransportationOrAlimentationBenefitsDeduction />

              {values.hasTransportationOrAlimentationBenefitsDeduction === "true" ? (
                <TransportationOrAlimentationBenefitsDeductionValue />
              ) : null}

              <BaseRetentionCalcValue value={inssCalculator.baseRetentionCalcValue} />

              <AliquotRetention />

              <HasAdditionalAliquotRetention />

              {values.hasAdditionalAliquotRetention === "true" ? (
                <AdditionalAliquotRetention />
              ) : null}

              <Button>Ver Resultado</Button>

              {result && valuesNotChangedSinceLastSubmit(values) ? (
                <SimulatorResultCard
                  componentRef={resultCardRef}
                  title="Valor da retenção previdenciária (INSS):"
                  value={floatToReais(result)}
                  description="O valor da retenção previdenciária corresponde ao resultado da multiplicação da alíquota da contribuição pela base de cálculo."
                />
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
