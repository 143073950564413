import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { formatRealStringToNumber } from "~/presentation/utils/formatCurrency";
import { AddtionalCompanyPlanForm } from "./Form";
import PageListHeader from "../../components/PageListHeader";
import validationSchema from "./Form/validationSchema";

function CreateEdit({ data, additionalCompanyPlanUseCases, company, closeModal, refreshData }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [errors, setErrors] = useState({});
  const isCreate = !data;

  const [values, setValues] = useState({
    id: 0,
    dateRegister: new Date(),
    type: null,
    amount: null,
    limitDate: new Date(),
    price: null,
    companyName: company.name,
    companyId: company.id,
  });

  useEffect(() => {
    if (data?.id) {
      setValues(() => {
        return {
          id: data.id,
          dateRegister: data.dateRegister ? new Date(data.dateRegister) : null,
          type: data.type,
          amount: data.amount,
          limitDate: data.limitDate ? new Date(data.limitDate) : null,
          price: data.price,
          companyId: company.id,
          companyName: company.name,
        };
      });
    }
  }, [data]);

  const handleChangeValues = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = async (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => handleSubmit(values))
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
    openBackdrop(false);
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} Adicional de Plano`);
    const submitValues = {
      id: values.id,
      type: values.type,
      amount: values.amount,
      limitDate: values.limitDate,
      companyId: values.companyId,
      price: !values.price ? 0 : formatRealStringToNumber(values.price),
    };

    try {
      if (isCreate) {
        await additionalCompanyPlanUseCases.createAdditionalCompanyPlan(submitValues);
      } else {
        await additionalCompanyPlanUseCases.updateAdditionalCompanyPlan(submitValues);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Adicional de Plano ${isCreate ? "criado" : "editado"}.`,
        type: "success",
      });
      await refreshData();
      closeModal();
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro. ${err.response.data}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };
  return (
    <Box>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} Adicional de Plano`}
        handleBack={closeModal}
        titleBack="Fechar"
        fontSize={22}
      />
      {values && (
        <AddtionalCompanyPlanForm
          values={values}
          handleSubmit={handleValidation}
          onChangeValue={handleChangeValues}
          errors={errors}
        />
      )}
    </Box>
  );
}

export default CreateEdit;
