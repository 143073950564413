import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Typography as Text,
  Button,
  Switch,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Input from "~/presentation/components/Common/Input";
import ProfileForms from "../../UserProfile/Forms/ProfileForms";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { validationCreate, validationEdit } from "./validationSchema";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { painelStyles } from "~/presentation/views/Client/MyPanel";

function UserForms({
  user,
  handleClose,
  handleSubmit,
  userProfiles,
  refreshProfiles,
  userProfilesUseCases,
}) {
  const { id: userId, companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const { openSecondDialog, closeSecondDialog } = useContext(DialogContext);

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ ...user, companyId: companyId });
  const isCreate = user.id === 0;

  const openModal = () => {
    openSecondDialog({
      customComponent: () => (
        <ProfileForms
          data={{
            id: 0,
            name: "",
            isInternalUse: false,
            companyId: companyId,
            permission: {
              admins: null,
              clients: null,
              reinfs: null,
            },
          }}
          userProfilesUseCases={userProfilesUseCases}
          companyId={companyId}
          refreshData={refreshProfiles}
          onCloseForm={closeSecondDialog}
        />
      ),
      maxWidth: "sm",
      fullWidth: true,
    });
  };

  const isLoggedUser = user.id === userId;

  const userProfileOptions = userProfiles?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    if (userProfiles.length === 0) {
      refreshProfiles();
    }
  }, []);

  const handleChangeInput = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = (event) => {
    event.preventDefault();

    const validation = isCreate ? validationCreate : validationEdit;

    validation
      .validate(values, { abortEarly: false })
      .then(() => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  return (
    <Box sx={{ padding: 3, background: "#fff" }}>
      <Text
        variant="h6"
        component="h2"
        sx={{ marginBlock: 2, fontSize: 20, fontFamily: "Montserrat", fontWeight: 700 }}
      >
        {isCreate ? "Criar" : "Editar"} Usuário
      </Text>

      <FormControl>
        {values && (
          <Grid container spacing={1}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                title="Nome"
                styles={painelStyles.input}
                name="name"
                type="text"
                value={values.name}
                onChange={(e) => handleChangeInput("name", e.target.value)}
                error={errors.name}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                title="Email"
                styles={painelStyles.input}
                name="email"
                type="email"
                value={values.email}
                onChange={(e) => handleChangeInput("email", e.target.value)}
                error={errors.email}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                type="mask"
                title="Telefone"
                mask="(00) 0000-0000"
                name="homePhone"
                value={values?.homePhone || ""}
                onChange={(e) => handleChangeInput("homePhone", e)}
                error={errors.homePhone}
                styles={painelStyles.input}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                type="mask"
                title="Celular"
                mask="(00) 00000-0000"
                name="mobilePhone"
                value={values?.mobilePhone || ""}
                onChange={(e) => handleChangeInput("mobilePhone", e)}
                error={errors.mobilePhone}
                styles={painelStyles.input}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                name="password"
                title="Senha *"
                type="password"
                value={values?.password || ""}
                onChange={(e) => handleChangeInput("password", e.target.value)}
                error={errors.password}
                styles={painelStyles.input}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                name="confirmPassword"
                title="Confirmar Senha *"
                type="password"
                value={values?.confirmPassword || ""}
                onChange={(e) => handleChangeInput("confirmPassword", e.target.value)}
                error={errors.confirmPassword}
                styles={painelStyles.input}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                type="select"
                title="Perfil de Usuário"
                name="userProfileId"
                placeholder="Selecione o perfil..."
                value={userProfileOptions.find((item) => item.value === values.userProfileId)}
                onChange={(e) => handleChangeInput("userProfileId", e.value)}
                options={userProfileOptions}
                error={errors.userProfileId}
                style={painelStyles.input}
                height={120}
                disabled={isLoggedUser}
                icon={{
                  title: "Criar uma novo perfil",
                  func: openModal,
                  securePaths: [clientPerms.addUserProfile],
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControlLabel
                sx={{ ...formStyles.controlLabel, pt: 2.5, pl: 1 }}
                required
                control={
                  <Switch
                    checked={values?.active}
                    onChange={(e) => handleChangeInput("active", e.target.checked)}
                  />
                }
                label="Usuário ativo?"
              />
            </Grid>
          </Grid>
        )}
      </FormControl>
      <Box sx={styles.ButtonGroup}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button sx={styles.CancelButton} variant="contained" onClick={handleClose}>
            Cancelar
          </Button>
          <Button sx={styles.Button} variant="contained" onClick={handleValidation}>
            Salvar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default UserForms;

const styles = {
  Button: {
    background: "#081445",
    paddingInline: { xs: 2, md: 4 },
    paddingBlock: 1,
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0e3279",
    },
  },
  ButtonGroup: { display: "flex", gap: 1, marginTop: 2, justifyContent: "end" },
  ButtonAdd: {
    background: "#1341a1",
    padding: 0,
    color: "#FFF",
    paddingInline: 4,
    borderRadius: 20,
    height: 48,
    "&:hover": {
      backgroundColor: "#1341a1",
    },
  },

  CancelButton: {
    backgroundColor: "#EFF2F4",
    color: "black",
    "&:hover": {
      color: "#fff",
    },
  },
};
