import { apiURL } from "../../usecases/api";
export class ApiSecurityPermissionUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getSecurityPermissions() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/SecurityPermission`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getSecurityPermission(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/SecurityPermission/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateSecurityPermission(securityPermission) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/SecurityPermission`,
        method: "PUT",
        data: securityPermission,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteSecurityPermission(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/SecurityPermission/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async createSecurityPermission(securityPermission) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/SecurityPermission`,
        method: "POST",
        data: securityPermission,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
