import React from "react";
import { useNavigate, useLocation } from "react-router";
import { Stack, Button } from "@mui/material";
import { hasPermission } from "~/presentation/security/SecurityPath";

export const Menu = ({ pages }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const page = query.get("page");

  const handlePage = (id) => {
    navigate(location.pathname + "?page=" + id);
  };

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        useFlexGap
        spacing={2}
        sx={{ paddingBlock: 3 }}
      >
        {pages.map((p) => {
          const permissions = p.securePaths ? hasPermission(p.securePaths) : true;
          if (!permissions) return;
          return (
            <Button
              key={p.id}
              onClick={() => handlePage(p.id)}
              sx={page == p.id ? { ...styles.button, ...styles.activeButton } : styles.button}
            >
              {p.label}
            </Button>
          );
        })}
      </Stack>
    </>
  );
};

const styles = {
  button: {
    backgroundColor: "#fff",
    paddingBlock: 2,
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontWeight: 500,
    width: "100%",
    border: "1px solid #000",
    borderRadius: 1.3,
    color: "#000",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  activeButton: {
    backgroundColor: "#1341A1",
    color: "#fff",
    border: "1px solid #1341A1",
    "&:hover": {
      backgroundColor: "#0d3b92",
    },
  },
};
