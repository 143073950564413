import React from "react";
import { QuestionLabel } from "~/presentation/views/GTFacil/Simulator/components/QuestionLabel";
import Checkbox from "~/presentation/components/Checkbox";
import { Box, FormControlLabel } from "@mui/material";
import FormikCustomErrorMessage from "~/presentation/components/ErrorMessage";

const defaultCheckbox = [{ value: false, label: "Teste checkbox" }];

export const CheckboxQuestion = ({
  name,
  text,
  excerpt,
  checkboxes = defaultCheckbox,
  onChange,
}) => {
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <QuestionLabel text={text} excerpt={excerpt} />
      <Box
        sx={{
          display: "flex",
          padding: "0 20px",
          "& .MuiTypography-body1": { fontWeight: 300, fontSize: "0.8rem" },
        }}
      >
        {checkboxes.map(({ label, value, name }) => (
          <FormControlLabel
            key={`checkbox-${label}-${value}`}
            control={
              <Checkbox label={label} onChange={() => onChange(!value, name)} checked={value} />
            }
            label={label}
          />
        ))}
        {name && <FormikCustomErrorMessage name={name} />}
      </Box>
    </Box>
  );
};
