import React from "react";
import useWindowSize from "~/presentation/hooks/useWindowSize";
import ModalBackground, {
  handleColorByWindowSize,
} from "~/presentation/views/LandingPage/Home/Modals/components/Bg";
import ModalContainer from "~/presentation/components/ModalContainer";
import {
  Header,
  Icon,
  Button,
  ContainerTextHeader,
} from "~/presentation/views/LandingPage/Home/Modals/components/Wrappers";
import paperIcon from "~/presentation/views/LandingPage/Home/Modals/icons/paper.webp";
import { Box } from "@mui/material";

const GTFacilModal = () => {
  const { width: screenWidth } = useWindowSize();

  const bgColorSelector = () => {
    return handleColorByWindowSize(screenWidth);
  };

  const handleOnClickContactButton = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5571992084907&text=Ol%C3%A1!%20Vim%20do%20site%20Gest%C3%A3o%20Tribut%C3%A1ria%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20ferramenta%20GT-Reinf%20junto%20a%20um%20representante.",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <ModalContainer backgroundUrl="/assets/sample_photos/magnifying-glass-pc2.jpg">
      <ModalBackground color={bgColorSelector()} />
      <Header>
        <ContainerTextHeader>
          <Icon src={paperIcon} alt="Modal Icon" />
          <div>
            <span>Conteúdo Selecionado</span>
          </div>
        </ContainerTextHeader>
        <Button onClick={handleOnClickContactButton}>CONTRATE</Button>
      </Header>

      <Box
        sx={{
          maxWidth: "46rem",
          zIndex: 2,
          padding: "2rem 3rem",
          "> span": {
            fontWeight: 700,
          },
          "@media (max-width: 83.75rem)": {
            maxWidth: "100%",
          },
        }}
      >
        <p>
          <span>Não perca tempo</span> pesquisando <span>artigos</span> e <span>legislação</span>{" "}
          específicos sobre as retenções e encargos que incidem sobre seus contratos. Nós
          selecionamos o que lhe interessa e publicamos aqui apenas o que diz respeito ao{" "}
          <span>
            INSS, IRRF, Contribuições Sociais (CSLL, PIS/Pasep e COFINS) e ISS incidentes na fonte
            nos pagamentos a pessoas físicas e jurídicas
          </span>
          , para que você não tenha o trabalho de filtrar na vasta legislação tributária somente o
          que se aplica ao contexto de sua empresa ou entidade pública.
        </p>
        <p>
          Também produzimos e publicamos vídeos explicativos acerca de questões polêmicas, que lhe
          orientam sobre como proceder diante de dúvidas comuns relacionadas à matéria.
        </p>
      </Box>
    </ModalContainer>
  );
};

export default GTFacilModal;
