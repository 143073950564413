export const tableStyles = (sx) => ({
  container: {
    width: "100%",
    borderRadius: 3,
    overflowX: "auto",
    mb: 10,
    ...sx?.container,
  },
  toolbar: {
    py: 1,
    display: "flex",
    gap: 1,
    alignItems: "center",
    justifyContent: { xs: "center", sm: "space-between" },
    flexDirection: { xs: "column", sm: "row" },
    ...sx?.toolbar,
  },
  flex: {
    display: "flex",
    gap: 1,
    alignItems: "center",
    justifyContent: { xs: "center", sm: "space-between" },
    flexDirection: { xs: "column", sm: "row" },
    ...sx?.flex,
  },
  exportButton: {
    button: { backgroundColor: "#e6e6e6" },
    ...sx?.exportButton,
  },
  searchInput: {
    ...sx?.searchInput,
  },
  table: {
    borderRadius: "12px 12px 0px 0px",
    overflow: "hidden",
    backgroundColor: "#e6e6e6",
    ...sx?.table,
  },
  tableHeader: {
    backgroundColor: "#001A50",
    ...sx?.tableHeader,
  },
  headerCell: {
    color: "#fff",
    height: 80,
    "> span": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 1,
      fontWeight: 500,
      textAlign: "center",
    },
    ...sx?.headerCell,
  },
  headerIconCell: {
    py: 0,
    px: 2,
    width: 30,
    ...sx?.headerIconCell,
  },
  headerCheckboxIcon: {
    color: "#fff", // Cor padrão do checkbox
    p: 0,
    "&.Mui-checked": {
      color: "#fff", // Cor do checkbox quando está marcado
      ...sx?.headerCheckboxIcon,
    },
    ...sx?.headerCheckboxIcon,
  },
  sortIcon: {
    color: "#fff",
    ...sx?.sortIcon,
  },
  bodyRow: {
    borderBlock: "2px solid #f2f2f2",
    "&:hover": {
      backgroundColor: "#e1e1e1",
    },
    ...sx?.bodyRow,
  },
  bodyCell: {
    px: 2,
    py: 0.5,
    height: 50,
    fontFamily: "Montserrat",
    ...sx?.bodyCell,
  },
  iconCell: {
    py: 0,
    px: 2,
    width: 30,
    ...sx?.iconCell,
  },
  collapseIcon: {
    color: "#222222", // Cor padrão do checkbox
    "&.Mui-checked": {
      color: "#222222", // Cor do checkbox quando está marcado
      ...sx?.collapseIcon,
    },

    ...sx?.collapseIcon,
  },
  checkboxIcon: {
    p: 0,
    color: "#222222", // Cor padrão do checkbox
    "&.Mui-checked": {
      color: "#222222", // Cor do checkbox quando está marcado
      ...sx?.checkboxIcon,
    },

    ...sx?.checkboxIcon,
  },
  collapseRow: {
    p: 0,
    ...sx?.collapseRow,
  },
  errorMsgRow: {
    "> span": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 1,
      width: "100%",
      ...sx?.errorMsgRow,
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "right",
    pr: 2,
    color: "#222222",
    border: "1px solid",
    borderColor: "#e6e6e6",
    borderRadius: "0px  0px 12px 12px",
    backgroundColor: "rgba(0,26,80,0.1)",
    "& *": {
      color: "#222222",
      fontFamily: "Montserrat",
    },
    "& .MuiTablePagination-root": {
      width: "100%",
      border: "none",
    },
    ...sx?.pagination,
  },
});
