import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import TableActions from "~/presentation/components/TableActions";
//functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import PageListHeader from "../components/PageListHeader";
import CreateEdit from "./CreateEdit/CreateEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";
import AdminTable from "../components/AdminTable/AdminTable";

const CNAE = ({ cnaesUseCases, serviceUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //manter a página da tabela sempre atual
  const [page, setPage] = useState(0);

  //Data
  const [cnaes, setCnaes] = useState([]);

  //Options Data
  const [services, setServices] = useState([]);
  const [cnaesList, setCnaesList] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const { data } = await cnaesUseCases.listCnaes({ checkPermission: true });
      setCnaes(data);
      setCnaesList(data);
    } catch (error) {
      showError(error, "Erro ao carregar CNAES");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "code", headerName: "Código", flex: 1, align: "center" },
    { field: "detailCode", headerName: "Detalhe", flex: 1, align: "center" },
    { field: "description", headerName: "Descrição", flex: 6, hide: "md" },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenForm(row)}
          editSecurePath={[adminPerms.editCnaes]}
          handleDelete={() => deleteDialog(row.id)}
          deleteSecurePath={[adminPerms.deleteCnaes]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo cnae");
    try {
      await cnaesUseCases.deleteCnae(id);
      setCnaes((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir CNAE");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este cnae?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchServices = async () => {
    try {
      openBackdrop(true, "Carregando serviços");
      const result = await serviceUseCases.listService({ checkPermission: false });
      setServices(result);
    } catch (error) {
      showError(error, "Erro ao carregar serviços");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Box>
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          fetchCNAE={fetchData}
          cnaesList={cnaesList}
          fetchServices={fetchServices}
          services={services}
        />
      ) : (
        <>
          <PageListHeader
            title="CNAE"
            handleAdd={() => handleOpenForm()}
            addSecurePath={[adminPerms.addCnaes]}
          />
          <AdminTable
            tableExport
            columns={columns}
            data={cnaes}
            tablePage={page}
            setTablePage={setPage}
            exportFileName="cnaes"
          />
        </>
      )}
    </Box>
  );
};

export default CNAE;
