import React, { useState } from "react";
import parse from "html-react-parser";
import { AnswerAccordionStyles } from "~/presentation/views/GTFacil/Answer/components/AnswerAccordion/AnswerAccordion.styles";
import { AccordionSummary, Popover } from "@mui/material";
import { AccordionDetails } from "@mui/material";

export const AnswerAccordion = ({ answers, selectedCategory }) => {
  const [expanded, setExpanded] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const answer = answers.filter((answer) => answer[selectedCategory] !== null)[0];
  const answerKey = !!answer && Object.keys(answer).find((key) => key === selectedCategory);
  const contents = !!answerKey && answer[answerKey].content;

  const handlePopoverOpen = (event, content) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent(null);
  };

  const replacePopovers = (node, id) => {
    if (node.type === "tag" && node.name === "a" && node.attribs["data-role"] === "popover") {
      return (
        <span key={id} style={{ display: "inline" }}>
          <AnswerAccordionStyles.PopoverTrigger
            aria-owns={!!anchorEl && "mouse-over-popover"}
            aria-haspopup="true"
            onClick={(e) => handlePopoverOpen(e, parse(node.attribs["data-popover-text"]))}
          >
            {node.children[0].data}
          </AnswerAccordionStyles.PopoverTrigger>
        </span>
      );
    }
    return null;
  };

  const open = Boolean(anchorEl);

  return (
    <AnswerAccordionStyles.Container>
      <>
        {contents &&
          contents?.map((item) => (
            <div key={`${item.id}-container`}>
              <AnswerAccordionStyles.AccordionComp
                key={item.id}
                expanded={expanded === item.id}
                onChange={handleChange(item.id)}
              >
                <AccordionSummary aria-controls={`${item.id}-content`} id={`${item.id}-header`}>
                  <AnswerAccordionStyles.Line />
                  <AnswerAccordionStyles.Title>{item.title}</AnswerAccordionStyles.Title>
                </AccordionSummary>
                <AccordionDetails>
                  <AnswerAccordionStyles.Text>
                    {parse(item.text, { replace: (data) => replacePopovers(data, item.id) })}
                  </AnswerAccordionStyles.Text>
                </AccordionDetails>
              </AnswerAccordionStyles.AccordionComp>
            </div>
          ))}
        {!contents && (
          <AccordionSummary>
            <AnswerAccordionStyles.Line />
            <AnswerAccordionStyles.Title>
              Não há resposta cadastrada para este tributo.
            </AnswerAccordionStyles.Title>
          </AccordionSummary>
        )}
      </>
      {popoverContent && (
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
        >
          <AnswerAccordionStyles.PopoverContent>
            {popoverContent}
          </AnswerAccordionStyles.PopoverContent>
        </Popover>
      )}
    </AnswerAccordionStyles.Container>
  );
};
