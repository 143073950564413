import React from "react";
import { Box, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import Grid from "@mui/material/Grid2";

const TypeAnswerForm = ({ values, onChangeValue, handleSubmit, errors }) => {
  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Tipo de Resposta</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 8 }}>
            <Input
              type="text"
              name="title"
              title="Título"
              value={values.title}
              onChange={(e) => onChangeValue("title", e.target.value)}
              error={errors.title}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Input
              type="number"
              name="order"
              title="Ordem"
              value={values.order}
              onChange={(e) => {
                onChangeValue("order", Number(e.target.value));
              }}
              error={errors.order}
            />
          </Grid>
          <Grid size={12}>
            <Input
              type="text"
              name="description"
              title="Descrição"
              value={values.description}
              onChange={(e) => onChangeValue("description", e.target.value)}
              error={errors.description}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default TypeAnswerForm;
