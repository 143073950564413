import React, { createContext, useContext, useMemo, useState } from "react";
import BackdropLoading from "~/presentation/components/BackdropLoading";
import CountDown from "../components/BackdropLoading/CountDown";

export const BackdropContext = createContext();
export const useBackdrop = () => useContext(BackdropContext);

export const BackdropProvider = ({ children }) => {
  const [backdrop, setBackdrop] = useState({
    open: false,
    msg: "",
  });

  const initialCountDown = { open: false, current: 0, total: 0, message: "" };

  const [countDown, setCountDown] = useState(initialCountDown);

  const openBackdrop = (open, msg = "") => {
    setBackdrop({ open, msg });
  };

  const closeBackdrop = () => openBackdrop(false);

  const openCountDownLoading = (props) => {
    //current , total, message
    setCountDown((prev) => ({
      ...prev,
      open: true,
      ...props,
    }));
  };

  const closeCountDownLoading = () => setCountDown(initialCountDown);

  const value = { openBackdrop, openCountDownLoading, closeCountDownLoading };

  return (
    <BackdropContext.Provider value={useMemo(() => value, [])}>
      {children}
      <BackdropLoading open={backdrop.open} message={backdrop.msg} handleClose={closeBackdrop} />
      <CountDown open={countDown.open} handleClose={closeCountDownLoading} countDown={countDown} />
    </BackdropContext.Provider>
  );
};
