import React from "react";
import { styled } from "@mui/material";

export const AnswerCard = ({ answer, onClick, disabled, isPF = false }) => {
  const color = answer?.color || "";

  const Container = styled("div")({
    textAlign: "center",
    fontSize: "max(18px, 0.7vw)",
    fontFamily: '"Montserrat", sans-serif',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: disabled ? "#c2c2c2" : color,
    color: "#fafafa",
    padding: "max(18px, 1.3vw)",
    borderRadius: "8px",
    boxShadow: "0px 8px 10px -5px rgba(0,0,0,0.17)",
    transition: "all 0.16s ease-in-out",
    cursor: disabled ? "not-allowed" : "pointer",
    "&:hover": {
      backgroundColor: disabled ? undefined : color,
      transform: disabled ? "none" : "scale(1.05)",
    },
  });

  const getLabel = (isPF, label) => {
    if (isPF) {
      if (label.includes("INSS")) {
        label = "INSS de Contribuintes Individuais";
      }
      if (label.includes("IRRF")) {
        label = "IRRF de Pessoas Físicas";
      }
      if (label.includes("ISS na")) {
        label = "ISS na Contratação de Pessoas Físicas";
      }
    }
    return label;
  };

  return (
    <Container onClick={disabled ? undefined : onClick} disabled={disabled}> 
      {getLabel(isPF, answer.label)}
    </Container>
  );
};
