import React from "react";
import { Button } from "@mui/material";

const PrimaryButton = ({
  children,
  fontWeight,
  backgroundColor,
  fullWidth,
  textColor,
  size,
  ...props
}) => (
  <Button
    sx={{
      color: textColor || "#fff",
      backgroundColor: backgroundColor || "#1341a1",
      padding: size === "small" ? "7px 12px" : "14px 22px",
      textTransform: "none",
      transition: "all 0.1s ease-in-out",
      width: fullWidth ? "100%" : "fit-content",
      fontWeight: fontWeight || 500,
      "&:hover": {
        background: backgroundColor || "#1341a1",
        filter: "brightness(0.95)",
      },
      "&.Mui-disabled": {
        backgroundColor: "#fff",
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export default PrimaryButton;
