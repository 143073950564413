import { styled } from "@mui/material/styles";

export const CardsWrapper = styled("div")({
  height: "100%",
  display: "grid",
  gridTemplateRows: "repeat(3, min-content)",
  gridTemplateColumns: "auto",
  gridRowGap: "140px",

  "@media screen and (min-width: 768px)": {
    gridTemplateRows: "auto",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridRowGap: "40px",
  },
});
