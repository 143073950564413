import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { formatDateToTable } from "~/presentation/functions/date";
import CreateEdit from "./CreateEdit/CreateEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";
import AdminTable from "../components/AdminTable/AdminTable";

const Survey = ({ surveyUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //Data
  const [surveys, setSurveys] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "md",
      fullWidth: true,
      customComponent: () => (
        <CreateEdit
          data={data}
          onCloseForm={closeDialog}
          refreshData={fetchData}
          surveyUseCases={surveyUseCases}
        />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const apiSurveys = await surveyUseCases.getAll();
      setSurveys(apiSurveys);
    } catch (error) {
      showError(error, "Erro ao carregar enquetes");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "question", headerName: "Pergunta", flex: 2 },
    {
      field: "publishDate",
      headerName: "Data",
      flex: 1,
      align: "center",
      renderCell: ({ value }) => formatDateToTable(value),
      hide: "md",
    },
    {
      field: "draft",
      headerName: "Rascunho",
      flex: 1,
      renderCell: ({ value }) => (value ? "Sim" : "Não"),
      sortable: false,
      align: "center",
      hide: "md",
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenForm(row)}
          editSecurePath={[adminPerms.editSurvey]}
          handleDelete={() => deleteDialog(row.id)}
          deleteSecurePath={[adminPerms.deleteSurvey]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo enquete");
      await surveyUseCases.delete(id);
      setSurveys((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError("Erro ao excluir enquete");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta enquete?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageListHeader
        title="Enquete"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addSurvey]}
      />
      <AdminTable tableExport columns={columns} data={surveys} exportFileName="enquetes" />
    </>
  );
};

export default Survey;
