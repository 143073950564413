import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Card } from "~/presentation/views/GTFacil/components/Card";
import { Box, Typography, Popover } from "@mui/material";

export const SearchCard = ({ getSearch, searchType }) => {
  const [search, setSearch] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState(null);

  const loadSearch = async () => {
    setSearch(await getSearch());
  };

  const renderPreTitle = () => {
    switch (searchType) {
      case "cnae":
        return "Código CNAE";
      case "lc116":
        return "Código na LC 116/2003";
      default:
        return "Código na LC 116/2003";
    }
  };

  const renderCode = () => {
    const { code, detailCode } = search;
    if (code && detailCode) {
      return `${code} (${detailCode}) - `;
    } else {
      return `${code} - `;
    }
  };

  const handlePopoverOpen = (event, content) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(parse(content));
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent(null);
  };

  const replacePopovers = (node, id) => {
    if (node.type === "tag" && node.name === "a" && node.attribs["data-role"] === "popover") {
      return (
        <span key={id} style={{ display: "inline" }}>
          <Typography
            component="span"
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            aria-owns={anchorEl ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={(e) => handlePopoverOpen(e, node.attribs["data-popover-text"])}
          >
            {node.children[0]?.data}
          </Typography>
        </span>
      );
    }
    return undefined;
  };

  useEffect(() => {
    loadSearch();
  }, []);

  useEffect(() => {
    if (search?.observation) {
      localStorage.setItem("selected-subitem", search?.observation.substring(0, 4));
    } else {
      localStorage.removeItem("selected-subitem");
    }
  }, search);

  return (
    <Card title="Sua pesquisa foi realizada por:" loading={!search}>
      {search && searchType === "cnae" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>{renderPreTitle()}:</b> {renderCode()}
            {search.description}
          </Typography>
          <Typography
            sx={{
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>Relacionado ao subitem da LC 116/2003:</b> {search.observation}
          </Typography>
          {search.annotation ? (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                sx={{
                  marginRight: 2,
                  fontWeight: 300,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  "> b": {
                    fontWeight: 500,
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    "& *": {
                      display: "inline",
                      fontWeight: 300,
                    },
                  }}
                >
                  {" "}
                  <b style={{ fontWeight: 500 }}>Observação:&nbsp;</b>
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      textWrap: "balance",
                      "& *": {
                        display: "inline",
                        fontWeight: 300,
                        fontFamily: "Roboto",
                      },
                    }}
                  >
                    {" "}
                    {parse(search.annotation, {
                      replace: (node, idx) => replacePopovers(node, idx),
                    })}
                  </Typography>
                </Typography>
              </Typography>
            </div>
          ) : null}
        </Box>
      )}
      {search && searchType === "answer" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>{renderPreTitle()}:</b> {renderCode()} {search.description}
            {search.gtAnswerName}
          </Typography>
          <Typography
            sx={{
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>Relacionado ao CNAE:</b> {search.observation}
          </Typography>
          {search.annotation ? (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                sx={{
                  marginRight: 2,
                  fontWeight: 300,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  "> b": {
                    fontWeight: 500,
                  },
                }}
              >
                {" "}
                <b>Observação:&nbsp;</b>
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    "& *": {
                      display: "inline",
                      fontWeight: 300,
                      fontFamily: "Roboto",
                    },
                  }}
                >
                  {parse(search.annotation, { replace: (node, idx) => replacePopovers(node, idx) })}
                </Typography>
              </Typography>
            </div>
          ) : null}
        </Box>
      )}

      {search && searchType === "freeSearch" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              marginRight: 2,
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>Pesquisa específica:</b> {search.term}
          </Typography>
          {search.annotation ? (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                sx={{
                  marginRight: 2,
                  fontWeight: 300,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  "> b": {
                    fontWeight: 500,
                  },
                }}
              >
                <b>Observação:&nbsp;</b>
                <Typography
                  sx={{
                    wordBreak: "break-word",

                    "& *": {
                      display: "inline",
                      fontWeight: 300,
                      fontFamily: "Roboto",
                    },
                  }}
                >
                  {parse(search.annotation, { replace: (node, idx) => replacePopovers(node, idx) })}
                </Typography>
              </Typography>
            </div>
          ) : null}
        </Box>
      )}

      <Popover
        id="mouse-over-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{popoverContent}</Typography>
      </Popover>
    </Card>
  );
};
