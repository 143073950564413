import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import CreateEdit from "./CreateEdit/CreateEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";
import AdminTable from "../components/AdminTable/AdminTable";

const BlogCategory = ({ blogCategoryUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showSuccess, showError } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //Data
  const [categories, setCategories] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <CreateEdit data={data} onCloseForm={closeDialog} refreshData={fetchData} />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const categories = await blogCategoryUseCases.getBlogCategories({ checkPermission: true });
      setCategories(categories);
    } catch (error) {
      showError(error, "Erro ao carregar cartegorias");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "title", headerName: "Título", flex: 2 },
    { field: "description", headerName: "Descrição", hide: "md", flex: 5 },
    {
      field: "isSerie",
      headerName: "É série?",
      align: "center",
      flex: 1,
      hide: "md",
      renderCell: ({ value }) => (value ? "Sim" : "Não"),
    },

    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenForm(row)}
          editSecurePath={[adminPerms.editBlogCategory]}
          handleDelete={() => deleteDialog(row.id)}
          deleteSecurePath={[adminPerms.deleteBlogCategory]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo categoria");
      await blogCategoryUseCases.deleteBlogCategory(id);
      showSuccess(`Categoria eliminada com sucesso!`);
      setCategories((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao eliminar categoria");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta categoria?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageListHeader
        title="Blog Categoria"
        titleAdd="Criar nova categoria"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addBlogCategory]}
      />
      <AdminTable
        columns={columns}
        data={categories}
        tableExport
        exportFileName="categorias-blog"
      />
    </>
  );
};

export default BlogCategory;
