import React, { useState } from "react";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { Payments } from "./sections/Payments";
import { makePaymentRecordUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";

export const CreatePayPerson = ({
  partners,
  getPartners,
  refreshData,
  handleReturn,
  incomeNatureOptions,
  onRefreshDocsData = async () => {},
}) => {
  const { openBackdrop } = useBackdrop();
  const { showError, showSuccess } = useSnackbar();
  const apiPayRecord = makePaymentRecordUseCases();
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));

  const [payData, setPayData] = useState({
    id: 0,
    dateRegister: new Date(),
    datePayment: new Date(),
    competence: new Date(),
    is13: false,
    totalAmount: 0,
    taxedAmount: 0,
    amountIR: 0,
    isRRA: false,
    fciScp: 0,
    cnpjFciScp: "",
    percentSCP: 0,
    isJudicialDecision: false,
    countryCode: "",
    observation: "",
    businessPartnerId: 0,
    incomeNatureId: 0,
    companyId: companyId,
  });

  const handleSubmit = async () => {
    openBackdrop(true, "Cadastrando Informações do pagamento...");

    const dataToSend = {
      ...payData,
      dateRegister: new Date().toISOString().split("T")[0],
      datePayment: new Date().toISOString().split("T")[0],
      competence: new Date().toISOString().split("T")[0],
    };

    try {
      await apiPayRecord.createPaymentRecord(dataToSend);
      await onRefreshDocsData();
      showSuccess("Pagamento cadastrado com sucesso");
      handleReturn();
    } catch (error) {
      showError(error, "Erro ao cadastrar pagamento.");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Payments
      partners={partners}
      getPartners={getPartners}
      refreshData={refreshData}
      handleReturn={handleReturn}
      incomeNatureOptions={incomeNatureOptions}
      payData={payData}
      setPayData={setPayData}
      handleSubmit={handleSubmit}
    />
  );
};
