import React from "react";
import { Box } from "@mui/material";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { PersonAddAlt1 } from "@mui/icons-material";
import { PartnerForm } from "~/presentation/views/Reinf/BusinessPartner/PartnerForm/PartnerForm";
import Grid from "@mui/material/Grid2";
import { useDialog } from "~/presentation/providers/DialogProvider";
import { addManualStyles } from "~/presentation/views/Reinf/DocPanel/Create/CreateNfse";

export const Payments = ({
  payData,
  setPayData,
  partners,
  getPartners,
  incomeNatureOptions,
  handleSubmit,
  handleReturn,
}) => {
  const { openSecondDialog, closeSecondDialog } = useDialog();
  const cpfPartnersFilter = partners.filter((partners) => partners.personType === 0);

  //changes values forms
  const handleDateChange = (name, date) => {
    setPayData((prev) => ({
      ...prev,
      [name]: date ? new Date(date) : "",
    }));
  };

  const handleChange = (name, value) => {
    setPayData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOpenDialog = () => {
    openSecondDialog({
      maxWidth: "md",
      customComponent: () => (
        <PartnerForm
          personType={0}
          onClose={closeSecondDialog}
          refreshData={getPartners}
          allPartners={partners}
        />
      ),
    });
  };

  return (
    <>
      <Box sx={addManualStyles.container}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 5, md: 3, xl: 2 }}>
            <Input
              title="Período de apuração"
              type="date"
              viewType="month-year"
              value={payData.dateRegister || ""}
              onChange={(date) => handleDateChange("dateRegister", date)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 7, md: 5 }}>
            <Input
              type="select"
              title="Beneficiário"
              options={cpfPartnersFilter}
              getOptionLabel={(option) => `${formatCpfCnpj(option.cpfCnpj)} - ${option.legalName}`}
              placeholder="Selecionar contratado"
              value={
                cpfPartnersFilter.find((item) => item.id === payData.businessPartnerId) || null
              }
              onChange={(value) => handleChange("businessPartnerId", value?.id)}
              height={180}
              icon={{
                title: "Adicionar beneficiário",
                func: handleOpenDialog,
                component: <PersonAddAlt1 sx={{ color: "#fff" }} />,
              }}
              error={!payData?.businessPartnerId && "Campo Obrigatório"}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 7, md: 4, xl: 5 }}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions.find((item) => item.id === payData.incomeNatureId) || null}
              onChange={(value) => handleChange("incomeNatureId", value?.id)}
              error={!payData?.incomeNatureId && "Campo Obrigatório"}
              height={180}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 5, md: 3 }}>
            <Input
              title="Data do fato gerador"
              type="date"
              value={payData.datePayment || ""}
              onChange={(date) => handleDateChange("datePayment", date)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4, md: 3 }}>
            <Input
              type="amount"
              title="Valor do rendimento bruto"
              name="totalAmount"
              value={payData.totalAmount || ""}
              onChange={(e) => handleChange("totalAmount", e)}
              error={
                payData?.totalAmount < payData?.taxedAmount
                  ? "Se informado, deve ser maior ou igual ao valor Rend. Tributável."
                  : null
              }
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4, md: 3 }}>
            <Input
              type="amount"
              title="Valor do rendimento tributável"
              name="taxedAmount"
              value={payData.taxedAmount || ""}
              onChange={(e) => handleChange("taxedAmount", e)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4, md: 3 }}>
            <Input
              type="amount"
              title="Valor do IRRF"
              name="amountIR"
              value={payData.amountIR || ""}
              onChange={(e) => handleChange("amountIR", e)}
            />
          </Grid>
        </Grid>
        <Box sx={addManualStyles.buttonsContainer}>
          <RButton sx={addManualStyles.cancelButton} variant="outlined" onClick={handleReturn}>
            Cancelar
          </RButton>
          <RButton
            sx={addManualStyles.saveButton}
            onClick={handleSubmit}
            disabled={!payData?.businessPartnerId || !payData?.incomeNatureId}
          >
            Salvar
          </RButton>
        </Box>
      </Box>
    </>
  );
};
