import React from 'react';
import { makeGTFacilUseCases } from '~/main/factories/use-cases/gt-facil';
import { makeCompanyUseCases } from '~/main/factories/use-cases/company';
import { makeCityUseCases } from '~/main/factories/use-cases/city';
import { makeValueVariableUseCases } from '~/main/factories/use-cases/value-variable';
import { makeGTFacilDialogUseCases } from '~/main/factories/use-cases/gt-facil-dialog';
import { makeContentUseCases } from '~/main/factories/use-cases/content';
import { Answer } from '~/presentation/views/GTFacil/Answer/Answer';
import { makeCnaesUseCases } from '../../use-cases/cnaes';

export const makeGTFacilAnswer = () => {
  return (
    <Answer
      cnaeUseCases={makeCnaesUseCases()}
      gtFacilUseCases={makeGTFacilUseCases()}
      companyUseCases={makeCompanyUseCases()}
      cityUseCases={makeCityUseCases()}
      valueVariableUseCases={makeValueVariableUseCases()}
      dialogUseCases={makeGTFacilDialogUseCases()}
      contentUseCases={makeContentUseCases()}
    />
  );
};
