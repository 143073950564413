import React, { useState } from "react";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { makeReinfUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import { isProductionoptions } from "~/presentation/views/Reinf/SendEDF/components/SendEvents/components/Options";
import RenderEventForm from "~/presentation/views/Reinf/SendEDF/components/SendEvents/components/RenderEventForm";

const SendR2099 = ({
  handleVerifyEventStatus,
  competence,
  data,
  companyId,
  companyInfor,
  certificateObj,
  isProduction,
  openDetailsModal,
  closeModal,
}) => {
  const apiSendReinf = makeReinfUseCases();
  const { showSuccess, showError } = useSnackbar();
  const { openBackdrop } = useBackdrop();
  const [prodution, setProdution] = useState(isProduction);

  const fieldsArray = [
    { type: "text", field: "companyName", value: companyInfor?.name, title: "Estabelecimento" },
    {
      type: "text",
      field: "digitalCertId",
      value: certificateObj?.name,
      title: "Certificado Digital",
    },
    {
      type: "select",
      title: "Tipo de ambiente",
      name: "isProduction",
      options: isProductionoptions,
      onChange: () => setProdution((prev) => !prev),
      value: isProductionoptions.find((i) => i.value === prodution),
    },
  ];

  const handleSubmit = async () => {
    openBackdrop(true, "Enviando dados do Evento...");
    const formmatedValues = {
      companyId: companyId,
      digitalCertId: certificateObj?.id,
      calculationDate: competence,
      isProduction: prodution,
      ...data,
    };

    sendReinf2099(formmatedValues);
  };

  const sendReinf2099 = async (params) => {
    try {
      const response = await apiSendReinf.sendEvent(params, "r2099");
      if (response) openDetailsModal(response);
      showSuccess("Evento registrado com sucesso");
    } catch (error) {
      showError(error, "Erro ao enviar evento R-2099");
    } finally {
      handleVerifyEventStatus(competence, "r2099");
      openBackdrop(false);
    }
  };

  return (
    <RenderEventForm
      fields={fieldsArray}
      closeModal={closeModal}
      eventName="R-2099"
      handleSubmit={handleSubmit}
    />
  );
};

export default SendR2099;
