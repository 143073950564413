import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, clientPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiExplanatoryVideoCategoryUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async create(data) {
    if (!hasPermission([adminPerms.addVideoCategory])) {
      throw new Error(securityErrorMessage("adicionar categorias"));
    }
    try {
      await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideoCategory`,
        method: "POST",
        data,
      });
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async get({ checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeVideoCategory])) {
        throw new Error(securityErrorMessage("visualizar categorias"));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideoCategory`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideoCategory/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log(err.response);
    }
  }

  async update(data) {
    if (!hasPermission([adminPerms.editVideoCategory])) {
      throw new Error(securityErrorMessage("editar categorias"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideoCategory/`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async delete(id) {
    if (!hasPermission([adminPerms.deleteVideoCategory])) {
      throw new Error(securityErrorMessage("excluir categorias"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ExplanatoryVideoCategory/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
