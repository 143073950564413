import * as Yup from "yup";

export default Yup.object({
  title: Yup.string().required("Campo Obrigatório!"),
  isSerie: Yup.boolean(),
  description: Yup.string().when("isSerie", {
    is: true,
    then: (schema) => schema.required("Campo Obrigatório!"),
    otherwise: (schema) => schema.notRequired(),
  }),
  imageUrl: Yup.string().when("isSerie", {
    is: true,
    then: (schema) => schema.required("Campo Obrigatório!"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
