import React from "react";
import { Navigate } from "react-router-dom";

const user = JSON.parse(localStorage.getItem("GT_user")) || {};
const permissions = user?.permissions || [];

//Componente para rodear outros components e caso não tenha permissão esconder o componente
export const SecurityPath = ({ children, securePaths = [] }) => {
  if (securePaths.length === 0) return children;
  const hasPermission = securePaths?.some((securePath) =>
    permissions?.some((permissionObj) => permissionObj.securityPermission === securePath)
  );
  if (hasPermission) return children;
  return null;
};

//Componente para rodear as rotas e caso não tenha permissão redirecionar para dashboard do módulo
export const SecurityRoute = ({
  children,
  secureMainRoute = null,
  securePaths = [],
  returnTo = "/",
}) => {
  let hasPermission = false;

  if (secureMainRoute === "admin") {
    hasPermission = hasAdminPermission();
  }

  if (secureMainRoute === "reinf") {
    hasPermission = hasReinfPermission();
  }

  if (secureMainRoute === null) {
    if (securePaths.length === 0) return children;
    hasPermission = securePaths?.some((securePath) =>
      permissions?.some((permissionObj) => permissionObj.securityPermission === securePath)
    );
  }

  if (hasPermission) return children;

  return <Navigate to={returnTo} />;
};

//Função que retornar true/false para condicionais
export const hasPermission = (securePaths = []) => {
  if (securePaths.length === 0) {
    return true;
  }
  const hasPermission = securePaths?.some((securePath) =>
    permissions?.some((permissionObj) => permissionObj.securityPermission === securePath)
  );

  return hasPermission;
};

//Função que retorna se o usuário tem alguma permissão de admin
export const hasAdminPermission = () => {
  const adminPerm = permissions?.some(
    (permission) => permission.securityPermission.split("\\")[0] === "admin"
  );
  return adminPerm;
};

//Função que retorna se o usuário tem alguma permissão de reinf
export const hasReinfPermission = () => {
  if (user?.companyInfor?.companyPlan?.isAllowReinf) {
    const reinfPerm = permissions?.some(
      (permission) => permission.securityPermission.split("\\")[0] === "reinf"
    );
    return reinfPerm;
  }
};

//Função que retorna se o usuário tem alguma permissão de cliente
export const hasClientPermission = () => {
  const clientPerm = permissions?.some(
    (permission) => permission.securityPermission.split("\\")[0] === "client"
  );
  return clientPerm;
};
