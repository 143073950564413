import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material";

export default function MyDropzone({ handleChange }) {
  const [file, setFile] = useState();

  const onDrop = (files) => {
    // handle only one file
    setFile(files[0]);
    handleChange(files[0]);
  };

  const unDrop = () => {
    setFile(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const Container = styled("div")(() => ({
    border: "2px solid #383f48",
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
    cursor: "pointer",
    fontSize: "14px",

    "&:focus": {
      outline: "none",
    },

    "&:hover": {
      backgroundColor: "#dedede",
    },
    "&:active": {
      backgroundColor: "#dedede",
    },

    "&.dragActive": {
      borderColor: "#26c6da",
      color: "#26c6da",
    },

    "& p.fileName": {
      textDecoration: "underline",
    },
  }));

  return (
    <Container className={isDragActive && "dragActive"} {...getRootProps()}>
      {file && (
        <div style={{ position: "relative", width: "100%", height: "auto" }} onClick={unDrop}>
          <div style={{ position: "absolute", right: 0, top: 0 }}>
            <CloseIcon />
          </div>
        </div>
      )}
      <input {...getInputProps()} />
      {file ? (
        <>
          <FileCopyIcon key={1} className="mb_12" />
          <h3 key={2} className="mb_12">
            Arquivo Selecionado:
          </h3>
          <p key={3} className="fileName">
            {file.name}
          </p>
        </>
      ) : (
        [
          <CloudUploadIcon key={1} className="mb_12" />,
          isDragActive ? (
            <p>Solte os arquivos aqui!</p>
          ) : (
            <p>Arraste e solte um arquivo ou clique para selecionar</p>
          ),
        ]
      )}
    </Container>
  );
}
