import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
import { apiURL } from "../../usecases/api";
import { hasPermission } from "~/presentation/security/SecurityPath";
export class ApiCnaes {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async listCnaes({ checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeCnaes])) {
        throw new Error(securityErrorMessage("visualizar cnae"));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/cnae`,
        method: "GET",
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getCnaeById(id) {
    if (!hasPermission([adminPerms.seeCnaes])) {
      throw new Error(securityErrorMessage("visualizar cnae"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/cnae/${id}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async createCnae(data) {
    if (!hasPermission([adminPerms.addCnaes])) {
      throw new Error(securityErrorMessage("adicionar cnae"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/cnae/`,
        method: "POST",
        data,
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateCnae(data) {
    if (!hasPermission([adminPerms.editCnaes])) {
      throw new Error(securityErrorMessage("editar cnae"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/cnae`,
        method: "PUT",
        data,
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteCnae(id) {
    if (!hasPermission([adminPerms.deleteCnaes])) {
      throw new Error(securityErrorMessage("excluir cnae"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/cnae/${id}`,
        method: "DELETE",
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
