import * as Yup from "yup";
import { validarTelefone } from "~/presentation/functions/phoneValidator";
import removeMask from "~/presentation/functions/removeMask";

export default Yup.object({
  name: Yup.string().required("Campo Obrigatório!"),
  email: Yup.string().email("Digite um email válido!").required("Campo Obrigatório!"),
  phoneNumber: Yup.string("Campo obrigatório!")
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(10, "Digite um número válido")
    .test("phone", "Digite um celular válido!", (value) => value && validarTelefone(value))
    .required("Campo obrigatório!"),
  subject: Yup.string().required("Campo Obrigatório!"),
  message: Yup.string().required("Campo Obrigatório!"),
});
