import buildQueryString from "~/presentation/utils/buildQueryString";
import { apiURL } from "../../usecases/api";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { adminPerms, clientPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";

export class ApiBlogUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getBlogAllPosts() {
    //usado no admin/blog
    if (!hasPermission([adminPerms.seeBlog])) {
      return new Error(securityErrorMessage("visualizar posts do blog."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getBlogPosts(queryParams) {
    //usado no /blog
    if (!hasPermission([clientPerms.seeBlog])) {
      return new Error(securityErrorMessage("visualizar posts do blog."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/posts?${buildQueryString(queryParams)}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getBlogTags() {
    //não tem permissão
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/tags`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getLastBlogPosts(number) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/posts?order=asc&limit=${number}&IsSummaryOnly=false`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteBlogPost(id) {
    //usado no admin/blog
    if (!hasPermission([adminPerms.deleteBlog])) {
      return new Error(securityErrorMessage("excluir posts do blog."));
    }

    const response = await this.httpClient.request({
      url: `${apiURL}/blog/${id}`,
      method: "DELETE",
    });
    return response.data;
  }

  async getBlogById(id) {
    //usado no /blog
    if (!hasPermission([clientPerms.seeBlog])) {
      return new Error(securityErrorMessage("visualizar post do blog."));
    }

    // let response = { data: blogPosts.find((post) => post.id == id) };
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getBlogPostToEdit(id) {
    // let response = { data: blogPosts.find((post) => post.id == id) };
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getHighlightBlogPost() {
    // let response = { data: blogPosts.find((post) => post.id == 1) };
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/posts?highlight=true`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getMostAccessedPosts(number) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/posts?orderBy=desc&fieldorder=views&limit=${number}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateBlogPost(data) {
    //usado no admin/blog
    if (!hasPermission([adminPerms.editBlog])) {
      return new Error(securityErrorMessage("editar posts do blog."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async createBlogPost(data) {
    //usado no admin/blog
    if (!hasPermission([adminPerms.addBlog])) {
      return new Error(securityErrorMessage("adicionar posts do blog."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async uploadPost(image) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/upload`,
        method: "POST",
        data: image,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
