import React, { useState, useEffect, useContext } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiExplanatoryVideoCategoryUseCases } from "~/data/usecases/explanatory-video-category/api-explanatory-video-category";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import SearchIcon from "@mui/icons-material/Search";
import { parseISO, format } from "date-fns";
import { Box, Typography, InputBase, Container, Paper, Stack, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Input from "~/presentation/components/Common/Input";

export const ExplanatoryVideos = () => {
  const postsUseCases = new ApiExplanatoryVideoCategoryUseCases(makeHttpClient());
  const { showError } = useContext(SnackbarContext);
  const [posts, setPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const postPerPage = 8;

  const getPosts = async () => {
    try {
      setLoading(true);
      const result = await postsUseCases.get({ checkPermission: false });
      setPosts(result);
    } catch (error) {
      showError(error, "Erro ao carregar posts");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPosts();
  }, []);

  useEffect(() => {
    if (posts.length > 0) {
      setSelectedCategory(posts[0]); // Define a primeira categoria como selecionada
    }
  }, [posts]);

  //lógica de páginação
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // lógica de filtro de pesquisa
  const filteredPosts = selectedCategory
    ? selectedCategory.explanatoryVideos.filter(
        (post) =>
          post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          post.text.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : posts;

  const startIndex = (page - 1) * postPerPage;
  const endIndex = startIndex + postPerPage;
  const currentPosts = filteredPosts.length > 0 ? filteredPosts.slice(startIndex, endIndex) : [];

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#0d62a5",
              paddingBlock: 6,
            }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={0}>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <Stack spacing={2} sx={{ textAlign: "left" }}>
                    <Typography variant="h1" sx={styles.h1}>
                      Central de Ajuda
                    </Typography>
                    <Typography variant="body1" sx={styles.paragraph}>
                      Aqui você encontrará guias detalhados para solucionar qualquer dúvida sobre a
                      utilização do nosso sistema. Explore nossos vídeos e descubra como tirar o
                      máximo proveito de nossas funcionalidades.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box sx={{ paddingBlock: 3, background: "#fff" }}>
            <Container maxWidth="lg">
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, sm: 8 }}>
                  <Input
                    type="select"
                    name="group"
                    placeholder="Selecione para filtrar por categoria"
                    title="Filtrar por categoria"
                    options={posts.map((category) => ({
                      value: category.id,
                      label: category.title,
                    }))}
                    value={posts.find((item) => item.id === selectedCategory)}
                    onChange={(option) => {
                      const newCategory = posts.find((post) => post.id === option.value);
                      setSelectedCategory(newCategory);
                      setPage(1);
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                  <Paper
                    component="form"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#E9E9E9",
                      paddingInline: "30px",
                      fontSize: "30px",
                      fontWeight: 500,
                      border: "none",
                      borderRadius: "20px",
                      height: 42,
                      marginTop: 3,
                    }}
                  >
                    <InputBase
                      sx={{ flex: 1, color: "#000000" }}
                      placeholder="Pesquisar"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <SearchIcon />
                  </Paper>
                </Grid>
                {loading ? (
                  <Box sx={styles.container}>
                    <Typography sx={{ textAlign: "center", fontSize: 18 }}>
                      Carregando vídeos...
                    </Typography>
                  </Box>
                ) : filteredPosts.length === 0 ? (
                  <Box sx={styles.cardsContainer}>
                    <Typography sx={{ fontSize: 18, color: "#666" }}>
                      Nenhum vídeo corresponde a busca ou categoria selecionada.
                    </Typography>
                  </Box>
                ) : (
                  currentPosts.map((item, index) => (
                    // Renderização dos cards
                    <Paper sx={styles.card} key={index} elevation={3}>
                      <Grid container spacing={10} sx={{ width: "100%" }}>
                        <Grid size={{ sm: 6 }}>
                          <Typography sx={{ ...styles.title, fontSize: 17, color: "#000" }}>
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{ ...styles.description, textAlign: "left", fontSize: 12 }}
                          >
                            Atualizado em:{" "}
                            {item.publishDate
                              ? format(parseISO(item.publishDate), "dd/MM/yyyy")
                              : "Data não disponível"}
                          </Typography>
                          <Typography
                            sx={{
                              ...styles.innerHTMLContentStyle,
                            }}
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          />
                        </Grid>
                        <Grid
                          size={6}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <iframe
                            style={{
                              borderRadius: "10px",
                              width: "100%",
                              height: 300,
                            }}
                            src={item.videoUrl}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))
                )}
                <Grid size={12} alignItems="center">
                  <Stack spacing={2}>
                    <Pagination
                      count={
                        selectedCategory
                          ? Math.ceil(selectedCategory.explanatoryVideos.length / postPerPage)
                          : 0
                      }
                      page={page}
                      onChange={handlePageChange}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        "& .MuiPaginationItem-root": {
                          color: "#1341A1",
                          "&.Mui-selected": {
                            backgroundColor: "#1341A1",
                            color: "#fff",
                          },
                        },
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </HomeLayout>
  );
};

const styles = {
  h1: {
    fontSize: 40,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    paddingBottom: 2,
    "@media (max-width: 768px)": {
      textAlign: "center",
      fontSize: 30,
    },
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "left",
    fontSize: 20,
    fontWeight: "bold",
  },
  innerHTMLContentStyle: {
    // estilos da descrição HTML
    fontSize: 14,
    paddingTop: 5,
    "&& strong": {
      "font-weight": "bold",

      "& *": {
        "font-weight": "bold",
      },
    },
  },
  paragraph: {
    fontSize: 18,
    color: "#afceef",
    "@media (max-width: 768px)": {
      textAlign: "center",
    },
  },
  textBox: {
    borderRadius: 5,
    height: "100%",
    width: "100%",
    cursor: "pointer",
    overflow: "hidden",
  },
  description: { color: "#3A3A3A", fontSize: 15, textAlign: "left" },
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "400px",
    width: "100%",
    textAlign: "center",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    height: 330,
    width: "100%",
    overflow: "hidden",
    borderRadius: 5,
    p: 4,
  },
  container: {
    minHeight: "100vh",
    minWidth: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
};
