import React, { useContext, useEffect, useState } from "react";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { PartnerForm } from "~/presentation/views/Reinf/BusinessPartner/PartnerForm/PartnerForm";
import TableActions from "~/presentation/components/TableActions";
import Table from "~/presentation/views/Reinf/components/Table/ReinfTable";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { makeBusinessPartnerUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";

function BusinessPartner() {
  const apiBusinessPartner = makeBusinessPartnerUseCases();
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  // Table Data
  const [partnersData, setPartnersData] = useState([]);

  const handleOpenForms = (item = []) => {
    openDialogBox({
      maxWidth: "md",
      customComponent: () => (
        <PartnerForm
          onClose={closeDialog}
          partnerFormData={item}
          refreshData={setDataIntoState}
          allPartners={partnersData}
        />
      ),
    });
  };

  const handleDelete = async (id) => {
    openDialogBox({
      maxWidth: "sm",
      message:
        "Não é recomendável excluir um parceiro de negócio, pois pode causar problemas nos documentos fiscais associadas a ele. Tem certeza que deseja excluir?",
      callback: () => {
        apiBusinessPartner
          .deleteBusinessPartner(id)
          .then(() => {
            //eliminar da tabela
            setPartnersData((prev) => prev.filter((partner) => partner.id !== id));
            showSuccess("Parceiro de Negócio excluído com sucesso");
          })
          .catch((error) => {
            showError(error, `Erro ao excluir Parceiro de Negócio`);
          });
      },
    });
  };

  useEffect(() => {
    setDataIntoState();
  }, []);

  const getData = async () => {
    const events = await apiBusinessPartner.listBusinessPartner();
    if (events) {
      return events;
    } else {
      showError({}, "Erro no retorno dos eventos, verifique sua conexão");
    }
  };

  const setDataIntoState = async () => {
    try {
      openBackdrop(true, "Carregando informação");
      const response = await getData();
      const data = response?.reverse(); //para o mais recente ficar em cima na tabela
      setPartnersData(data);
    } catch (error) {
      showError(error, "Erro no retorno dos dados");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    {
      field: "cpfCnpj",
      headerName: "CPF/CNPJ do contratado",
      align: "center",
      renderCell: ({ value }) => formatCpfCnpj(value) || "-",
      hide: "md",
    },
    { field: "legalName", headerName: "Nome Empresarial" },

    {
      field: "tradeName",
      headerName: "Nome Fantasia",
      hide: "md",
    },
    {
      field: "ie",
      headerName: "Inscrição Estadual",
      align: "center",
      renderCell: ({ value }) => value || "-",
      sortable: false,
      hide: "lg",
    },
    {
      field: "im",
      headerName: "Inscrição Municipal",
      align: "center",
      renderCell: ({ value }) => value || "-",
      sortable: false,
      hide: "lg",
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenForms(params.row)}
          handleDelete={() => handleDelete(params.row.id)}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderSectionPage
        title="Parceiros de Negócio"
        handleAdd={() => handleOpenForms()}
        titleAdd="Cadastrar Parceiro"
      />
      <Table data={partnersData} columns={columns} rowPadding="4px" />
    </>
  );
}

export default BusinessPartner;
