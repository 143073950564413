import React, { useContext, useState } from "react";
import { useLocation } from "react-router";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { Menu } from "~/presentation/views/Client/MyPanel/components/Menu";
import { NewTaxInformation } from "./Pages/NewTaxInformation";
import { UserData } from "./Pages/UserData";
import { UserPlan } from "./Pages/UserPlan";
import { Container } from "@mui/material";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Visualizations from "./Pages/Visualizations/Visualizations";

const DEFAULT_IMAGE = "/assets/escritorio.jpg";

const pages = [
  {
    id: 1,
    label: "Envio e Controle de IF's",
    securePaths: [clientPerms.seeFiscalInformation],
  },
  { id: 2, label: "Meu Plano" },
  { id: 3, label: "Meus Dados" },
  { id: 4, label: "Visualizar Informações", securePaths: [clientPerms.seeStats] },
];

const MyPanel = ({
  userUseCases,
  companyUseCases,
  taxInformationUseCases,
  userProfilesUseCases,
}) => {
  const query = new URLSearchParams(useLocation().search);
  const page = query.get("page");
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const { showError } = useContext(SnackbarContext);

  // DADOS DO PLANO PAGINA 2 e EMPRESA PAGINA 3
  const [companyData, setCompanyData] = useState({});

  const getCompany = async () => {
    try {
      const result = await companyUseCases.getCompanyById({
        id: companyId,
        checkPermission: false,
      });
      setCompanyData(result);
    } catch (error) {
      showError(error, "Erro ao carregar dados da empresa");
      setCompanyData({});
    }
  };

  const renderPages = () => {
    const pagesComponents = {
      1: (
        <NewTaxInformation
          taxInformationUseCases={taxInformationUseCases}
          companyId={companyId}
          userUseCases={userUseCases}
        />
      ),
      2: (
        <UserPlan
          planDetails={companyData?.plan}
          onGetPlanDetails={getCompany}
          userUseCases={userUseCases}
          userProfilesUseCases={userProfilesUseCases}
        />
      ),
      3: (
        <UserData userUseCases={userUseCases} companyData={companyData} onGetCompany={getCompany} />
      ),
      4: <Visualizations userUseCases={userUseCases} />,
    };

    //se o usuário for para a page=1 (manualmente) e não tiver permissões, redireciona para page=2
    const hasFiscalPerm = hasPermission([clientPerms.seeFiscalInformation]);
    if (page == 1 && !hasFiscalPerm) return pagesComponents[2];

    const hasStatsPerm = hasPermission([clientPerms.seeStats]);
    if (page == 4 && !hasStatsPerm) return pagesComponents[1];

    return pagesComponents[page];
  };

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <SectionTitlePost
            redirectURL="/cliente/meu-painel?page=1"
            title="Meu Painel"
            image={DEFAULT_IMAGE}
            blur={0.8}
          />
          <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
            <Menu pages={pages} />
            {renderPages()}
          </Container>
        </>
      )}
    </HomeLayout>
  );
};

export default MyPanel;

export const painelStyles = {
  bgColor: "#EFF2F4",
  textColor: "#021148",
  hoverColor: "#e2ecf4",
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#222222",
  },
  subtitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 18,
    fontWeight: "bold",
    color: "#021148",
  },
  text: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    color: "#021148",
  },
  container: {},
  subcontainer: {
    backgroundColor: "#EFF2F4",
    borderRadius: "12px",
    padding: 3,
  },
  controlLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: 16,
      fontWeight: 500,
      textAlign: "left",
      fontFamily: "Montserrat",
      color: "#021148",
      maxWidth: "100%",
      paddingLeft: "2px",
    },
  },
  icon: {
    color: "#021148", // Cor padrão do checkbox
    "&.Mui-checked": {
      color: "#021148", // Cor do checkbox quando está marcado
    },
    "& .MuiSvgIcon-root": {
      fontSize: 24, // Tamanho do ícone do checkbox
    },
  },
  input: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    border: "none",
    height: "54px",
    "&:hover": {
      border: "none",
      backgroundColor: "#e2ecf4",
    },
  },
  flex: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    gap: 2,
    width: "100%",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  ButtonAdd: {
    px: 2,
    py: 1,
    backgroundColor: "#081445",
    borderRadius: 3,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    gap: 1,
    "&:hover": {
      backgroundColor: "#1e2b60",
    },
  },
};
