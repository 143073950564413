import React, { useContext, useRef, useState } from "react";
//components
import Navbar from "~/presentation/components/Navbar/Navbar";
import MobileNavigation from "~/presentation/views/LandingPage/components/MobileNavigation/MobileNavigation";
import Modal from "~/presentation/views/LandingPage/components/Modal";
// hooks
import useMobile from "~/presentation/hooks/useMobile";
// sections
import AppContext from "~/presentation/AppContext";
import LoginBox from "~/presentation/views/LandingPage/Home/LoginBox";
import { useNavigate, useLocation } from "react-router-dom";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { useTokenCheck } from "~/presentation/hooks/useTokenCheck";
import { Box } from "@mui/material";
import Footer from "~/presentation/components/Footer";

const HomeLayout = ({ navbarItems, loggedNavbarItems = navbarItems, noNavbarSpace, children }) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [modalState, setModalState] = useState({
    open: false,
    component: null,
  });
  const [mobile] = useMobile(960);
  const homeContainerRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogged } = useContext(AppContext);
  const { openDialogBox } = useContext(DialogContext);
  const { isTokenValid } = useTokenCheck();

  const isLandingPage = location.pathname === "/";

  //na landing page para ser considerado usuário logado tem de ter o token válido
  //nos restos das páginas não para aparecer o modal reLogin
  const userLogged = () => {
    if (isLandingPage) {
      return isTokenValid && isLogged();
    }
    return isLogged();
  };

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const openModal = (component) => {
    setModalState({ open: true, component });
  };

  const closeModal = () => {
    setModalState({ ...modalState, open: false });
  };

  const resetModal = () => {
    setModalState({ open: false, component: null });
  };

  const renderLoginBox = () => (
    <LoginBox
      handleClose={() => openDialogBox({ ...openDialogBox, open: false })}
      navigate={navigate}
    />
  );

  const openLoginDialog = () => {
    openDialogBox({
      disableScrollLock: true,
      customComponent: () => renderLoginBox(),
    });
  };

  const getNavbarItems = () => {
    return userLogged() ? loggedNavbarItems : navbarItems;
  };

  return (
    <>
      <Modal
        open={modalState.open}
        closeModal={closeModal}
        resetModal={resetModal}
        component={modalState.component}
      />
      {mobile && (
        <MobileNavigation
          open={mobileDrawerOpen}
          openLoginDialog={() => openLoginDialog()}
          toggleMobileDrawer={toggleMobileDrawer}
          navbarItems={getNavbarItems()}
          isLogged={userLogged()}
        />
      )}
      <Box
        sx={{
          height: "100vh",
          overflowY: mobile ? "auto" : "unset",
          display: "grid",
          gridTemplateColumns: "auto",
          position: "relative",
        }}
      >
        <Navbar
          parentRef={homeContainerRef}
          toggleMobileDrawer={toggleMobileDrawer}
          openLoginDialog={() => openLoginDialog()}
          navbarItems={getNavbarItems()}
          mobile={mobile}
          isLogged={userLogged()}
        />
        {!noNavbarSpace && (
          <Box
            sx={{
              height: mobile ? "75px" : "70px",
              width: "100%",
              background: "#fafafa",
            }}
            id="top"
          />
        )}
        <Box
          sx={{
            background: "#fff",
          }}
        >
          {children({ mobile, openModal })}
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default HomeLayout;
