import { styled } from "@mui/material/styles";
import {
  List as MuiList,
  ListItem as MuiListItem,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";

// Lista estilizada
export const List = styled(MuiList)(() => ({
  position: "relative",
  overflowY: "auto",

  "& .listItemRelativeWrapper": {
    display: "flex",
    gap: 1,
    alignItems: "center",
  },

  "& .checkbox": {
    zIndex: 10,
  },
}));

// CircularProgress estilizado
export const CircularProgress = styled(MuiCircularProgress)(() => ({
  color: "#383f48",
}));

// Item da lista estilizado
export const ListItem = styled(MuiListItem)(() => ({
  "& .MuiListItem-root": {
    display: "grid",
    gap: "max(1vw, 10px)",
    alignItems: "center",
    minWidth: "100%",
    width: "100%",
    gridTemplateColumns: "min-content auto min-content",
    paddingRight: "10px",

    "@media screen and (min-width: 768px)": {
      paddingRight: 0,
    },

    "& .container": {
      "&:hover": {
        backgroundColor: "#E6E6E6",
      },
    },

    "& .checkbox-spacement": {
      width: "38px",
      height: "38px",
    },

    "& .titleAndSubtitle": {
      width: "100%",
      overflow: "hidden",
      marginLeft: "38px",

      "& .title, & .subtitle": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },

      "& .subtitle": {
        color: "#383f48", // Usando alpha para definir a cor com transparência
        fontSize: "smaller",
      },
    },
  },
}));
