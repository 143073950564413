import React, { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import {
  Call as CallIcon,
  MailOutline as MailOutlineIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import validationSchema from "~/presentation/views/LandingPage/Home/Sections/Contact/validationSchema";
import { transparentize } from "polished";
import Input from "~/presentation/views/Client/components/Common/Input";

const Contact = ({ id, contactUseCases }) => {
  const { showError } = useContext(SnackbarContext);
  const [sentMessage, setSentMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phoneNumber: "",
  });

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = () => {
    setIsSubmitting(true);
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        try {
          await contactUseCases.contactForm(values);
          setSentMessage(true);
        } catch (error) {
          showError(error, "Erro ao enviar mensagem");
        }
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
    setIsSubmitting(false);
  };

  const sendAnimation = () => {
    const wrapper = document.querySelector("[data-animation='wrapper']");
    const container = document.querySelector("[data-animation='container']");
    const animDuration = 1000;

    wrapper.animate(
      [
        { transform: "scale(1)" },
        { transform: "scale(0.8) translateX(0%)" },
        { transform: "scale(0.8) translateX(130%)" },
      ],
      {
        duration: animDuration,
        easing: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
        fill: "forwards",
      }
    );
    // remove wrapper section after animation
    setTimeout(() => {
      container.removeChild(wrapper);
    }, animDuration);
  };

  const sentMsgAnimation = () => {
    const sentMsg = document.querySelector("[data-animation='sent-message']");

    // opacity animation for thank container
    sentMsg.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: 1500,
      easing: "ease-in-out",
      fill: "forwards",
      delay: 400,
    });
  };

  useEffect(() => {
    if (sentMessage) {
      sendAnimation();
      sentMsgAnimation();
    }
  }, [sentMessage]);

  return (
    <Box sx={styles.container} id={id}>
      <Box sx={styles.background}>
        <Box
          sx={styles.background.img}
          component="img"
          src="/assets/bg/bg-opensoluc.png"
          alt="quotation marks bg"
        />
        <Box sx={styles.content}>
          <Box
            size={{ sx: 12, md: 6 }}
            sx={{ display: "flex", flexFlow: "column", gap: 2, flex: 1 }}
          >
            <Box>
              <Typography sx={styles.title}>Contato</Typography>
              <Box sx={styles.line} />
            </Box>
            <Typography sx={styles.text}>
              Mantenha contato conosco através do preenchimento do formulário ao lado ou pelos
              seguintes canais de contato:
            </Typography>
            <Box sx={styles.iconTextWrapper}>
              <CallIcon fontSize="small" />
              <Typography sx={styles.iconTextWrapper.small}>0800 888 1482</Typography>
            </Box>
            <Box sx={styles.iconTextWrapper}>
              <MailOutlineIcon fontSize="small" />
              <Typography sx={styles.iconTextWrapper.small}>
                contato@gestaotributaria.com.br
              </Typography>
            </Box>
          </Box>

          <Box data-animation="container" sx={{ flex: 1 }}>
            {sentMessage && (
              <Box sx={styles.sentMessage} data-animation="sent-message">
                <CheckIcon />
                <div>
                  <div>Mensagem enviada.</div>
                  <div>Em breve entraremos em contato!</div>
                </div>
              </Box>
            )}

            <Grid container spacing={1} data-animation="wrapper">
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  styles={{ ...styles.input, border: "none" }}
                  type="text"
                  placeholder="Nome"
                  name="name"
                  value={values.name}
                  onChange={(e) => handleChangeValue("name", e.target.value)}
                  error={errors.name}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  styles={{ ...styles.input, border: "none" }}
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  value={values.email}
                  onChange={(e) => handleChangeValue("email", e.target.value)}
                  error={errors.email}
                />
              </Grid>
              <Grid size={12}>
                <Input
                  styles={{ ...styles.input, border: "none" }}
                  type="text"
                  placeholder="Assunto"
                  name="subject"
                  value={values.subject}
                  onChange={(e) => handleChangeValue("subject", e.target.value)}
                  error={errors.subject}
                />
              </Grid>
              <Grid size={12}>
                <Input
                  styles={styles.input}
                  type="mask"
                  mask="(00) 0000-0000"
                  placeholder="(00) 0000-0000"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={(value) => handleChangeValue("phoneNumber", value)}
                  error={errors.phoneNumber}
                />
              </Grid>
              <Grid size={12}>
                <Input
                  styles={{ ...styles.input, border: "none" }}
                  type="text"
                  placeholder="Mensagem"
                  name="message"
                  multiline
                  minRows={8}
                  value={values.message}
                  onChange={(e) => handleChangeValue("message", e.target.value)}
                  error={errors.message}
                />
              </Grid>
              <Grid size={12} sx={styles.buttonSend}>
                <Button onClick={handleValidation} sx={styles.buttonSend.button} type="submit">
                  {isSubmitting ? <CircularProgress color="inherit" size="22px" /> : "ENVIAR"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;

const styles = {
  container: {
    position: "relative",
    background: "linear-gradient(180deg, #102342 0%, #2455b2 100%)",
    paddingBottom: { xs: "15%", lg: 0 },
  },
  background: {
    position: "relative",
    top: "30px",
    left: "50%",
    transform: "translateX(-50%)",
    height: "fit-content",
    backgroundImage: 'url("/assets/bg/paper.png")',
    boxShadow: "0px 0px 20px -5px rgba(0, 0, 0, 0.25)",
    borderRadius: "18px",
    overflow: "hidden",
    width: "min(1230px, 94%)",
    fontFamily: '"Montserrat", sans-serif',
    "@media (min-width: 1280px)": {
      top: "-30px",
      minHeight: "600px",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      background: transparentize(0.75, "#fff"),
    },
    img: {
      position: "absolute",
      bottom: "-80px",
      left: "-25px",
      height: "300px",
      width: "300px",
      opacity: 0.5,
      userSelect: "none",
    },
  },
  content: {
    padding: "5% 5% 10% 5%",
    display: "flex",
    gap: "32px",
    flexDirection: "column",
    "@media (min-width: 768px)": {
      flexDirection: "row",
      padding: "5%",
    },
  },
  title: {
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: "normal",
    fontSize: "40px",
    lineHeight: "43px",
  },
  line: {
    marginTop: "14px",
    width: "53px",
    height: "2px",
    background: "#cf9455",
  },
  text: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: "1rem",
  },
  iconTextWrapper: {
    display: "flex",
    gap: "12px",
    small: {
      fontWeight: 300,
      fontSize: "14px",
      textAlign: "center",
      letterSpacing: "0.06em",
      color: "#333",
      wordWrap: "break-word",
    },
  },
  sentMessage: {
    opacity: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    gap: "20px",
    width: "100%",
    "> svg": {
      transform: "scale(1.5)",
    },
    "> div": {
      textAlign: "center",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "130%",
      letterSpacing: "0.02em",
    },
  },
  form: {},
  input: {
    background: "white",
    borderRadius: "12px",
    outline: 0,
  },
  buttonSend: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    button: {
      width: "min(300px, 100%)",
      height: "50px",
      borderRadius: "16px",
      background: "linear-gradient(180deg, #2455b2 0%, #102342 100%)",
      color: "#fff",
      fontFamily: '"Bebas Neue", sans-serif',
      fontSize: "30px",
      lineHeight: "normal",
      border: "none",
    },
  },
};
