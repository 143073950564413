import React, { useEffect, useState } from "react";
import ReinfTable from "~/presentation/views/Reinf/components/Table/ReinfTable";
import { getColumns } from "~/presentation/views/Reinf/SendEDF/components/DocsTable/components/Columns";
import RenderLayout from "~/presentation/views/Reinf/components/Layouts/nfseLayout/RenderLayout";
import useHandleErrors from "~/presentation/views/Reinf/Hooks/useHandleErrors";
import useHandleDocFiscais from "~/presentation/views/Reinf/Hooks/useHandleDocFiscais";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { useDialog } from "~/presentation/providers/DialogProvider";
import DownButtons from "~/presentation/views/Reinf/SendEDF/components/DocsTable/components/DownButtons";
import TableFilters from "~/presentation/views/Reinf/SendEDF/components/DocsTable/components/TableFilters";
import { useEDF } from "~/presentation/views/Reinf/SendEDF/SendEDF";
import { Box } from "@mui/material";
import { phisicalPersonColumns } from "~/presentation/views/Reinf/DocPanel/Table/Columns";
import RenderPaymentLayout from "~/presentation/views/Reinf/components/Layouts/paymentLayout/RenderPaymentLayout";

function DocsTable({
  setInvalidStates,
  allInvalidNfse,
  currentInvalidNfse,
  setCurrentInvalidNfse,
  setValidStates,
  allValidNfse,
  currentValidNfse,
  setCurrentValidNfse,
  setTableType,
  tableType,
  tablePage,
  setTablePage,
  selectedRows,
  setSelectedRows,
}) {
  const { openDialogBox } = useDialog();
  const { handleClearError } = useHandleErrors();
  const { handleChangedNfse } = useHandleDocFiscais();
  const {
    fetchDataOptions,
    serviceClassificationOptions,
    incomeNatureOptions,
    businessPartnerOptions,
    activeFilters,
    isEventClosed,
    refreshData,
  } = useEDF();

  const [nfseChanged, setNfseChanged] = useState([]);

  const [errors, setErrors] = useState({});

  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const R2010 = activeFilters.event === "r2010";
  const R4010 = activeFilters?.event === "r4010";
  const R4020AB = activeFilters.event === "r4020" && (QSO === "A" || QSO === "B");
  const R4020C = activeFilters.event === "r4020" && QSO === "C";
  const R4020D = activeFilters.event === "r4020" && QSO === "D";

  const isSendTable = tableType === "sendDoc";
  const isCorrectionTable = tableType === "correctDoc";
  const allDataTable = isSendTable ? allValidNfse : allInvalidNfse;
  const dataTable = isSendTable ? currentValidNfse : currentInvalidNfse;
  const setDataTable = isSendTable ? setCurrentValidNfse : setCurrentInvalidNfse;

  const handleChangeFieldValue = ({ newItem, id, field, byPass }) => {
    //byPass é para as contas automaticas não darem à nota o estado de alterada
    if (field) handleClearError(`[${id}].${field}`, setErrors);
    changeField({ setData: setDataTable, id: id, newItem: newItem });
    if (!byPass) handleNfseChanges(id, newItem);
  };

  const changeField = ({ setData, id, newItem }) => {
    setData((prev) => prev.map((doc) => (doc.id === id ? { ...doc, ...newItem } : doc)));
  };

  const handleChangeINSSfield = ({ newItem, id, nestedIndex, field, byPass }) => {
    //byPass é para as contas automaticas não darem à nota o estado de alterada
    if (field) handleClearError(`[${id}].itemsNFse[${nestedIndex}].${field}`, setErrors);

    changeInssField({
      state: dataTable,
      newItem: newItem,
      id: id,
      nestedIndex: nestedIndex,
      byPass: byPass,
    });
  };

  const changeInssField = ({ state, newItem, id, nestedIndex, byPass }) => {
    const currentNFse = state.find((i) => i.id === id);
    if (!currentNFse) return;
    const updatedItemINSS = [...currentNFse.itemsNFse];
    updatedItemINSS.splice(nestedIndex, 1, newItem);
    handleChangeFieldValue({
      newItem: { itemsNFse: updatedItemINSS },
      id: id,
      byPass: byPass,
    });
    if (!byPass) handleNfseChanges(id, { itemsNFse: updatedItemINSS });
  };

  const handleNfseChanges = (id, item) => {
    //Enviar para o state de notas alteradas o id e os campos alterados com seus devidos valores
    setNfseChanged((prev) => {
      const index = prev?.findIndex((key) => key.id === id);
      if (index !== -1) {
        return prev.map((entry, i) => (i === index ? { ...entry, ...item } : entry));
      }
      return [...prev, { id, ...item }];
    });
  };

  useEffect(() => {
    const handleChange = (id, isEqual = false) => {
      handleChangeFieldValue({
        newItem: { hadChanges: isEqual },
        id: id,
        byPass: true,
      });
    };

    //HOOK que lida com as notas que foram alteradas e verifica o seu estado
    handleChangedNfse({
      changedNfse: nfseChanged,
      allNfse: allDataTable,
      setChanged: (id) => handleChange(id, true),
      setEqual: (id) => handleChange(id, false),
    });
  }, [nfseChanged]);

  const handleSetDocReadyToSend = () => {
    const text =
      selectedRows.length === 1 ? "o documento" : "os " + selectedRows.length + " documentos";

    const selectedRowsIDs = selectedRows.map((item) => item.id);

    openDialogBox({
      message: `Deseja habilitar ${text} para envio?`,
      callback: () => {
        setValidStates([...allValidNfse, ...selectedRows]);
        setInvalidStates(allInvalidNfse.filter((item) => !selectedRowsIDs.includes(item.id)));
        setTableType("sendDoc");
      },
    });
  };

  const columns = R4010
    ? phisicalPersonColumns
    : getColumns({ R2010: R2010, R4020AB: R4020AB, R4020C: R4020C, R4020D: R4020D });

  const renderCollapse = (row) => {
    if (R4010) {
      return (
        <RenderPaymentLayout
          data={row}
          businessPartnerOptions={businessPartnerOptions}
          incomeNatureOptions={incomeNatureOptions}
          refreshDatePay={refreshData}
        />
      );
    }
    return (
      <RenderLayout
        nfseData={row}
        selectedEvent={activeFilters.event}
        refreshData={refreshData}
        isEventClosed={isEventClosed}
        handleChangeFieldValue={handleChangeFieldValue}
        handleChangeINSSfield={handleChangeINSSfield}
        serviceClassificationOptions={serviceClassificationOptions}
        incomeNatureOptions={incomeNatureOptions}
        businessPartnerOptions={businessPartnerOptions}
        errors={errors}
        setErrors={setErrors}
        fetchOptions={fetchDataOptions}
      />
    );
  };

  return (
    <>
      <Box sx={tableContainer}>
        {isCorrectionTable && (
          <RButton
            onClick={handleSetDocReadyToSend}
            disabled={selectedRows.length === 0}
            sx={{ width: "min(300px, 100%)", mb: 1 }}
          >
            Habilitar Doc. para Envio
          </RButton>
        )}
        <TableFilters
          setData={setDataTable}
          R2010={R2010}
          allData={allDataTable}
          setTablePage={setTablePage}
          isCorrectionTable={isCorrectionTable}
        />
        <ReinfTable
          columns={columns}
          data={dataTable}
          collapse={renderCollapse}
          disabledCheckBox={(row) => row.hasEventSentWithSameCnpj}
          disabledCheckBoxTooltip="Já existe um envio feito com este CNPJ."
          tablePage={tablePage}
          setTablePage={setTablePage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          customStyles={{ container: { mb: 0 } }}
          disabledmMargin
          tableSearch={false}
        />
        <DownButtons isSendTable={isSendTable} selectedRows={selectedRows} />
      </Box>
    </>
  );
}

export default DocsTable;

export const tableContainer = {
  p: { xs: 1, md: 2 },
  backgroundColor: "#e6e6e6",
  borderRadius: 3,
  mx: { xs: 1, md: 3 },
  my: 1,
  mb: 12,
};
