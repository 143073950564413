import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { TabsStyles } from "./Tab.styles";

export function Tabs({ tabs, handleOpenGenerateReportDialog }) {
  const [value, setValue] = useState(0);

  const handleChange = (newValue, isDisabled) => {
    if (isDisabled) return;
    setValue(newValue);
  };

  const isSelected = (v) => v === value;

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 max(10px, 0.5vw)",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <TabsStyles.TabsHeader>
          {tabs.map(({ title, isDisabled }, index) => (
            <TabsStyles.Tab
              onClick={() => handleChange(index, isDisabled)}
              key={`tab-header-${title}-${index}`}
              selected={isSelected(index)}
              isDisabled={isDisabled}
            >
              {title}
            </TabsStyles.Tab>
          ))}
        </TabsStyles.TabsHeader>
        <TabsStyles.Tab
          onClick={handleOpenGenerateReportDialog}
          key={`tab-header-gerar-relatorio`}
          selected={true}
          isDisabled={false}
        >
          Gerar Relatório
        </TabsStyles.Tab>
      </Box>
      <Box
        sx={{
          height: { xs: "20vh", sm: "35vh", md: "55vh" },
          overflowY: "auto",
        }}
      >
        <TabsStyles.TabPanelWrapper>
          {tabs.map(
            ({ panel: Panel }, index) => isSelected(index) && <Panel key={`tabs-panel-${index}`} />
          )}
        </TabsStyles.TabPanelWrapper>
      </Box>
    </Container>
  );
}
