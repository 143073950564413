import * as Yup from "yup";

export default Yup.object({
  question: Yup.string().required("Campo Obrigatório!"),
  answers: Yup.array().of(
    Yup.object().shape({
      text: Yup.string().required("Campo Obrigatório!"),
    })
  ),
  publishDate: Yup.date().typeError("Selecione uma data").required("Campo obrigatório"),
});
