import React from "react";

const TaskIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="64px"
      viewBox="0 -960 960 960"
      width="64px"
      fill="#FFF"
    >
      <path d="m434-254.76 230.91-231.15-40.67-40.68-190 190L331-439.83l-40.91 40.92L434-254.76ZM222.15-74.02q-27.6 0-47.86-20.27-20.27-20.26-20.27-47.86v-675.7q0-27.7 20.27-48.03 20.26-20.34 47.86-20.34h361.48l222.59 222.59v521.48q0 27.6-20.34 47.86-20.33 20.27-48.03 20.27h-515.7Zm326.7-557.83v-186h-326.7v675.7h515.7v-489.7h-189Zm-326.7-186v186-186 675.7-675.7Z" />
    </svg>
  );
};

export default TaskIcon;
