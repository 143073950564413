import React, { useState } from "react";
import { Clear } from "@mui/icons-material";
import { InputWrapper, ImgSearch } from "./styles";

const SecondarySearchField = ({
  onSearch,
  initialValue = "",
  clearSearch,
  width,
  maxWidth,
  ...props
}) => {
  const inputRef = React.useRef();
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleClear = () => {
    setValue("");
    onSearch("");
  };

  const onEnterPress = (event) => {
    if (event.key == "Enter") {
      onSearch(value);
    }
  };

  return (
    <InputWrapper
      {...props}
      onClick={() => inputRef.current.focus()}
      maxWidth={maxWidth}
      width={width}
    >
      <input ref={inputRef} value={value} onChange={handleChange} onKeyPress={onEnterPress} />
      {value && clearSearch && <Clear onClick={handleClear} />}
      <button onClick={() => onSearch(value)}>
        <ImgSearch src="/assets/icons/SearchIcon.png" alt="iconSearch" />

        <span />
      </button>
    </InputWrapper>
  );
};

export default SecondarySearchField;
