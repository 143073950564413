import { Box, IconButton, Input, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { Add, Delete, InfoOutlined } from "@mui/icons-material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { inputStyles } from "~/presentation/components/ListComponent/ListComponent";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

const cleaner = (str) => str.replace(/[\s-/.()]/g, "").trim();

export const onInBulkDelete = ({
  codes = [],
  data = [],
  onChange = () => {},
  showUpdateCount = () => {},
}) => {
  let updatedCount = 0;
  const newArray = data.filter((i) => {
    const codeC = cleaner(i["code"]) || "";
    const detailC = i["detailCode"] || "";
    const isPart = codes.includes(`${codeC}${detailC}`);
    if (isPart) updatedCount++;
    return !isPart;
  });
  onChange(newArray); //entraga o novo fitrado
  showUpdateCount(updatedCount); //entrega o numero de items apagados
};

function InBulkHandler({
  action = "add",
  field = "code",
  nameField = "CNAES",
  onChange = () => {}, //recebe array de items para delete por props no action "delete"
  data = [],
}) {
  const { showInfo } = useContext(SnackbarContext);
  const [inBulkQuery, setInBulkQuery] = useState("");

  const handleQueryChange = (e) => setInBulkQuery(e.target.value);

  const isAdd = action === "add";

  const Icon = ({ ...props }) => (isAdd ? <Add {...props} /> : <Delete {...props} />);

  const handleInBulk = () => {
    const codes = inBulkQuery
      .replaceAll(",", ";")
      .split(";")
      .map((item) => cleaner(item));
    if (codes?.length > 0) {
      if (isAdd) {
        onAdd(codes); //ListComponent
      } else {
        onChange(codes); //Answer Form
      }
    }
  };

  const onAdd = (codes) => {
    let updatedCount = 0;
    const newSelections = [];

    if (nameField === "CNAES") {
      codes.forEach((c) => {
        data.filter((i) => {
          const codeC = cleaner(i[field]) || "";
          const detailC = i["detailCode"] || "";
          if (`${codeC}${detailC}` === c) {
            // Verifica se o item já está no newSelections pelo id
            const exists = newSelections.some((item) => item.id === i.id);

            if (!exists) {
              updatedCount++;
              newSelections.push(i);
            }
          }
        });
      });
    } else {
      codes.forEach((c) => {
        data.filter((i) => {
          const codeC = cleaner(i[field]) || "";
          if (codeC === c) {
            // Verifica se o item já está no newSelections pelo id
            const exists = newSelections.some((item) => item.id === i.id);

            if (!exists) {
              updatedCount++;
              newSelections.push(i);
            }
          }
        });
      });
    }

    showInfo(
      updatedCount === 1
        ? `Foi selecionado 1 ${nameField}`
        : `Foram selecionados ${updatedCount} ${nameField}`
    );
    onChange((prev) => {
      const checkedIds = [...prev].map((i) => i.id);
      const filteredNewSelections = newSelections.filter((i) => !checkedIds.includes(i.id));
      return [...prev, ...filteredNewSelections];
    });
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "end", width: "100%" }}>
      <Input
        type="text"
        placeholder={`${isAdd ? "Adicione" : "Exclua"} separando por ; ou ,`}
        value={inBulkQuery}
        onChange={handleQueryChange}
        disabled={data?.length === 0}
        disableUnderline
        endAdornment={
          <Tooltip
            placement="top-end"
            title={
              <Box sx={{ fontFamily: "Montserrat", fontSize: 14, color: "#fff" }}>
                <p style={{ fontWeight: 700 }}>Use apenas códigos!</p>
                <p>
                  <strong>Ex:</strong>{" "}
                  {nameField === "CNAES" ? "0000-0/00 (00);11122233,222-2/22" : "1.01;102,103"}...
                </p>
              </Box>
            }
          >
            <span>
              <InfoOutlined sx={{ cursor: "help", color: "gray", mt: "6px", pl: "6px" }} />
            </span>
          </Tooltip>
        }
        sx={inputStyles}
      />
      <Tooltip placement="top-end" title={`${isAdd ? "Adicionar" : "Excluir"} em massa!`}>
        <span>
          <IconButton
            type="button"
            disabled={!inBulkQuery}
            onClick={handleInBulk}
            sx={{ ...formStyles.addIcon, height: "36px" }}
          >
            <Icon sx={formStyles.addIcon.icon} />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
}

export default InBulkHandler;
