import React from "react";
import { Box } from "@mui/material";
import SetPeriodButton from "./SetPeriodButton";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Grid from "@mui/material/Grid2";
import { getDocType } from "~/presentation/views/Reinf/Utils/utilsReinf";
import ActiveFilters from "~/presentation/views/Reinf/components/ActiveFilters/ActiveFilters";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";
import useHandleDates from "~/presentation/views/Reinf/Hooks/useHandleDates";
import { firstDayPastMonth, today } from "~/presentation/utils/constants";

const user = JSON.parse(localStorage.getItem("GT_user"));

function Filters({
  unactivefilters,
  onChangeFilter,
  onFilter,
  activeFilters: aFilters,
  onCleanAllFilters,
}) {
  const { handleDateErrors, handleVerifyPeriod } = useHandleDates();

  const isDateWrong = handleDateErrors(unactivefilters.initialDate, unactivefilters.finalDate);
  const isPeriodMoreThanTwoMonths = handleVerifyPeriod(
    unactivefilters.initialDate,
    unactivefilters.finalDate
  );

  const orderByOptions = {
    dateOfIssue: "Data de Emissão",
    dateOfPayment: "Data de Pagamento",
  };

  const eventSentOptions = {
    isR2010: "R-2010",
    isR4020: "R-4020",
    never: "Não enviado",
  };

  const date =
    aFilters.initialDate && aFilters.finalDate
      ? `${formatDateToBrazilian(aFilters.initialDate)} - ${formatDateToBrazilian(aFilters.finalDate)}`
      : null;

  const type = [0, 1, 2, 3, 4, 98, 99].includes(aFilters.documentType)
    ? getDocType(aFilters.documentType)
    : "";

  const activeFilterData = [
    {
      value: aFilters?.orderBy ? orderByOptions[aFilters.orderBy] : "",
      label: "Filtrar por",
    },
    {
      value: date,
      label: "Período",
    },
    {
      value: aFilters?.documentSituation ? eventSentOptions[aFilters.documentSituation] : "",
      label: "Situação do doc",
    },
    {
      value: type,
      label: "Tipo de doc",
    },
  ];

  const disabledDeleteAll = () => {
    if (aFilters?.orderBy || aFilters?.documentSituation || type) return false;
    if (aFilters.initialDate !== firstDayPastMonth || aFilters.finalDate !== today) return false;
    return true;
  };

  const disabledFilterButton =
    JSON.stringify(aFilters) === JSON.stringify(unactivefilters) ||
    isDateWrong ||
    isPeriodMoreThanTwoMonths;

  return (
    <Box sx={filterStyles.container}>
      <ActiveFilters
        onDeleteFilters={onCleanAllFilters}
        filtersData={activeFilterData}
        disabledDeleteAll={disabledDeleteAll()}
      />
      <Grid container spacing={1} sx={filterStyles.gridContainer}>
        <Grid size={{ xs: 12, sm: 6, md: 2.5 }}>
          <RButton
            type="filterSelect"
            title="Filtrar Por"
            defaultValue="dateOfIssue"
            placeholder="Selecione uma ordem"
            value={unactivefilters.orderBy}
            onChange={(option) => onChangeFilter({ orderBy: option.target?.value })}
            options={[
              { value: "dateOfIssue", label: "Data de emissão" },
              { value: "dateOfPayment", label: "Data de pagamento" },
            ]}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2.5 }}>
          <SetPeriodButton
            initialDate={unactivefilters.initialDate}
            setInitialDate={(date) => onChangeFilter({ initialDate: date })}
            finalDate={unactivefilters.finalDate}
            setFinalDate={(date) => onChangeFilter({ finalDate: date })}
            placeholder="Selecione um período"
            isDateWrong={isDateWrong || isPeriodMoreThanTwoMonths}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2.5 }}>
          <RButton
            type="filterSelect"
            title="Situação"
            value={unactivefilters.documentSituation}
            onChange={(option) => onChangeFilter({ documentSituation: option.target?.value })}
            options={[
              { value: "never", label: "Não enviados em nenhum evento" },
              { value: "isR2010", label: "Enviados no evento R-2010" },
              { value: "isR4020", label: "Enviados no evento R-4020" },
            ]}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2.5 }}>
          <RButton
            type="filterSelect"
            title="Tipo de Documento"
            value={unactivefilters.documentType}
            onChange={(option) => onChangeFilter({ documentType: option.target?.value })}
            options={[
              { value: 0, label: "NFSe - Nota fiscal de serviço" },
              { value: 1, label: "NFe - Nota fiscal de produto" },
              { value: 2, label: "SIAFI" },
              { value: 3, label: "Planilha" },
              { value: 4, label: "Recibo" },
              ...(["admin", "superAdmin"].includes(user?.role)
                ? [{ value: 98, label: "Pagamento pessoa física" }]
                : []),
              { value: 99, label: "Outros" },
            ]}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 2 }} sx={{ pb: { md: "1px" }, mt: { xs: 1, md: 0 } }}>
          <RButton disabled={disabledFilterButton} onClick={onFilter}>
            APLICAR
          </RButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Filters;

export const inputStyle = {
  backgroundColor: "#DCDCDC",
  border: "none",
  borderRadius: "5px",
  padding: "0px 10px",
  height: "40px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  fontSize: 14,
  fontFamily: "Montserrat",
  outline: "none",
  boxShadow: "none",
};

export const filterStyles = {
  container: {
    backgroundColor: "white",
    mt: 1,
    mx: { xs: 1, md: 3 },
  },
  gridContainer: {
    display: "flex",
    alignItems: "end",
    mt: 1,
  },
};
