export default [
  {
    id: 1,
    label: "Início",
    name: "home",
    link: "/#home",
  },
  {
    id: 2,
    label: "Sobre",
    name: "about",
    link: "/#about",
  },
  {
    id: 3,
    label: "Produtos",
    name: "products",
    link: "/#products",
  },
  {
    id: 6,
    label: "Planos",
    name: "plans",
    link: "/#plans",
  },
  {
    id: 7,
    label: "Contato",
    name: "contact",
    link: "/#contact",
  },
  {
    id: 8,
    label: "Blog",
    name: "blog",
    link: "/#blog",
  },
];
