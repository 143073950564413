import React from "react";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { ModalImport } from "~/presentation/views/Reinf/components/ModalImport/ModalImport";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { reinfPerms } from "~/presentation/security/SecurePaths";
import { useDialog } from "~/presentation/providers/DialogProvider";
import { CreateNfse } from "~/presentation/views/Reinf/DocPanel/Create/CreateNfse";

const Header = ({ onRefreshData, hasUnsavedDocuments }) => {
  const { openDialogBox, closeDialog, openSecondDialog } = useDialog();

  const modalProps = { onClose: closeDialog, onRefreshData: onRefreshData };

  const handleOpen = (type) => {
    if (hasUnsavedDocuments) {
      openSecondDialog({
        maxWidth: "sm",
        message: hasUnsavedDocuments,
        callback: () => openModal(type),
      });
    } else {
      openModal(type);
    }
  };

  const openModal = (type) => {
    openDialogBox({
      blockScreen: true,
      maxWidth: "lg",
      customComponent: () => {
        if (type === "import") {
          return <ModalImport {...modalProps} />;
        } else {
          return <CreateNfse {...modalProps} />;
        }
      },
    });
  };

  const buttonStyles = {
    borderRadius: "20px",
    minHight: 40,
    maxHeight: 50,
    fontFamily: "Montserrat",
  };

  return (
    <>
      <HeaderSectionPage
        title="Painel de Documentos Fiscais"
        pageReturn="/reinf"
        buttonPrimary={
          <SecurityPath
            securePaths={[
              reinfPerms.importXml,
              reinfPerms.importSpreadsheet,
              reinfPerms.importSiafi,
            ]}
          >
            <RButton
              startIcon={<FolderCopyOutlinedIcon />}
              onClick={() => handleOpen("import")}
              sx={buttonStyles}
            >
              Importar arquivos
            </RButton>
          </SecurityPath>
        }
        buttonSecond={
          <SecurityPath securePaths={[reinfPerms.addFiscalDocument]}>
            <RButton onClick={() => handleOpen("create")} sx={buttonStyles}>
              Adicionar Doc. Manualmente
            </RButton>
          </SecurityPath>
        }
      />
    </>
  );
};

export default Header;
