import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { makeLandingPageHome } from "~/main/factories/pages/landing-page/home";

const LandingPageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={makeLandingPageHome()} />
      <Route path="/" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default LandingPageRoutes;
