import React, { useState } from "react";
import {
  Typography as Text,
  Box,
  FormControlLabel,
  Tooltip,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Input, { errorColor } from "~/presentation/views/Reinf/components/Common/Inputs";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import Grid from "@mui/material/Grid2";
import { radioIconStyle } from "../ModalImport";
import Checkbox from "~/presentation/components/Checkbox";
import { renderIcon } from "./SHEET";
import useHandleDates from "~/presentation/views/Reinf/Hooks/useHandleDates";

function SIAFI({ handleSIAFI, handleSIAFIOB, files = [], setFiles, handleClose }) {
  const { handleVerifyPeriod } = useHandleDates();
  const { showError } = useSnackbar();
  const [xmlFile, setXmlFile] = useState(null);
  const [xlsxFile, setXlsxFile] = useState(null);

  const [importType, setImportType] = useState("0");
  const [importDDF, setImportDDF] = useState({
    isDDF025: true,
    isDDF021: false,
  });
  const isImportSIAFI = importType === "0";

  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  const handleVerifyDates = () => {
    const isPeriodMoreThanTwoMonths = handleVerifyPeriod(initialDate, finalDate);
    if (isPeriodMoreThanTwoMonths) {
      showError({}, "O período tem de ser no máximo de 2 meses.");
    } else {
      submit();
    }
  };

  const submit = () => {
    if (isImportSIAFI) {
      handleSIAFI({
        xml: xmlFile,
        xlsx: xlsxFile,
        dateI: initialDate,
        dateF: finalDate,
        isDDF025: importDDF.isDDF025,
        isDDF021: importDDF.isDDF021,
      });
    } else {
      handleSIAFIOB(xlsxFile);
    }
  };

  const responseXLSLFile = files[0]?.fileXLSL || null;
  const responseXMLFile = files[0]?.fileXML || null;
  const areBothDDFDisabled = !importDDF.isDDF021 && !importDDF.isDDF025;

  const disabledButtonSIAFI =
    !xmlFile ||
    !xlsxFile ||
    !finalDate ||
    !initialDate ||
    responseXMLFile ||
    responseXLSLFile ||
    areBothDDFDisabled;
  const disableButtonNotSIAFI = !xlsxFile || responseXLSLFile;

  const handleSIAFIChange = (event) => {
    const { id, files } = event.target;
    if (files.length > 0) {
      if (id === "xml-file-input") {
        setXmlFile(files[0]);
      } else if (id === "xlsx-file-input") {
        setXlsxFile(files[0]);
      }
    }
  };

  const handleCancel = () => {
    setXlsxFile(null);
    setXmlFile(null);
    setFiles([]);
  };

  const formImportTypeFields = [
    {
      tooltip: "Importa XML SIAFI + Ordem bancária de um período",
      label: "Importar XML SIAFI + ordem bancária",
      value: "0",
    },
    {
      tooltip:
        "Complementa as informações de docs já presentes no sistema com as informações da ordem bancária importada",
      label: "Importar ordem bancária",
      value: "1",
    },
  ];

  const formImportDDFFields = [
    {
      tooltip: "Importar DDF025 (IR)",
      label: "Importar DDF025 (IR)",
      value: "isDDF025",
    },
    {
      tooltip: "Importar DDF021 (INSS)",
      label: "Importar DDF021 (INSS)",
      value: "isDDF021",
    },
  ];

  const renderMessage = (item) => {
    if (!isImportSIAFI && typeof item?.message === "string") {
      return (
        <Box sx={styles.answers.box}>
          {renderIcon(item?.code || 3)}
          <Text sx={styles.answers.box.text}>{item?.message}</Text>
        </Box>
      );
    }

    if (Array.isArray(item?.message)) {
      // Caso haja respostas do BACK
      if (item?.message.length > 0) {
        return item?.message.map(({ code, message }, index) => (
          <Box key={index} sx={styles.answers.box}>
            {renderIcon(code)}
            <Text sx={styles.answers.box.text}>{message}</Text>
          </Box>
        ));
      } else {
        // Caso a resposta venha []
        return (
          <Box sx={styles.answers.box}>
            {renderIcon(3)}
            <Text sx={styles.answers.box.text}>Arquivo importado com sucesso.</Text>
          </Box>
        );
      }
    } else if (typeof item?.message === "string") {
      return (
        // Caso a resposta seja uma string apenas
        <Box sx={styles.answers.box}>
          {renderIcon(1)}
          <Text sx={styles.answers.box.text}>{item?.message}</Text>
        </Box>
      );
    } else {
      return (
        // Caso a resposta não seja string nem array
        <Box sx={styles.answers.box}>
          {renderIcon(1)}
          <Text sx={styles.answers.box.text}>Erro na importação.</Text>
        </Box>
      );
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* RESPOSTA API */}
      {Array.isArray(files) &&
        files.map((item, index) => (
          <Box key={index}>
            {item.loading ? (
              <Skeleton variant="rounded" sx={{ borderRadius: 2 }} width="100%" height="25vh" />
            ) : (
              <Box sx={styles.answerContainer}>
                <Text sx={styles.answerContainer.title}>Resposta:</Text>
                <Box sx={styles.answers}>{renderMessage(item)}</Box>
              </Box>
            )}
          </Box>
        ))}

      {/* FILTRO -> SIAFI OU ORDEM BANCÁRIA */}
      <Grid container spacing={2} sx={{ width: "100%", mt: 2 }}>
        {formImportTypeFields.map(({ tooltip, label, value }, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6 }}>
            <RadioGroup
              row
              value={importType}
              onChange={(event) => {
                handleCancel();
                setImportType(event.target.value);
              }}
            >
              <Tooltip title={tooltip}>
                <FormControlLabel
                  control={<Radio sx={radioIconStyle} />}
                  label={label}
                  sx={styles.controlLabel}
                  value={value}
                />
              </Tooltip>
            </RadioGroup>
          </Grid>
        ))}
      </Grid>

      {/* FILTRO -> DDF021 OU DDF025 */}
      {isImportSIAFI && (
        <Grid container spacing={2} sx={{ width: "100%" }}>
          {formImportDDFFields.map(({ tooltip, label, value }, index) => (
            <Grid key={index} size={{ xs: 12, sm: 6 }}>
              <Tooltip
                title={
                  areBothDDFDisabled
                    ? "Pelo menos um dos itens precisa de estar selecionado"
                    : tooltip
                }
              >
                <FormControlLabel
                  control={<Checkbox color={areBothDDFDisabled ? errorColor : "#1341A1"} />}
                  label={label}
                  sx={[styles.controlLabel, areBothDDFDisabled && styles.controlLabelError]}
                  checked={importDDF[value]}
                  onChange={(event) =>
                    setImportDDF((prev) => ({ ...prev, [value]: event.target.checked }))
                  }
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      )}

      {/* FILTRO -> PERÍODO */}
      <Grid container spacing={2}>
        {isImportSIAFI && (
          <>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                type="date"
                title="Data Inicial do Período"
                value={initialDate}
                onChange={(date) => setInitialDate(date)}
                maxDate={finalDate ? finalDate : new Date()}
                error=""
                sx={{ backgroundColor: "#f1f1f1" }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                type="date"
                title="Data Final do Período"
                value={finalDate}
                onChange={(date) => setFinalDate(date)}
                minDate={initialDate ? initialDate : new Date("2016-01-01")}
                error=""
                sx={{ backgroundColor: "#f1f1f1" }}
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* CARREGAR FICHEIROS */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          width: "100%",
        }}
      >
        {!responseXMLFile && !responseXLSLFile && (
          <>
            {isImportSIAFI && (
              <Box sx={{ flex: 1 }}>
                {xmlFile ? (
                  <RButton
                    color="secondary"
                    sx={styles.showName.container}
                    onClick={() => setXmlFile(null)}
                  >
                    <Text sx={styles.showName.text}>Eliminar {xmlFile.name}</Text>
                    <HighlightOffIcon />
                  </RButton>
                ) : (
                  <>
                    <input
                      type="file"
                      id="xml-file-input"
                      style={{ display: "none" }}
                      accept=".xml"
                      onChange={handleSIAFIChange}
                      disabled={xmlFile}
                    />
                    <label htmlFor="xml-file-input">
                      <RButton component="span" disabled={xmlFile}>
                        Carregar SIAFI Extração (xml)
                      </RButton>
                    </label>
                  </>
                )}
              </Box>
            )}
            <Box sx={{ flex: 1, maxWidth: { sm: "50%" }, ml: { sm: "auto" } }}>
              {xlsxFile ? (
                <RButton
                  color="secondary"
                  sx={styles.showName.container}
                  onClick={() => setXlsxFile(null)}
                >
                  <Text sx={styles.showName.text}>Eliminar {xlsxFile.name}</Text>
                  <HighlightOffIcon />
                </RButton>
              ) : (
                <>
                  <input
                    type="file"
                    id="xlsx-file-input"
                    style={{ display: "none" }}
                    accept=".xlsx"
                    onChange={handleSIAFIChange}
                    disabled={xlsxFile}
                  />
                  <label htmlFor="xlsx-file-input">
                    <RButton component="span" disabled={xlsxFile}>
                      Carregar Ordens Bancárias (xlsx)
                    </RButton>
                  </label>
                </>
              )}
            </Box>
          </>
        )}
      </Box>

      {/* BOTÕES NOVA IMPORTAÇÃO / CANCELAR / IMPORTAR */}
      <Grid container spacing={2} sx={{ justifyContent: "right" }}>
        {(responseXMLFile || responseXLSLFile) && (
          <Grid size={{ xs: 12, sm: 4 }}>
            <RButton color="orange" variant="outlined" onClick={handleCancel}>
              Nova Importação
            </RButton>
          </Grid>
        )}
        <Grid size={{ xs: 12, sm: 3 }}>
          <RButton variant="outlined" onClick={handleClose}>
            Fechar
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 5 }}>
          <RButton
            disabled={isImportSIAFI ? disabledButtonSIAFI : disableButtonNotSIAFI}
            onClick={() => {
              if (isImportSIAFI) {
                return handleVerifyDates();
              } else {
                return submit();
              }
            }}
          >
            Importar Arquivos
          </RButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SIAFI;

const styles = {
  answerContainer: {
    borderRadius: 2,
    p: 1,
    backgroundColor: "#EFF2F4",
    title: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 700,
    },
  },
  loading: {
    height: "25vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  answers: {
    maxHeight: "25vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: 1,
    pt: 1,
    box: {
      pl: 1,
      display: "flex",
      alignItems: "center",
      gap: 1,
      text: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: 15,
      },
    },
  },
  text: {
    maxWidth: "40ch" /* Tamanho */,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Montserrat, sans-serif",
    whiteSpace: "nowrap",
  },
  infoText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "15px",
    alignText: "center",
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: 2,
  },
  fields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    flexDirection: "column",
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
  },
  fieldsText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },

  showName: {
    container: {
      display: "flex",
      alignItems: "center",
      gap: 1,
    },
    text: {
      maxWidth: "30ch" /* Tamanho */,
      fontFamily: "Montserrat, sans-serif",
      fontSize: 12,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "left",
    fontFamily: "Montserrat",
    color: "#000000",
  },
  controlLabel: {
    border: "2px solid #c1c1c1",
    width: "100%",
    borderRadius: 1,
    textAlign: "center",
    margin: "0 auto",
    paddingLeft: 2,
    backgroundColor: "#c1c1c1",
    ml: 0,
    transition: "all 400ms",
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Montserrat",
      color: "#000000",
      paddingLeft: "0",
    },
  },
  controlLabelError: {
    borderColor: errorColor,
  },
};
