import React, { useContext } from "react";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { HeaderSectionPage } from "../components/HeaderSectionPage";
import { Box, Typography } from "@mui/material";
import RButton from "../components/Common/Buttons/RButton";
import { Delete } from "@mui/icons-material";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeNfseUseCases } from "../Utils/utilsApiCalls";

export const Settings = () => {
  const { openDialogBox } = useContext(DialogContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const apiNfse = makeNfseUseCases();

  const handleDelete = (id) => {
    openDialogBox({
      message: `Tem certeza que deseja apagar a base de dados de documentos fiscais ?`,
      callback: async () => {
        try {
          openBackdrop(true, "Limpando base de dados...");
          await apiNfse.deleteAllNfse(id);
          showSuccess("Base deletada com sucesso");
        } catch (error) {
          showError(error, "Não foi possível limpar a base.");
        } finally {
          openBackdrop(false);
        }
      },
    });
  };

  return (
    <>
      <HeaderSectionPage title="Configurações" />
      <Box
        sx={{
          padding: { xs: 1, md: 3 },
          display: "flex",
          flexDirection: "column",
          gap: { xs: 1, md: 3 },
        }}
      >
        <Box sx={styles.container}>
          <Box>
            <Typography sx={styles.title}>Excluir base de dados</Typography>
            <Typography sx={styles.text}>
              Deleta permenentemente todos os documentos fiscais existentes.
            </Typography>
          </Box>
          <RButton
            variant="outlined"
            sx={{ width: { xs: "80%", md: "20%" } }}
            color="error"
            startIcon={<Delete />}
            onClick={() => handleDelete(companyId)}
          >
            Limpar base de dados
          </RButton>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  title: {
    fontSize: 17,
    fontWeight: 700,
    textAlign: "left",
    fontFamily: "Montserrat",
    color: "#000000",
  },
  text: {
    fontSize: 13,
    textAlign: "left",
    fontFamily: "Montserrat",
    color: "#000000",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    backgroundColor: "#E6E6E6",
    padding: 3,
    borderRadius: "12px",
    gap: 1,
  },
};
