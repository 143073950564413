import React from "react";
import { Box, Checkbox, FormControlLabel, Radio, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import { painelStyles } from "~/presentation/views/Client/MyPanel";
import Grid from "@mui/material/Grid2";
import { searchOptions } from "~/presentation/views/Admin/Stats/Display/StatsForm";

const StatsForm = ({ errors, values, onChangeValue, usersData, onSubmit, setStatsData }) => {
  return (
    <>
      <Grid container spacing={2} sx={painelStyles.subcontainer}>
        <Grid size={12}>
          <Typography sx={painelStyles.subtitle}>Tipo de busca</Typography>
        </Grid>
        {searchOptions.map((type, index) => (
          <Grid key={index} size={{ xs: 12, md: 4 }}>
            <FormControlLabel
              sx={painelStyles.controlLabel}
              control={
                <Radio
                  sx={painelStyles.icon}
                  checked={type.value === values?.searchType}
                  onChange={() => {
                    onChangeValue("searchType", type.value);
                    setStatsData([]);
                  }}
                  name={type.value}
                />
              }
              label={type.label}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} sx={painelStyles.subcontainer}>
        <Grid size={12}>
          <Typography sx={painelStyles.subtitle}>Filtros</Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Input
            type="date"
            title="Data Inicial"
            name="initialDate"
            value={values?.initialDate}
            onChange={(date) => onChangeValue("initialDate", date)}
            error={errors?.initialDate}
            sx={{ ...painelStyles.input, flex: 1, backgroundColor: "#fff" }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Input
            type="date"
            title="Data Final"
            name="finalDate"
            value={values?.finalDate}
            onChange={(date) => onChangeValue("finalDate", date)}
            error={errors?.finalDate}
            sx={{ ...painelStyles.input, flex: 1, backgroundColor: "#fff" }}
          />
        </Grid>
        {values.searchType === "search" && (
          <Grid size={{ xs: 12, md: 4 }}>
            <Input
              type="select"
              title="Usuário"
              name="userId"
              value={
                values?.userId ? usersData?.users?.find((item) => item.id === values?.userId) : null
              }
              onChange={(option) => onChangeValue("userId", option.id)}
              options={usersData?.users}
              disabled={usersData?.loading}
              error={errors?.userId}
              style={{ ...painelStyles.input, flex: 1, background: "#fff" }}
            />
          </Grid>
        )}
        {values.searchType === "content-access" && (
          <Grid size={{ xs: 12, sm: "auto" }} sx={[formStyles.checkBoxContainer, { pt: 2 }]}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values?.isDetail || null}
                  onChange={() => onChangeValue("isDetail", !values?.isDetail)}
                  name="isDetail"
                />
              }
              label="Exibir conteúdo detalhado"
            />
          </Grid>
        )}
      </Grid>

      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={onSubmit}>
          Pesquisar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default StatsForm;
