import React from "react";
import {makeExplanatoryVideoUseCases} from "~/main/factories/use-cases/explanatory-video";
import { ExplanatoryVideos } from "~/presentation/views/Client/ExplanatoryVideos";

export const makeExplanatoryVideo = (params) => {
  return (
    <ExplanatoryVideos
      explanatoryVideosUseCases={makeExplanatoryVideoUseCases()}
      {...params}
    />
  );
};
