import React, { useContext, useEffect, useState } from "react";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";
import Form from "./Form/Form";

function CreateEditCompanyCategory({ data, onCloseForm, refreshData }) {
  const companyUseCases = makeCompanyUseCases();
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSucess } = useContext(SnackbarContext);

  //input value and errors
  const [values, setValues] = useState({ id: 0, name: "", letter: "" });
  const [errors, setErrors] = useState({});

  const isCreate = !data;

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data]);

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    try {
      openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} Categoria`);
      if (isCreate) {
        await companyUseCases.registerCompanyCategory(values);
      } else {
        await companyUseCases.updateCompanyCategory(values);
      }
      setErrors({});
      showSucess(`Categoria ${isCreate ? "cadastrada" : "editada"} com sucesso!`);
      onCloseForm();
      await refreshData();
    } catch (error) {
      showError(error, `Erro ao ${isCreate ? "cadastrar" : "editar"} categoria`);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Cadastrar" : "Editar"} Categoria`}
        handleBack={onCloseForm}
        titleBack="Voltar para Categorias"
      />
      {values && (
        <Form
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
        />
      )}
    </>
  );
}

export default CreateEditCompanyCategory;
