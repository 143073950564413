import React, { useState, useEffect } from "react";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { formatDateFilter } from "~/presentation/views/Reinf/Utils/utilsDate";
import { useTableFilters } from "~/presentation/views/Reinf/Hooks/useTableFilters";
import Filters from "~/presentation/views/Reinf/DocPanel/Table/Filters/Filters.jsx";
import { useDialog } from "~/presentation/providers/DialogProvider";
import DocTable from "~/presentation/views/Reinf/DocPanel/Table/DocTable";
import useHandleDocFiscais from "~/presentation/views/Reinf/Hooks/useHandleDocFiscais";
import { firstDayPastMonth, today } from "~/presentation/utils/constants";
import {
  makeNfseUseCases,
  makePaymentRecordUseCases,
} from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import Header from "~/presentation/views/Reinf/DocPanel/Header";

const DocPanel = () => {
  const apiNfse = makeNfseUseCases();
  const apiPayment = makePaymentRecordUseCases();
  const { showError } = useSnackbar();
  const { openDialogBox } = useDialog();
  const { openBackdrop } = useBackdrop();
  const { handleChangedNfse } = useHandleDocFiscais();
  const { docSituationFilter, docTypeFilter, docSetOrder } = useTableFilters();

  //DATA -> PESSOA JURIDICA
  const [changedLegalPersonDocs, setChangedLegalPersonDocs] = useState([]);
  const [initalLegalPersonDocs, setInitalLegalPersonDocs] = useState([]);
  const [legalPersonDocs, setLegalPersonDocs] = useState([]);

  const hasUnsavedDocuments =
    changedLegalPersonDocs.length > 0
      ? `${
          changedLegalPersonDocs.length === 1
            ? `Tem 1 documento alterado que não foi salvo.`
            : `Tem ${changedLegalPersonDocs.length} documentos alterados que não foram salvos.`
        } Ao processeguir, esses dados não serão salvos. Deseja continuar?`
      : null;

  const handleUpdateStateDocs = (doc) => {
    updateState(setInitalLegalPersonDocs, doc);
    updateState(setLegalPersonDocs, doc);
  };

  //Ao abrir uma nota, adicionar toda a informação nos state de dados
  const updateState = (setState, fullDoc) => {
    setState((prev) => prev.map((summary) => (summary.id === fullDoc.id ? fullDoc : summary)));
  };

  //DATA -> PESSOA FÍSICA
  const [phisicalPersonData, setPhisicalPersonData] = useState([]);

  //ERROS CAMPOS -> PESSOA JURIDICA
  const [errors, setErrors] = useState({});

  //Página tabela controlada
  const [tablePage, setTablePage] = useState(0);

  const initialFilters = {
    orderBy: "dateOfIssue",
    initialDate: firstDayPastMonth,
    finalDate: today,
    documentSituation: "",
    documentType: "",
  };

  const [filters, setFilters] = useState(initialFilters);
  const [activeFilters, setActiveFilters] = useState(initialFilters);

  const onChangeFilter = (newItem) => setFilters((prev) => ({ ...prev, ...newItem }));

  const handleCleanAllFilters = () => {
    if (hasUnsavedDocuments) {
      openDialogBox({
        maxWidth: "sm",
        message: hasUnsavedDocuments,
        callback: () => {
          setChangedLegalPersonDocs([]);
          cleanAll();
        },
      });
    } else {
      cleanAll();
    }
  };

  const cleanAll = () => {
    const cleanObj = {
      orderBy: "",
      documentSituation: "",
      documentType: "",
      initialDate: firstDayPastMonth,
      finalDate: today,
    };

    const params = {
      start: formatDateFilter(cleanObj.initialDate),
      end: formatDateFilter(cleanObj.finalDate),
      isSummary: true,
    };

    setFilters(cleanObj);
    setActiveFilters(cleanObj);
    fetchDataIntoState(params);
  };

  const isPhisicalPersonTable = Boolean(activeFilters.documentType === 98);

  // ⇣⇣⇣ PARAMS para o fetch
  const params = {
    start: formatDateFilter(filters.initialDate),
    end: formatDateFilter(filters.finalDate),
    isDateOfPayment: filters.orderBy === "dateOfPayment" ? true : false,
    isSummary: true,
  };

  const loadData = async (params) => {
    try {
      openBackdrop(true, "Carregando dados de Doc.Fiscais");
      const { data } = await apiNfse.getNfse(params);
      if (data) return data;
    } catch (error) {
      showError(error, "Erro ao carregar documentos fiscais");
    } finally {
      openBackdrop(false);
    }
  };

  const loadPaymentData = async () => {
    openBackdrop(true, "Carregando dados");
    try {
      const { data } = await apiPayment.getPaymentRecord(params);
      if (data) return data;
    } catch (error) {
      showError(error, "Erro no retorno dos dados de pagamento");
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    fetchDataIntoState(params);
  }, []);

  const fetchDataIntoState = async (data) => {
    const result = await loadData(data);
    setLegalPersonDocs(result);
    setInitalLegalPersonDocs(result);
  };

  const handleFilterTableData = () => {
    if (hasUnsavedDocuments) {
      openDialogBox({
        maxWidth: "sm",
        message: hasUnsavedDocuments,
        callback: () => {
          setChangedLegalPersonDocs([]);
          handleFilter();
        },
      });
    } else {
      handleFilter();
    }
  };

  const handleFilter = () => {
    setTablePage(0);
    setChangedLegalPersonDocs([]);

    if (filters.documentType === 98) {
      handleFilterPhisicalPersonData(); // Filtra pessoas fisicas
    } else {
      handleFiltersLegalPersonData(); // Filtrar pessoas juridicas
    }
  };

  const handleFiltersLegalPersonData = async () => {
    let result = await loadData(params);

    setActiveFilters(filters);

    if (filters.documentSituation) {
      result = docSituationFilter(result, filters.documentSituation);
    }

    if ([0, 1, 2, 3, 4, 98, 99].includes(filters.documentType)) {
      result = docTypeFilter(result, "typeDoc", filters.documentType);
    }

    setLegalPersonDocs(result);
  };

  const handleFilterPhisicalPersonData = async () => {
    let result = await loadPaymentData();

    setActiveFilters(filters);

    // por ordem
    if (filters.orderBy) {
      result = docSetOrder(
        result,
        filters.orderBy === "dateOfPayment" ? "datePayment" : "dateRegister"
      );
    }

    setPhisicalPersonData(result);
  };

  const handleDeleteDoc = (id) => {
    openDialogBox({
      message: `Deseja eliminar permanentemente este documento?`,
      callback: async () => {
        try {
          openBackdrop(true, "Eliminando documento");
          if (isPhisicalPersonTable) {
            await apiPayment.deletePaymentRecord(id);
            setPhisicalPersonData((prev) => prev.filter((i) => i.id !== id));
          } else {
            await apiNfse.deleteNfse(id);
            setLegalPersonDocs((prev) => prev.filter((i) => i.id !== id));
          }
        } catch (error) {
          showError(error, "Erro ao eliminar documento");
        } finally {
          openBackdrop(false);
        }
      },
    });
  };

  const handleChangeFieldValue = ({ newItem, id, byPass }) => {
    setLegalPersonDocs((prev) => prev.map((doc) => (doc.id === id ? { ...doc, ...newItem } : doc)));
    if (!byPass) handleNfseChanges(id, newItem);
  };

  const handleChangeINSSfield = ({ newItem, id, nestedIndex, byPass }) => {
    const currentNFse = legalPersonDocs.find((i) => i.id === id);
    if (!currentNFse) return;
    const updatedItemINSS = [...currentNFse.itemsNFse];
    updatedItemINSS.splice(nestedIndex, 1, newItem);
    handleChangeFieldValue({
      newItem: { itemsNFse: updatedItemINSS },
      id: id,
      byPass: byPass,
    });
    if (!byPass) handleNfseChanges(id, { itemsNFse: updatedItemINSS });
  };

  const handleNfseChanges = (id, item) => {
    //Enviar para o state de notas alteradas o id e os campos alterados com seus devidos valores
    setChangedLegalPersonDocs((prev) => {
      const index = prev?.findIndex((key) => key.id === id);
      if (index !== -1) {
        return prev.map((entry, i) => (i === index ? { ...entry, ...item } : entry));
      }
      return [...prev, { id, ...item }];
    });
  };

  useEffect(() => {
    const handleChange = (id, isEqual = false) => {
      handleChangeFieldValue({
        newItem: { hadChanges: isEqual },
        id: id,
        byPass: true,
      });
    };

    //HOOK que lida com as notas que foram alteradas e verifica o seu estado
    handleChangedNfse({
      changedNfse: changedLegalPersonDocs,
      allNfse: initalLegalPersonDocs,
      setChanged: (id) => handleChange(id, true),
      setEqual: (id) => handleChange(id, false),
    });
  }, [changedLegalPersonDocs]);

  return (
    <>
      <Header onRefreshData={handleFilter} hasUnsavedDocuments={hasUnsavedDocuments} />
      <Filters
        unactivefilters={filters}
        activeFilters={activeFilters}
        onChangeFilter={onChangeFilter}
        onFilter={handleFilterTableData}
        onCleanAllFilters={handleCleanAllFilters}
      />
      <DocTable
        legalPersonData={legalPersonDocs}
        phisicalPersonData={phisicalPersonData}
        isPhisicalPersonTable={isPhisicalPersonTable}
        onDelete={handleDeleteDoc}
        onChangeFieldValue={handleChangeFieldValue}
        onChangeINSSfield={handleChangeINSSfield}
        errors={errors}
        setErrors={setErrors}
        tablePage={tablePage}
        setTablePage={setTablePage}
        onUpdateStateDocs={handleUpdateStateDocs}
      />
    </>
  );
};

export default DocPanel;
