import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography as Text, Button } from "@mui/material";
import ProfileForms from "./Forms/ProfileForms";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { LoadingTable } from "~/presentation/views/Client/MyPanel/components/LoadingTable";
import MyPainelTable from "~/presentation/views/Client/MyPanel/components/MyPainelTable";
import { painelStyles } from "~/presentation/views/Client/MyPanel";

export const UserProfile = ({
  companyId,
  profileId,
  userProfiles,
  refreshData,
  userProfilesUseCases,
  loading,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);
  const [currentProfiles, setCurrentProfiles] = useState([]);

  useEffect(() => {
    if (userProfiles.length === 0) {
      refreshData();
    }
  }, []);

  useEffect(() => {
    if (userProfiles.length > 0) {
      setCurrentProfiles(userProfiles);
    }
  }, [userProfiles]);

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo perfil");
      await userProfilesUseCases.deleteProfile(id);
      showSuccess("Perfil excluído!");
      setCurrentProfiles((prev) => prev.filter((i) => i.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir o perfil.");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este perfil?`,
      callback: () => handleDelete(id),
    });
  };

  const openModal = (data = null) => {
    openDialogBox({
      maxWidth: "md",
      fullWidth: true,
      customComponent: () => (
        <ProfileForms
          data={
            data || {
              id: 0,
              name: "",
              isInternalUse: false,
              companyId: companyId,
              permission: {
                admins: null,
                clients: null,
                reinfs: null,
              },
            }
          }
          userProfilesUseCases={userProfilesUseCases}
          companyId={companyId}
          refreshData={refreshData}
          onCloseForm={closeDialog}
          deleteDialog={deleteDialog}
        />
      ),
    });
  };

  const renderLabel = (label) => {
    const newLabel = label.slice(0, -1).toUpperCase();
    if (newLabel === "CLIENT") return "CLIENTE";
    return newLabel;
  };

  const verificarValores = (data) => {
    let result = [];
    for (let key in data) {
      if (data[key] && data[key].some((item) => item.items && item.items.length > 0)) {
        result.push(renderLabel(key));
      }
    }
    if (result.length === 0) {
      return "Sem permissões";
    }
    return result.join(" + ");
  };

  const blockActions = (perfilId) => {
    //Se for um dos perfis GERAIS e não for um perfil da Open, nao pode editar/eliminar
    const isGeralPerfil = [1, 2, 3, 4].includes(perfilId);
    const isOpenUser = companyId === 1;
    return isGeralPerfil && !isOpenUser;
  };

  //Se é o mesmo perfil do usuário, não pode editar/eliminar
  const isUserProfile = (perfilId) => profileId === perfilId;

  const columns = [
    { field: "name", headerName: "Nome", flex: 3 },
    {
      field: "permission",
      headerName: "Permissões",
      renderCell: ({ value }) => verificarValores(value),
      align: "center",
      flex: 3,
      sortable: false,
      hide: "xs",
    },
    {
      field: "actions",
      headerName: "Ações",
      flex: 1,
      sortable: false,
      align: "center",
      renderCell: ({ row }) => {
        const disabledBtn = blockActions(row.id) || isUserProfile(row.id);
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
            <SecurityPath securePaths={[clientPerms.deleteUserProfile]}>
              <Button
                sx={styles.DeleteButton}
                onClick={() => deleteDialog(row.id)}
                disabled={disabledBtn}
              >
                Deletar
              </Button>
            </SecurityPath>
            <SecurityPath securePaths={[clientPerms.editUserProfile]}>
              <Button
                sx={{ ...styles.Button, mr: 1 }}
                name="edit"
                onClick={() => openModal(row)}
                disabled={disabledBtn}
              >
                Editar
              </Button>
            </SecurityPath>
          </Box>
        );
      },
      securePaths: [clientPerms.editUserProfile, clientPerms.deleteUserProfile],
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mb: 4,
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Text sx={styles.title}>Perfil de Usuário</Text>
        <SecurityPath securePaths={[clientPerms.addUserProfile]}>
          <Button sx={painelStyles.ButtonAdd} onClick={() => openModal()}>
            Adicionar novo perfil <AddIcon />
          </Button>
        </SecurityPath>
      </Box>
      {loading ? (
        <LoadingTable />
      ) : (
        <MyPainelTable columns={columns} data={currentProfiles} exportFileName="perfis-usuario" />
      )}
    </Box>
  );
};
const styles = {
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    textAlign: { xs: "center", md: "left" },
  },

  Button: {
    background: "#081445",
    border: "1px solid #081445",
    paddingInline: { xs: 2, md: 4 },
    color: "#FFF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#081445",
    },
    "&:disabled": {
      border: "1px solid #999",
      background: "transparent",
    },
  },
  DeleteButton: {
    border: "1px solid red",
    paddingInline: { xs: 1, md: 2 },
    color: "red",
    "&:hover": {
      backgroundColor: "red",
      color: "#fff",
    },
    "&:disabled": {
      border: "1px solid #999",
      background: "transparent",
    },
  },
};
