import buildQueryString from "~/presentation/utils/buildQueryString";
import { apiURL } from "../../usecases/api";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { adminPerms, clientPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";

const types = {
  none: 0,
  news: 1,
  article: 2,
  legislation: 3,
  multimedia: 4,
};

export class ApiContentUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getContentTypes() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/content/GetTypes`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getAllContents(IsSummaryOnly = false) {
    if (!hasPermission([adminPerms.seeContent])) {
      throw new Error(securityErrorMessage("visualizar conteúdo selecionado"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Content?IsSummaryOnly=${IsSummaryOnly}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async registerContent(data) {
    if (!hasPermission([adminPerms.addContent])) {
      throw new Error(securityErrorMessage("adicionar conteúdo selecionado"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/content/`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getContentById({ id = id, checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([clientPerms.seeArticles, clientPerms.seeLegislation])) {
        throw new Error(securityErrorMessage("visualizar conteúdo selecionado"));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/content/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateContent(data) {
    if (!hasPermission([adminPerms.editContent])) {
      throw new Error(securityErrorMessage("editar conteúdo selecionado"));
    }
    try {
      await this.httpClient.request({
        url: `${apiURL}/content`,
        method: "PUT",
        data,
      });
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteContent(id) {
    if (!hasPermission([adminPerms.deleteContent])) {
      throw new Error(securityErrorMessage("excluir conteúdo selecionado"));
    }
    try {
      await this.httpClient.request({
        url: `${apiURL}/content/${id}`,
        method: "DELETE",
      });
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getAllLegislation(queryParams) {
    try {
      let url = `${apiURL}/content?type=${types.legislation}`;
      url += `&${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getLegislation(queryParams) {
    if (!hasPermission([clientPerms.seeLegislation])) {
      throw new Error(securityErrorMessage("visualizar conteúdos de legislação"));
    }
    try {
      let url = `${apiURL}/legislation?`;
      url += `${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getArticle(queryParams) {
    if (!hasPermission([clientPerms.seeArticles])) {
      throw new Error(securityErrorMessage("visualizar artigos"));
    }
    try {
      let url = `${apiURL}/article?`;
      url += `${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getLegislationCategories() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ContentCategory/Legislation`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getMostAccessedLegislation(queryParams) {
    // let response = { data: fakeLegislationList };
    try {
      let url = `${apiURL}/legislation`;
      url += `?${buildQueryString(queryParams)}`;

      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getMostAccessedArticles(limit = 3) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/article?&limit=${limit}&fieldOrder=views&orderBy=desc`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getAllArticle() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/content?type=${types.article}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getAllMultimedia(queryParams) {
    if (!hasPermission([clientPerms.seeMultimedia])) {
      throw new Error(securityErrorMessage("visualizar multimídea"));
    }

    try {
      let url = `${apiURL}/multimidia?`;
      url += `${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
