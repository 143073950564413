import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography as Text, Switch, Button } from "@mui/material";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import UserForms from "./Forms/UserForms";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { LoadingTable } from "~/presentation/views/Client/MyPanel/components/LoadingTable";
import MyPainelTable from "~/presentation/views/Client/MyPanel/components/MyPainelTable";
import { painelStyles } from "~/presentation/views/Client/MyPanel";

const newUserObject = {
  id: 0,
  name: "",
  email: "",
  homePhone: "",
  mobilePhone: "",
  password: "",
  confirmPassword: "",
  role: "",
  userProfileId: null,
  active: true,
};

export const UserTable = ({
  userUseCases,
  companyId,
  users,
  refreshUsers,
  userProfiles,
  refreshProfiles,
  userProfilesUseCases,
  loading,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  const [currentUsers, setCurrentUsers] = useState([]);

  useEffect(() => {
    if (users.length === 0) {
      refreshUsers();
    }
    if (users.length > 0) {
      setCurrentUsers(users);
    }
  }, [users]);

  const openModal = (data = null) => {
    openDialogBox({
      maxWidth: "md",
      fullWidth: true,
      customComponent: () => (
        <UserForms
          user={data || newUserObject}
          handleClose={closeDialog}
          handleSubmit={handleSubmit}
          userProfiles={userProfiles}
          refreshProfiles={refreshProfiles}
          userProfilesUseCases={userProfilesUseCases}
        />
      ),
    });
  };

  const handleSubmit = async (user) => {
    const isEdit = user.id !== 0;

    try {
      openBackdrop(true, `${isEdit ? "Editar" : "Registar"} usuário`);
      if (isEdit) {
        await userUseCases.updateUser({ id: user.id, data: user, checkPermission: true });
      } else {
        await userUseCases.register({ ...user, role: "user" });
      }
      showSuccess(`Usuário ${isEdit ? "editado" : "registado"} com sucesso!`);
      await refreshUsers();
      closeDialog();
    } catch (error) {
      showError(error, `Erro ao ${isEdit ? "editar" : "registar"} usuário`);
    } finally {
      openBackdrop(false);
    }
  };

  const handleDelete = (userId) => {
    openDialogBox({
      message: `Deseja mesmo deletar este usuário?`,
      callback: async () => {
        try {
          openBackdrop(true, "Excluindo usuário");
          await userUseCases.deleteUser(userId);
          setCurrentUsers((prev) => prev.filter((i) => i.id !== userId));
          showSuccess("Usuário deletado com sucesso!");
        } catch (error) {
          showError(error, "Erro ao deletar usuário");
        } finally {
          openBackdrop(false);
        }
      },
    });
  };

  const changeStatus = (userId, isChecked) =>
    setCurrentUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.id === userId) {
          return { ...user, active: isChecked };
        } else {
          return user;
        }
      })
    );

  const handleChangeSwitch = async (userId, isChecked) => {
    try {
      changeStatus(userId, isChecked); //alterar na tabela
      await userUseCases.updateUser({
        id: userId,
        data: { active: isChecked }, //chamar endpoint PUT User
        checkPermission: true,
      });
      showSuccess(isChecked ? "Usuário ativado com sucesso!" : "Usuário desativado com sucesso!");
    } catch (error) {
      showError(error, "Erro ao atualizar usuário");
      changeStatus(userId, !isChecked); //caso erro, voltar ao valor anterior na tabela
    }
  };

  const columns = [
    { field: "name", headerName: "Nome", flex: 4 },
    { field: "email", headerName: "Email", flex: 4, hide: "md" },
    {
      field: "userProfile",
      headerName: "Perfil de usuário",
      flex: 2,
      align: "center",
      hide: "md",
    },
    {
      field: "active",
      headerName: "Status",
      flex: 4,
      hide: "md",
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            width: 140,
          }}
        >
          {row.active ? "Ativo" : "Inativo"}
          <Switch
            checked={row.active}
            onChange={(e) => handleChangeSwitch(row.id, e.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
      ),
      securePaths: [clientPerms.editUsers],
    },
    {
      field: "actions",
      headerName: "Ações",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
          <SecurityPath securePaths={[clientPerms.deleteUsers]}>
            <Button sx={styles.DeleteButton} onClick={() => handleDelete(row.id)}>
              Deletar
            </Button>
          </SecurityPath>
          <SecurityPath securePaths={[clientPerms.editUsers]}>
            <Button sx={styles.Button} onClick={() => openModal(row)}>
              Editar
            </Button>
          </SecurityPath>
        </Box>
      ),
      securePaths: [clientPerms.deleteUsers, clientPerms.editUsers],
      sortable: false,
      align: "center",
      flex: 1,
    },
  ];

  return (
    <Box sx={{ marginBottom: "2rem" }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mb: 4,
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Text sx={styles.title}>Usuários cadastrados</Text>
        <SecurityPath securePaths={[clientPerms.addUsers]}>
          <Button sx={painelStyles.ButtonAdd} onClick={() => openModal()}>
            Adicionar novo usuário <AddIcon />
          </Button>
        </SecurityPath>
      </Box>
      {loading ? (
        <LoadingTable />
      ) : (
        <MyPainelTable exportFileName="usuarios" data={currentUsers} columns={columns} />
      )}
    </Box>
  );
};

const styles = {
  TableHead: {
    justifyContent: "flex-start",
    fontWeight: 500,
    backgroundColor: "#EFF2F4",
  },
  TableCell: {
    fontSize: "0.9rem",
    textAlign: "left",
    backgroundColor: "#EFF2F4",
    color: "#021148",
    fontFamily: "Montserrat",
  },
  TableRow: {
    backgroundColor: "#EFF2F4",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    height: "70px",
  },
  Actions: {
    "&:hover": {
      color: "#0e4292",
    },
  },
  Button: {
    background: "#081445",
    border: "1px solid #081445",
    paddingInline: { xs: 2, md: 4 },
    color: "#FFF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#081445",
    },
  },
  DeleteButton: {
    border: "1px solid red",
    paddingInline: { xs: 1, md: 2 },
    color: "red",
    "&:hover": {
      backgroundColor: "red",
      color: "#fff",
    },
    "&:disabled": {
      border: "1px solid #999",
      background: "transparent",
    },
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    textAlign: { xs: "center", md: "left" },
  },
  filtersContainer: {
    display: "flex",
    width: "100%",
    flexDirection: { xs: "column", md: "row" },
  },
  filterField: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    borderRadius: "3px",
    height: "54px",
    border: "1px solid #ccc ",
    borderColor: "#ccc",
  },
};
