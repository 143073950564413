import React, { useContext, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { FormControl, Button, Box } from "@mui/material";
import validationSchema from "./validationSchema";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import Input from "~/presentation/components/Common/Input";
import Grid from "@mui/material/Grid2";

export const EditUserForm = ({ user, updateUser, refreshUser }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [userEdit, setUserEdit] = useState(user);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false); // State to track password visibility

  const cleanError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleChangeData = (field, value) => {
    setUserEdit((prev) => ({ ...prev, [field]: value }));
    cleanError(field);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleCancel = () => {
    setUserEdit({ ...user, password: "", confirmPassword: "" });
  };

  const handleSubmit = async (values) => {
    event.preventDefault();
    const data = {
      companyId: user.companyId,
      name: values.name ?? user.name,
      email: values.email ?? user.email,
      password: values.password ?? user.password,
      confirmPassword: values.confirmPassword ?? user.confirmPassword,
      role: user.role,
      userProfileId: user.userProfileId,
      active: values.active,
      homePhone: values?.homePhone ?? user.homePhone,
      mobilePhone: values?.mobilePhone,
    };
    validationSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        openDialogBox({
          message: `Deseja mesmo editar este usuário?`,
          callback: async () => {
            try {
              openBackdrop(true, "Editando usuário");
              await updateUser(data);
              showSuccess("Usuário editado com sucesso!");
              setUserEdit((prev) => ({ ...prev, password: "", confirmPassword: "" }));
              setErrors({});
              refreshUser();
            } catch (error) {
              showError(error, "Erro ao editar usuário");
            } finally {
              openBackdrop(false);
            }
          },
        });
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  return (
    <Box>
      {user && (
        <FormControl>
          {user && (
            <>
              <Grid container spacing={1}>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <Input
                    title="Nome"
                    styles={styles.field}
                    name="name"
                    type="text"
                    value={userEdit?.name ?? user.name}
                    onChange={(e) => handleChangeData("name", e.target.value)}
                    error={errors.name}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <Input
                    title="Email"
                    styles={styles.field}
                    name="email"
                    type="email"
                    value={userEdit?.email ?? user.email}
                    onChange={(e) => handleChangeData("email", e.target.value)}
                    error={errors.email}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <Input
                    title="Telefone"
                    type="mask"
                    name="homePhone"
                    mask="(00) 00000-0000"
                    value={userEdit?.homePhone ?? user.homePhone}
                    styles={styles.field}
                    onChange={(value) => {
                      handleChangeData("homePhone", value);
                    }}
                    error={errors.homePhone}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <Input
                    title="Celular"
                    type="mask"
                    name="mobilePhone"
                    mask="(00) 00000-0000"
                    value={userEdit?.mobilePhone ?? user.mobilePhone}
                    styles={styles.field}
                    onChange={(value) => {
                      handleChangeData("mobilePhone", value);
                    }}
                    error={errors.mobilePhone}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <Input
                    styles={styles.field}
                    title="Nova senha"
                    name="password"
                    placeholder="Digite sua nova senha"
                    type={showPassword ? "text" : "password"}
                    value={userEdit?.password ?? user.password}
                    onChange={(e) => handleChangeData("password", e.target.value)}
                    error={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <Input
                    title="Confirmar Senha"
                    placeholder="Digite sua senha"
                    styles={styles.field}
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    value={userEdit?.confirmPassword ?? user.confirmPassword}
                    onChange={(e) => handleChangeData("confirmPassword", e.target.value)}
                    error={errors.confirmPassword}
                  />
                </Grid>
              </Grid>
              <Box size={12} sx={styles.ButtonContainer}>
                <Button sx={styles.CancelButton} onClick={handleCancel}>
                  Cancelar
                </Button>
                <Button sx={styles.Button} onClick={() => handleSubmit(userEdit)}>
                  Salvar
                </Button>
              </Box>
            </>
          )}
        </FormControl>
      )}
    </Box>
  );
};

const styles = {
  button: {
    backgroundColor: "#081445",
    color: "white",
    padding: "10px 30px",
    borderRadius: 1,
    fontSize: 16,
    fontWeight: 500,
    cursor: "pointer",
    transition: "background-color 0.12s ease-in-out",
    "&:hover": {
      backgroundColor: "#0092C7",
    },
    mr: 2,
  },
  field: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    height: "54px",
    borderRadius: "6px",
    border: "1px solid #EFF2F4",
  },
  ButtonContainer: {
    marginTop: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: 2,
  },
  CancelButton: {
    backgroundColor: "#EFF2F4",
    height: 40,
    color: "#000",
    width: "120px",
    border: "1px solid #D7D7D7",
    "&:hover": {
      boxShadow: "1px 4px 4px #999",
      color: "#d32f2f",
    },
  },
  Button: {
    background: "#081445",
    flex: 1,
    maxWidth: "200px",
    height: 40,
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0e3279",
      boxShadow: "1px 4px 4px #888",
    },
  },
};
