import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiTaxClassification } from "~/data/usecases/taxClassification/apiTaxClassification";
import { ApiItemCompany } from "~/data/usecases/itemCompany/apiItemCompany";
import {
  Typography as Text,
  Switch,
  Stack,
  Box,
  Paper,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { format } from "date-fns";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Grid from "@mui/material/Grid2";

export const ItemCompany = ({ item, closeModal, refreshData }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const itemCompanyUseCases = new ApiItemCompany(makeHttpClient());
  const taxClassificationApi = new ApiTaxClassification(makeHttpClient());
  const [taxClassificationOptions, setTaxClassificationOptions] = useState([]);
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const CompanyUser = user.companyId;

  const [itemData, setItemData] = useState({
    id: 0,
    companyId: CompanyUser,
    taxClassificationId: "",
    isECD: false,
    isExemptionFrom: false,
    isExemptionAgreement: false,
    isUnion: false,
    efr: 0,
    cnpjEFR: "",
    startDate: null,
    endDate: null,
    situation: 0,
  });

  const isCreate = itemData?.id === 0;

  const situationOptions = [
    { id: 0, description: "Situação normal" },
    { id: 1, description: "Extinção" },
    { id: 2, description: "Fusão" },
    { id: 3, description: "Cisão" },
    { id: 4, description: "Incorporação" },
  ];

  useEffect(() => {
    const getTaxClassificationOptions = async () => {
      try {
        const response = await taxClassificationApi.getTaxClassification();
        setTaxClassificationOptions(response);
      } catch (error) {
        console.error("Classificação Tributária não foi carregada:", error);
      }
    };
    getTaxClassificationOptions();
  }, []);

  useEffect(() => {
    if (item) {
      openBackdrop(true, "Carregando dados");
      const startDate = item.startDate && new Date(item.startDate);
      const endDate = item.endDate && new Date(item.endDate);
      setItemData({
        ...item,
        startDate: startDate,
        endDate: endDate,
      });
      openBackdrop(false);
    }
  }, [item]);

  const handleOnChange = (field, value) => {
    setItemData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setDisabledBtn(false);
    if (field) cleanError(field);
  };

  const cleanError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const [errors, setErrors] = useState({});
  const [disabledBtn, setDisabledBtn] = useState(false);
  //current time
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const minDate = new Date(currentYear - 6, currentMonth, 1); //6 anos atrás
  const maxDate = new Date(); //Hoje

  const handleValidation = () => {
    let errors = {};

    if (!itemData?.startDate) {
      errors.startDate = "Campo obrigatório";
    } else if (itemData?.startDate > maxDate) {
      errors.startDate = "Selecione uma data no presente";
    }

    if (itemData?.startDate == "Invalid Date") {
      errors.startDate = "Data inválida";
    }

    if (itemData?.endDate == "Invalid Date") {
      errors.endDate = "Data inválida";
    }

    if (itemData?.endDate && itemData?.startDate > itemData?.endDate) {
      errors.startDate = "Selecione uma data inferior ao Fim da Validade";
    }

    if (itemData.efr === 1 && itemData.cnpjEFR.length < 14) {
      errors.cnpjEFR = "CNPJ deve ter 14 caracteres";
    }

    if (!itemData.taxClassificationId) {
      errors.taxClassificationId = "Campo obrigatório";
    }

    setErrors(errors);
    setDisabledBtn(true);

    // Verifica se errors está vazio
    if (Object.keys(errors).length === 0) {
      setDisabledBtn(false);
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Carregando dados");

    const values = {
      ...itemData,
      startDate: format(itemData.startDate, "yyyy-MM-dd"),
      endDate: itemData?.endDate ? format(itemData.endDate, "yyyy-MM-dd") : null,
    };

    if (isCreate) {
      handleCreateItemCompany(values);
    } else {
      handleUpdateItemCompany(values);
    }
  };

  const handleUpdateItemCompany = async (values) => {
    openBackdrop(true, "Atualizando item");
    try {
      await itemCompanyUseCases.updateItemCompany(values);
      showSuccess("Dados atualizados com sucesso!");
      await refreshData();
      closeModal();
    } catch (error) {
      showError(error, "Erro ao atualizar dados");
    } finally {
      openBackdrop(false);
    }
  };

  const handleCreateItemCompany = async (values) => {
    openBackdrop(true, "Incluindo item");
    try {
      await itemCompanyUseCases.createItemCompany(values);
      showSuccess("Dados cadastrados com sucesso!");
      await refreshData();
      closeModal();
    } catch (error) {
      showError(error, `Erro ao cadastrar`);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <Box sx={styles.Content}>
        <Grid container spacing={1}>
          <Grid size={{ xs: 6, sm: 11 }}>
            <Text style={styles.Title}>Informações do contribuinte</Text>
          </Grid>
          <Grid
            size={{ xs: 6, sm: 1 }}
            sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
          >
            <IconButton>
              <Close onClick={closeModal} />
            </IconButton>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="date"
              viewType="month-year"
              title="Data de início"
              value={itemData?.startDate || null}
              minDate={minDate}
              maxDate={itemData?.endDate || maxDate}
              onChange={(date) => {
                handleOnChange("startDate", date);
              }}
              error={errors.startDate}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="date"
              viewType="month-year"
              title="Fim da Validade"
              minDate={itemData?.startDate || minDate}
              value={itemData?.endDate || null}
              onChange={(date) => {
                handleOnChange("endDate", date);
              }}
              error={errors.endDate}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="select"
              title="Classificação Tributária"
              placeholder="Selecione uma classificação Tributária"
              options={taxClassificationOptions}
              getOptionLabel={(item) => `${item.code} - ${item.description}`}
              value={taxClassificationOptions.find(
                (item) => item.id === itemData?.taxClassificationId
              )}
              onChange={(option) => {
                handleOnChange("taxClassificationId", option.id);
              }}
              error={errors.taxClassificationId}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="select"
              title="Situação da Pessoa Jurídica"
              placeholder="Selecione uma Situação da Pessoa Jurídica"
              options={situationOptions}
              getOptionLabel={(item) => `${item.description}`}
              value={situationOptions.find((item) => item.id === itemData?.situation)}
              onChange={(option) => {
                handleOnChange("situation", option.id);
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ marginBlock: 2 }}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              padding: 2,
              borderRadius: 4,
              justifyContent: "space-between",
            }}
          >
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Text sx={styles.Inputtitle}>
                Obrigatória a escrituração contábil na ECD (Escrituração Contábil Digital)?
              </Text>
              <Switch
                name="isECD"
                checked={itemData?.isECD}
                onChange={() => handleOnChange("isECD", !itemData?.isECD)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            <Divider />
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Text sx={styles.Inputtitle}>Desoneração da folha pela CPRB?</Text>
              <Switch
                name="isExemptionAgreement"
                checked={itemData?.isExemptionAgreement}
                onChange={() =>
                  handleOnChange("isExemptionAgreement", !itemData?.isExemptionAgreement)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            <Divider />
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Text sx={styles.Inputtitle}>Possui acordo internacional para isenção de multa?</Text>
              <Switch
                name="isExemptionFrom"
                checked={itemData?.isExemptionFrom}
                onChange={() => handleOnChange("isExemptionFrom", !itemData?.isExemptionFrom)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            <Divider />
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Text sx={styles.Inputtitle}>Órgão/Empresa vinculado à União?</Text>
              <Switch
                name="isUnion"
                checked={itemData?.isUnion}
                onChange={() => handleOnChange("isUnion", !itemData?.isUnion)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            <Divider />
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Text sx={styles.Inputtitle}>Ente Federativo Responsável?</Text>
              <RadioGroup
                name="efr"
                aria-label="efr"
                row
                value={itemData?.efr}
                onChange={(event) => handleOnChange("efr", parseInt(event.target.value))}
              >
                <FormControlLabel value={0} control={<Radio />} label="Não se aplica" />
                <FormControlLabel value={1} control={<Radio />} label="Não" />
                <FormControlLabel value={2} control={<Radio />} label="Sim" />
              </RadioGroup>
            </Stack>
            <Divider />
            <Box sx={{ display: itemData?.efr === 1 ? "block" : "none" }}>
              <Text sx={styles.Inputtitle}>CNPJ do Ente Federativo Responsável</Text>
              <Input
                style={styles.inputs}
                type="mask"
                mask={"00.000.000/0000-00"}
                placeholder="00.000.000/0000-00"
                name="cnpjEFR"
                value={itemData?.cnpjEFR}
                onChange={(value) => {
                  handleOnChange("cnpjEFR", value);
                }}
                error={errors.cnpjEFR}
              />
            </Box>
          </Paper>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, marginTop: 2 }}>
            <RButton sx={{ width: "150px", p: 1.5 }} variant="outlined" onClick={closeModal}>
              Fechar
            </RButton>
            <RButton
              disabled={disabledBtn}
              type="submit"
              sx={{ width: "150px", p: 1.5 }}
              onClick={handleValidation}
            >
              {isCreate ? "Incluir" : "Atualizar"}
            </RButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  Content: {
    p: 4,
    backgroundColor: "#E8E8E8",
  },
  Title: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  Inputtitle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  inputs: {
    border: "1px solid #E6E6E6",
    borderRadius: "3px",
    background: "#fff",
    height: "100%",
  },
};
