import React from "react";
import { Box, Modal } from "@mui/material";

const ModalContainer = ({ open, onClose, children, sx, ...props }) => {
  //se onClose === null é impossivel sair do modal clicando no background
  return (
    <Modal open={open} onClose={onClose} {...props}>
      <Box sx={{ ...styles.modal, ...sx }}>{children}</Box>
    </Modal>
  );
};

export default ModalContainer;

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "800px",
    width: "90%",
    borderRadius: "8px 4px 4px 8px",
    bgcolor: "#E8E8E8",
    boxShadow: 24,
    maxHeight: "90vh",
    scrollBehavior: "smooth",
    overflowY: "auto",
  },
};
