import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import ButtonCommon from "../Common/Button";
import PageListHeader from "~/presentation/views/Admin/components/PageListHeader";
import QsoInBulk from "~/presentation/views/Admin/Answer/CreateEdit/CustomFilterListComp/QsoInBulk";
import InBulkHandler from "./components/InBulkHandler";
import TheList from "./components/TheList";

const ListComponent = ({
  data,
  title,
  handleAdd,
  handleCreate,
  titleAdd,
  checkedData = [],
  onClose,
  disablePadding = true,
  securePaths = [],
  blockCheckNumber = null,
  costumFilter = null, //"QSO", "InBulk"
  itemsPerPage = 7,
  setHeightAuto = false,
}) => {
  //Checked items
  const [checked, setChecked] = useState([]);
  const idsChecked = checked.length > 0 ? checked.map((i) => i.id) : [];
  const verifyChecked = (id) => idsChecked.includes(id);

  const blockCheckBoxes = blockCheckNumber && checked.length >= blockCheckNumber;

  //Renderiar a altura da lista responsiva
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(setHeightAuto ? "auto" : 600);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight;
        const headerHeight =
          containerRef.current.querySelector(".page-list-header")?.offsetHeight || 0;
        const filtersHeight = containerRef.current.querySelector(".filters")?.offsetHeight || 0;
        const paginationHeight =
          containerRef.current.querySelector(".pagination")?.offsetHeight || 0;
        const buttonsHeight = containerRef.current.querySelector(".add-button")?.offsetHeight || 0;
        const spacing = 32; // Espaço extra de margem/padding

        // Define a altura disponível para a lista
        setContainerHeight(
          containerHeight -
            (headerHeight + filtersHeight + paginationHeight + buttonsHeight + spacing)
        );
      }
    };

    if (!setHeightAuto) {
      updateHeight();
      window.addEventListener("resize", updateHeight);

      return () => {
        window.removeEventListener("resize", updateHeight);
      };
    }
  }, []);

  //Onchange de cada item
  const handleToggle = (value, isChecked) => () => {
    if (isChecked) {
      setChecked((prev) => prev.filter((selectedItem) => selectedItem.id !== value.id));
    } else {
      let uptadedArray = [...checked];
      if (blockCheckBoxes) return;
      uptadedArray.push(value);
      setChecked(uptadedArray);
    }
  };

  //Fazer a 1º checagem no que vem do "Pai"
  useEffect(() => {
    let firstRender = false;
    if (checkedData?.length > 0) {
      if (!firstRender) {
        setChecked([...checkedData]);
        firstRender = true;
      }
    }
  }, [checkedData]);

  const CostumFilterComponent = () => {
    const { title = "", field = "", nameField = "" } = costumFilter;
    switch (title) {
      case "QSO":
        return <QsoInBulk verifyChecked={verifyChecked} onChange={setChecked} data={data} />;
      case "InBulk":
        return (
          <Box sx={{ width: "min(375px,100%)" }}>
            <InBulkHandler
              action="add"
              onChange={setChecked}
              data={data}
              field={field}
              nameField={nameField}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      ref={containerRef}
      sx={[
        styles.container,
        setHeightAuto
          ? { height: "auto", minHeight: 500 }
          : { height: "100%", minHeight: "70vh", maxHeight: "90vh" },
      ]}
    >
      <PageListHeader
        title={title}
        handleAdd={handleCreate}
        fontSize={20}
        titleAdd={titleAdd}
        handleBack={onClose}
        addSecurePath={securePaths?.length > 0 ? securePaths : null}
      />
      {costumFilter && (
        <Box sx={styles.filters}>
          <CostumFilterComponent
            type={costumFilter}
            onChange={setChecked}
            verifyChecked={verifyChecked}
            data={data}
          />
        </Box>
      )}
      <TheList
        containerHeight={containerHeight}
        itemsPerPage={itemsPerPage}
        verifyChecked={verifyChecked}
        handleToggle={handleToggle}
        blockCheckBoxes={blockCheckBoxes}
        disablePadding={disablePadding}
        setChecked={setChecked}
        data={data}
        checked={checked}
      />
      {handleAdd && (
        <Box sx={styles.addButton}>
          <ButtonCommon
            onClick={() => {
              handleAdd(checked);
              onClose();
            }}
          >
            Salvar
          </ButtonCommon>
        </Box>
      )}
    </Box>
  );
};

export default ListComponent;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  filters: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    px: 3,
    pt: 1,
  },
  addButton: {
    width: "min(375px, 100%)",
    p: 3,
    mx: "auto",
  },
};

export const inputStyles = {
  width: "min(100%, 375px)",
  marginInLine: "auto",
  height: "36px",
  background: "#E6E6E6",
  borderRadius: "12px",
  border: "1px solid #000000",
  padding: "0px 10px",
  "& input::placeholder": {
    color: "gray",
    opacity: 1,
  },
  "&:hover": {
    border: "2px solid #000000",
  },
};
