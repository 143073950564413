import React from "react";
import { makeExplanatoryVideoCategories } from "~/main/factories/use-cases/explanatory-category";
import { makeExplanatoryVideoUseCases } from "~/main/factories/use-cases/explanatory-video";
import ExplanatoryVideo from "~/presentation/views/Admin/ExplanatoryVideo/ExplanatoryVideo";

export const makeExplanatoryVideoTable = (params) => {
  return (
    <ExplanatoryVideo
      {...params}
      explanatoryVideoUseCases={makeExplanatoryVideoUseCases()}
      explanatoryVideoCategory={makeExplanatoryVideoCategories()}
    />
  );
};
