import * as Yup from "yup";

export const statsValidation = Yup.object().shape({
  initialDate: Yup.date().typeError("Data inválida").required("Campo obrigatório"),
  finalDate: Yup.date()
    .when(
      "initialDate",
      (dateStart, yup) =>
        dateStart && yup.min(dateStart, "A data final não pode ser anterior à data de início")
    )
    .typeError("Data inválida")
    .required("Campo obrigatório"),
});
