import React, { useEffect, useState } from "react";
import { Badge, Box, Button, Popover } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import Input from "~/presentation/components/Common/Input";

export const AnswerFilters = ({ onFilter, appliedFilters, setAppliedFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [filters, setFilters] = useState({
    ContentType: "",
    ItemContent: "",
    CnaeCode: "",
    CompanyCategory: "",
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleApplyFilter = () => {
    const formattedFilters = { ...filters };
    if (formattedFilters?.CnaeCode?.includes("(")) {
      formattedFilters.CnaeCode = formattedFilters.CnaeCode.trim()
        .replace(" (", ";")
        .replace(")", "");
    }
    onFilter(formattedFilters);
    setAnchorEl(null);
    updateAppliedFilters(filters);
  };

  const handleClearFilters = () => {
    setFilters({
      ContentType: "",
      ItemContent: "",
      CnaeCode: "",
      CompanyCategory: "",
    });
    onFilter({});
    setAppliedFilters([]);
    setAnchorEl(null);
  };

  const updateAppliedFilters = (currentFitlers) => {
    setAppliedFilters(currentFitlers);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  useEffect(() => {
    if (appliedFilters) {
      setFilters(appliedFilters);
    }
  }, [appliedFilters]);

  const badgeContentCount = Object.entries(appliedFilters).filter(([key, value]) => value).length;

  return (
    <Box>
      <Badge badgeContent={badgeContentCount} color="primary" sx={{ top: "2px" }}>
        <Button
          aria-describedby={id}
          onClick={handleOpen}
          sx={{
            color: "#222",
            gap: "0.25rem",
            fontSize: "0.8125rem",
            background: "#e6e6e6",
            borderRadius: 3,
            px: 2,
            "&:hover": {
              backgroundColor: "#e1e1e1",
            },
          }}
        >
          <FilterAlt sx={{ width: "1.3rem" }} titleAccess={"Filtros"} />
          Filtros
        </Button>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: 2, maxWidth: "300px" }}>
          <h5>Filtros</h5>
          <Grid container spacing={1}>
            <Grid size={12}>
              <Input
                type="text"
                name="ContentType"
                title="Título do item de resposta"
                value={filters.ContentType ?? ""}
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Input
                type="text"
                name="ItemContent"
                title="Itens da resposta"
                value={filters.ItemContent ?? ""}
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Input
                type="text"
                name="CnaeCode"
                title="CNAE"
                value={filters.CnaeCode ?? ""}
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Input
                type="text"
                name="CompanyCategory"
                title="Categoria"
                value={filters.CompanyCategory ?? ""}
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
              }}
              size={{ xs: 12 }}
            >
              <Button variant="outlined" color="error" onClick={handleClearFilters}>
                Limpar filtros
              </Button>
              <Button variant="outlined" color="inherit" onClick={handleApplyFilter}>
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
};
