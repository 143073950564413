import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Badge,
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import Input from "~/presentation/components/Common/Input";
import { hasPermission } from "~/presentation/security/SecurityPath";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { tableStyles } from "./tableStyles";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";
import { useSize } from "~/presentation/hooks/useSize";
import TableExport from "~/presentation/components/TableExport/TableExport";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";

const CommonTable = ({
  columns = [],
  data = [],
  errorMsg = false, //errorMsg costumizado
  selectedRows = null,
  setSelectedRows = null, //entrega as linhas selecinadas
  disabledCheckBox = () => false,
  disabledCheckBoxTooltip = "",
  loading = false,
  tablePage = 0,
  setTablePage = null,
  tableExport = false,
  tableSearch = true,
  collapse = false,
  blockCollapse = () => false,
  costumStyles = {},
  exportFileName = "",
  costumFilter = null,
  costumSearch = null,
  rowsPage = 20,
}) => {
  const { isMobile } = useSize();
  //Search ref para não renderizar com paginação e manter o value
  const queryRef = useRef("");
  // forceUpdate para forçar update da informação no search
  const [forceUpdate, setForceUpdate] = useState(false);
  //Data e Colunas
  const [currentColumns, setCurrentColumns] = useState([]);
  //Paginação
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPage);
  //Seleção
  const [openRows, setOpenRows] = useState([]);
  const [selectedRowsInternal, setSelectedRowsInternal] = useState([]);
  //Ordenação
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  //Nome para exportação

  const styles = tableStyles(costumStyles);

  const sortedData = useMemo(() => {
    if (!data) return [];
    let sorted = Array.isArray(data) && data.length > 0 ? data : [];
    if (orderBy) {
      sorted.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return orderDirection === "asc" ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return orderDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [data, orderBy, orderDirection]);

  const normalizeText = (text = "") => {
    return text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .toUpperCase();
  };

  const filteredData = useMemo(() => {
    if (!sortedData) return [];
    if (queryRef.current === "") return sortedData;

    const searchTerm = normalizeText(queryRef.current);

    return sortedData.filter((row) => {
      return Object.entries(row).some(([key, value]) => {
        const isColumnField = currentColumns.some((col) => col.field === key);

        if (!isColumnField || !value) return false;

        let formattedValue = value;

        if (key.toLowerCase().includes("date")) {
          formattedValue = formatDateToBrazilian(value);
        } else if (key.toLowerCase().includes("cnpj")) {
          formattedValue = formatCpfCnpj(value);
        }

        const normalizedValue =
          typeof formattedValue === "string"
            ? normalizeText(formattedValue)
            : String(formattedValue);

        return normalizedValue.includes(searchTerm);
      });
    });
  }, [sortedData, forceUpdate]);

  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedData = filteredData.slice(startIndex, endIndex);

  const handleSearch = (event) => {
    queryRef.current = event.target.value;
    setCurrentPage(0);
    if (setTablePage) setTablePage(0);
    setForceUpdate((prev) => !prev);
  };

  //Pagination
  const onChangePage = (_, page) => {
    setCurrentPage(page);
    if (setTablePage) setTablePage(page);
  };

  useEffect(() => {
    if (selectedRows) setSelectedRowsInternal(selectedRows);
  }, [selectedRows]);
  useEffect(() => setCurrentPage(tablePage), [tablePage]);

  // IDs das linhas selecionadas
  const selectedRowsIds = new Set(selectedRowsInternal.map((i) => i.id));
  const ableRowsToSelect = data?.length > 0 ? data.filter((i) => !disabledCheckBox(i)) : [];

  // Verifica se uma linha está selecionada
  const isRowSelected = (row) => Boolean(selectedRowsIds.has(row.id));

  // Alterna a seleção de uma única linha
  const toogleRow = (row) => {
    setSelectedRowsInternal((prevSelected) => {
      const updatedSelectedRows = isRowSelected(row)
        ? prevSelected.filter((i) => i.id !== row.id)
        : [...prevSelected, row];

      if (setSelectedRows) setSelectedRows(updatedSelectedRows);
      return updatedSelectedRows;
    });
  };

  // Verifica se todas as linhas da página estão selecionadas
  const isAllAbleRowsSelected = () => {
    if (ableRowsToSelect.length > 0 && selectedRowsInternal.length > 0) {
      return Boolean(ableRowsToSelect.length === selectedRowsInternal.length);
    }
    return false;
  };

  // Alterna a seleção de todas as linhas da página
  const onToogleAllPageRows = () => {
    setSelectedRowsInternal(() => {
      const newSelectedRows = isAllAbleRowsSelected() ? [] : [...ableRowsToSelect];
      if (setSelectedRows) setSelectedRows(newSelectedRows);
      return newSelectedRows;
    });
  };

  //Collapse Rows
  const isCollapseOpen = (row) => openRows.some((item) => item.id === row.id);

  const onToogleCollapse = (row, isOpen) => {
    setOpenRows((prev) => (isOpen ? prev.filter((i) => i.id !== row.id) : [...prev, row]));
  };

  const onToogleSort = (field) => {
    if (field) {
      const isAsc = orderBy === field && orderDirection === "asc";
      setOrderDirection(isAsc ? "desc" : "asc");
      setOrderBy(field);
    }
  };

  // Columns (renderizar coluns de acordo ao screen size)
  useEffect(() => {
    if (!columns) return [];

    const updateColumns = () => {
      let cols = columns?.length > 0 ? columns : [{ field: "", headerName: "Tabela", flex: 1 }];

      const width = window.innerWidth;

      const newColumns = cols?.filter((col) => {
        if (col.hide === "xs" && width < 600) return false;
        if (col.hide === "md" && width < 900) return false;
        if (col.hide === "lg" && width < 1280) return false;
        if (col.hide === "xl" && width < 1440) return false;
        return true;
      });

      setCurrentColumns(newColumns);
    };

    updateColumns();
    window.addEventListener("resize", updateColumns);

    return () => window.removeEventListener("resize", updateColumns);
  }, [columns]);

  return (
    <TableContainer sx={styles.container}>
      {(tableExport || tableSearch) && (
        <Box sx={styles.toolbar}>
          <Box sx={styles.flex}>
            {tableExport && (
              <TableExport
                data={data}
                columns={columns}
                fileName={`${exportFileName || "informações"}-${formatDateToBrazilian(new Date())}`}
                styles={styles.exportButton}
              />
            )}
            {costumFilter && costumFilter}
          </Box>
          {tableSearch && costumSearch ? (
            costumSearch
          ) : (
            <Input
              type="search"
              disabled={data?.length === 0}
              placeholder="Pesquisar..."
              ref={queryRef}
              onChange={handleSearch}
              style={styles.searchInput}
            />
          )}
        </Box>
      )}
      <MuiTable sx={styles.table}>
        <TableHead sx={styles.tableHeader}>
          <TableRow>
            {collapse && <TableCell sx={styles.headerIconCell}></TableCell>}
            {setSelectedRows && (
              <TableCell sx={styles.headerIconCell}>
                <Badge badgeContent={selectedRowsInternal?.length} max={999} color="primary">
                  <Checkbox
                    sx={styles.headerCheckboxIcon}
                    checked={isAllAbleRowsSelected()}
                    onClick={onToogleAllPageRows}
                    disabled={ableRowsToSelect?.length === 0}
                    value=""
                  />
                </Badge>
              </TableCell>
            )}
            {currentColumns.map((col, colIndex) => {
              if (col?.securePaths && !hasPermission(col.securePaths)) return;
              return (
                <React.Fragment key={colIndex}>
                  <TableCell
                    sx={{
                      ...styles.headerCell,
                      flexGrow: col.flex || 1,
                      flexBasis: 0,
                      cursor: col.sortable === false ? "auto" : "pointer",
                    }}
                    onClick={() => {
                      if (col.sortable === false) return; //precisa ser === false
                      onToogleSort(col.field);
                    }}
                  >
                    <span>
                      {col.headerName}
                      {orderBy === col.field ? (
                        orderDirection === "asc" ? (
                          <ArrowDownward sx={styles.sortIcon} fontSize="small" />
                        ) : (
                          <ArrowUpward sx={styles.sortIcon} fontSize="small" />
                        )
                      ) : null}
                    </span>
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {slicedData?.length > 0 ? (
            slicedData.map((row, rowIndex) => {
              const isDocChanged = row?.hadChanges || false;
              const isRowOpen = isCollapseOpen(row);
              const isCheckBoxDisabled = disabledCheckBox(row);
              const isDocSelected = isRowSelected(row);
              return (
                <React.Fragment key={rowIndex}>
                  <TableRow sx={styles.bodyRow} className={isDocChanged ? "changed" : ""}>
                    {collapse && (
                      <TableCell sx={styles.iconCell}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (!blockCollapse(row)) onToogleCollapse(row, isRowOpen);
                          }}
                          disabled={loading}
                          sx={styles.collapseIcon}
                        >
                          {blockCollapse(row) ? (
                            <></>
                          ) : isRowOpen ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      </TableCell>
                    )}
                    {setSelectedRows && (
                      <TableCell
                        sx={{
                          ...styles.iconCell,
                          cursor: isCheckBoxDisabled ? "not-allowed" : "pointer",
                        }}
                      >
                        <Tooltip title={isCheckBoxDisabled ? disabledCheckBoxTooltip : ""}>
                          <span>
                            <Checkbox
                              sx={styles.checkboxIcon}
                              disabled={isCheckBoxDisabled}
                              checked={isDocSelected}
                              onChange={() => toogleRow(row)}
                              value=""
                            />
                          </span>
                        </Tooltip>
                      </TableCell>
                    )}
                    {currentColumns.map((col, colIndex) => {
                      if (col?.securePaths && !hasPermission(col.securePaths)) return;
                      const text = row[col.field] || "";
                      const textLength = row[col.field]?.length;
                      const isTextBig = textLength > 100;
                      const substringText = isTextBig
                        ? String(text).substring(0, 100) + "..."
                        : text;
                      return (
                        <TableCell
                          sx={{
                            ...styles.bodyCell,
                            flexGrow: col.flex || 1,
                            flexBasis: 0,
                          }}
                          key={colIndex}
                          align={col.align || "left"}
                        >
                          {col.renderCell ? (
                            col.renderCell({ value: row[col.field], row })
                          ) : (
                            <Tooltip title={isTextBig ? text : ""}>{substringText}</Tooltip>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {collapse && (
                    <TableRow>
                      <TableCell sx={styles.collapseRow} colSpan="100%">
                        <Collapse in={isRowOpen} timeout="auto" unmountOnExit>
                          {collapse(row)}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan="100%" sx={styles.errorMsgRow}>
                {loading ? (
                  <span>Carregando informações...</span>
                ) : (
                  <span>{errorMsg || "Nenhum dado encontrado."}</span>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
      {filteredData?.length > 4 && (
        <TablePagination
          sx={styles.pagination}
          count={filteredData?.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          showFirstButton={isMobile ? false : true}
          showLastButton={isMobile ? false : true}
          onPageChange={onChangePage}
          onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
          rowsPerPageOptions={[5, 20, 50, 100]}
          labelRowsPerPage={isMobile ? "" : "Linhas por página:"}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          getItemAriaLabel={(type) => {
            if (type == "first") return `Primeira página`;
            if (type == "previous") return `Página anterior`;
            if (type == "next") return `Próxima página`;
            if (type == "last") return `Última página`;
          }}
        />
      )}
    </TableContainer>
  );
};

export default CommonTable;
