import React, { useContext, useEffect, useState } from "react";
import { EditUserForm } from "~/presentation/views/Client/MyPanel/Pages/UserData/components/EditUserForm/index";
import { Box, Typography as Text } from "@mui/material";
import Input from "~/presentation/components/Common/Input";
import Grid from "@mui/material/Grid2";
import { LoadingTable } from "../../components/LoadingTable";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { painelStyles } from "../..";

export const UserData = ({ userUseCases, onGetCompany, companyData = null }) => {
  const { id } = JSON.parse(localStorage.getItem("GT_user"));
  const { showError } = useContext(SnackbarContext);
  const [loading, setLoading] = useState({ user: true, company: true });
  const [company, setCompany] = useState({});
  const [user, setUser] = useState(null);

  const getUser = async () => {
    try {
      setLoading((prev) => ({ ...prev, user: true }));
      const result = await userUseCases.getUserById({ id: id, checkPermission: false });
      setUser(result);
    } catch (error) {
      showError(error, "Erro ao carregar usuários");
    } finally {
      setLoading((prev) => ({ ...prev, user: false }));
    }
  };

  const updateUser = (data) => {
    return userUseCases.updateUser({ id: id, data: data, checkPermission: false });
  };

  const handleSetCompany = async (companyData) => {
    const mobilePhone = companyData?.mobilePhone || "";
    const homePhone = companyData?.mobilePhone || "";
    const cnpj = companyData?.cnpj || "";
    const cep = companyData?.zipCode || "";
    const formattedMobilePhone = mobilePhone ? (
      <Input type="mask" mask="(00) 00000-0000" value={mobilePhone} styles={styles.input} />
    ) : null;

    const formattedHomePhone = homePhone ? (
      <Input type="mask" mask="(00) 00000-0000" value={homePhone} styles={styles.input} />
    ) : null;

    const formattedCpnj = cnpj ? (
      <Input type="mask" mask="00.000.000/0000-00" value={cnpj} styles={styles.input} />
    ) : null;

    const formattedCep = cnpj ? (
      <Input type="mask" mask="00000-000" value={cep} styles={styles.input} />
    ) : null;

    setCompany([
      ["Categoria", companyData?.category?.name],
      ["Plano", companyData?.plan?.name],
      ["CNPJ", formattedCpnj],
      ["Nome", companyData?.name],
      ["Endereço", companyData?.address],
      ["Complemento", companyData?.complement],
      ["CEP", formattedCep],
      ["Cidade", companyData?.city?.name],
      ["Estado", companyData?.state?.name],
      ["Telefone", formattedHomePhone],
      ["Celular", formattedMobilePhone],
      ["E-mail", companyData?.email],
    ]);

    setLoading((prev) => ({ ...prev, company: false }));
  };

  useEffect(() => {
    if (!companyData) {
      onGetCompany();
    }
    getUser();
  }, []);

  useEffect(() => {
    if (companyData) {
      handleSetCompany(companyData);
    }
  }, [companyData]);

  return (
    <Box sx={{ ...painelStyles.flexCol, mb: 12 }}>
      <Text sx={styles.title}>Minha Empresa</Text>
      {loading.company ? (
        <LoadingTable />
      ) : (
        <Grid container spacing={2}>
          {company?.map((c, i) => (
            <Grid size={{ xs: 12, sm: 6 }} key={i}>
              <Box sx={styles.box}>
                <Text sx={[styles.text, { fontWeight: 500 }]}>{c[0]}</Text>
                <Text sx={styles.text}>{c[1]}</Text>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      <Text sx={styles.title}>Editar minhas Informações</Text>
      {loading.user ? (
        <LoadingTable />
      ) : (
        <EditUserForm user={user} updateUser={updateUser} refreshUser={getUser} />
      )}
    </Box>
  );
};

const styles = {
  box: {
    backgroundColor: "#EFF2F4",
    borderRadius: 3,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  ifs: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#EFF2F4",
    padding: "20px 20px",
    borderRadius: 2,
  },
  border: {
    borderLeft: "2px solid #9EA5AA",
    height: 20,
  },
  text: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    pb: 3,
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    backgroundColor: "transparent",
    border: "none",
    textAlign: "right",
    color: "#021148",
    height: "fit-content",
  },
};
