import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

export const QuestionLabel = ({ text, excerpt }) => {
  const [show, setShow] = useState(false);

  const toggleExcerpt = () => {
    setShow((prev) => !prev);
  };

  return (
    <Box sx={{ fontFamily: "Roboto, sans-serif", fontWeight: 300 }}>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Typography variant="subtitle2">{text}</Typography>
        {excerpt && (
          <Info
            fontSize="small"
            sx={{ margin: "0 12px 0 auto", color: "#000", transform: "scale(0.9)" }}
            onClick={toggleExcerpt}
          />
        )}
      </Box>
      {excerpt && (
        <Box
          sx={{
            height: show ? "fit-content" : 0,
            overflow: "hidden",
            transition: "all 0.2s ease-in-out",
            opacity: show ? 1 : 0,
          }}
        >
          <span style={{ fontSize: "14px" }}>{excerpt}</span>
        </Box>
      )}
    </Box>
  );
};
