import { apiURL } from "../../usecases/api";
export class ApiContactUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async contactForm(values) {
    try {
      const result = await this.httpClient.request({
        url: `${apiURL}/contactus`,
        method: "POST",
        data: values,
      });
      return result;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async newsletterSubscribe(email) {
    try {
      const result = await this.httpClient.request({
        url: `${apiURL}/newsletter-subscribe`,
        method: "POST",
        data: {
          email,
        },
      });
      return result;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async subscription(data) {
    const apiData = {
      categoryId: data.category.value,
      planId: data.plan.value,
      company: data.company,
      email: data.email,
      name: data.name,
      phone: data.phone,
    };

    try {
      const result = await this.httpClient.request({
        url: `${apiURL}/subscription`,
        method: "POST",
        data: apiData,
      });
      return result;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
