import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";

function PeriodFilter({ period, onChange, onSubmit }) {
  const isDateWrong = period.start > period.end;
  const isDateInTheFuture = period.start > new Date() || period.end > new Date();
  const errorDate = isDateWrong || isDateInTheFuture;

  const errorMessage = () => {
    if (isDateWrong) return "Data final superior à inicial.";
    if (isDateInTheFuture) return "Data no futuro.";
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ ...formStyles.title, mb: 1 }}>Período</Typography>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: { xs: "column", lg: "row" },
          pb: 1,
          maxWidth: "100%",
        }}
      >
        <Input
          type="date"
          title="Data inicial"
          value={period.start}
          onChange={(value) => onChange("start", value)}
          sx={{ backgroundColor: "transparent", minWidth: "120px !important" }}
          maxDate={period.end || new Date()}
          error={errorDate && errorMessage()}
        />
        <Input
          type="date"
          title="Data final"
          value={period.end}
          onChange={(value) => onChange("end", value)}
          sx={{ backgroundColor: "transparent", minWidth: "120px !important" }}
          error={errorDate && errorMessage()}
          maxDate={new Date()}
        />
      </Box>
      <ButtonCommon onClick={onSubmit} sx={{ borderRadius: "12px", height: "36px" }}>
        Aplicar
      </ButtonCommon>
    </Box>
  );
}

export default PeriodFilter;
