import { Formik, Form } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { floatToReais } from "~/presentation/utils/reais-parser";
import {
  Button,
  FormWrapper,
  MoneyQuestion,
  RadioQuestion,
  SelectQuestion,
  SimulatorResultCard,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { INSSCalculator } from "~/presentation/views/GTFacil/Simulator/entities/inss-calculator";
import {
  HasMaterialOrEquipmentExclusion,
  MaterialOrEquipmentExclusionValue,
  HasTransportationOrAlimentationBenefitsDeduction,
  TransportationOrAlimentationBenefitsDeductionValue,
} from "~/presentation/views/GTFacil/Simulator/forms/common/inss/questions";
import { reportMapper } from "./reportMapper";

export const additionalAliquotRetentionOptions = [
  { label: "9%", value: 0.09 },
  { label: "7%", value: 0.07 },
  { label: "5%", value: 0.05 },
];

const initialValues = {
  hasMaterialOrEquipmentExclusion: "false",
  materialOrEquipmentExclusionValue: 0.0,
  hasTransportationOrAlimentationBenefitsDeduction: "false",
  transportationOrAlimentationBenefitsDeductionValue: 0.0,
  aliquotRetention: 0.15,
  hasAdditionalAliquotRetention: "false",
  additionalAliquotRetention: additionalAliquotRetentionOptions[0],
};

export const InssCooperative = () => {
  const resultCardRef = useRef();
  const [result, setResult] = useState();
  const [lastSubmitValues, setLastSubmitValues] = useState("");
  const { simulatorValue, setInvalidSimulatorValue, handleCacheReport } =
    useContext(SimulatorContext);

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  const inssCalculatorFactory = (values) => new INSSCalculator(mapValuesToCalculatorProps(values));

  const mapValuesToCalculatorProps = (values) => ({
    materialOrEquipmentExclusionValue: values.materialOrEquipmentExclusionValue,
    transportationOrAlimentationBenefitsDeductionValue:
      values.transportationOrAlimentationBenefitsDeductionValue,
    simulatorValue: simulatorValue,
    aliquotRetention: values.aliquotRetention,
    additionalAliquotRetention:
      values.hasAdditionalAliquotRetention === "true" ? values.additionalAliquotRetention.value : 0,
  });

  useEffect(() => {
    if (result) {
      resultCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }
        action.setSubmitting(true);
        setLastSubmitValues(values);

        const inssCalculator = inssCalculatorFactory(values);

        setResult(inssCalculator.retentionValue);

        action.setSubmitting(false);

        const response = reportMapper(inssCalculator, inssCalculator.retentionValue);

        handleCacheReport(response);

        resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
      }}
    >
      {({ setFieldValue, values }) => {
        const inssCalculator = inssCalculatorFactory(values);

        return (
          <Form>
            <FormWrapper>
              <HasMaterialOrEquipmentExclusion />

              {values.hasMaterialOrEquipmentExclusion === "true" ? (
                <MaterialOrEquipmentExclusionValue />
              ) : null}

              <HasTransportationOrAlimentationBenefitsDeduction />

              {values.hasTransportationOrAlimentationBenefitsDeduction === "true" ? (
                <TransportationOrAlimentationBenefitsDeductionValue />
              ) : null}

              <MoneyQuestion
                text="Base de cálculo da contribuição patronal:"
                value={inssCalculator.baseRetentionCalcValue}
                excerpt="A base de cálculo é o valor bruto da nota fiscal excluídos e deduzidos os valores dos materiais/equipamentos e do vale-transporte ou despesa com alimentação, respectivamente."
                allowNegative
                disabled
              />

              <MoneyQuestion
                text="Alíquota da contribuição patronal:"
                excerpt="De acordo com o art. 72, IV da IN 971/2009, a alíquota da contribuição patronal da cooperativa de trabalho é de 15%."
                value={values.aliquotRetention * 100}
                prefix="%"
                disabled
                allowNegative
              />

              <RadioQuestion
                text="Há incidência da alíquota adicional do art. 72, § 2º, III, da IN 971?"
                excerpt="As alíquotas adicionais incidem quando a atividade do segurado na empresa contratante for exercida em condições especiais que prejudiquem a saúde ou a integridade física destes, de forma a possibilitar a concessão de aposentadoria especial após 15, 20 ou 25 anos de trabalho."
                onChange={(value) => {
                  setFieldValue("hasAdditionalAliquotRetention", value);
                  setFieldValue(
                    "additionalAliquotRetention",
                    initialValues.additionalAliquotRetention
                  );
                }}
                value={values.hasAdditionalAliquotRetention}
              />

              {values.hasAdditionalAliquotRetention === "true" ? (
                <SelectQuestion
                  text="Qual a alíquota adicional?"
                  excerpt="As alíquotas adicionais serão de 9%, 7% ou 5%, caso ensejem a concessão da aposentadoria especial após 15 (quinze), 20 (vinte) ou 25 (vinte e cinco) anos de trabalho, respectivamente."
                  value={values.additionalAliquotRetention.value}
                  onChange={(value) => setFieldValue("additionalAliquotRetention", value)}
                  options={additionalAliquotRetentionOptions}
                />
              ) : null}

              <Button>Ver Resultado</Button>

              {result && valuesNotChangedSinceLastSubmit(values) ? (
                <SimulatorResultCard
                  componentRef={resultCardRef}
                  title="Valor da contribuição patronal:"
                  value={floatToReais(result)}
                  description="O valor da contribuição patronal corresponde ao resultado da multiplicação da alíquota da contribuição pela base de cálculo. (Após decisão do STF não há mais a contribuição previdenciária patronal de 15% nas contratações de cooperativas de trabalho. Por isso, não proceder ao recolhimento desse valor. Esse valor poderá ser útil para possíveis repetições de indébito ou similares)."
                />
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
