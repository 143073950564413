import React, { useEffect, useState } from "react";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import cnpjSearch from "~/data/functions/cnpj-search";
import { Box, Typography as Text, IconButton, Tooltip } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import validation from "./validation";
import Grid from "@mui/material/Grid2";
import TableActions from "~/presentation/components/TableActions";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { useDependentsOptions } from "~/presentation/views/Reinf/Utils/utilsOptionsReinf";
import Table from "~/presentation/views/Reinf/components/Table/ReinfTable";
import { DependentModal } from "~/presentation/views/Reinf/components/Layouts/paymentLayout/sections/Dependent";
import { validarCPF } from "~/presentation/functions/cpfValidator";
import { useDialog } from "~/presentation/providers/DialogProvider";
import {
  makeBusinessPartnerUseCases,
  makeDependentUseCases,
} from "~/presentation/views/Reinf/Utils/utilsApiCalls";

const typesPartnerOptions = [
  {
    value: 0,
    title: "Pessoa Física",
  },
  {
    value: 1,
    title: "Pessoa Jurídica",
  },
];

export const PartnerForm = ({
  onClose,
  partnerFormData = null,
  refreshData,
  personType,
  allPartners,
}) => {
  const apiBusinessPartner = makeBusinessPartnerUseCases();
  const apiDependent = makeDependentUseCases();
  const { closeSecondDialog, openSecondDialog } = useDialog();
  const { showError, showSuccess } = useSnackbar();
  const { openBackdrop } = useBackdrop();
  const { getDescriptionDependent } = useDependentsOptions();

  const [errors, setErrors] = useState({});
  const [dependents, setDependents] = useState([]);

  const disabledChooseTypePerson = personType === 1 || personType === 0;

  const [partnerData, setPartnerData] = useState({
    cpfCnpj: "",
    personType: disabledChooseTypePerson ? personType : 1, //PersonType (0 = PF, 1 = PJ)
    legalName: "",
    ie: "",
    im: "",
    tradeName: "",
    address: "",
    number: "",
    complement: "",
    municipalityCode: "",
    zipCode: "",
    homePhone: "",
    email: "",
  });

  const isEdit = partnerData?.id;
  const isLegalPartner = partnerData.personType === 1;

  const isCpfCnpjAlreadyRegistered =
    !isEdit &&
    allPartners?.some(
      (partner) =>
        partner.cpfCnpj === partnerData?.cpfCnpj && partner.personType === partnerData?.personType
    );

  const isAlreadyRegistered =
    !isEdit &&
    isCpfCnpjAlreadyRegistered &&
    `Já existe um parceiro com esse ${isLegalPartner ? "CNPJ" : "CPF"}`;

  const isCpfWrong = !validarCPF(partnerData?.cpfCnpj) && "Digite um CPF válido";

  useEffect(() => {
    if (partnerFormData?.id) {
      setPartnerData(partnerFormData);
      getDependents(partnerFormData.id);
    }
  }, [partnerFormData]);

  const handleChange = (name, value) => {
    setPartnerData((prev) => ({ ...prev, [name]: value }));
    clearError(name);
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleCnpjSearch = async () => {
    const cnpj = partnerData?.cpfCnpj;
    openBackdrop(true, "Buscando CNPJ...");
    try {
      const data = await cnpjSearch(cnpj);
      const legalName = data?.razao_social || "";
      const tradeName = data?.estabelecimento.nome_fantasia || "";
      const address =
        `${data?.estabelecimento.tipo_logradouro || ""} ${
          data?.estabelecimento.logradouro || ""
        }` || "";
      const number = data?.estabelecimento.numero || "";
      const complement = data?.estabelecimento.complemento || "";
      const municipalityCode =
        `${data?.estabelecimento.cidade.id || ""} ${"-"} ${
          data?.estabelecimento.cidade.nome || ""
        } ${"/"} ${data?.estabelecimento.estado.sigla || ""}` || "";
      const zipCode = data?.estabelecimento.cep;
      const homePhone =
        `${data?.estabelecimento.ddd1 || ""}${data?.estabelecimento.telefone1 || ""}` || "";
      const email = data?.estabelecimento.email || "";
      legalName && handleChange("legalName", legalName);
      tradeName && handleChange("tradeName", tradeName);
      address && handleChange("address", address);
      number && handleChange("number", number);
      complement && handleChange("complement", complement);
      municipalityCode && handleChange("municipalityCode", municipalityCode);
      zipCode && handleChange("zipCode", zipCode);
      homePhone && handleChange("homePhone", homePhone);
      email && handleChange("email", email);
    } catch (error) {
      showError({}, `CNPJ não encontrado`);
    } finally {
      openBackdrop(false);
    }
  };

  const handleSubmit = async () => {
    openBackdrop(true, `${isEdit ? "Atualizando" : "Cadastrando"} Parceiro`);

    const dataToSend = {
      ...partnerData,
      municipalityCode: partnerData?.municipalityCode?.split(" ")[0] || "",
    };

    validation
      .validate(dataToSend, { abortEarly: false })
      .then(async () => {
        setErrors({});
        try {
          if (isEdit) {
            await apiBusinessPartner.updateBusinessPartner(dataToSend, isEdit);
          } else {
            await apiBusinessPartner.createBusinessPartner(dataToSend);
          }
          showSuccess(
            isEdit ? "Parceiro atualizado com sucesso" : "Novo parceiro cadastrado com sucesso"
          );
          refreshData();
          onClose();
        } catch (error) {
          showError(error, `Erro ao processar dados`);
        } finally {
          openBackdrop(false);
        }
      })
      .catch((error) => {
        setErrors(
          error?.inner?.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleOpenDependentsForms = (dependent = null) => {
    openSecondDialog({
      maxWidth: "sm",
      customComponent: () => (
        <DependentModal
          payData={partnerData}
          initialValue={dependent}
          handleClose={closeSecondDialog}
          refreshData={refreshDependets}
        />
      ),
    });
  };

  const getDependents = async (id) => {
    openBackdrop(true, "Carregando dependentes");
    try {
      const result = await apiDependent.getDependent(id);
      setDependents(result);
    } catch (error) {
      showError(error, "Erro ao carregar dependentes");
    } finally {
      openBackdrop(false);
    }
  };

  const handleDeleteDependent = async (id) => {
    try {
      await apiDependent.deleteDependent(id);
      setDependents((prev) => prev.filter((i) => i.id !== id));
    } catch (error) {
      showError(error, "Erro ao eliminar dependente.");
    }
  };

  const refreshDependets = async () => {
    await getDependents();
    closeSecondDialog();
  };

  const columnsDependents = [
    {
      field: "cpf",
      headerName: "CPF",
      align: "center",
      renderCell: ({ value }) => formatCpfCnpj(value),
    },
    {
      field: "dependentType",
      headerName: "Tipo de dependente",
      align: "center",
      renderCell: ({ value }) => getDescriptionDependent(value),
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenDependentsForms(row)}
          handleDelete={() => handleDeleteDependent(row?.id)}
        />
      ),
    },
  ];

  return (
    <>
      <Box sx={styles.Content}>
        <Grid container spacing={1}>
          <Grid
            size={12}
            sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}
          >
            <Text style={styles.Title}>{isEdit ? "Detalhes do" : "Adicionar novo"} contratado</Text>
            <IconButton title="Fechar" onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Input
              disabled={disabledChooseTypePerson}
              type="select"
              title="Tipo de contratado"
              options={typesPartnerOptions}
              getOptionLabel={(option) => option.title}
              placeholder="Selecione o tipo de documento"
              value={typesPartnerOptions.find((item) => item.value === partnerData?.personType)}
              onChange={(option) => {
                setPartnerData({ ...partnerData, personType: option.value });
              }}
            />
          </Grid>
          {isLegalPartner ? (
            <>
              <Grid size={{ xs: 12, sm: 8 }} sx={{ position: "relative" }}>
                <Input
                  type="mask"
                  title="CNPJ do Contratado"
                  mask="00.000.000/0000-00"
                  name="cpfCnpj"
                  value={partnerData.cpfCnpj}
                  onChange={(e) => handleChange("cpfCnpj", e)}
                  error={errors?.cpfCnpj || isAlreadyRegistered}
                />
                {partnerData?.cpfCnpj?.length == 14 && (
                  <Box sx={{ position: "absolute", top: "21px", right: "3px" }}>
                    <Tooltip title="Pesquisar CNPJ na RFB">
                      <IconButton disabled={isCpfCnpjAlreadyRegistered} onClick={handleCnpjSearch}>
                        <Search style={{ color: "#14213D" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 7 }}>
                <Input
                  type="text"
                  title="Nome Empresarial"
                  name="legalName"
                  value={partnerData.legalName}
                  onChange={(e) => handleChange("legalName", e.target.value)}
                  error={errors?.legalName}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 5 }}>
                <Input
                  type="text"
                  title="Nome Fantasia"
                  name="tradeName"
                  value={partnerData.tradeName}
                  onChange={(e) => handleChange("tradeName", e.target.value)}
                  error={errors?.tradeName}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 9 }}>
                <Input
                  type="text"
                  title="Endereço"
                  name="address"
                  value={partnerData.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 3 }}>
                <Input
                  type="text"
                  title="Número"
                  name="number"
                  value={partnerData.number}
                  onChange={(e) => handleChange("number", e.target.value)}
                  error={errors?.number}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 5 }}>
                <Input
                  type="text"
                  title="Inscrição Estadual"
                  name="ie"
                  value={partnerData.ie}
                  onChange={(e) => handleChange("ie", e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 7 }}>
                <Input
                  type="text"
                  title="Inscrição Municipal"
                  name="im"
                  value={partnerData.im}
                  onChange={(e) => handleChange("im", e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 8 }}>
                <Input
                  type="text"
                  disabled
                  title="Cidade/ UF"
                  name="municipalityCode"
                  value={partnerData.municipalityCode}
                  onChange={(e) => handleChange("municipalityCode", e.target.value)}
                  error={errors?.municipalityCode}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <Input
                  type="mask"
                  title="CEP"
                  name="cep"
                  mask={"00000-000"}
                  value={partnerData.zipCode}
                  onChange={(e) => handleChange("zipCode", e)}
                  error={errors?.zipCode}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 3 }}>
                <Input
                  type="mask"
                  title="Telefone"
                  name="homePhone"
                  mask={"(00) 0000-0000"}
                  value={partnerData.homePhone}
                  onChange={(e) => handleChange("homePhone", e)}
                  error={errors?.homePhone}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 9 }}>
                <Input
                  type="text"
                  title="Email"
                  name="email"
                  value={partnerData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  error={errors?.email}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid size={{ xs: 12, md: 8 }}>
                <Input
                  type="mask"
                  title="CPF do Contratado"
                  mask="000.000.000-00"
                  name="cpfCnpj"
                  value={partnerData.cpfCnpj}
                  onChange={(value) => handleChange("cpfCnpj", value)}
                  error={isCpfWrong || isAlreadyRegistered}
                />
              </Grid>
              <Grid size={12}>
                <Input
                  type="text"
                  title="Nome"
                  name="legalName"
                  value={partnerData.legalName}
                  onChange={(e) => handleChange("legalName", e.target.value)}
                  error={errors?.legalName}
                />
              </Grid>
              {isEdit && ( //só aparece quando existe parceiro PF já cadastrado
                <Grid size={{ xs: 12 }}>
                  <Table
                    data={dependents}
                    columns={columnsDependents}
                    titleTable={"Dependentes"}
                    handleAdd={() => handleOpenDependentsForms()}
                    searchInput={false}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
          <RButton
            sx={{ width: "min(150px, 100%)" }}
            type="button"
            variant="outlined"
            onClick={onClose}
          >
            Cancelar
          </RButton>
          <RButton
            sx={{ width: "min(175px, 100%)" }}
            disabled={isCpfCnpjAlreadyRegistered}
            variant="contained"
            onClick={handleSubmit}
          >
            {isEdit ? "Salvar" : "Cadastrar"}
          </RButton>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  Content: {
    p: 4,
    backgroundColor: "#E8E8E8",
  },

  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    userSelect: "none",
  },
};
