import { Box, TablePagination } from "@mui/material";
import React, { useState } from "react";
import ItemAcordion from "./ItemAcordion";
import { tableStyles } from "~/presentation/views/Reinf/components/Styles/TableStyles";
import Input from "~/presentation/components/Common/Input";
import { useTableFilters } from "~/presentation/hooks/useTableFilters";

function AnswerItemsTab({ values, typesAnswer, getTypesAnswer, handleDelete, fetchItemAnswer }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const { handleSearchTable } = useTableFilters();
  const [currentData, setCurrentData] = useState(values || []);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedData = currentData?.length > 0 ? currentData?.slice(startIndex, endIndex) : [];

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    handleSearchTable(value, values, setCurrentData);
  };

  return (
    <Box sx={{ px: 2 }}>
      <Input type="search" placeholder="Pesquisar..." value={search} onChange={handleSearch} />
      <Box sx={styles.tabsContainer}>
        {slicedData.length > 0 ? (
          slicedData.map((item, index) => (
            <ItemAcordion
              key={index}
              item={item}
              typesAnswer={typesAnswer}
              getTypesAnswer={getTypesAnswer}
              handleDelete={handleDelete}
              fetchItemAnswer={fetchItemAnswer}
            />
          ))
        ) : (
          <ItemAcordion
            item={{ title: "Não existem items para mostrar" }}
            typesAnswer={[]}
            getTypesAnswer={() => {}}
            handleDelete={() => {}}
            noItemsToShow
          />
        )}
        <Box sx={tableStyles.pagination}>
          <TablePagination
            component="div"
            count={currentData.length}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value))}
            labelRowsPerPage="Linhas por página:"
            rowsPerPageOptions={[5, 10, 15]}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AnswerItemsTab;

const styles = {
  tabsContainer: {
    borderRadius: "12px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    gap: 0.5,
    mt: 1,
    mb: 6,
    backgroundColor: "#fff",
  },
};
