import React, { useState, useEffect, useContext, useRef } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
// functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import RegisterEdit from "./RegisterEdit/RegisterEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";
import { CompanyFilter } from "./CompanyFilter/CompanyFilter";
import AdminTable from "../components/AdminTable/AdminTable";

const User = ({ userUseCases, companyUseCases, companyIdFromForms }) => {
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);
  const [currentCompanyId, setCurrentCompanyId] = useState(companyIdFromForms ?? user?.companyId);
  //Data
  const [users, setUsers] = useState([]);

  //Select
  const [companies, setCompanies] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "md",
      customComponent: () => (
        <RegisterEdit
          data={data}
          onCloseForm={closeDialog}
          refreshData={fetchData}
          userUseCases={userUseCases}
          companies={companies}
          fetchCompanys={fetchCompanys}
        />
      ),
    });
  };

  const columns = [
    { field: "name", headerName: "Nome", flex: 4 },
    { field: "email", headerName: "Email", flex: 4, hide: "md" },

    {
      field: "userProfile",
      headerName: "Perfil de usuário",
      flex: 2,
      align: "center",
      hide: "md",
    },
    {
      field: "actions",
      headerName: "Ações",
      renderCell: ({ row }) => handleActions(row),
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
    },
  ];

  const fetchData = async (companyId) => {
    openBackdrop(true, `Carregando usuários ${companyId === 0 ? "." : "da empresa"}`);
    try {
      setCurrentCompanyId(companyId);
      const users = await userUseCases.getUsers({ checkPermission: true, companyId: companyId });
      setUsers(users);
    } catch (error) {
      showError(error, "Erro ao carregar usuários");
    } finally {
      openBackdrop(false);
    }
  };

  const handleActions = (user) => {
    return (
      <TableActions
        handleDelete={() => deleteDialog(user.id)}
        deleteSecurePath={[adminPerms.deleteUsers]}
        handleEdit={() => handleOpenForm(user)}
        editSecurePath={[adminPerms.editUsers]}
      />
    );
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este usuário?`,
      callback: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo usuário");
      await userUseCases.deleteUser(id);
      showSuccess("Usuário excluído!");
      setUsers((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir usuário.");
    } finally {
      openBackdrop(false);
    }
  };

  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) return;
    fetchData(currentCompanyId);
    if (!companyIdFromForms) {
      fetchCompanys();
    }
    firstRender.current = false;
  }, []);

  const fetchCompanys = async () => {
    try {
      openBackdrop(true, "Carregando dados do formulário");
      const apiCompanies = await companyUseCases.getCompanies({
        isSummary: false,
        checkPermission: false,
      });
      apiCompanies.forEach((company) => {
        company.value = company.id;
        company.label = company.name;
      });
      setCompanies(apiCompanies);
    } catch (error) {
      showError(error, "Erro ao carregar empresas");
      setCompanies([]);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        bg="white"
        title="Usuários"
        titleAccess="Novo usuário"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addUsers]}
      />
      <Box sx={{ margin: companyIdFromForms ? 0 : { xs: 1, md: 3 } }}>
        <AdminTable
          columns={columns}
          data={users}
          disabledMargin
          tableExport={!companyIdFromForms}
          exportFileName="usuarios"
          costumFilter={
            !companyIdFromForms ? (
              <CompanyFilter
                onFilter={fetchData}
                currentCompanyId={currentCompanyId}
                companyOptions={companies}
              />
            ) : null
          }
        />
      </Box>
    </>
  );
};

export default User;
