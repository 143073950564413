import React, { useMemo, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AutocompleteSelect from "~/presentation/components/AutocompleteSelect";
import { Card as ContentCard } from "~/presentation/views/GTFacil/components/Card";
import { GTLayout } from "../components/GTLayout";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { Box, debounce, Typography, Stack, Alert } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { clientPerms } from "~/presentation/security/SecurePaths";

export const Home = ({ gtFacilUseCases }) => {
  const { showError } = useContext(SnackbarContext);
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [nullValueSearch, setNullValueSearch] = useState(false);
  const cnaeSearchPermission = hasPermission([clientPerms.seeCnaeResearch]);
  const lcSearchPermission = hasPermission([clientPerms.seeLcResearch]);
  const freeSearchPermission = hasPermission([clientPerms.seeFreeSearch]);

  const disabledSearchButton =
    loadingFilter || (!cnaeSearchPermission && !lcSearchPermission && !freeSearchPermission);

  const initialFilters = [
    {
      name: "cnae",
      label: "Pesquisa por CNAE",
      hasPermission: cnaeSearchPermission,
    },
    {
      name: "lc116",
      label: "Pesquisa pela LC 116/2003",
      hasPermission: lcSearchPermission,
    },
    {
      name: "specificSearch",
      label: "Pesquisa Específica",
      hasPermission: freeSearchPermission,
    },
  ];

  const [filters] = useState(initialFilters);
  const [selectedFilter, setSelectedFilter] = useState(filters[0].name);

  useEffect(() => {
    selectFilter(filters[0]?.name);
  }, []);

  const cnaeLoad = async (iscnae = true) => {
    try {
      setLoadingFilter(true);
      const cnaes = await gtFacilUseCases.getCnaeGT(iscnae);
      setOptions(dataToSelectMapper(cnaes));
    } catch (error) {
      showError(error, `Erro ao carregar ${iscnae ? "CNAE" : "LC116"}.`);
    } finally {
      setLoadingFilter(false);
    }
  };

  const loadTerms = async () => {
    try {
      setLoadingFilter(true);
      const terms = await gtFacilUseCases.getSearchTerms();
      setOptions(dataToSelectMapper(terms));
    } catch (error) {
      showError(error, "Erro ao carregar termos de pesquisa.");
    } finally {
      setLoadingFilter(false);
    }
  };

  const selectFilter = async (filter) => {
    if (loadingFilter) return;

    setSelectedFilter(filter);
    switch (filter) {
      case "cnae":
      case "lc116":
        cnaeLoad(filter === "cnae");
        break;
      default:
        loadTerms();
    }
  };

  const handleSearch = async (value) => {
    let shouldOpenAutoComplete = true;
    if (selectedFilter === "specificSearch" && value.length < 3) {
      shouldOpenAutoComplete = false;
    }
    setIsAutoCompleteOpen(shouldOpenAutoComplete);
  };

  const handleSearchClick = (item) => {
    let url = `/gt-facil/pesquisa`;
    if (selectedFilter === "cnae") {
      navigate({ pathname: url, search: `?cnaeId=${item.id}` }, { state: { cnae: item } });
    } else if (selectedFilter === "freeSearch" || selectedFilter === "specificSearch") {
      navigate({ pathname: url, search: `?searchTermId=${item.id}` }, { state: { term: item } });
    } else {
      navigate({ pathname: url, search: `?answerId=${item.id}` }, { state: { answer: item } });
    }
  };

  const debouncedSearchHandler = useMemo(() => debounce(handleSearch, 400), [selectedFilter]);

  const renderOptions = (props, option) => {
    const detailCode = option.detailCode ? ` (${option.detailCode})` : "";
    const { key, ...optionProps } = props;

    return (
      <Box key={`${key}-${option.id}`} {...optionProps}>
        {selectedFilter === "specificSearch" ? (
          <Typography sx={{ fontWeight: 700, fontFamily: "Montserrat" }}>{option.term}</Typography>
        ) : (
          <>
            <Box sx={{ fontWeight: 700, fontFamily: "Montserrat" }}>
              {`${option.code}${detailCode} - ${option.description}`}
              <Typography
                sx={{ fontStyle: "italic", fontSize: 12, color: "grey", fontFamily: "Montserrat" }}
              >
                <strong>
                  {selectedFilter === "cnae" ? "Relacionado ao subitem:" : "Relacionado ao CNAE:"}{" "}
                </strong>
                {option.observation}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    );
  };

  return (
    <GTLayout title="Consulte a tributação de seus contratos com o GT-Fácil">
      <ContentCard title="Como deseja realizar a pesquisa?">
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
          {filters.map((filter, index) => {
            if (!filter.hasPermission) return;
            return (
              <ButtonCommon
                sx={{
                  maxWidth: "fit-content",
                  backgroundColor: filter.name === selectedFilter ? "#1341a1" : "#fff",
                  color: filter.name === selectedFilter ? "#fff" : "#1341a1",
                  border: "1px solid #1341a1",
                  "&:hover": {
                    backgroundColor: filter.name === selectedFilter ? "#1341a1" : "#fff",
                    color: filter.name === selectedFilter ? "#fff" : "#1341a1",
                  },
                }}
                key={`${filter.name}-${index}`}
                onClick={() => {
                  selectFilter(filter.name);
                }}
              >
                {filter.label}
              </ButtonCommon>
            );
          })}
        </Stack>
        {nullValueSearch && (
          <Box sx={{ paddingTop: 2, marginBottom: -2 }}>
            <Alert severity="info">Escolha uma opção válida ou pesquise um termo disponível.</Alert>
          </Box>
        )}
        <Box sx={{ padding: "28px 0" }}>
          <AutocompleteSelect
            freeSolo
            open={isAutoCompleteOpen}
            onOpen={() => setIsAutoCompleteOpen(true)}
            onClose={() => setIsAutoCompleteOpen(false)}
            isDisabled={disabledSearchButton}
            options={options}
            label="Pesquisar"
            value={null}
            inputValue={inputValue}
            onInputChange={(event, newValue) => setInputValue(newValue)}
            onChange={(e, value) => {
              if (!value || !options.find((option) => option.id === value.id)) {
                setNullValueSearch(true);
                setInputValue("");
                return;
              }
              handleSearchClick(value);
            }}
            noOptionsText="Nenhuma opção encontrada."
            inputProps={{
              onChange: (event) => {
                event.persist();
                debouncedSearchHandler(event.target.value);
              },
            }}
            getOptionLabel={(option) =>
              selectedFilter === "specificSearch"
                ? option.term
                : `${option.code} - ${option.description} - ${option.synonym} - ${option.codeNumbers}`
            }
            renderOption={(props, option) => {
              return renderOptions(props, option);
            }}
          />
        </Box>
        <Typography sx={{ fontSize: 14 }}>
          * Selecione ou clique em um botão para filtrar a pesquisa.
        </Typography>
      </ContentCard>
    </GTLayout>
  );
};
