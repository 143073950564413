import { styled } from "@mui/material/styles";
import { transparentize } from "polished";
import { Pagination as MuiPagination } from "@mui/material";

export const Container = styled("div")({
  height: "calc(100% - 40px - 12px)", // 40: h2 height // 12px: margin-top
  display: "grid",
  gap: "20px",

  "@media (min-width: 768px)": {
    gridTemplateColumns: "minmax(min-content, 25%) auto",
  },
});

export const Wrapper = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "10px",
  overflowY: "auto",
  padding: "1vw",
  maxHeight: "100%",
  overflow: "hidden",
  display: "grid",
  gridTemplateRows: "8% 84% 8%",

  // Preserve the padding bottom
  "&::after": {
    content: '""',
    display: "block",
    height: "1px",
    width: "100%",
  },

  ".divider": {
    border: "none",
    height: "1px",
    width: "100%",
    backgroundColor: "#383f48",
    margin: "12px 0 4px",
  },

  ".MuiSvgIcon-root": {
    color: "#383f48",
  },

  "&.filters": {
    "@media (max-width: 768px)": {
      gridTemplateRows: "auto auto",
    },
  },

  "&.messages": {
    "@media (min-width: 600px)": {
      minWidth: "450px",
    },
  },
});

export const ListHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "@media (min-width: 768px)": {
    "> div:first-child": {
      paddingLeft: "15px",
    },
  },
});

export const Pagination = styled(MuiPagination)(() => ({
  display: "flex",
  alignItems: "center",

  ".MuiPaginationItem-root": {
    color: "inherit",

    "&.Mui-selected": {
      backgroundColor: transparentize(0.75, "#1341a1"),
    },
  },
}));
