import { styled } from "@mui/material/styles";
import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
} from "@mui/material";

export const List = styled(MuiList)({
  position: "relative",
  overflowY: "auto",
});

export const ListItem = styled(MuiListItem)({
  display: "grid",
  gridTemplateColumns: "auto auto",
  gap: "max(1vw, 10px)",
});

export const ListItemIcon = styled(MuiListItemIcon)({
  minWidth: "unset",
});
