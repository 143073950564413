import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import { formatDateToTable } from "~/presentation/functions/date";
import CreateEdit from "./CreateEdit/CreateEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";
import AdminTable from "../components/AdminTable/AdminTable";

const Blog = ({ blogUseCases, blogCategoryUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //manter a página da tabela sempre atual
  const [page, setPage] = useState(0);

  //data
  const [posts, setPosts] = useState([]);
  const [blogTags, setBlogTags] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando conteúdo");
      const response = await blogUseCases.getBlogAllPosts();
      await getCategories();
      setPosts(response);
    } catch (error) {
      showError(error, "Erro ao carregar conteúdo");
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getTags = async () => {
    try {
      openBackdrop(true, "Carregando tags");
      let tags = await blogUseCases.getBlogTags();
      tags = dataToSelectMapper(tags);
      setBlogTags(tags);
    } catch (error) {
      showError(error, "Erro ao carregar tags");
    } finally {
      openBackdrop(false);
    }
  };

  const getCategories = async () => {
    try {
      openBackdrop(true, "Carregando categorias");
      let categories = await blogCategoryUseCases.getBlogCategories({ checkPermission: false });
      categories = dataToSelectMapper(categories, "id", "title");
      setBlogCategories(categories);
    } catch (error) {
      showError(error, "Erro ao carregar categorias");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    {
      field: "categoryId",
      headerName: "Categoria",
      filter: false,
      sort: false,
      flex: 1,
      align: "center",
      renderCell: ({ value }) => blogCategories.find((category) => category.id === value)?.title,
      hide: "md",
    },
    { field: "title", headerName: "Título", flex: 4 },
    {
      field: "publishDate",
      headerName: "Data",
      filter: false,
      renderCell: ({ value }) => formatDateToTable(value),
      flex: 1,
      align: "center",
      hide: "md",
    },
    {
      field: "isDraft",
      headerName: "Rascunho",
      sort: false,
      renderCell: ({ value }) => (value ? "Sim" : "Não"),
      flex: 1,
      align: "center",
      hide: "md",
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenForm(row)}
          editSecurePath={[adminPerms.editBlog]}
          handleDelete={() => deleteDialog(row.id)}
          deleteSecurePath={[adminPerms.deleteBlog]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo post do blog");
      await blogUseCases.deleteBlogPost(id);
      setPosts((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir post");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este post?`,
      callback: () => handleDelete(id),
    });
  };

  return (
    <Box>
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          blogUseCases={blogUseCases}
          blogTags={blogTags}
          blogCategories={blogCategories}
          getTags={getTags}
          getCategories={getCategories}
        />
      ) : (
        <>
          <PageListHeader
            title="Blog"
            titleAdd="Criar novo post"
            handleAdd={() => handleOpenForm()}
            addSecurePath={[adminPerms.addBlog]}
          />
          <AdminTable
            columns={columns}
            data={posts}
            categories={blogCategories}
            tablePage={page}
            setTablePage={setPage}
            tableExport
            exportFileName="blogs"
          />
        </>
      )}
    </Box>
  );
};

export default Blog;
