import React, { createContext, useContext, useEffect, useState } from "react";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import { useDialog } from "~/presentation/providers/DialogProvider";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";
import {
  makeBusinessPartnerUseCases,
  makeDigitalCertUseCases,
  makeIncomeNatureUseCases,
  makeNfseUseCases,
  makePaymentRecordUseCases,
  makeReinfUseCases,
  makeServiceClassUseCases,
} from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import EventsTable from "~/presentation/views/Reinf/SendEDF/components/EventsTable/EventsTable";
import TableTabs from "~/presentation/views/Reinf/SendEDF/components/Tabs/TableTabs";
import useHandleDocFiscais from "~/presentation/views/Reinf/Hooks/useHandleDocFiscais";
import {
  getEventType,
  validateDocumentsBasedOnEFDReinfEvent,
} from "~/presentation/views/Reinf/Utils/utilsReinf";
import { getLastDayOfMonth, getMonth } from "~/presentation/views/Reinf/Utils/utilsDate";
import { currentYear, lastMonth } from "~/presentation/utils/constants";
import DocsTable from "~/presentation/views/Reinf/SendEDF/components/DocsTable/DocsTable";
import SendEvents from "~/presentation/views/Reinf/SendEDF/components/SendEvents/SendEvents";
import { AttentionBanner } from "~/presentation/views/Reinf/components/AttentionBanner/AttentionBanner";
import PageFilters from "~/presentation/views/Reinf/SendEDF/components/PageFilters/PageFilters";
import { Info } from "@mui/icons-material";
import PageR2099 from "./components/PageR2099/PageR2099";

const SendEDFContext = createContext();
export const useEDF = () => useContext(SendEDFContext);

function SendEDF() {
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")) || "";
  const { companyId } = JSON.parse(localStorage.getItem("GT_user")) || "";

  const apiNfse = makeNfseUseCases();
  const apiReinf = makeReinfUseCases();
  const apiDigitalCert = makeDigitalCertUseCases();
  const apiServiceclass = makeServiceClassUseCases();
  const apiIncomeNature = makeIncomeNatureUseCases();
  const apiBusinessPartner = makeBusinessPartnerUseCases();
  const apiPayment = makePaymentRecordUseCases();

  const { openDialogBox, closeDialog } = useDialog();
  const { openBackdrop } = useBackdrop();
  const { showError } = useSnackbar();
  const { getNewItemsNFse } = useHandleDocFiscais();

  //TODAS AS NOTAS E EVENTOS
  const [currentEvents, setCurrentEvents] = useState([]);

  //NOTAS TABELA PRONTAS A ENVIAR
  const [allValidNfse, setAllValidNfse] = useState([]);
  const [currentValidNfse, setCurrentValidNfse] = useState([]);
  //NOTAS TABELA CORREÇÃO
  const [allInvalidNfse, setAllInvalidNfse] = useState([]);
  const [currentInvalidNfse, setCurrentInvalidNfse] = useState([]);

  //SELECT INPUT OPTIONS
  const [serviceClassificationOptions, setServiceClassificationOptions] = useState([]); //select Classificação Serviço
  const [incomeNatureOptions, setIncomeNatureOptions] = useState([]); //select Natureza Rendimento
  const [businessPartnerOptions, setBusinessPartnerOptions] = useState([]); //lista de contratados

  const setValidStates = (docs) => {
    setAllValidNfse(docs);
    setCurrentValidNfse(docs);
  };

  const setInvalidStates = (docs) => {
    setAllInvalidNfse(docs);
    setCurrentInvalidNfse(docs);
  };

  // Status do Evento
  const [isEventClosed, setIsEventClosed] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  //Certificados
  const [allCertificates, setAllCertificates] = useState([]);

  const initalFilters = {
    certificate: "",
    month: "",
    year: "",
    event: "",
  };

  const [filters, setFilters] = useState(initalFilters);
  const [activeFilters, setActiveFilters] = useState(initalFilters);
  const [tableType, setTableType] = useState("sendDoc");
  const [tablePage, setTablePage] = useState(0);

  const filtersAreEqual = JSON.stringify(filters) === JSON.stringify(activeFilters);
  const displayTable =
    filtersAreEqual && ["r9000", "r2010", "r4010", "r4020"].includes(activeFilters.event);
  const displayTableTabs = ["r2010", "r4010", "r4020"].includes(activeFilters.event);

  const hasUnsavedDocs = currentValidNfse.some((i) => i.hadChanges)
    ? "aptos"
    : currentInvalidNfse.some((i) => i.hadChanges)
      ? "inaptos"
      : false;

  const handleChangeFilter = (newFilters) => {
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  const handleClearAllFilters = () => {
    if (hasUnsavedDocs) {
      openDialogBox({
        maxWidth: "sm",
        message:
          "Tem documentos alterados que não foram salvos. Ao processeguir, esses dados não serão salvos. Deseja continuar?",
        callback: () => {
          setActiveFilters(initalFilters);
        },
      });
    } else {
      setActiveFilters(initalFilters);
    }
  };

  const handleVerifyFilters = () => {
    if (hasUnsavedDocs) {
      openDialogBox({
        maxWidth: "sm",
        message:
          "Tem documentos alterados que não foram salvos. Ao processeguir, esses dados não serão salvos. Deseja continuar?",
        callback: () => {
          handlePageFilters();
        },
      });
    } else {
      handlePageFilters();
    }
  };

  const getFirstAndLastDayOfMonth = ({ month, year }) => ({
    start: `01 ${getMonth(month)} ${year}`,
    end: `${getLastDayOfMonth(year, month)} ${getMonth(month)} ${year}`,
  });

  const cleanAllStats = () => {
    setSelectedRows([]);
    setValidStates([]);
    setInvalidStates([]);
    setCurrentEvents([]);
  };

  const handlePageFilters = () => {
    setActiveFilters(filters);
    cleanAllStats();

    handleVerifyEventStatus(`${filters?.year}-${filters?.month}-01`, filters?.event);

    if (["r2098", "r4099"].includes(filters.event)) return openSendModal(filters);

    const period = getFirstAndLastDayOfMonth(filters);

    if (filters.event === "r2099") return handleApplyFiltersR099(period);

    // ⇣⇣⇣ params para o fetch dos documentos
    const paramsDocs = {
      ...period,
      isDateOfPayment: filters?.event === "r4020",
    };

    // ⇣⇣⇣ params para o fetch dos eventos

    const paramsEvent = {
      ...period,
      ...(getEventType(filters?.event) ? { reinfEvent: getEventType(filters?.event) } : {}),
    };

    fetchData(paramsDocs, paramsEvent, filters);
  };

  const handleFilterDocs = (data, filter) => {
    let filteredData = data;

    const isR2010 = filter?.event === "r2010";
    const isR4020 = filter?.event === "r4020";

    if (isR2010 || isR4020) {
      filteredData = filteredData.filter((item) => !item[isR2010 ? "isR2010" : "isR4020"]);
    }

    if (isR2010) {
      filteredData = filteredData.filter((item) => item["typeDoc"] !== 1);
    }

    // ⇣⇣⇣ validar documentos aptas / inaptas
    const event = isR4020 ? `r4020${QSO}` : filter.event;
    filteredData = validateDocumentsBasedOnEFDReinfEvent(event, filteredData);

    //Tabela Enviar Aptas
    const validDocumentsList = filteredData.filter((item) => item.isReady);
    setValidStates(validDocumentsList);

    //Tabela Correção Inaptas
    const invalidDocumentsList = filteredData.filter((item) => !item.isReady);
    setInvalidStates(invalidDocumentsList);
  };

  const fetchDataOptions = async () => {
    if (serviceClassificationOptions.length === 0) {
      try {
        const serviceClassResponse = await apiServiceclass.listServiceClassification();
        setServiceClassificationOptions(serviceClassResponse);
      } catch (error) {
        showError(error, "Erro ao carregar opções de classificação de serviço");
      }
    }

    if (incomeNatureOptions.length === 0) {
      try {
        const incomeNatureResponse = await apiIncomeNature.listIncomeNature();
        setIncomeNatureOptions(incomeNatureResponse);
      } catch (error) {
        showError(error, "Erro ao carregar opções de Natureza de Rend.");
      }
    }

    if (businessPartnerOptions.length === 0) {
      try {
        const businessPartnerResponse = await apiBusinessPartner.listBusinessPartner();
        setBusinessPartnerOptions(businessPartnerResponse);
      } catch (error) {
        showError(error, "Erro ao carregar opções de contratado");
      }
    }
  };

  const handleFilterEvents = (data, filter) => {
    let filteredData = data;

    const possibleEventsToExclude = [
      "R-2010",
      "R-2020",
      "R-2070",
      "R-4010",
      "R-4020",
      "R-4040",
      "R-4080",
    ];

    if (filter?.event === "r9000") {
      filteredData = filteredData.filter(
        (item) => possibleEventsToExclude.includes(item.reinfEvent) && item.receiptNumber
      );
    }

    setCurrentEvents(filteredData);
  };

  const fetchData = async (paramsNfse, paramsEvent, filters) => {
    setTableType(filters.event === "r9000" ? "events" : "sendDoc");

    const responseEvents = await loadEvents(paramsEvent);
    if (responseEvents) handleFilterEvents(responseEvents, filters);

    if (["r2010", "r4020"].includes(filters.event)) {
      const responseNfse = await loadNfse(paramsNfse);
      if (responseNfse) {
        const newNfseArray = responseNfse.map((nfse) => ({
          ...nfse,
          hasEventSentWithSameCnpj: responseEvents.some(
            ({ tag, contractorCNPJ }) =>
              nfse.businessPartnerCNPJ === tag && nfse.contractorCNPJ === contractorCNPJ
          ),
        }));
        handleFilterDocs(newNfseArray, filters);
      }
    }

    if (filters.event === "r4010") {
      const payments = await loadPayments(paramsNfse);
      if (payments) handleFilterDocs(payments, filters);
    }
  };

  const loadNfse = async (params) => {
    try {
      openBackdrop(true, "Carregando documentos");
      const { data, status } = await apiNfse.getNfse(params);
      if (status === 200) {
        return data.map((nfse) => ({
          ...nfse,
          itemsNFse: getNewItemsNFse(nfse.itemsNFse),
        }));
      }
    } catch (error) {
      showError(error, "Erro no retorno dos documentos");
    } finally {
      openBackdrop(false);
    }
  };

  const handleApplyFiltersR099 = async (period) => {
    openBackdrop(true, "Carregando informações");
    const events2010 = await loadEvents({
      ...period,
      reinfEvent: 3,
    });

    const events4020 = await loadEvents({
      ...period,
      reinfEvent: 10,
    });

    setCurrentEvents([...events2010, ...events4020]);
    openBackdrop(false);
  };

  const loadEvents = async (params) => {
    try {
      openBackdrop(true, "Carregando eventos");
      const { data, status } = await apiReinf.getReinfProtocol(params);
      if (status === 200) return data.map((i, index) => ({ id: index, ...i }));
    } catch (error) {
      showError(error, "Erro no retorno dos eventos");
    } finally {
      openBackdrop(false);
    }
  };

  const loadPayments = async (params) => {
    try {
      openBackdrop(true, "Carregando dados dos pagamentos");
      const { data, status } = await apiPayment.getPaymentRecord(params);
      if (status === 200) return data;
    } catch (error) {
      showError(error, "Erro ao buscar dados dos pagamentos");
    } finally {
      openBackdrop(false);
    }
  };

  const handleVerifyEventStatus = async (date, event) => {
    const verifyStatusData = {
      companyId: companyId,
      calculationDate: date,
    };

    setIsEventClosed(await apiReinf.verifyClosedPeriod(verifyStatusData, event));
  };

  const refreshData = () => {
    cleanAllStats();

    //refresh com os filtros atuais
    const period = getFirstAndLastDayOfMonth(activeFilters);

    const paramsDocs = {
      ...period,
      isDateOfPayment: activeFilters?.event === "r4020",
    };

    const paramsEvent = {
      ...period,
      ...(getEventType(activeFilters?.event)
        ? { reinfEvent: getEventType(activeFilters?.event) }
        : {}),
    };

    fetchData(paramsDocs, paramsEvent, activeFilters);
  };

  const openSendModal = (filters = {}) => {
    openDialogBox({
      fullWidth: true,
      maxWidth: "md",
      customComponent: () => (
        <SendEvents
          data={selectedRows}
          filter={filters}
          certificates={allCertificates}
          closeModal={closeDialog}
          refreshData={refreshData}
          handleVerifyEventStatus={handleVerifyEventStatus}
        />
      ),
    });
  };

  useEffect(() => {
    const firstRender = async () => {
      openBackdrop(true);
      const certs = await getCertificates();
      setAllCertificates(certs);
      const firstCert = certs[0]?.id || "";
      handleChangeFilter({
        certificate: firstCert,
        year: currentYear,
        month: lastMonth,
      });
      openBackdrop(false);
    };
    firstRender();
  }, []);

  const getCertificates = async () => {
    const result = await apiDigitalCert.getDigitalCerts();
    return result;
  };

  const handleRenderTable = () => {
    switch (tableType) {
      case "correctDoc":
      case "sendDoc":
        return (
          <DocsTable
            setInvalidStates={setInvalidStates}
            setValidStates={setValidStates}
            allInvalidNfse={allInvalidNfse}
            currentInvalidNfse={currentInvalidNfse}
            setCurrentInvalidNfse={setCurrentInvalidNfse}
            allValidNfse={allValidNfse}
            currentValidNfse={currentValidNfse}
            setCurrentValidNfse={setCurrentValidNfse}
            setTableType={setTableType}
            tableType={tableType}
            tablePage={tablePage}
            setTablePage={setTablePage}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        );
      case "events":
        return (
          <EventsTable
            currentEvents={currentEvents}
            allValidNfse={allValidNfse}
            setCurrentEvents={setCurrentEvents}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
          />
        );
    }
  };

  return (
    <SendEDFContext.Provider
      value={{
        activeFilters,
        certificates: allCertificates,
        isEventClosed,
        refreshData,
        openSendModal,
        hasUnsavedDocs,
        fetchDataOptions,
        serviceClassificationOptions,
        incomeNatureOptions,
        businessPartnerOptions,
      }}
    >
      <HeaderSectionPage title="Envio da EFD-Reinf" />
      <PageFilters
        filters={filters}
        activeFilters={activeFilters}
        onChangeFilter={handleChangeFilter}
        onPageFilters={handleVerifyFilters}
        onClearAllFilters={handleClearAllFilters}
        allCertificates={allCertificates}
      />
      {activeFilters.event ? (
        activeFilters.event === "r2099" ? (
          <PageR2099
            filters={activeFilters}
            certificateData={allCertificates}
            isEventClosed={isEventClosed}
            handleVerifyEventStatus={handleVerifyEventStatus}
            currentEventsData={currentEvents}
          />
        ) : displayTable ? (
          <>
            {displayTableTabs && (
              <TableTabs
                tableType={tableType}
                setTableType={setTableType}
                allInvalidNfse={allInvalidNfse}
                allEvents={currentEvents}
                allValidNfse={allValidNfse}
                setSelectedRows={setSelectedRows}
                isR4010={activeFilters.event === "r4010"}
              />
            )}
            {handleRenderTable()}
          </>
        ) : (
          <AttentionBanner icon={Info} />
        )
      ) : (
        <AttentionBanner icon={Info} />
      )}
    </SendEDFContext.Provider>
  );
}

export default SendEDF;
