function useHandleErrors() {
  //Função para tirar o erro do campo quando ele começa a digitar
  const handleClearError = (field, setErrors) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  return { handleClearError };
}

export default useHandleErrors;
