import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Chip } from "@mui/material";
import { Close } from "@mui/icons-material";

function Categories({ blogCategories, categoryId, handleSelectCategory, clearCategory }) {
  const categoriesContainerRef = useRef(null);
  const [isCategoriesContainerAtStart, setCategoriesContainerIsAtStart] = useState(true);
  const [isCategoriesContainerAtEnd, setIsCategoriesContainerAtEnd] = useState(false);

  const handleCategoriesContainerScroll = (direction) => {
    const scrollAmount = 200;

    if (direction === "right") {
      categoriesContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    } else {
      categoriesContainerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
    }

    const currentScrollLeft =
      categoriesContainerRef.current.scrollLeft +
      (direction === "right" ? scrollAmount : -scrollAmount);
    const maxScrollLeft =
      categoriesContainerRef.current?.scrollWidth - categoriesContainerRef.current?.clientWidth;

    setCategoriesContainerIsAtStart(currentScrollLeft <= 0);
    setIsCategoriesContainerAtEnd(currentScrollLeft >= maxScrollLeft);
  };

  useEffect(() => {
    if (isCategoriesContainerAtEnd && categoryId && categoriesContainerRef.current) {
      categoriesContainerRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  }, [categoryId, isCategoriesContainerAtEnd]);

  return (
    <Box sx={styles.container}>
      <Box
        sx={styles.categories}
        onClick={() => handleCategoriesContainerScroll("left")}
        disabled={isCategoriesContainerAtStart}
      >
        <Box component="img" src="/assets/icons/arrow-left-rounded.svg" alt="arrow-left" />
      </Box>
      <Box ref={categoriesContainerRef} sx={styles.leftArrow(categoryId)}>
        {/* Mapeando as categorias do blog */}
        {blogCategories?.map((category) => (
          <Box key={category.id} sx={styles.categories.category}>
            <Chip
              sx={styles.chip}
              onClick={() => handleSelectCategory(category.id)}
              value={category.id}
              label={category.title}
              clickable
            />
          </Box>
        ))}
      </Box>
      <Box
        sx={styles.rightArrow}
        onClick={() => handleCategoriesContainerScroll("right")}
        disabled={isCategoriesContainerAtEnd}
      >
        <Box component="img" src="/assets/icons/arrow-left-rounded.svg" alt="arrow-left" />
      </Box>

      {categoryId && (
        <Box sx={styles.categoryId}>
          <Button sx={styles.categoryId.button} onClick={clearCategory}>
            <Close />
            Limpar Filtro
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default Categories;

const styles = {
  container: {
    width: "100%",
    background: "transparent",
    display: "flex",
    justifyContent: "center",
    gap: 2,
    margin: "0 auto",
    maxWidth: { xs: 300, sm: 550, md: 860, lg: 1200 },
  },
  categories: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    rotate: "180deg",
    cursor: "pointer",
    category: {
      minWidth: "180px",
      whiteSpace: "nowrap",
      display: "flex",
      justifyContent: "center",
    },
  },
  chip: {
    fontFamily: "Montserrat",
    color: "#000", // cor do texto
    fontWeight: 500,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: 17,
    backgroundColor: "transparent", // cor de fundo do Chip (opcional)
    "& .MuiChip-label": {
      fontSize: 17,
    },
    "&:hover": {
      transform: "scale(1.03)",
      backgroundColor: "#e6e6e6",
    },
    "&:active": {
      transform: "scale(1.03)",
      backgroundColor: "#e6e6e6",
    },
  },
  leftArrow: (categoryId) => ({
    maxWidth: categoryId ? "80%" : "100%",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    overflowX: "auto",
    alignItems: "center",
    transition: "scrollLeft 0.5s ease-in-out",
    paddingBlock: { xs: 2, md: 2 },
    paddingInline: { xs: 0, md: 2 },
    "&::-webkit-scrollbar": {
      width: "12px",
      height: "2px",
      background: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "transparent",
    },
  }),
  rightArrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundImage:
      "linear-gradient(to right, rgb(255 255 255 / 0), white var(--linaria-theme_spacing-macro40px))",
  },
  categoryId: {
    minWidth: "200px",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "center",
    scrollBehavior: "smooth",
    overflowX: "hidden",
    button: {
      fontFamily: "Montserrat",
      color: "#6D6D6D",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
};
