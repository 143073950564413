import React, { useState } from "react";
import { Close, ExpandMore } from "@mui/icons-material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Popover } from "@mui/material";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";
import { inputStyle } from "~/presentation/views/Reinf/DocPanel/Table/Filters/Filters";
import { commonColors } from "~/presentation/themes/styles.App";

function SetPeriodButton({
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  placeholder,
  isDateWrong,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <p
        style={{
          fontSize: 12,
          fontStyle: "italic",
          color: isDateWrong ? commonColors.error : "#222222",
        }}
      >
        Período
      </p>
      <button
        style={{
          ...inputStyle,
          border: isDateWrong ? `2px solid ${commonColors.error}` : "none",
        }}
        aria-describedby={id}
        onClick={handleClick}
      >
        {isDateWrong ? (
          <p style={{ color: "#000", fontStyle: "italic" }}>{placeholder}</p>
        ) : (
          `${formatDateToBrazilian(initialDate)} - ${formatDateToBrazilian(finalDate)}`
        )}
        <ExpandMore sx={styles.expandButton(open)} />
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={styles.Box}>
          <Box
            sx={[styles.Option, styles.deleteFilterOption]}
            onClick={() => {
              setInitialDate(null);
              setFinalDate(null);
            }}
          >
            <em style={styles.Filter}>
              <Close fontSize="small" /> Apagar filtro
            </em>
          </Box>
          <Box sx={styles.Option}>
            Inicial:
            <Input
              styles={{ height: "50px" }}
              type="date"
              value={initialDate || null}
              maxDate={finalDate ? finalDate : new Date()}
              onChange={(date) => {
                setInitialDate(date);
              }}
            />
          </Box>
          <Box sx={styles.Option}>
            Final:
            <Input
              styles={{ height: "50px" }}
              type="date"
              value={finalDate || null}
              minDate={initialDate ? initialDate : false}
              onChange={(date) => {
                setFinalDate(date);
              }}
            />
          </Box>
          <Box>
            {isDateWrong && (
              <span style={styles.error}>
                <ErrorIcon fontSize="small" sx={{ padding: 0, margin: 0 }} />
                <span>{isDateWrong}</span>
              </span>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

export default SetPeriodButton;

const styles = {
  Box: {
    backgroundColor: "#FBFBFB",
    borderRadius: 1,
    minHeight: 130,
  },
  Option: {
    display: "flex",
    fontSize: 14,
    alignItems: "center",
    justifyContent: "space-between",
    gap: "3px",
    cursor: "pointer",
    pl: 1.6,
    py: 0.4,
  },
  deleteFilterOption: {
    backgroundColor: "rgba(25, 118, 210, 0.08)",
    mt: 1,
    height: 32,
    "&:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.1)",
    },
  },
  error: {
    userSelect: "none",
    color: commonColors.error,
    display: "flex",
    alignItems: "center",
    gap: 6,
    justifyContent: "center",
    paddingBlock: 4,
  },
  Filter: {
    fontSize: 14,
    opacity: 0.8,
    fontWeight: "400",
    color: commonColors.error,
    display: "flex",
    alignItems: "center",
  },
  expandButton: (open) => ({
    marginLeft: "auto",
    color: "#BBBBBB",
    transform: open ? "rotate(180deg)" : "unset",
    backgroundColor: "#DCDCDC",
  }),
};
