import React from "react";
import { Typography as Text, Button, Box } from "@mui/material";
import Fade from "~/presentation/components/Animation/Fade/Fade";

const Home = ({ id }) => (
  <Box sx={styles.container} id={id}>
    <Box sx={styles.content}>
      <Fade style={styles.titleWrapper}>
        <Text sx={styles.title}>
          O melhor sistema <i style={{ fontFamily: "Montserrat", fontWeight: 500 }}>web</i> para
          quem trabalha com Retenções Tributárias.
        </Text>
        <Text sx={styles.subtitle}>
          Simulador de retenções online, sistema de{" "}
          <span style={{ fontWeight: 700 }}>envio rápido da EFD-Reinf</span>, artigos e legislação
          focados nas incidências de INSS, Imposto de Renda, Contribuições Sociais e ISS na fonte.
        </Text>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send?phone=5571992084907&text=Ol%C3%A1!%20Quero%20experimentar%20o%20GT-F%C3%A1cil%20junto%20a%20um%20representante%20da%20Open%20Solu%C3%A7%C3%B5es%20Tribut%C3%A1rias"
        >
          <Button
            variant="contained"
            sx={{
              marginTop: "23px",
              marginBottom: "48px",
              width: "min(300px, 100%)",
              borderRadius: "26px",
              backgroundColor: "#ef5f00",
              color: "#fff",
              fontSize: "25px",
              paddingInline: "12px 12px 12px",
              fontWeight: 500,
              transition: "background-color 0.3s",
              fontFamily: "Bebas Neue",
              boxShadow: "0 0 15px #fff",
              "&:hover": {
                backgroundColor: "#ff6702",
                boxShadow: "0 0 15px #ff6702",
              },
            }}
          >
            QUERO EXPERIMENTAR AGORA
          </Button>
        </a>
        <Box sx={styles.iconArrow}>
          <Box
            component="img"
            className="pulse"
            src="/assets/icons/arrow.png"
            alt="Ícone de seta"
          />
        </Box>
      </Fade>
    </Box>
  </Box>
);
export default Home;

const styles = {
  container: {
    position: "relative",
    backgroundImage: ' url("/assets/maze5.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    textAlign: "center",
    width: "fit-content",
    maxWidth: "80%",
    color: "#fff",
    paddingTop: "53px",
  },
  titleWrapper: {
    maxWidth: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    gap: 1,
  },
  title: {
    marginTop: "30px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textShadow: "0px 5px 5px rgba(51, 51, 51, 0.25)",
    lineHeight: 1.2,
    fontSize: "clamp(2rem,4vw,4.25rem)",
  },
  iconArrow: {
    width: "2.5rem",
    height: "1.55rem",
    opacity: 0.7,
    "@media (max-width: 1260px) ": {
      paddingBottom: "5rem",
    },
  },
  subtitle: {
    fontFamily: "Montserrat",
    textAlign: "center",
    lineHeight: 1.5,
    fontSize: 14,
    "@media (min-width: 480px)": { fontSize: 18 },
    "@media (min-width: 768px)": { fontSize: 24 },
  },
  //modal styles
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
    maxHeight: "80%",
    borderRadius: "18px",
    overflow: "auto",
    boxShadow: 24,
    bgcolor: "#E8E8E8",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "18px",
  },
};
