import React from "react";
//components
import TextEditor from "~/presentation/components/TextEditor";
// schema
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";

const ValueVariableForm = ({ values, onChangeValue, handleSubmit, errors }) => {
  const popup = values?.isPopup;
  const html = values?.isPopup && values?.isHtml;

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Informações</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 4 }}>
            <Input
              type="text"
              name="code"
              title="Código"
              value={values?.code}
              onChange={(e) => onChangeValue("code", e.target.value)}
              error={errors.code}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Input
              type="text"
              name="name"
              title="Nome da variável"
              value={values?.name}
              onChange={(e) => onChangeValue("name", e.target.value)}
              error={errors.name}
            />
          </Grid>
          <Grid container spacing={1} sx={formStyles.checkBoxContainer}>
            <Grid size="auto">
              <FormControlLabel
                sx={formStyles.controlLabel}
                control={
                  <Checkbox
                    sx={formStyles.icon}
                    checked={values.isPopup}
                    onChange={() => onChangeValue("isPopup", !values.isPopup)}
                    name="isPopup"
                  />
                }
                label="Modelo popup"
              />
            </Grid>
            <Grid size="auto">
              {popup && (
                <FormControlLabel
                  sx={formStyles.controlLabel}
                  control={
                    <Checkbox
                      sx={formStyles.icon}
                      checked={values.isHtml}
                      onChange={() => onChangeValue("isHtml", !values.isHtml)}
                      name="isHtml"
                    />
                  }
                  label="Conteúdo em texto"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {html ? (
            <Grid size={12}>
              <Typography sx={{ ...formStyles.subtitle, color: errors.value && "#d32f2f" }}>
                Texto
              </Typography>
              <TextEditor
                initialValue={values.value}
                name="value"
                height={200}
                handleEditorChange={(content) => {
                  onChangeValue("value", content);
                }}
                error={errors.value}
              />
              {errors.value && <Typography sx={formStyles.errorMsg}>{errors.value}</Typography>}
            </Grid>
          ) : (
            <Grid size={12}>
              <Input
                type="text"
                name="value"
                title="Valor da variável"
                value={values?.value}
                onChange={(e) => onChangeValue("value", e.target.value)}
                error={errors.value}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default ValueVariableForm;
