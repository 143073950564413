import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { Search } from "@mui/icons-material";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import {
  handleR2010Filters,
  handleR4020Filters,
} from "~/presentation/views/Reinf/Utils/utilsFilter";

const TableFilters = ({ setData, allData, R2010, setTablePage, isCorrectionTable }) => {
  const { showInfo } = useSnackbar();
  const [filters, setFilters] = useState({
    typeDoc: "",
    retention: "",
    filterType: "",
    searchQuery: "",
  });

  const handleChange = (field, newItem) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [field]: newItem };
      if (["typeDoc", "retention"].includes(field)) handleSearchData(newFilters);
      return newFilters;
    });
  };

  const cleanField = (arr) => {
    if (typeof arr !== "string") return arr;
    return arr
      .toLowerCase()
      .replace(/[\s-/.()]/g, "")
      .trim();
  };

  const handleSearchData = ({ typeDoc, retention, filterType, searchQuery }) => {
    let filteredData = [...allData];

    if (typeDoc) {
      filteredData = filteredData.filter((i) => i.typeDoc === typeDoc);
    }

    if (retention) {
      if (R2010) {
        filteredData = handleR2010Filters(retention, filteredData);
      } else {
        filteredData = handleR4020Filters(retention, filteredData);
      }
    }

    if (searchQuery) {
      const arrayOfQuerysSearch = searchQuery.split(";").map((i) => cleanField(i));

      let newArray = [];
      if (filterType === "serviceClassification") {
        newArray = filteredData.filter((item) =>
          item.itemsNFse?.some((nfse) =>
            arrayOfQuerysSearch.includes(cleanField(nfse.serviceClassification))
          )
        );
      } else {
        newArray = filteredData.filter((item) => {
          if (filterType === "businessPartnerCNPJ") {
            return (
              arrayOfQuerysSearch.includes(cleanField(item[filterType])) ||
              arrayOfQuerysSearch.includes(cleanField(item["businessPartnerName"]))
            );
          }
          return arrayOfQuerysSearch.includes(cleanField(item[filterType]));
        });
      }
      if (newArray.length === 0) {
        showInfo("Não foram encontrados nenhuns documentos");
      } else {
        filteredData = newArray;
      }
    }

    setTablePage(0);
    setData(filteredData);
  };

  const docTypeOptions = [
    { value: 2, label: "SIAFI" },
    { value: 3, label: "Planilha" },
    { value: 4, label: "Recibo" },
    { value: 99, label: "Outros" },
  ];

  const retentionOptions = [
    { value: "contains", label: "Com retenções" },
    { value: "notContains", label: "Sem retenções" },
  ];

  const filterTypeOptions = [
    { value: "nFseNumber", label: "Número do documento" },
    { value: "businessPartnerCNPJ", label: "Contratado" },
    { value: "contractorCNPJ", label: "Contratante" },
    ...(R2010
      ? [{ value: "serviceClassification", label: "Classificação do serviço" }]
      : [{ value: "incomeNatureDescription", label: "Natureza do rendimento" }]),
  ];

  const filtersData = [
    {
      title: "Tipo de Documento",
      value: "typeDoc",
      options: docTypeOptions,
    },
    ...(isCorrectionTable
      ? [
          {
            title: "Retenção",
            value: "retention",
            options: retentionOptions,
          },
        ]
      : []),
    {
      title: "Escolha o filtro",
      value: "filterType",
      options: filterTypeOptions,
    },
  ];

  return (
    <Grid container spacing={1} sx={{ pb: 2 }}>
      {filtersData.map(({ title, value, options }, index) => (
        <Grid key={index} size={{ xs: 12, sm: 6, lg: 3, xl: 2.5 }}>
          <Input
            type="select"
            deleteFilter={() => handleChange(value, "")}
            title={title}
            placeholder={title}
            value={filters[value] ? options.find((i) => i.value === filters[value]) : null}
            onChange={(option) => handleChange(value, option.value)}
            options={options}
          />
        </Grid>
      ))}
      <Grid size={{ xs: 12, sm: 6, lg: 3, xl: 4.5 }}>
        <Input
          title={`Pesquise ${filters.filterType ? "por " + filterTypeOptions.find((i) => i.value === filters.filterType)?.label : "separando por ;"}`}
          type="search"
          value={filters.searchQuery}
          onChange={(e) => handleChange("searchQuery", e.target.value)}
          deleteFilter={() => {
            handleChange("searchQuery", "");
            handleSearchData({ ...filters, searchQuery: "" });
          }}
          onKeyDown={(e) => e.key === "Enter" && handleSearchData(filters)}
          placeholder="Separe por ;"
          disabled={!filters.filterType}
          endAdornment={
            <Search
              onClick={() => handleSearchData(filters)}
              sx={{ color: "gray", cursor: "pointer" }}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default TableFilters;
