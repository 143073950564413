import { useMediaQuery, useTheme } from "@mui/material";

export const useSize = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // 0 - 600
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg")); // 600 - 1200
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg")); // + 1200

  const isUpBreakPoint = (breakPoint) => useMediaQuery(`(min-width:${breakPoint})`); //ex: "960px"

  return { isMobile, isTablet, isDesktop, isUpBreakPoint };
};
