import React, { useState } from "react";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { Box } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { AddBusiness } from "@mui/icons-material";
import { PartnerForm } from "~/presentation/views/Reinf/BusinessPartner/PartnerForm/PartnerForm";
import { useDialog } from "~/presentation/providers/DialogProvider";
import Grid from "@mui/material/Grid2";
import { addManualStyles } from "~/presentation/views/Reinf/DocPanel/Create/CreateNfse";
import { makeNfseUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";

export const CompanyForm = ({
  partners,
  handleReturn,
  refreshData,
  onRefreshDocsData,
  allPartners,
}) => {
  const apiNfse = makeNfseUseCases();
  const { openSecondDialog, closeSecondDialog } = useDialog();
  const { openBackdrop } = useBackdrop();
  const { showError, showSuccess } = useSnackbar();
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  const [nFseNumberError, setNfseNumberError] = useState(null);

  //filtrando cnpjs partners
  const cnpjPartnersFilter = partners.filter((partners) => partners.personType === 1);

  const [nfseData, setNfseData] = useState({
    businessPartnerId: "",
    nFseNumber: "",
    dateOfIssue: new Date(),
    amountBC: "",
    typeDoc: QSO === "D" ? 0 : null,
  });

  const isTypeDocEmpy = QSO !== "D" && nfseData.typeDoc === null;

  const isSomeFieldEmpty =
    !nfseData?.businessPartnerId || !nfseData?.dateOfIssue || !nfseData?.amountBC || isTypeDocEmpy;

  const handleChange = (e) => {
    const newValue = e.target.value;
    const maxDigits = newValue.length <= 15;
    if (maxDigits) {
      setNfseData({ ...nfseData, nFseNumber: newValue });
      setNfseNumberError(null);
    } else {
      setNfseNumberError("Deve ter no máximo 15 dígitos.");
    }
  };

  const handleDateChange = (name, date) => {
    setNfseData((prev) => ({
      ...prev,
      [name]: date ? new Date(date) : "",
    }));
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Cadastrando Documento Fiscal...");
    try {
      await apiNfse.createNfse(nfseData);
      await onRefreshDocsData();
      showSuccess("Doc. cadastrado com sucesso");
      handleReturn();
    } catch (error) {
      showError(error, "Erro ao cadastrar.");
    } finally {
      openBackdrop(false);
    }
  };

  const handleOpenDialog = () => {
    openSecondDialog({
      maxWidth: "md",
      customComponent: () => (
        <PartnerForm
          personType={1}
          onClose={closeSecondDialog}
          refreshData={refreshData}
          allPartners={allPartners}
        />
      ),
    });
  };

  const messageErrorText = "Campo Obrigatório";

  const documentOptions = [
    {
      value: 1,
      title: "Nota Fiscal de Produto Eletrônica (NF-e)",
    },
    {
      value: 0,
      title: "Nota Fiscal de Serviço Eletrônica (NFs-e)",
    },
  ];

  return (
    <>
      <Box sx={addManualStyles.container}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Input
              type="select"
              title="Selecionar contratado"
              options={cnpjPartnersFilter}
              getOptionLabel={(option) => `${formatCpfCnpj(option.cpfCnpj)} - ${option.legalName}`}
              placeholder="Selecione o contratado"
              value={
                cnpjPartnersFilter.find((item) => item.id === nfseData.businessPartnerId) || null
              }
              onChange={(option) => setNfseData({ ...nfseData, businessPartnerId: option?.id })}
              error={!nfseData?.businessPartnerId && messageErrorText}
              height={200}
              icon={{
                title: "Criar novo contratado",
                func: handleOpenDialog,
                component: <AddBusiness sx={{ color: "#fff" }} />,
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 8, lg: 4 }}>
            <Input
              disabled={QSO === "D"}
              type="select"
              title="Tipo de Documento Fiscal"
              options={documentOptions}
              getOptionLabel={(option) => option.title}
              placeholder="Selecione o tipo de documento"
              value={documentOptions.find((item) => item.value === nfseData?.typeDoc)}
              onChange={(option) => setNfseData({ ...nfseData, typeDoc: option.value })}
              error={nfseData?.typeDoc === null && messageErrorText}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 4, lg: 2 }}>
            <Input
              title="Nº do Doc. Fiscal"
              type="text"
              name="nFseNumber"
              value={nfseData.nFseNumber}
              onChange={(e) => handleChange(e)}
              error={nFseNumberError}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
            <Input
              title="Data de Emissão"
              type="date"
              value={nfseData.dateOfIssue}
              onChange={(date) => handleDateChange("dateOfIssue", date)}
              error={!nfseData?.dateOfIssue && messageErrorText}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
            <Input
              title="Valor Bruto"
              type="amount"
              name="amountBC"
              value={nfseData.amountBC}
              onChange={(value) => setNfseData({ ...nfseData, amountBC: value })}
              error={!nfseData?.amountBC && messageErrorText}
            />
          </Grid>
        </Grid>
        <Box sx={addManualStyles.buttonsContainer}>
          <RButton sx={addManualStyles.cancelButton} variant="outlined" onClick={handleReturn}>
            Cancelar
          </RButton>
          <RButton
            sx={addManualStyles.saveButton}
            onClick={handleSubmit}
            disabled={isSomeFieldEmpty}
          >
            Salvar
          </RButton>
        </Box>
      </Box>
    </>
  );
};
