import React, { useState } from "react";
import { Box, List, Paper, Switch, Typography as Text, Typography } from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { PriorityHigh } from "@mui/icons-material";
import { useDialog } from "~/presentation/providers/DialogProvider";
import { commonColors } from "~/presentation/themes/styles.App";
import SendEvents from "../SendEvents/SendEvents";

const itemsOptions = [
  { id: "R-2010", label: "Contratou serviços sujeitos à retenção de INSS?", checked: false },
  { id: "R-2020", label: "Prestou serviços sujeitos à retenção de INSS?", checked: false },
  {
    id: "R-2030",
    label:
      "A associação desportiva que mantém equipe de futebol profissional possui informações sobre recursos recebidos?",
    checked: false,
  },
  {
    id: "R-2040",
    label:
      "Possui informações sobre repasses efetuados à associação desportiva que mantém equipe de futebol profissional?",
    checked: false,
  },
  {
    id: "R-2050",
    label: "O produtor rural PJ/Agroindústria possui informações de comercialização de produção?",
    checked: false,
  },
  { id: "R-2055", label: "Possui informações sobre aquisição de produção rural?", checked: false },
  {
    id: "R-2060",
    label:
      "Possui informações sobre a apuração da contribuição previdenciária sobre a receita bruta (CPRB)?",
    checked: false,
  },
];

const PageR2099 = ({
  filters,
  certificateData,
  isEventClosed,
  handleVerifyEventStatus,
  currentEventsData,
}) => {
  const { openDialogBox, closeDialog } = useDialog();

  const [items, setItems] = useState(itemsOptions);
  const isNoMovment = currentEventsData.length === 0;

  const openSendModal = () => {
    openDialogBox({
      fullWidth: true,
      maxWidth: "md",
      customComponent: () => (
        <SendEvents
          data={{
            isR2010: items[0].checked,
            isR2020: items[1].checked,
            isR2030: items[2].checked,
            isR2040: items[3].checked,
            isR2050: items[4].checked,
            isR2055: items[5].checked,
            isR2060: items[6].checked,
          }}
          filter={filters}
          certificates={certificateData}
          closeModal={closeDialog}
          handleVerifyEventStatus={handleVerifyEventStatus}
        />
      ),
    });
  };

  const handleToggle = (id) => () => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item))
    );
  };

  return (
    <Box sx={styles.container}>
      <RButton sx={styles.button} onClick={openSendModal} disabled={!isNoMovment || isEventClosed}>
        FECHAR R-2099 SEM MOVIMENTO
      </RButton>
      <Box component={Paper} elevation={3} sx={styles.itensContainer}>
        <Typography sx={{ ...styles.text, ...styles.title }}>Informações de Fechamento</Typography>
        <List sx={{ width: "100%" }}>
          {items.map((item, index) => (
            <Box key={item.id} sx={styles.item(index === items.length - 1)}>
              <Typography sx={styles.text}>{item.label}</Typography>
              <Switch
                sx={styles.switch}
                edge="end"
                onChange={handleToggle(item.id)}
                checked={item.checked}
              />
            </Box>
          ))}
        </List>
      </Box>
      <Box sx={styles.buttonsContainer}>
        {isEventClosed && (
          <Text sx={{ mt: 2, display: "flex", alignItems: "center" }} color="error">
            <PriorityHigh />
            {`Período ${filters?.month.toString().length === 1 ? `0${filters?.month}` : filters?.month}/${filters?.year} está fechado. Para fazer modificações no período é necessário o envio de um evento de REABERTURA.`}
          </Text>
        )}
        <RButton
          sx={{ ...styles.button, minWidth: 200, ml: "auto" }}
          onClick={openSendModal}
          disabled={
            !filters?.year ||
            !filters?.month ||
            !filters?.event ||
            !filters?.certificate ||
            isEventClosed ||
            isNoMovment
          }
        >
          Enviar
        </RButton>
      </Box>
    </Box>
  );
};

export default PageR2099;

const styles = {
  container: {
    mx: { xs: 1, md: 3 },
    py: 1,
    display: "flex",
    flexDirection: "column",
    gap: 2,
    mb: 12,
  },
  button: {
    width: "fit-content",
    p: 3,
  },
  itensContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 1,
    p: 2,
    borderRadius: 4,
    width: "100%",
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  title: {
    fontWeight: 600,
    textAlign: "left",
    width: "100%",
    pl: 2,
    fontSize: 18,
  },
  item: (isLast) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: !isLast && "1px solid #d1d1d1",
    p: 1,
  }),
  switch: {
    "& .MuiSwitch-switchBase": {
      transitionDuration: "300ms",
      "&.Mui-checked": {
        color: commonColors.reinf.primary,
        "& + .MuiSwitch-track": {
          color: commonColors.reinf.primary,
        },
      },
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
};
