import React, { useContext, useState } from "react";
import TextEditor from "~/presentation/components/TextEditor";
import { Box, Typography } from "@mui/material";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";
import PageListHeader from "../../../components/PageListHeader";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import TableActions from "~/presentation/components/TableActions";
import CreateEdit from "../../../Term/CreateEdit/CreateEdit";
import { Tab, TabPanel, Tabs, TabsList } from "~/presentation/components/Tabs/Tabs";
import { adminPerms } from "~/presentation/security/SecurePaths";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import InBulkHandler, {
  onInBulkDelete,
} from "~/presentation/components/ListComponent/components/InBulkHandler";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import AdminTable from "../../../components/AdminTable/AdminTable";

const AnnotationForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  cnaes,
  terms,
  fetchTerms,
}) => {
  const { openDialogBox, closeDialog, closeSecondDialog, openSecondDialog } =
    useContext(DialogContext);
  const { showInfo } = useContext(SnackbarContext);

  // Controlar tabs para não alterar o estado quando existem alterações
  const [openTabs, setOpenTabs] = useState(1);
  const handleChangeTabs = (_, newValue) => setOpenTabs(newValue);

  const handleCnaeList = () => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          disablePadding={false}
          data={cnaes}
          checkedData={values?.cnaes}
          title="Cnaes"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("cnaes", values)}
          costumFilter={{
            title: "InBulk",
            field: "code",
            nameField: "CNAES",
          }}
        />
      ),
    });
  };

  const handleDeleteInBulk = (codes = []) => {
    onInBulkDelete({
      codes: codes,
      data: values?.cnaes,
      onChange: (values) => onChangeValue("cnaes", values),
      showUpdateCount: (items) =>
        showInfo(items === 1 ? "Foi removido 1 CNAE" : `Foram removidos ${items} CNAES`),
    });
  };

  const handleDeleteCnae = (params) => {
    if (values.cnaes.length === 1) {
      onChangeValue("cnaes", []);
    } else {
      const filtedValue = values?.cnaes?.filter((item) => item.id !== params.row.id);
      onChangeValue("cnaes", filtedValue);
    }
  };

  const handleTermsList = () => {
    openDialogBox({
      maxWidth: "xs",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          disablePadding={false}
          data={terms}
          checkedData={values?.terms}
          title="Termos de Pesquisa"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("terms", values)}
          handleCreate={handleTermsModal}
          titleAdd="Criar novo termo de pesquisa"
          securePaths={[adminPerms.addSearchTerms]}
          setHeightAuto
        />
      ),
    });
  };

  const handleTermsModal = () => {
    openSecondDialog({
      customComponent: () => (
        <CreateEdit data={null} onCloseForm={closeSecondDialog} refreshData={fetchTerms} />
      ),
    });
  };

  const handleDeleteTerm = (params) => {
    if (values.terms.length === 1) {
      onChangeValue("terms", []);
    } else {
      const filtedValue = values?.terms?.filter((item) => item.id !== params.row.id);
      onChangeValue("terms", filtedValue);
    }
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={{ ...formStyles.title, color: errors.name && "#d32f2f" }}>
          Nome da Anotação
        </Typography>
        <Input
          type="text"
          name="name"
          placeholder="Inserir nome"
          value={values.name}
          onChange={(e) => onChangeValue("name", e.target.value)}
          error={errors.name}
        />
        <Typography sx={{ ...formStyles.title, color: errors.text && "#d32f2f", marginTop: 2 }}>
          Texto
        </Typography>
        <TextEditor
          initialValue={values.text}
          name="text"
          height={300}
          handleEditorChange={(content) => {
            onChangeValue("text", content);
          }}
          error={errors.text}
        />
        {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
      </Box>

      <Tabs value={openTabs} onChange={handleChangeTabs}>
        <TabsList>
          <Tab value={1}>CNAES</Tab>
          <Tab value={2}>Termos de Pesquisa</Tab>
        </TabsList>
        <TabPanel value={1}>
          <PageListHeader title="Cnaes" titleAdd="Adicionar Cnaes" handleAdd={handleCnaeList} />
          <AdminTable
            columns={[
              { field: "code", headerName: "Código", flex: 1, align: "center" },
              { field: "detailCode", headerName: "Detalhe", flex: 1, align: "center" },
              { field: "description", headerName: "Descrição", flex: 6, hide: "md" },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDeleteCnae(params)} />
                ),
              },
            ]}
            costumFilter={
              <Box sx={{ minWidth: { sm: 360 } }}>
                <InBulkHandler
                  onChange={handleDeleteInBulk}
                  data={values?.cnaes}
                  action="delete"
                  field="code"
                  nameField="CNAES"
                />
              </Box>
            }
            data={values?.cnaes}
          />
        </TabPanel>
        <TabPanel value={2}>
          <PageListHeader
            title="Termos de Pesquisa"
            titleAdd="Adicionar Termo de Pesquisa"
            handleAdd={handleTermsList}
          />
          <AdminTable
            columns={[
              { field: "term", headerName: "Termo de Pesquisa", flex: 1 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDeleteTerm(params)} />
                ),
              },
            ]}
            data={values?.terms}
          />
        </TabPanel>
      </Tabs>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default AnnotationForm;
