import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import {
  makeArticle,
  makeArticlePost,
  makeClientHome,
  makeExplanatoryVideo,
  makeLegislation,
  makeLegislationPost,
  makeMultimediaHome,
  makeMyPanel,
  makeQSO,
} from "~/main/factories/pages/client";
import { SecurityRoute } from "../security/SecurityPath";
import { clientPerms } from "../security/SecurePaths";
import { makeBlogHome, makeBlogPost } from "~/main/factories/pages/blog";

const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={makeClientHome()} />

      {/** legislation routes */}
      <Route
        path="/conteudo-selecionado/legislacao/"
        element={
          <SecurityRoute securePaths={[clientPerms.seeLegislation]} returnTo="/cliente">
            {makeLegislation()}
          </SecurityRoute>
        }
      />
      <Route
        path="/conteudo-selecionado/legislacao/post/:id"
        element={
          <SecurityRoute securePaths={[clientPerms.seeLegislation]} returnTo="/cliente">
            {makeLegislationPost()}
          </SecurityRoute>
        }
      />

      {/** article routes */}
      <Route
        path="/conteudo-selecionado/artigo/"
        element={
          <SecurityRoute securePaths={[clientPerms.seeArticles]} returnTo="/cliente">
            {makeArticle()}
          </SecurityRoute>
        }
      />
      <Route
        path="/conteudo-selecionado/artigo/post/:id"
        element={
          <SecurityRoute securePaths={[clientPerms.seeArticles]} returnTo="/cliente">
            {makeArticlePost()}
          </SecurityRoute>
        }
      />

      {/* multimedia routes */}
      <Route
        path="/conteudo-selecionado/multimidia"
        element={
          <SecurityRoute securePaths={[clientPerms.seeMultimedia]} returnTo="/cliente">
            {makeMultimediaHome()}
          </SecurityRoute>
        }
      />

      {/* Blog routes */}
      <Route
        path="/blog"
        element={
          <SecurityRoute securePaths={[clientPerms.seeBlog]} returnTo="/cliente">
            {makeBlogHome()}
          </SecurityRoute>
        }
      />
      <Route
        path="/blog/post/:id"
        element={
          <SecurityRoute securePaths={[clientPerms.seeBlog]} returnTo="/cliente">
            {makeBlogPost()}
          </SecurityRoute>
        }
      />

      {/* explanatory videos routes */}
      <Route path="/ajuda" element={makeExplanatoryVideo()} />

      {/* qso routes */}
      <Route path="/qso" element={makeQSO()} />

      {/* my panel routes */}
      <Route path="/meu-painel" element={makeMyPanel()} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ClientRoutes;
