import React from "react";
import { Box, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";

function Form({ values, onChangeValue, handleSubmit, errors }) {
  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Dados da Categoria</Typography>
        <Grid container spacing={1}>
          <Grid size={12}>
            <Input
              type="text"
              title="Título"
              name="title"
              maxLength={1}
              onChange={(e) => onChangeValue("title", e.target.value)}
              value={values?.title}
              error={errors.title}
            />
          </Grid>
          <Grid size={12}>
            <Input
              type="textarea"
              title="Descrição"
              name="description"
              onChange={(e) => onChangeValue("description", e.target.value)}
              value={values?.description}
              error={errors.description}
              maxRows={2}
              minRows={2}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
}

export default Form;
