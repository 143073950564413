import React, { useContext, useEffect, useState } from "react";
import TableActions from "~/presentation/components/TableActions";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import PageListHeader from "../components/PageListHeader";
import CreateEdit from "./CreateEdit/CreateEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";
import AdminTable from "../components/AdminTable/AdminTable";

function ExplanatoryCategory({ explanatoryVideoCategory }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //Data
  const [categories, setCategories] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      customComponent: () => (
        <CreateEdit data={data} onCloseForm={closeDialog} refreshData={fetchData} />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const categories = await explanatoryVideoCategory.get({ checkPermission: true });
      setCategories(categories);
    } catch (error) {
      showError(error, "Erro ao carregar categoria");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    { field: "title", headerName: "Título", flex: 2 },
    { field: "description", headerName: "Descrição", flex: 5, hide: "md" },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenForm(row)}
          editSecurePath={[adminPerms.editVideoCategory]}
          handleDelete={() => deleteDialog(row.id)}
          deleteSecurePath={[adminPerms.deleteVideoCategory]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo categoria");
      await explanatoryVideoCategory.delete(id);
      showSuccess(`Categoria eliminada com sucesso!`);
      setCategories((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao eliminar categoria");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta categoria?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageListHeader
        title="Categorias FAQ"
        titleAdd="Criar nova categoria"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addVideoCategory]}
      />
      <AdminTable tableExport columns={columns} data={categories} exportFileName="categorias-faq" />
    </>
  );
}

export default ExplanatoryCategory;
