import React from "react";
import { Typography as Text, Box, CircularProgress } from "@mui/material";
import RButton from "../../Common/Buttons/RButton";
import { CheckCircle, Error, InsertDriveFileOutlined } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

function NOTA({ handleNOTAS, files, handleClose }) {
  return (
    <>
      <Text sx={styles.subTitle}>{files?.length} arquivos selecionados</Text>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {files.map((file, index) => (
          <Grid size={12} key={index}>
            <Grid container sx={styles.containerFields}>
              <Grid size={12} sx={styles.fields}>
                <Box
                  sx={{
                    ...styles.fieldsText,
                    maxWidth: "100%",
                    "@media (min-width: 600px)": {
                      maxWidth: "60%",
                    },
                  }}
                >
                  <InsertDriveFileOutlined />
                  <Text sx={styles.text}>{file.name}</Text>
                </Box>
                <Box sx={styles.fieldsText}>
                  <Text sx={styles.errorText}>{file.success ? file.message : file.errorMsg}</Text>
                  {file.loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : file.success ? (
                    <CheckCircle color="success" />
                  ) : (
                    <Error color="error" />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} sx={{ justifyContent: "right" }}>
        <Grid size={{ xs: 12, sm: 3 }}>
          <RButton variant="outlined" onClick={handleClose}>
            Fechar
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 5 }}>
          <>
            <input
              type="file"
              id="file-input"
              style={{ display: "none" }}
              multiple
              accept=".xml"
              onChange={handleNOTAS}
              disabled={files.length > 0}
            />
            <label htmlFor="file-input">
              <RButton component="span" disabled={files.length > 0} sx={{ border: "none" }}>
                Selecionar Arquivos
              </RButton>
            </label>
          </>
        </Grid>
      </Grid>
    </>
  );
}

export default NOTA;

const styles = {
  subTitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 15,
    fontWeight: 500,
    color: "#404040",
    textAlign: "right",
  },
  errorText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "15px",
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: 2,
  },
  fields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    flexDirection: "column",
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
  },
  fieldsText: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  text: {
    maxWidth: "40ch" /* Tamanho */,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Montserrat, sans-serif",
    whiteSpace: "nowrap",
  },
};
