import React from "react";
import { makeCityUseCases } from "~/main/factories/use-cases/city";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";
import { makeLegalNatureUseCases } from "~/main/factories/use-cases/legal-nature";
import { makePlanUseCases } from "~/main/factories/use-cases/plan";
import { makeStateUseCases } from "~/main/factories/use-cases/state";
import { makeUserUseCases } from "~/main/factories/use-cases/user";
import Company from "~/presentation/views/Admin/Company/Company";

export const makeCompanyTable = (params) => {
  return (
    <Company
      {...params}
      companyUseCases={makeCompanyUseCases()}
      planUseCases={makePlanUseCases()}
      stateUseCases={makeStateUseCases()}
      cityUseCases={makeCityUseCases()}
      legalNatureUseCases={makeLegalNatureUseCases()}
      userUseCases={makeUserUseCases()}
    />
  );
};
