import * as Yup from "yup";

import { formatRealStringToNumber } from "~/presentation/utils/formatCurrency";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";

export default Yup.object({
  type: Yup.string().required("Campo obrigatório!").typeError("Campo obrigatório!"),
  amount: Yup.number()
    .positive("Digite um número positivo.")
    .required("Campo obrigatório!")
    .integer("Digite um número inteiro")
    .typeError("Campo obrigatório!"),
  limitDate: Yup.date()
    .nullable()
    .when(
      "dateRegister",
      (startDate, yup) =>
        startDate &&
        yup.min(
          startDate,
          `A data de vencimento não pode ser anterior à data de registro ${formatDateToBrazilian(startDate)}`
        )
    )
    .typeError("Campo obrigatório!"),

  companyId: Yup.string().required("Campo obrigatório!"),
  companyName: Yup.string().required("Campo obrigatório!"),
  price: Yup.string()
    .required("Campo obrigatório!")
    .typeError("Campo obrigatório!")
    .test("is-positive", "Digite valor positivo.", (value) => {
      const formattedValue = formatRealStringToNumber(value);
      if (formattedValue) {
        return formattedValue > 0;
      }
      return true;
    }),
});
