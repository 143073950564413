import * as Yup from "yup";

export const datesValidation = Yup.object().shape({
  start: Yup.date().typeError("Data inválida").required("Campo obrigatório"),
  end: Yup.date()
    .when(
      "start",
      (start, yup) => start && yup.min(start, "A data final não pode ser anterior à data de início")
    )
    .typeError("Data inválida")
    .required("Campo obrigatório"),
});
