import React from "react";
import Fade from "~/presentation/components/Animation/Fade/Fade";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { Box, IconButton, Typography as Text, Tooltip } from "@mui/material";
import { PostAdd } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import { commonColors } from "~/presentation/themes/styles.App";

export const HeaderSectionPage = ({
  title,
  buttonPrimary,
  buttonSecond,
  titleAdd,
  handleAdd,
  sx,
  addSecurePath = [],
}) => {
  return (
    <Box sx={{ backgroundColor: "#E6E6E6", p: 3, ...sx }}>
      <Fade>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, lg: 6, xl: 7 }} sx={styles.titleContainer}>
            <Text sx={styles.title}>{title}</Text>
            {handleAdd && (
              <SecurityPath securePaths={addSecurePath}>
                <Box sx={styles.addIcon}>
                  <Tooltip title={titleAdd || `Cadastrar novo`}>
                    <IconButton onClick={handleAdd}>
                      <PostAdd sx={{ color: "#fff" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </SecurityPath>
            )}
          </Grid>
          {buttonPrimary && (
            <Grid sx={{ marginLeft: "auto" }} size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2.5 }}>
              {buttonPrimary}
            </Grid>
          )}
          {buttonSecond && (
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2.5 }}>{buttonSecond}</Grid>
          )}
        </Grid>
      </Fade>
    </Box>
  );
};

const styles = {
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#242424",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  addIcon: {
    backgroundColor: commonColors.reinf.primary,
    padding: "2px",
    borderRadius: "12px",
  },
};
