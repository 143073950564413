import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import { ApiDependentUseCases } from "~/data/usecases/dependent/apiDependent";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import { ApiEstablishmentUseCases } from "~/data/usecases/establishments/apiEstablishments";
import { ApiIncomeNature } from "~/data/usecases/incomeNature/incomeNature";
import { ApiItemCompany } from "~/data/usecases/itemCompany/apiItemCompany";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import { ApiPaymentRecordUseCases } from "~/data/usecases/paymentRecord/apiPaymentRecord";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { ApiServiceClassification } from "~/data/usecases/serviceClassification/apiServiceClassification";
import { makeHttpClient } from "~/main/factories/infra/http-client";

export const makeNfseUseCases = () => new ApiNfseUseCases(makeHttpClient());
export const makePaymentRecordUseCases = () => new ApiPaymentRecordUseCases(makeHttpClient());
export const makeServiceClassUseCases = () => new ApiServiceClassification(makeHttpClient());
export const makeIncomeNatureUseCases = () => new ApiIncomeNature(makeHttpClient());
export const makeBusinessPartnerUseCases = () => new ApiBusinessPartnerUseCases(makeHttpClient());
export const makeReinfUseCases = () => new ApiReinfUseCases(makeHttpClient());
export const makeDigitalCertUseCases = () => new ApiDigitalCertUseCases(makeHttpClient());
export const makeCompanyUseCases = () => new ApiCompanyUseCases(makeHttpClient());
export const makeEstablishmentsUseCases = () => new ApiEstablishmentUseCases(makeHttpClient());
export const makeItemCompanyUseCases = () => new ApiItemCompany(makeHttpClient());
export const makeDependentUseCases = () => new ApiDependentUseCases(makeHttpClient());
