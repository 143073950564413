import axios from "axios";

import { authUrl } from "~/data/usecases/authentication/apiAuthentication";
export class HttpClient {
  constructor(baseUrl = "") {
    this.baseUrl = baseUrl;
    this.axios = axios.create({
      baseURL: this.baseUrl,
    });
    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const isAuthenticationEndpoint = error.response.request.responseURL.includes(authUrl);

        if (error.response && error.response.status === 401 && !isAuthenticationEndpoint) {
          window.location.href = "/";
        }

        return Promise.reject(error);
      }
    );
  }

  setAuthorization(accessToken) {
    this.axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
  }

  clearAuthorization() {
    this.axios.defaults.headers.Authorization = "";
  }

  async request(request) {
    const axiosRequest = {
      url: request.url,
      data: request.data,
      params: request.params,
      method: request.method,
      headers: request.headers,
      responseType: request.responseType,
    };
    try {
      const response = await this.axios.request(axiosRequest);
      return response;
    } catch (error) {
      console.error("Request error:", error);
      throw error;
    }
  }
}
