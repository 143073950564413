export default [
  {
    id: 0,
    description: "PJ",
  },
  {
    id: 1,
    description: "MEI",
  },
  {
    id: 2,
    description: "PF",
  },
  {
    id: 3,
    description: "Cooperativa",
  },
  {
    id: 4,
    description: "Simples Nacional",
  },
];
