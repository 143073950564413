import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiAnnotationUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAnnotations() {
    if (!hasPermission([adminPerms.seeAnnotations])) {
      return new Error(securityErrorMessage("visualizar anotações."));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Annotation`,
        method: "GET",
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getAnnotation(id) {
    if (!hasPermission([adminPerms.seeAnnotations])) {
      return new Error(securityErrorMessage("visualizar anotação."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Annotation/${id}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateAnnotation(data) {
    if (!hasPermission([adminPerms.editAnnotations])) {
      return new Error(securityErrorMessage("editar anotações."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Annotation`,
        method: "PUT",
        data,
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async createAnnotation(data) {
    if (!hasPermission([adminPerms.addAnnotations])) {
      return new Error(securityErrorMessage("criar anotações."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Annotation`,
        method: "POST",
        data,
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteAnnotation(id) {
    if (!hasPermission([adminPerms.deleteAnnotations])) {
      return new Error(securityErrorMessage("excluir anotações."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Annotation/${id}`,
        method: "DELETE",
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
