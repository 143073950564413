import { styled } from "@mui/material/styles";

export const Container = styled("div")({
  display: "grid",
  gap: "2px",

  "> div": {
    display: "grid",
    gridTemplateColumns: "min-content auto",
    alignItems: "center",

    "> small": {
      minWidth: "5rem",
    },

    "> span": {
      marginLeft: "12px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  "@media screen and (max-width: 600px)": {
    padding: "6px 12px",
  },

  "> hr": {
    marginTop: "10px",
    border: "none",
    height: "1px",
    background: "#383f48",
  },
});
