import React from "react";
import MyPanel from "~/presentation/views/Client/MyPanel";
import { makeUserUseCases } from "~/main/factories/use-cases/user";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";
import { makeTaxInformationUseCases } from "~/main/factories/use-cases/tax-information";
import { makeUserProfilesUseCases } from "~/main/factories/use-cases/user-profiles";

export const makeMyPanel = (params) => {
  return (
    <MyPanel
      {...params}
      userUseCases={makeUserUseCases()}
      companyUseCases={makeCompanyUseCases()}
      taxInformationUseCases={makeTaxInformationUseCases()}
      userProfilesUseCases={makeUserProfilesUseCases()}
    />
  );
};
