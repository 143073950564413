import { floatToReais, formatPercentageReport } from "~/presentation/utils/reais-parser";

export const reportMapper = (data, result) => {
  if(data.retentionAliquot.code === 0) {
    return {
      title: 'IRPJ, CSLL, PIS/Pasep e COFINS',
      tax: 2,
      retentionValue: result,
      items: [
        {
          descriptionItem: "IRPJ",
          value: `${formatPercentageReport((data.irpj.value*100).toFixed(2))}%`
        },
        {
          descriptionItem: "CSLL",
          value: `${formatPercentageReport((data.csll.value*100).toFixed(2))}%`
        },
        {
          descriptionItem: "PIS/Pasep",
          value: `${formatPercentageReport((data.pisPasep.value*100).toFixed(2))}%`
        },
        {
          descriptionItem: "COFINS",
          value: `${formatPercentageReport((data.cofins.value*100).toFixed(2))}%`
        },
        {
          descriptionItem: "Valor da retenção do IRPJ, CSLL, PIS/Pasep e COFINS",
          value: floatToReais(result)
        }
      ]
    }
  }

  const report = {
    title: 'IRPJ, CSLL, PIS/Pasep e COFINS',
    tax: 2,
    retentionValue: result,
    items: [
      {
        descriptionItem: "Alíquota da retenção",
        value: `${formatPercentageReport((data.retentionAliquot.value*100).toFixed(2))}%`
      },
      {
        descriptionItem: "Valor da retenção do IRPJ, CSLL, PIS/Pasep e COFINS",
        value: floatToReais(result)
      }

    ]
  }
  return report;
}
