import React from "react";
import Grid from "@mui/material/Grid2";
import { Box, Typography as Text } from "@mui/material";

function InfoPlan({ planDetails }) {
  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Text sx={styles.title}>Dados do Plano</Text>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>Descrição do plano:</Text>
          <Text>{planDetails?.name || ""}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>Consultar ISS:</Text>
          <Text>{planDetails?.isConsultISS ? "Sim" : "Não" || ""}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>Gerar relatório:</Text>
          <Text>{planDetails?.isGenerateReport ? "Sim" : "Não" || ""}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>Informações Fiscais:</Text>
          <Text>{planDetails?.isAllowTaxInformation ? "Sim" : "Não" || ""}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>N° de Informações Fiscais: </Text>
          <Text>{planDetails?.taxInformationNumber || 0}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>N° de consultas GT-Fácil:</Text>
          <Text>{planDetails?.consultLimit || 0}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>N° de usuários: </Text>
          <Text>{planDetails?.numberOfUsers || 0}</Text>
        </Box>
      </Grid>
    </Grid>
  );
}

export default InfoPlan;

const styles = {
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  text: {
    color: "#021148",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  planDetailsText: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    mb: 2,
  },
  tabs: {
    tab: {
      selectedBg: "#1341a1",
      padding: "15px 30px",
      fontSize: "1rem",
      border: "1px solid #000",
      selectBorder: "1px solid #1341a1",
    },
    tabpainel: { backgroundColor: "transparent", paddingTop: 3 },
  },
};
