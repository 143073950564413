import React, { useContext, useEffect, useRef, useState } from "react";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { Box, Typography } from "@mui/material";
import StatsForm from "./components/StatsForm";
import { painelStyles } from "~/presentation/views/Client/MyPanel";
import Table from "./components/Table";
import { statsValidation } from "./components/validation";
import { makeStatsUseCases } from "~/main/factories/use-cases/stats";
import { firstDayOfMonth, today } from "~/presentation/utils/constants";

function Visualizations({ userUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError } = useContext(SnackbarContext);
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const statsUseCases = makeStatsUseCases();

  const [usersData, setUsersData] = useState({ loading: false, users: [] });
  const [statsData, setStatsData] = useState([]);
  const tableRef = useRef(null);

  const [hasSearching, setHasSearching] = useState(false);

  const [errors, setErrors] = useState({});
  //Filtro
  const [values, setValues] = useState({
    searchType: "search",
    initialDate: firstDayOfMonth,
    finalDate: today,
    userId: null,
    isDetail: false,
  });

  const handleChangeValue = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
    if (field === "searchType") setHasSearching(false);
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (companyId) {
      getUsers(companyId);
    }
  }, [companyId]);

  const getUsers = async (id) => {
    setUsersData((prev) => ({ ...prev, loading: true }));
    try {
      const response = await userUseCases.getUsers({
        checkPermission: false,
        companyId: id,
      });
      setUsersData((prev) => ({
        ...prev,
        users: dataToSelectMapper([
          {
            name: "Remover filtro",
            value: null,
          },
          ...response,
        ]),
      }));
    } catch (error) {
      showError(error, "Erro ao carregar usuários da empresa.");
    } finally {
      setUsersData((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleValidation = async () => {
    statsValidation
      .validate(values, { abortEarly: false })
      .then(async () => handleSubmit(values))
      .catch((errors) => {
        setErrors(
          errors?.inner?.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Pesquisando");
    setHasSearching(true);

    const initialDate = values?.initialDate.toISOString().split("T")[0];
    const finalDate = values?.finalDate.toISOString().split("T")[0];

    try {
      switch (values.searchType) {
        case "search":
          setStatsData(
            await statsUseCases.getStatsSearch({
              companyId: companyId,
              userId: values?.userId,
              DateStart: initialDate,
              DateEnd: finalDate,
            })
          );
          break;

        case "search-summary":
          setStatsData(
            await statsUseCases.getStatsSearchSummary({
              companyId: companyId,
              dataStart: initialDate,
              dateEnd: finalDate,
            })
          );
          break;

        case "content-access":
          setStatsData(
            await statsUseCases.getStatsContentAccess({
              companyId: companyId,
              isDetail: values.isDetail,
              dataStart: initialDate,
              dateEnd: finalDate,
            })
          );
          break;

        default:
          break;
      }
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      showError(error, "Erro durante a pesquisa");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Box sx={painelStyles.flexCol}>
      <Typography sx={painelStyles.title}>Visualizações</Typography>
      <StatsForm
        usersData={usersData}
        onSubmit={handleValidation}
        setStatsData={setStatsData}
        errors={errors}
        values={values}
        onChangeValue={handleChangeValue}
      />
      {hasSearching && (
        <Table tableRef={tableRef} data={statsData} searchType={values.searchType} />
      )}
    </Box>
  );
}

export default Visualizations;
