const aliquotData = {
    range1: {
      minValue: 0,
      maxValue: 2259.20,
      aliquot: 0.0,
      deductionParcel: 0,
    },
    range2: {
      minValue: 2259.21,
      maxValue: 2826.65,
      aliquot: 0.075,
      deductionParcel: 169.44,
    },
    range3: {
      minValue: 2826.66,
      maxValue: 3751.05,
      aliquot: 0.15,
      deductionParcel: 381.44,
    },
    range4: {
      minValue: 3751.06,
      maxValue: 4664.68,
      aliquot: 0.225,
      deductionParcel: 662.77,
    },
    range5: {
      minValue: 4664.69,
      maxValue: Number.POSITIVE_INFINITY,
      aliquot: 0.275,
      deductionParcel: 896.00,
    },
  };

  export default aliquotData;