import React from "react";
import { PriorityHigh } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { useEDF } from "~/presentation/views/Reinf/SendEDF/SendEDF";

function DownButtons({ isSendTable, selectedRows }) {
  const { activeFilters, isEventClosed, openSendModal } = useEDF();

  const disabledSendButton = isEventClosed
    ? "Período fechado."
    : selectedRows.length === 0
      ? "Selecione os documentos que pretende enviar."
      : !activeFilters.certificate
        ? "Selecione um certificado para poder fazer um envio."
        : "";

  return (
    <SecurityPath securePaths={[`reinf\\envio\\${activeFilters.event}`]}>
      <Box sx={styles.container}>
        {isEventClosed && (
          <Typography sx={{ display: "flex", alignItems: "center" }} color="error">
            <PriorityHigh />
            {`Período ${
              activeFilters?.month.toString().length === 1
                ? `0${activeFilters?.month}`
                : activeFilters?.month
            }/${
              activeFilters?.year
            } está fechado. Para fazer modificações no período é necessário o envio de um evento de REABERTURA.`}
          </Typography>
        )}
        {isSendTable && (
          <Box sx={{ ml: "auto" }}>
            <Tooltip title={disabledSendButton}>
              <span>
                <RButton
                  onClick={() => openSendModal(activeFilters)}
                  disabled={disabledSendButton}
                  sx={styles.sendButton}
                >
                  Enviar
                </RButton>
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>
    </SecurityPath>
  );
}

export default DownButtons;

const styles = {
  container: {
    my: 2,
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    justifyContent: { xs: "center", md: "space-between" },
  },
  sendButton: {
    width: "200px",
  },
};
