import React from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import AnswerContainer from "../components/AnswerContainer";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";

const SurveyForm = ({ values, onChangeValue, onChangeAnswer, handleSubmit, errors }) => {
  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Enquete</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, lg: 9 }}>
            <Input
              type="text"
              name="question"
              title="Pergunta"
              value={values?.question}
              onChange={(e) => onChangeValue("question", e.target.value)}
              error={errors.question}
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 3 }}>
            <Input
              type="date"
              name="publishDate"
              title="Data da publicação"
              value={values?.publishDate}
              onChange={(e) => onChangeValue("publishDate", e)}
              error={errors.publishDate}
            />
          </Grid>
          <Grid size={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <Typography sx={formStyles.title}>Respostas</Typography>
              <Tooltip title="Adicionar nova resposta">
                <IconButton
                  sx={{ marginBottom: "8px" }}
                  onClick={() =>
                    onChangeValue("answers", [
                      ...values.answers,
                      {
                        id: 0,
                        text: "",
                        isCorrect: false,
                      },
                    ])
                  }
                >
                  <ControlPointIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {values.answers.map((answer, index) => (
                <>
                  <Box key={"answers-wrapper-" + index}>
                    <AnswerContainer
                      answer={answer}
                      index={index}
                      onChangeValue={onChangeValue}
                      onChangeAnswer={onChangeAnswer}
                      values={values}
                    />
                  </Box>
                </>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={formStyles.doubleButtonContainer}>
        <ButtonCommon
          sx={formStyles.button}
          color="secondary"
          onClick={() => handleSubmit({ ...values, draft: true })}
        >
          Salvar como rascunho
        </ButtonCommon>
        <ButtonCommon
          sx={formStyles.button}
          onClick={() => handleSubmit({ ...values, draft: false })}
        >
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default SurveyForm;
