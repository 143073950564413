import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";

function QsoInBulk({ onChange, verifyChecked, data }) {
  const QSO = ["A", "B", "C", "D"];

  //Lógica para costum filter QSO
  const handleAddQSOItems = (letter, data, isChecked) => {
    if (isChecked) {
      onChange((prev) => prev.filter((selectedItem) => selectedItem.letter !== letter));
    } else {
      onChange((prev) => [...prev, ...data]);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Typography sx={formStyles.subtitle}>Adicione por QSO:</Typography>
      <Box>
        {QSO.map((letter) => {
          const allItemsQSO = data.filter((i) => i.letter === letter);
          const allItemsAreChecked =
            allItemsQSO.length > 0 && allItemsQSO.every((item) => verifyChecked(item.id));
          return (
            <FormControlLabel
              key={letter}
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  edge="end"
                  onChange={() => handleAddQSOItems(letter, allItemsQSO, allItemsAreChecked)}
                  checked={allItemsAreChecked}
                />
              }
              label={letter}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default QsoInBulk;
