import React, { useState } from "react";
import TextEditor from "~/presentation/components/TextEditor";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { Box, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import CreateEditTypes from "~/presentation/views/Admin/TypeAnswer/CreateEdit/CreateEdit";
import Grid from "@mui/material/Grid2";
import { adminPerms } from "~/presentation/security/SecurePaths";
import CreateEditValue from "~/presentation/views/Admin/ValueVariable/CreateEdit/CreateEdit";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import LinkedAnswers from "../LinkedAnswers/LinkedAnswers";

const ItemAnswerForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  typesAnswer = [],
  getTypesAnswer,
}) => {
  //MODAL criar tipo resposta
  const [openTypeAnswer, setOpenTypeAnswer] = useState(false);
  const handleOpenTypeAnswer = () => setOpenTypeAnswer(true);
  const handleCloseTypeAnswer = () => setOpenTypeAnswer(false);

  //MODAL CRIAR VARIÁVEL DE VALOR
  const [openVariableValue, setOpenVariableValue] = useState(false);
  const handleOpenVariableValue = () => setOpenVariableValue(true);
  const handleCloseVariableValue = () => setOpenVariableValue(false);

  //MODAL Respostas Vinculadas
  const [openAnswers, setOpenAnswers] = useState(false);
  const handleOpenAnswers = () => setOpenAnswers(true);
  const handleCloseAnswers = () => setOpenAnswers(false);

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Item de Resposta</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, lg: 6 }}>
            <Input
              type="text"
              name="title"
              title="Título"
              value={values.title}
              onChange={(e) => onChangeValue("title", e.target.value)}
              error={errors.title}
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <Input
              type="select"
              name="gtAnswerItemType"
              title="Tipo de Resposta"
              value={typesAnswer.find((item) => item.id === values.gtAnswerItemType.id)}
              onChange={(e) => onChangeValue("gtAnswerItemType", { id: e.id, title: e.title })}
              error={errors.gtAnswerItemType}
              options={typesAnswer}
              getOptionLabel={(option) => option.title}
              icon={{
                title: "Criar tipo de resposta",
                func: () => handleOpenTypeAnswer(),
                securePaths: [adminPerms.addAnswerTypes],
              }}
            />
            <ModalBox
              open={openTypeAnswer}
              onClose={handleCloseTypeAnswer}
              bg="white"
              maxWidth={700}
            >
              <CreateEditTypes
                data={null}
                onCloseForm={handleCloseTypeAnswer}
                refreshData={getTypesAnswer}
              />
            </ModalBox>
          </Grid>

          <Grid size={12}>
            <Typography sx={{ ...formStyles.subtitle, color: errors.text && "#d32f2f" }}>
              Texto
            </Typography>
            <TextEditor
              initialValue={values.text}
              name="text"
              height={400}
              handleEditorChange={(content) => {
                onChangeValue("text", content);
              }}
              error={errors.text}
            />
            {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.buttons}>
        {values?.id > 0 && (
          <ButtonCommon
            sx={formStyles.button}
            color="secondary"
            onClick={() => handleOpenAnswers()}
          >
            Respostas Vinculadas
          </ButtonCommon>
        )}
        <Box sx={styles.buttons2}>
          <SecurityPath securePaths={[adminPerms.addValueVariables]}>
            <ButtonCommon
              variant="outlined"
              color="secondary"
              sx={formStyles.button}
              onClick={handleOpenVariableValue}
            >
              Criar Variável de Valor
            </ButtonCommon>
          </SecurityPath>
          <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
            Salvar
          </ButtonCommon>
        </Box>
      </Box>
      <ModalBox
        open={openVariableValue}
        onClose={handleCloseVariableValue}
        bg="white"
        maxWidth={700}
      >
        <CreateEditValue
          data={null}
          onCloseForm={handleCloseVariableValue}
          refreshData={() => {}}
        />
      </ModalBox>
      <ModalBox open={openAnswers} onClose={handleCloseAnswers} bg="white" maxWidth={800}>
        <LinkedAnswers answerId={values.id} onClose={handleCloseAnswers} />
      </ModalBox>
    </>
  );
};

export default ItemAnswerForm;

const styles = {
  buttons: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: 1,
    justifyContent: { md: "space-between" },
    alignItems: "center",
    marginBottom: 4,
    mx: 3,
  },
  buttons2: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: 1,
    justifyContent: { md: "space-between" },
    alignItems: "center",
    flex: 1,
    width: "100%",
  },
};
