import React from "react";
import {
  IconButton,
  Container,
} from "~/presentation/views/Admin/components/MessageListActions/styles";
import Checkbox from "~/presentation/components/Checkbox";
import { Delete as DeleteIcon, Refresh as RefreshIcon, Security } from "@mui/icons-material";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { Box } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";

/**
 * Actions to be shown on top of MessageList component.
 * Some very used icons and functions are refactored here.
 * Additional actions can be added by passing them to the
 * renderAfter prop.
 * In the future, it may be a good idea to add conditionals
 * and props to control default actions and icons. For instance,
 * no over-engineering needed.
 */

const MessageListActions = ({
  selectedItems,
  items,
  onChange,
  handleDelete,
  deleteSercurePath,
  handleRefresh,
  renderAfter: RenderAfter,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column-reverse", sm: "row" }, gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        {/* unselect all button */}
        <Checkbox
          sx={formStyles.icon}
          size="small"
          indeterminate={selectedItems.length > 0}
          checked={selectedItems.length > 0}
          onClick={() =>
            selectedItems.length > 0 ? onChange([]) : onChange(items.map((item) => item))
          }
        />

        {/* refresh button */}
        <IconButton onClick={handleRefresh} sx={formStyles.icon}>
          <RefreshIcon fontSize="small" />
        </IconButton>

        {/* delete button */}
        <SecurityPath securePaths={deleteSercurePath}>
          <IconButton
            sx={formStyles.icon}
            disabled={!selectedItems.length}
            onClick={() => handleDelete(selectedItems.map(({ id }) => id))}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </SecurityPath>
      </Box>

      {RenderAfter && (
        <Box sx={{ display: "flex", flex: 1, justifyContent: { xs: "center", sm: "end" } }}>
          <RenderAfter />
        </Box>
      )}
    </Box>
  );
};

export default MessageListActions;
