import { styled } from "@mui/material/styles";

const Container = styled("div")({});

const TabsHeader = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "6px",
  flexFlow: "column",

  [theme.breakpoints.up("sm")]: {
    gap: "4px",
    flexFlow: "row",
    padding: `0 max(10px, 0.6vw)`,
  },
}));

const TabPanelWrapper = styled("div")(({ theme }) => ({
  padding: "0.5rem",
  maxWidth: "100%",

  [theme.breakpoints.up("sm")]: {
    borderTop: "solid 1px #bcbcbc",
    height: "100%",
    overflowY: "auto",
  },
}));

const Tab = styled("div")(({ selected, isDisabled }) => ({
  cursor: isDisabled ? "alias" : "pointer",
  background: selected ? "#1341a1" : "transparent",
  color: isDisabled ? "#bcbcbc" : selected ? "#fafafa" : "#000",
  fontSize: "1rem",
  fontFamily: "Roboto, sans-serif",
  padding: "0.5rem 0.75rem",
  border: "solid 1px #bcbcbc",
  borderRadius: "0.25rem",
  transition: "all 0.2s ease-in-out",
  minWidth: "5rem",
  textAlign: "center",

  "&:hover": {
    background: isDisabled ? "transparent" : selected ? "#1341a1" : "#d7d7d7",
  },

  "@media (min-width: 600px)": {
    border: "unset",
    borderRadius: "unset",
    borderTop: "solid 1px #bcbcbc",
    borderLeft: "solid 1px #bcbcbc",
    borderRight: "solid 1px #bcbcbc",
    borderTopLeftRadius: "0.25rem",
    borderTopRightRadius: "0.25rem",
  },
}));

export const TabsStyles = {
  Container,
  TabsHeader,
  TabPanelWrapper,
  Tab,
};
