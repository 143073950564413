
  const companyTypes = [
    { value: 0, label: "Pessoa Jurídica (PJ)" },
    { value: 1, label: "Microempreendedor Individual (MEI)" },
    { value: 2, label: "Pessoa Física (PF)" },
    { value: 3, label: "Cooperativa" },
  ];

  export {
    companyTypes
  }