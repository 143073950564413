import React, { useState, useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../../components/PageListHeader";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import CreateEdit from "../../CompanyCategory/CreateEdit/CreateEdit";
import { Box, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { adminPerms } from "~/presentation/security/SecurePaths";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import AdminTable from "../../components/AdminTable/AdminTable";

const UpdateCitiesForm = ({ cityUseCases, companyUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog, closeSecondDialog, openSecondDialog } =
    useContext(DialogContext);

  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState({ city: null, iss: 0, totalRetentions: null });

  const handleCategoryList = () => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          data={categories}
          checkedData={values?.totalRetentions}
          title="Categorias"
          titleAdd="Criar uma nova categoria"
          handleCreate={handleCategoryModal}
          onClose={closeDialog}
          handleAdd={(values) => setValues((prev) => ({ ...prev, totalRetentions: values }))}
        />
      ),
    });
  };

  const handleCategoryModal = () => {
    openSecondDialog({
      customComponent: () => (
        <CreateEdit data={null} onCloseForm={closeSecondDialog} refreshData={getCategories} />
      ),
    });
  };

  const getCities = async () => {
    try {
      openBackdrop(true, "Carregando municípios");
      const cities = await cityUseCases.getAll({ checkPermission: true });
      setCities(cities);
    } catch (error) {
      showError(error, "Erro ao carregar municípios");
    } finally {
      openBackdrop(false);
    }
  };

  const getCategories = async () => {
    try {
      const categories = await companyUseCases.getCompanyCategories({ checkPermission: false });
      const mapperCategories = dataToSelectMapper(categories);
      setCategories(mapperCategories);
    } catch (error) {
      showError(error, "Erro ao carregar categorias");
    }
  };

  const setNewValues = async (values) => {
    try {
      openBackdrop(true, "Carregando município");
      const { id } = values;
      const city = await cityUseCases.getCityById({ id, checkPermission: true });
      setValues({ ...city, totalRetentions: city.totalRetentions ? city.totalRetentions : [] });
    } catch (error) {
      showError(error, "Erro ao carregar município");
    } finally {
      openBackdrop(false);
    }
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Atualizando município");
    try {
      const submitValues = {
        ...values,
        totalRetentions: values.totalRetentions.map((item) => {
          return {
            id: item.id,
            name: item.name,
            letter: item.letter,
          };
        }),
      };
      await cityUseCases.updateCity(submitValues);
      showSuccess("Município atualizado com sucesso");
      await getCities();
    } catch (error) {
      showError(error, "Erro ao atualizar município");
    } finally {
      openBackdrop(false);
    }
  };

  const handleDeleteSubstitute = (id) => {
    if (values.totalRetentions.length === 1) {
      setValues((prev) => ({ ...prev, totalRetentions: [] }));
    } else {
      const filtedValue = values?.totalRetentions?.filter((item) => item.id !== id);
      setValues((prev) => ({ ...prev, totalRetentions: filtedValue }));
    }
  };

  useEffect(() => {
    getCities();
    getCategories();
  }, []);

  const userHasPermitionToEdit = hasPermission([adminPerms.editCities]);

  return (
    <>
      <PageListHeader title="Atualizar Municípios" />
      <>
        <Box sx={formStyles.container}>
          <Typography sx={formStyles.title}>Município</Typography>
          <Grid container spacing={1}>
            <Grid size={{ xs: 12, md: 8 }}>
              <Input
                type="select"
                name="city"
                title="Selecionar Município"
                value={cities.find((item) => item === values.city)}
                options={cities}
                onChange={(event) => setNewValues(event)}
                getOptionLabel={(option) => `${option.name} (${option.uf})`}
              />
            </Grid>
            {values?.id && (
              <>
                <Grid size={{ xs: 12, md: 4 }}>
                  <Input
                    name="iss"
                    type="number"
                    title="Alíquota do ISS"
                    value={values.iss}
                    onChange={(e) => setValues((prev) => ({ ...prev, iss: e.target.value }))}
                    disabled={!userHasPermitionToEdit}
                  />
                </Grid>
                <Grid size={12}>
                  <Box sx={{ paddingBlock: 2 }}>
                    <PageListHeader
                      title="Retenção Total"
                      titleAdd="Adicionar retenção"
                      handleAdd={handleCategoryList}
                      fontSize={18}
                      addSecurePath={[adminPerms.editCities]}
                    />
                    <AdminTable
                      columns={[
                        { field: "name", headerName: "Nome", flex: 2, align: "center" },
                        { field: "letter", headerName: "Letra", flex: 1, align: "center" },
                        {
                          field: "actions",
                          headerName: "Ações",
                          sortable: false,
                          filterable: false,
                          align: "center",
                          renderCell: ({ row }) => (
                            <TableActions
                              handleDelete={() => {
                                handleDeleteSubstitute(row.id);
                              }}
                            />
                          ),
                        },
                      ]}
                      data={values?.totalRetentions}
                    />
                  </Box>
                </Grid>
                <Box sx={formStyles.buttonContainer}>
                  <ButtonCommon
                    sx={formStyles.button}
                    disabled={!userHasPermitionToEdit}
                    onClick={() => handleSubmit(values)}
                  >
                    Salvar
                  </ButtonCommon>
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </>
    </>
  );
};

export default UpdateCitiesForm;
