import React from "react";
import Select from "react-select";
import { PostAdd } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { SecurityPath } from "~/presentation/security/SecurityPath";

function SelectInput(props) {
  const renderIcon = () => {
    if (!props?.icon) return;

    const icon = (
      <Box sx={styles.addIcon}>
        <Tooltip
          title={props?.icon?.title || `Adicionar ${props?.title}`}
          disabled={props.disabled}
        >
          <IconButton onClick={props?.icon?.func}>
            <PostAdd sx={styles.addIcon.icon} />
          </IconButton>
        </Tooltip>
      </Box>
    );

    if (props?.icon?.securePaths && props.icon.securePaths.length > 0) {
      return <SecurityPath securePaths={props.icon.securePaths}>{icon}</SecurityPath>;
    } else {
      return icon;
    }
  };

  const disabled = props?.disabled || !props.options || props.options.length === 0;

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box sx={{ width: "100%" }}>
        <Select
          styles={styleSelectInput(props?.style, props?.error, props?.scrollbar, props?.disabled)}
          noOptionsMessage={() => "Não há opções"}
          isDisabled={disabled}
          placeholder={props?.placeholder || "Selecione " + props?.title.toLowerCase()}
          maxMenuHeight={props?.height || 250}
          {...props}
        />
      </Box>
      {renderIcon()}
    </Box>
  );
}

export default SelectInput;

const styles = {
  addIcon: {
    backgroundColor: "#001A50",
    borderRadius: "12px",
    icon: {
      color: "#fff",
      height: "22px",
      width: "22px",
    },
  },
};

const styleSelectInput = (styles, error, scrollbar = {}, disabled = false) => ({
  control: (provided, state) => ({
    width: "100%",
    border: "solid",
    borderColor: error
      ? "#d32f2f"
      : disabled
        ? "rgba(118, 118, 118, 0.5)"
        : state.isFocused
          ? "#1769aa"
          : "#7D7D7D",
    borderWidth: state.isFocused ? "2px" : "1px",
    fontSize: 14,
    fontFamily: "Montserrat",
    height: "38px",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    "&:hover": { borderColor: error ? "#a72626" : "#000" },
    ...styles,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    background: styles?.backgroundColor || "#FBFBFB",
    borderRadius: styles?.borderRadius || "12px",
    border: "1px solid #7D7D7D",
    fontSize: 14,
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "12px",
    },
    "::-webkit-scrollbar-track": {
      background: "#c1c1c1",
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    ...scrollbar,
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: styles?.color || "#000",
  }),
  option: (provided) => ({
    ...provided,
    background: "transparent",
    color: styles?.color || "#000",
    textAlign: "left",
    ":hover": {
      background: styles?.hoverColor || "#CDCDCD",
      cursor: "pointer",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: disabled ? "rgba(118, 118, 118, 0.5)" : styles?.color || "#000",
    padding: "6px 10px",
  }),
  dropdownIndicator: (styles) => ({
    color: styles.color || "#000000",
    cursor: "pointer",
    padding: "6px 10px 0px 6px",
  }),
});
