import React, { useContext, useEffect, useState } from "react";
import PageListHeader from "../components/PageListHeader";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import TableActions from "~/presentation/components/TableActions";
import CreateEditProfiles from "./CreateEditProfiles/CreateEditProfiles";
import { adminPerms } from "~/presentation/security/SecurePaths";
import AdminTable from "../components/AdminTable/AdminTable";

function UserProfiles({ userProfilesUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showSuccess, showError } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);
  const { companyId, profileId } = JSON.parse(localStorage.getItem("GT_user"));

  //Data
  const [profiles, setProfiles] = useState([]);

  const handleOpenForm = (data = null) => {
    const obj = data || {
      id: 0,
      name: "",
      isInternalUse: false,
      companyId: companyId,
      permission: {
        admins: null,
        clients: null,
        reinfs: null,
      },
    };

    openDialogBox({
      maxWidth: "md",
      fullWidth: true,
      customComponent: () => (
        <CreateEditProfiles data={obj} onCloseForm={closeDialog} refreshData={fetchData} />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados da tabela");
      const response = await userProfilesUseCases.getProfiles({
        companyId: companyId,
        chechPermission: true,
      });
      setProfiles(response);
    } catch (error) {
      showError(error, "Erro ao carregar perfis de usuário");
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderLabel = (label) => {
    const newLabel = label.slice(0, -1).toUpperCase();
    if (newLabel === "CLIENT") return "CLIENTE";
    return newLabel;
  };

  const verificarValores = (data) => {
    let result = [];

    for (let key in data) {
      if (data[key] && data[key].some((item) => item.items)) {
        result.push(renderLabel(key));
      }
    }

    if (result.length === 0) {
      return "Sem permissões";
    }

    return result.join(" + ");
  };

  const columns = [
    { field: "name", headerName: "Nome", flex: 3 },
    {
      field: "permission",
      headerName: "Permissões",
      renderCell: ({ value }) => verificarValores(value),
      align: "center",
      sortable: false,
      flex: 3,
      hide: "xs",
    },
    {
      field: "actions",
      headerName: "Ações",
      flex: 1,
      sortable: false,
      align: "center",
      renderCell: ({ row }) => {
        if (blockActions(row.id)) return;
        if (isUserProfile(row.id)) return;
        return (
          <TableActions
            handleEdit={() => handleOpenForm(row)}
            editSecurePath={[adminPerms.editUserProfile]}
            handleDelete={() => deleteDialog(row.id)}
            deleteSecurePath={[adminPerms.deleteUserProfile]}
          />
        );
      },
    },
  ];

  const blockActions = (perfilId) => {
    //Se for um dos perfis GERAIS e não for um perfil da Open, nao pode editar/eliminar
    const isGeralPerfil = [1, 2, 3, 4].includes(perfilId);
    const isOpenUser = companyId === 1;
    return isGeralPerfil && !isOpenUser;
  };

  //Se é o mesmo perfil do usuário, não pode editar/eliminar
  const isUserProfile = (perfilId) => profileId === perfilId;

  const handleDelete = async (id) => {
    try {
      openBackdrop(true, "Excluindo plano");
      await userProfilesUseCases.deleteProfile(id);
      showSuccess("Plano excluído!");
      setProfiles((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir o plano. ");
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este plano?`,
      callback: () => handleDelete(id),
    });
  };

  return (
    <>
      <PageListHeader
        bg="white"
        title="Perfis de Usuário"
        titleAccess="Novo perfil"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addUserProfile]}
      />
      <AdminTable tableExport columns={columns} data={profiles} exportFileName="perfis-usuario" />
    </>
  );
}

export default UserProfiles;
