import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { formStyles } from "../../Styles/formStyles";
import { renderText } from "./HelperFunctions";
import { Delete } from "@mui/icons-material";
import InputSearch from "./InputSearch";

function TheList({
  containerHeight,
  itemsPerPage,
  verifyChecked,
  handleToggle,
  blockCheckBoxes,
  disablePadding,
  setChecked,
  data,
  checked,
}) {
  const [filteredData, setFilteredData] = useState([]);

  //Paginação
  const [page, setPage] = useState(1);
  const handleChangePage = (_, newPage) => setPage(newPage);
  const paginatedData = filteredData?.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const showSearchAndPagination = data?.length > itemsPerPage;

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          px: 2,
          pt: 1,
        }}
      >
        {showSearchAndPagination && (
          <Box sx={{ justifyContent: "center", display: "flex", flex: 1 }}>
            <InputSearch
              setFilteredData={setFilteredData}
              setPage={setPage}
              data={data}
              sx={{ width: "min(100%, 329px)" }}
            />
          </Box>
        )}
        <Box sx={{ justifySelf: "end", ml: "auto" }}>
          <Tooltip title="Eliminar selecionados">
            <IconButton
              type="button"
              disabled={checked.length === 0}
              onClick={() => setChecked([])}
              sx={{ ...formStyles.addIcon, height: "36px", width: "36px" }}
            >
              <Delete sx={formStyles.addIcon.icon} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <List sx={[styles.list, { maxHeight: containerHeight * 0.5 }]}>
        {paginatedData.map((value, index) => {
          const isChecked = verifyChecked(value?.id);
          const text = renderText(value);
          return (
            <ListItem
              key={`${value.id}-${value.name}-${index}`}
              secondaryAction={
                <Checkbox
                  sx={formStyles.icon}
                  edge="end"
                  onChange={handleToggle(value, isChecked)}
                  checked={isChecked}
                  disabled={blockCheckBoxes && !isChecked}
                />
              }
              disablePadding={disablePadding}
              sx={styles.listItem(isChecked)}
            >
              <ListItemText sx={styles.listItemText} primary={text} />
            </ListItem>
          );
        })}
      </List>
      {filteredData?.length > itemsPerPage && (
        <Pagination
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          count={Math.ceil(filteredData.length / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      )}
    </>
  );
}

export default TheList;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  list: {
    mt: 1,
    mb: 2,
    mx: 1,
    flex: 1,
    p: 0,

    overflowY: "auto",
    borderRadius: "12px",
  },
  listItem: (checked) => ({
    backgroundColor: checked ? "#dfdfdf" : "#ededed",
    transition: "all 0.5s ease-in-out",
    pl: 2,
    minHeight: 40,
    ":first-child": { borderRadius: "12px 12px 0px 0px" },
    ":last-child": { borderRadius: "0px 0px 12px 12px" },
  }),
  listItemText: { maxWidth: "90%" },
  cleanAllButton: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  filters: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    pt: 3,
    px: 3,
  },
  addIcon: {
    backgroundColor: "#001A50",
    padding: "2px",
    borderRadius: "12px",
  },
  titleAndButtonContainer: {
    background: "#E6E6E6",
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  addButton: {
    width: "min(375px, 100%)",
    p: 3,
    mx: "auto",
  },
};
