import React, { useContext, useEffect, useState } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import blogNavbarItems from "~/presentation/views/Blog/data/blogNavbarItems";
import clientNavbarItems from "../../Client/data/clientNavbarItems";
import PostContent from "./Sections/PostContent";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
import { useParams } from "react-router-dom";

const Post = ({ blogUseCases, blogCommentUseCases }) => {
  const { user } = useContext(AppContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const postId = useParams()?.id;
  const [post, setPost] = useState();
  const [postComments, setPostComments] = useState();
  const [loading, setLoading] = useState(true);
  const { openBackdrop } = useContext(BackdropContext);

  const getPost = async () => {
    const response = await blogUseCases.getBlogById(postId);
    setPost(response);
  };

  const getPostComments = async () => {
    setLoading(true);
    const response = await blogCommentUseCases.getCommentsByBlogPostId(postId);
    response ?? setPostComments(response);
    setLoading(false);
  };

  const handleComment = async (message) => {
    openBackdrop(true, "Enviando comentário");
    const submitValues = {
      id: 0,
      publishDate: new Date(),
      commentText: message,
      userId: user.id,
      postId: post.id,
      isDraft: false,
    };
    try {
      await blogCommentUseCases.addNewComment(submitValues);
      await getPostComments();
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Comentário enviado com sucesso!",
        type: "success",
      });
    } catch (error) {
      console.error("Erro ao enviar comentário:", error);
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Comentário enviado com sucesso!",
        type: "error",
      });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
    getPost();
    getPostComments();
  }, [postId]);

  return (
    <HomeLayout
      className="blog"
      navbarItems={blogNavbarItems}
      loggedNavbarItems={clientNavbarItems()}
    >
      {() => (
        <>
          <SectionTitlePost
            redirectURL="/cliente/blog"
            title="BLOG"
            image={"/assets/multimedia-banner.png"}
            blur={0.1}
          />
          <PostContent
            post={post}
            handleComment={handleComment}
            postComments={postComments}
            loading={loading}
          />
        </>
      )}
    </HomeLayout>
  );
};

export default Post;
