export const renderText = (value) => {
  if (value?.code && value?.description && value?.detailCode) {
    return `${value.code} (${value.detailCode}) - ${value.description};`;
  }

  if (value?.code && value?.description) {
    return value.code + " - " + value.description;
  }

  if (value?.name) {
    return value.name;
  }

  if (value?.label) {
    return value.label;
  }

  if (value?.term) {
    return value.term;
  }

  if (value?.title && value?.description) {
    return value.title + " - " + value.description;
  }

  if (value?.title) {
    return value.title;
  }

  if (value?.description) {
    return value.description;
  }

  if (value?.value) {
    return value.value;
  }
};
