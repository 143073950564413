import Report from "./report";
import {
  createCommonBody,
  heightHandler,
  tableStyle,
  cellStyle,
  getBackgroundTitle,
  fontColorItem,
  gray,
  lightGray,
} from "./common";

import currency from "currency.js";
import { floatToReais } from "~/presentation/utils/reais-parser";

const report = new Report();
const noBoders = [false, false, false, false];

const createDetailBody = (data) => {
  const tables = [];

  data?.map((value) => {
    const headerTable = [
      {
        text: value.title,
        alignment: "center",
        color: "white",
        bold: true,
        fillColor: getBackgroundTitle(value.title),
        colSpan: 2,
        border: noBoders,
      },
      {},
    ];

    const rowTable = [];

    value.items?.map((item) => {
      rowTable.push([
        {
          text: item.descriptionItem,
          fontSize: 12,
          fillColor: "#F0EEEF",
          borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
          color: "#555555",
        },
        {
          text: item.value,
          alignment: "right",
          fillColor: "#fafafa",
          borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
        },
      ]);
    });

    tables.push([headerTable, ...rowTable]);
  });
  const formattedTable = tables.map((table) => {
    return {
      layout: "#fff",
      margin: [0, 10],
      table: {
        heights: heightHandler,
        widths: [435, 80],
        style: tableStyle,
        headerRows: 1,
        bodyStyles: cellStyle,
        body: table,
      },
    };
  });

  return formattedTable;
};

const createRetentionTable = (data) => {
  let inssRetentionValue = 0;
  let irrfRetentionValue = 0;
  let csllRetentionValue = 0;
  let issRetentionValue = 0;

  let isolatedIR = true;

  const body = [
    [
      {
        text: "RESUMO DAS RETENÇÕES",
        alignment: "center",
        color: fontColorItem,
        fillColor: "#dddddd",
        colSpan: 2,
        margin: 5,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
        unbreakable: true,
      },
      {},
    ],
  ];

  for (let item of data) {
    if (item.title === "INSS") {
      inssRetentionValue = item.retentionValue;
      continue;
    }

    if (item.title === "IRRF") {
      irrfRetentionValue = item.retentionValue > 0.0 ? item.retentionValue : 0;
      continue;
    }

    if (
      item.title === "CSLL, PIS/Pasep e COFINS" ||
      item.title === "IRPJ, CSLL, PIS/Pasep e COFINS"
    ) {
      if (item.title === "IRPJ, CSLL, PIS/Pasep e COFINS") {
        isolatedIR = false;
      }

      csllRetentionValue = item.retentionValue;
      continue;
    }

    if (item.title === "ISS") {
      issRetentionValue = item.retentionValue;
      continue;
    }
  }

  const retentionValues = [
    inssRetentionValue,
    irrfRetentionValue,
    csllRetentionValue,
    issRetentionValue,
  ];

  const sumAllRetentionValues = retentionValues.reduce((acc, value) => acc.add(value), currency(0));
  const netValue = currency(data[0].grossValue).subtract(sumAllRetentionValues);

  body.push([
    {
      text: "INSS",
      fontSize: 12,
      fillColor: gray,
      margin: 5,
      color: fontColorItem,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
    {
      text: floatToReais(inssRetentionValue),
      margin: 5,
      fillColor: lightGray,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      alignment: "right",
    },
  ]);

  if (isolatedIR) {
    body.push([
      {
        text: "IRRF",
        fontSize: 12,
        fillColor: gray,
        margin: 5,
        color: fontColorItem,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      {
        text: floatToReais(irrfRetentionValue),
        margin: 5,
        fillColor: lightGray,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
        alignment: "right",
      },
    ]);
  }

  body.push([
    {
      text: isolatedIR ? "CSLL, PIS/Pasep e COFINS" : "IRPJ, CSLL, PIS/Pasep e COFINS",
      fontSize: 12,
      fillColor: gray,
      margin: 5,
      color: fontColorItem,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
    {
      text: floatToReais(csllRetentionValue),
      margin: 5,
      fillColor: lightGray,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      alignment: "right",
    },
  ]);

  body.push([
    {
      text: "ISS",
      fontSize: 12,
      fillColor: gray,
      margin: 5,
      color: fontColorItem,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
    {
      text: floatToReais(issRetentionValue),
      margin: 5,
      fillColor: lightGray,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      alignment: "right",
    },
  ]);

  body.push([
    {
      text: "TOTAL DAS RETENÇÕES:",
      fontSize: 12,
      fillColor: "#dddddd",
      margin: 5,
      color: fontColorItem,
      bold: true,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
    {
      text: floatToReais(sumAllRetentionValues),
      margin: 5,
      fillColor: "#dddddd",
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      alignment: "right",
    },
  ]);

  body.push([
    {
      text: "Valor líquido a ser pago ao contratado:",
      fontSize: 12,
      fillColor: "#dddddd",
      margin: 5,
      color: fontColorItem,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
    {
      text: floatToReais(netValue),
      margin: 5,
      fillColor: "#dddddd",
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      alignment: "right",
    },
  ]);

  return body;
};

const createContent = (data = []) => {
  const body = createCommonBody(data[0]);
  const bodyDetail = createDetailBody(data);
  const retentionTable = createRetentionTable(data);

  let content = [
    { text: "Simulação de Cálculo", margin: [0, 20], style: "reportTitle" },
    {
      layout: "#fff",
      margin: [0, 15],
      table: {
        heights: [30, 10],
        widths: [175, "auto"],
        style: tableStyle,
        headerRows: 1,
        bodyStyles: cellStyle,
        body,
      },
    },
    ...bodyDetail,
    {
      layout: "#fff",
      margin: [0, 30],
      table: {
        heights: heightHandler,
        widths: [350, 175],
        style: tableStyle,
        headerRows: 1,
        bodyStyles: cellStyle,
        body: retentionTable,
      },
      unbreakable: true,
    },
  ];
  report.setContent(content);
};

const generateSimulatorReport = (data) => {
  createContent(data);
  report.generateReport();
};

export default generateSimulatorReport;
