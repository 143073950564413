import React, { useEffect, useState } from "react";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Typography, IconButton } from "@mui/material";
import ReinfTable from "~/presentation/views/Reinf/components/Table/ReinfTable";
import Grid from "@mui/material/Grid2";
import { Close } from "@mui/icons-material";

function AddMoreNfse({ onClose, protocol, validNfse, currentNfse, setData, columns }) {
  const CNPJcontratante = currentNfse[0]?.contractorCNPJ;
  const CNPJcontratado = currentNfse[0]?.businessPartnerCNPJ;

  const [possibleNfseToAdd, setPossibleNfseToAdd] = useState([]);

  //LINHAS SELECIONADAS
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const handleSetValidNfse = () => {
      let result = [...validNfse];
      const current = [...currentNfse];
      result = result.filter(
        (item) =>
          item.businessPartnerCNPJ === CNPJcontratado && item.companyCNPJ === CNPJcontratante
      );
      result = result.filter((item) => !current.some((currentItem) => currentItem.id === item.id));
      setPossibleNfseToAdd(result);
    };

    handleSetValidNfse();
  }, [currentNfse]);

  const handleAddNfseToEvent = () => {
    setData(selectedRows);
    onClose();
  };

  return (
    <Grid container spacing={2} sx={styles.content}>
      <Grid
        size={12}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography sx={styles.Title}>
          Adicionar mais docs. fiscais {protocol ? `ao evento ${protocol}` : "a este evento"}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Grid>
      <Grid size={12}>
        <ReinfTable
          columns={columns.filter((i) => !i.field.includes("amount"))}
          data={possibleNfseToAdd}
          disabledmMargin
          customStyles={{ container: { mb: 0 } }}
          setSelectedRows={setSelectedRows}
          tableSearch={false}
          rowsPage={5}
        />
      </Grid>
      {possibleNfseToAdd.length > 0 ? (
        <>
          <Grid size={{ xs: 12, sm: 4, md: 6 }}>
            <Typography sx={styles.text}>
              <b>Documentos selecionados:</b> {selectedRows.length}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 4, md: 3 }}>
            <RButton variant="outlined" onClick={onClose}>
              CANCELAR
            </RButton>
          </Grid>
          <Grid size={{ xs: 12, sm: 4, md: 3 }}>
            <RButton onClick={handleAddNfseToEvent}>ADICIONAR</RButton>
          </Grid>
        </>
      ) : (
        <Grid size={12} sx={{ display: "flex", justifyContent: "end" }}>
          <RButton variant="outlined" sx={{ width: 200 }} onClick={onClose}>
            CANCELAR
          </RButton>
        </Grid>
      )}
    </Grid>
  );
}

export default AddMoreNfse;

const styles = {
  content: {
    padding: 2,
  },
  Title: {
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "Montserrat",
    marginBlock: 2,
  },
};
