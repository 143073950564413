import React, { useEffect, useState } from "react";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import LogicAndValidation from "./LogicAndValidation/LogicAndValidation";
import { Box, Skeleton } from "@mui/material";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { reinfPerms } from "~/presentation/security/SecurePaths";
import { makeNfseUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import useHandleDocFiscais from "~/presentation/views/Reinf/Hooks/useHandleDocFiscais";

export default function RenderLayout({
  nfseData,
  serviceClassificationOptions = [],
  incomeNatureOptions = [],
  businessPartnerOptions = [],
  selectedEvent,
  refreshData,
  isEventClosed,
  handleChangeFieldValue,
  handleChangeINSSfield,
  errors,
  setErrors,
  fetchOptions,
  onUpdateStateDocs = null,
  isProtocolsTable = false,
}) {
  const apiNfsevalues = makeNfseUseCases();
  const { openBackdrop } = useBackdrop();
  const { showError, showSuccess } = useSnackbar();
  const [inputDisabled, setInputDisabled] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getNewItemsNFse } = useHandleDocFiscais();

  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const QSOAB = QSO === "A" || QSO === "B";
  const QSOC = QSO === "C";
  const QSOD = QSO === "D";

  useEffect(() => {
    if (onUpdateStateDocs && !nfseData?.itemsNFse) {
      fetchFullDoc();
    } else {
      setLoading(false);
    }
  }, []);

  const fetchFullDoc = async () => {
    try {
      setLoading(true);
      const result = await apiNfsevalues.getNfseById(nfseData.id);
      if (result) {
        const fullDoc = {
          ...result,
          itemsNFse: getNewItemsNFse(result.itemsNFse), //hook para atribuir corretamente o itemnNFSE
        };
        onUpdateStateDocs(fullDoc);
      }
    } catch (error) {
      showError(error, "Erro ao carregar os dados do documento.");
    } finally {
      setLoading(false);
    }
  };

  //UPDATE NFSE
  const handleUpdateNfse = async (data) => {
    openBackdrop(true, "Atualizando nota");
    //enviar para o back a nota sem o campo hadChanges
    const { hadChanges, isReady, ...values } = data;

    try {
      await apiNfsevalues.updateNfse(values);
      //mudar campo hadChanges no state para false
      handleChangeFieldValue({
        newItem: { hadChanges: false },
        id: values?.id,
        byPass: true,
      });
      showSuccess("Doc. Fiscal atualizado com sucesso");
      if (selectedEvent) refreshData();
    } catch (error) {
      showError(error, "Erro ao atualizar Doc. Fiscal");
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    if (nfseData?.id) handleDisableInputs(nfseData);
  }, [nfseData?.id, nfseData?.isR2010, nfseData?.isR4020]);

  const handleDisableInputs = (nfseData) => {
    setInputDisabled(() => {
      const hasEditDocPermission = hasPermission([reinfPerms.editFiscalDocument]) || true;
      if (!hasEditDocPermission) return "ALL";
      if (isProtocolsTable) return "PRINCIPAL";
      if (nfseData.isR2010) return "R2010";
      if (nfseData.isR4020) {
        if (QSOAB) return "R4020AB";
        else if (QSOC) return "R4020C";
        else if (QSOD) return "R4020D";
      }
    });
  };

  useEffect(() => {
    if (
      serviceClassificationOptions?.length === 0 ||
      incomeNatureOptions?.length === 0 ||
      businessPartnerOptions?.length === 0
    ) {
      fetchOptions();
    }
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      {loading ? (
        <>
          <Skeleton height={180} sx={{ my: 1 }} variant="rounded" />
          <Skeleton height={40} sx={{ my: 1 }} variant="rounded" />
          <Skeleton height={360} sx={{ my: 1 }} variant="rounded" />
        </>
      ) : (
        <LogicAndValidation
          values={nfseData}
          onSubmit={handleUpdateNfse}
          serviceClassificationOptions={serviceClassificationOptions}
          incomeNatureOptions={incomeNatureOptions}
          businessPartnerOptions={businessPartnerOptions}
          handleChangeINSSfield={handleChangeINSSfield}
          handleChangeFieldValue={handleChangeFieldValue}
          selectedEvent={selectedEvent}
          isEventClosed={isEventClosed}
          errors={errors}
          setErrors={setErrors}
          inputDisabled={inputDisabled}
          isProtocolsTable={isProtocolsTable}
        />
      )}
    </Box>
  );
}
