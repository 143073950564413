import React from "react";
import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ backgroundUrl }) => ({
  position: "relative",
  minHeight: "100%",
  height: "fit-content",
  background: `url("${backgroundUrl}")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  color: "#fafafa",
}));

const ModalContainer = ({ children, backgroundUrl, ...props }) => (
  <Container backgroundUrl={backgroundUrl} {...props}>
    {children}
  </Container>
);
export default ModalContainer;
