import React from "react";
import {
  Assessment,
  AssignmentInd,
  Business,
  Dashboard,
  FileCopy,
  Group,
  History,
  Send,
} from "@mui/icons-material";
import { reinfPerms } from "../security/SecurePaths";

const menus = [
  {
    id: 0,
    name: "Painel GT-Reinf",
    icon: <Dashboard />,
    link: "/reinf",
  },
  {
    id: 1,
    name: "Estabelecimento",
    icon: <Business />,
    link: "/reinf/estabelecimentos-r1000",
    securePaths: [reinfPerms.seeCompany],
  },
  {
    id: 2,
    name: "Certificado Digital",
    icon: <AssignmentInd />,
    link: "/reinf/certificado-digital",
    securePaths: [reinfPerms.seeCertificate],
  },
  {
    id: 3,
    name: "Parceiros de Negócio",
    icon: <Group />,
    link: "/reinf/parceiros-negocio",
  },
  {
    id: 4,
    name: "Documentos Fiscais",
    icon: <FileCopy />,
    link: "/reinf/painel-doc-fiscais",
    securePaths: [
      reinfPerms.seeFiscalDocument,
      reinfPerms.addFiscalDocument,
      reinfPerms.importSiafi,
      reinfPerms.importSpreadsheet,
      reinfPerms.importXml,
    ],
  },
  {
    id: 5,
    name: "Enviar EFD-REINF",
    icon: <Send />,
    link: "/reinf/eventos-efd-reinf",
    securePaths: [
      reinfPerms.sendR2010,
      reinfPerms.sendR2020,
      reinfPerms.sendR2098,
      reinfPerms.sendR2099,
      reinfPerms.sendR4010,
      reinfPerms.sendR4020,
      reinfPerms.sendR4099,
      reinfPerms.sendR9000,
      reinfPerms.sendRectification,
    ],
  },
  {
    id: 6,
    name: "Relatórios",
    icon: <Assessment />,
    link: "/reinf/relatorios",
  },
  {
    id: 7,
    name: "Histórico de Eventos",
    icon: <History />,
    link: "/reinf/historico-eventos",
  },
];
export default menus;
