import React, { useContext, useEffect, useState } from "react";
import { NewTIForm } from "./components/NewTIForm";
import { Avatar, Box, Divider, Typography as Text, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { LoadingTable } from "../../components/LoadingTable";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { painelStyles } from "../..";
import MyPainelTable from "../../components/MyPainelTable";
import DetailsIF from "./components/DetailsIF/DetailsIF";
import Filter from "./components/Filters/Filter";
import { datesValidation } from "./components/validationDates";
import { firstDayOfMonth, today } from "~/presentation/utils/constants";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { formatDateToTable } from "~/presentation/functions/date";

export const NewTaxInformation = ({ taxInformationUseCases, companyId, userUseCases }) => {
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);
  //Data
  const [taxInfo, setTaxInfo] = useState([]);
  const [taxData, setTaxData] = useState({});
  //Loading
  const [loading, setLoading] = useState({ data: true, info: true });
  //Table
  const [page, setPage] = useState(0);
  const [period, setPeriod] = useState({
    start: firstDayOfMonth,
    end: today,
  });

  const [errors, setErrors] = useState({});

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleChangeValue = (field, value) => {
    setPeriod((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  const getTaxInfo = async () => {
    datesValidation
      .validate(period, { abortEarly: false })
      .then(async () => {
        try {
          setLoading((prev) => ({ ...prev, info: true }));
          const taxResult = await taxInformationUseCases.getTaxInformationListByCompany({
            companyId,
            DateStart: period.start.toISOString().split("T")[0],
            DateEnd: period.end.toISOString().split("T")[0],
          });
          setTaxInfo(taxResult);
        } catch (error) {
          showError(error, "Erro ao carregar informações fiscais");
        } finally {
          setLoading((prev) => ({ ...prev, info: false }));
        }
      })
      .catch((errors) => {
        setErrors(
          errors?.inner?.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  const getTax = async () => {
    try {
      setLoading((prev) => ({ ...prev, data: true }));
      const result = await userUseCases.getTaxData();
      setTaxData(result);
    } catch (error) {
      showError(error, "Erro ao carregar informações fiscais");
    } finally {
      setLoading((prev) => ({ ...prev, data: false }));
    }
  };

  const handleSelect = async (id) => {
    try {
      const selectedInfo = await taxInformationUseCases.getById(id);
      if (selectedInfo) {
        selectedInfo.datetime = selectedInfo.ResponseDate;
        openModal(selectedInfo);
      }
    } catch (error) {
      showError(error, "Erro ao carregar informações");
    }
  };

  const openModal = (selectedInfo) => {
    openDialogBox({
      maxWidth: "lg",
      customComponent: () => (
        <DetailsIF
          selectedTaxInfo={selectedInfo}
          statusTranslations={statusTranslations}
          handleClose={closeDialog}
        />
      ),
    });
  };

  // translation status text values in table
  const statusTranslations = {
    Inbox: "Em análise",
    Answered: "Respondida",
    Finished: "Finalizada",
    // status iFS
  };

  useEffect(() => {
    getTax();
    getTaxInfo();
  }, []);

  const infoIF = [
    {
      title: "Nº DE IFS TOTAL",
      value: taxData?.total || 0,
    },
    {
      title: "Nº DE IF’s USADAS",
      value: taxData?.total - taxData?.balance || 0,
    },
    {
      title: "Nº DE IF’s DISPONÍVEIS",
      value: taxData?.balance || 0,
    },
  ];

  const tableColumnsIF = [
    {
      id: 0,
      field: "publishDate",
      headerName: "Data de Envio",
      align: "center",
      renderCell: ({ value }) => (value ? formatDateToTable(value) : ""),
    },
    {
      id: 1,
      field: "subject",
      headerName: "Assunto",
      align: "center",
    },
    {
      id: 2,
      field: "authorName",
      headerName: "Autor",
      align: "center",
      hide: "xs",
    },
    {
      id: 3,
      field: "email",
      headerName: "E-mail",
      align: "center",
      hide: "md",
    },
    {
      id: 4,
      field: "statusText",
      headerName: "Status",
      align: "center",
      renderCell: ({ value }) => statusTranslations[value] || value,
      hide: "xs",
    },
    {
      id: 5,
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <Button sx={styles.Button} onClick={() => handleSelect(row.id)}>
          Ver detalhes
        </Button>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2} sx={{ paddingBottom: 3 }}>
        {infoIF.map((item, index) => (
          <Grid key={index} sx={styles.item} size={{ xs: 12, sm: 4 }}>
            <Box sx={styles.ifsContainer}>
              <Text sx={painelStyles.text}>{item.title}</Text>
              <Box sx={styles.border}></Box>
              <Text sx={painelStyles.text}>{item.value}</Text>
            </Box>
          </Grid>
        ))}
      </Grid>

      <SecurityPath securePaths={[clientPerms.addFiscalInformation]}>
        <Grid container sx={painelStyles.subcontainer} alignItems="center">
          <Grid size={{ xs: 12, md: 6 }} sx={{ pr: 6 }}>
            <Box sx={styles.containerInfo}>
              <Box>
                <Text sx={styles.title}>Nova</Text>
                <Text sx={styles.headerText}>IF</Text>
              </Box>
              <Text sx={styles.subtitle}>
                Para solicitar nova Informação <br /> Fiscal (IF) utilize o formulário ao lado.
              </Text>
            </Box>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              sx={{ backgroundColor: "#EFF2F4", my: 2, maxWidth: "100%" }}
            />
            <Box sx={styles.containerInfo}>
              <Avatar
                alt="3 dias úteis"
                src="/assets/3-dias-uteis-clock.png"
                sx={{ width: 100, height: 100 }}
              />
              <Text sx={styles.text}>
                Após o envio da mensagem você receberá a resposta em até 3 (três) dias úteis.
              </Text>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <NewTIForm taxInformationUseCases={taxInformationUseCases} refreshData={getTaxInfo} />
          </Grid>
        </Grid>
      </SecurityPath>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          mt: 6,
          mb: 1,
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
        }}
      >
        <Text sx={{ ...styles.title, width: "100%", textAlign: { xs: "center", md: "left" } }}>
          Controle de IFs
        </Text>
        <Filter
          values={period}
          onChangeValue={handleChangeValue}
          errors={errors}
          onSubmit={getTaxInfo}
        />
      </Box>
      {loading?.info ? (
        <LoadingTable />
      ) : (
        <MyPainelTable
          columns={tableColumnsIF}
          data={taxInfo}
          tablePage={page}
          setTablePage={setPage}
          tableExport
          exportFileName="informacoes-fiscais"
        />
      )}
    </>
  );
};

const styles = {
  ifsContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#EFF2F4",
    padding: "20px 20px",
    borderRadius: 3,
  },
  border: {
    borderLeft: "2px solid #9EA5AA",
    height: 20,
  },
  box: {
    border: "2px solid #EFF2F4",
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  containerInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
    width: "100%",
  },

  text: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    fontFamily: "Montserrat, sans-serif",
    mt: 0,
    mb: 0,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  headerText: {
    fontSize: 70,
    fontWeight: 800,
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
    lineHeight: 1,
  },

  //Table styles
  TableHead: {
    justifyContent: "flex-start",
    fontWeight: 500,
    backgroundColor: "#EFF2F4",
  },
  TableCell: {
    fontSize: "0.9rem",
    padding: "4px",
    textAlign: "left",
    backgroundColor: "#EFF2F4",
    color: "#021148",
    fontFamily: "Montserrat",
    "&:hover": {
      color: "#0e4292",
    },
  },
  TableRow: {
    backgroundColor: "#EFF2F4",
    "&:hover": {
      backgroundColor: "#ccc",
    },
    height: "70px",
  },
  Button: {
    background: "inherit",
    paddingInline: 4,
    paddingBlock: 1,
    color: "#1976d2",
  },
  Actions: {
    "&:hover": {
      color: "#0e4292",
    },
  },
  //modal details
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    width: "100%",
    maxHeight: "80%",
    border: "solid 2px #EFF2F4",
    borderRadius: "5px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 5,
    overflow: "auto",
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: 500,
    width: "60%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  searchField: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    margin: 0,
    border: "none",
  },
};
