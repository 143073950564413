import React, { useEffect, useRef } from "react";

function Slide({
  children,
  style,
  direction = "down",
  from = "100px",
  duration = "1s",
  delay = "0s",
  ...props
}) {
  if (!children) return null;

  const elementRef = useRef(null);
  const hasAnimated = useRef(false);

  useEffect(() => {
    const element = elementRef.current;

    const observer = new IntersectionObserver(
      ([entry], observerInstance) => {
        if (entry.isIntersecting && !hasAnimated.current) {
          element.classList.add("visible");
          hasAnimated.current = true;
          observerInstance.unobserve(element);
        }
      },
      { threshold: 0.1 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  const correctDirections = ["down", "up", "left", "right"];
  const initialState = {
    up: `translateY(${from})`,
    down: `translateY(-${from})`,
    left: `translateX(${from})`,
    right: `translateX(-${from})`,
  };

  return (
    <>
      <div
        ref={elementRef}
        className="slide"
        style={{
          ...style,
          animationDelay: delay,
          transform: correctDirections.includes(direction)
            ? initialState[direction]
            : "translate(0, 0)",
        }}
        {...props}
      >
        {children}
      </div>
      <style>
        {` 
          .slide {
            opacity: 0;
          }
            
          .slide.visible {
            animation: slide-in ${duration} ease-in-out forwards;           
          }

          @keyframes slide-in {
            to {
              opacity: 1;
              transform: translate(0, 0);
            }
          }
        `}
      </style>
    </>
  );
}

export default Slide;
