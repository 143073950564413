import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  makeGTFacilHome,
  makeGTFacilSearch,
  makeGTFacilAnswer,
} from "~/main/factories/pages/gt-facil";

const GTFacilRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={makeGTFacilHome()} />
      <Route path="/pesquisa" element={makeGTFacilSearch()} />
      <Route path="/resposta" element={makeGTFacilAnswer()} />
    </Routes>
  );
};

export default GTFacilRoutes;
