import { styled } from "@mui/material/styles";
import { InputBase as MuiInputBase } from "@mui/material";
import { transparentize } from "polished";

export const SearchIconWrapper = styled("div")(({ type }) => ({
  position: "relative",
  right: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "none",
  width: type === "secondary" ? "100%" : "fit-content",
  height: type === "secondary" ? "40px" : "min-content",
  cursor: "pointer",
  marginTop: "0.2rem",
  padding: "2px 10px",
}));

export const SearchFieldContainer = styled("div")(
  ({ backgroundColor, borderRadius = "20px", type }) => ({
    position: "relative",
    borderRadius,
    background: backgroundColor ? backgroundColor : transparentize(0.95, "#383f48"),
    maxWidth: "100%",
    height: type === "secondary" ? "40px" : "min-content",
    display: "flex",
    alignItems: "center",

    "& .MuiInputBase-root": {
      width: "100%",
      padding: "0 0.2rem",
    },
  })
);

export const InputBase = styled(MuiInputBase)(({ color, type }) => ({
  "& .inputInput": {
    transition: "width 0.4s ease-in-out",
    color: color || "#383f48",
    padding: type === "secondary" ? "0 0.6rem" : "0 0.6rem",
    fontSize: type === "secondary" ? "0.7rem" : "0.9rem",

    "&::placeholder": {
      color: "inherit",
      opacity: type === "secondary" ? "0" : "1",
      fontSize: type === "secondary" ? "0.7rem" : "0.9rem",
    },

    "@media (min-width: 1250px)": {
      fontSize: type === "secondary" ? "0.8rem" : "0.9rem",

      "&::placeholder": {
        fontSize: type === "secondary" ? "0.8rem" : "0.9rem",
      },
    },
  },
}));
