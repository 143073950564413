import React from "react";
import { makeExplanatoryVideoCategories } from "~/main/factories/use-cases/explanatory-category";
import ExplanatoryCategory from "~/presentation/views/Admin/ExplanatoryCategory/ExplanatoryCategory";

function makeExplanatoryCategory(params) {
  return (
    <ExplanatoryCategory {...params} explanatoryVideoCategory={makeExplanatoryVideoCategories()} />
  );
}

export default makeExplanatoryCategory;
