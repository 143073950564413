import React from "react";
import Grid from "@mui/material/Grid2";
import {
  currentMonth,
  currentYear,
  getDateToShowFilter,
} from "~/presentation/views/Reinf/Utils/utilsDate";
import useHandleDates from "~/presentation/views/Reinf/Hooks/useHandleDates";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import ActiveFilters from "~/presentation/views/Reinf/components/ActiveFilters/ActiveFilters";
import { Box } from "@mui/material";
import { filterStyles } from "~/presentation/views/Reinf/DocPanel/Table/Filters/Filters";

function Filters({
  onClearFilters,
  filter,
  onChangeFilter,
  onFilters,
  certificates,
  activeFilters: aFilters,
}) {
  const { handleDateErrors } = useHandleDates();
  const minDate = new Date(currentYear - 10, currentMonth, 1);
  const maxDate = new Date();
  const isDateWrong = handleDateErrors(filter?.start, filter?.end);

  const currectCertsArray = Array.isArray(certificates)
    ? certificates?.map((cert) => ({
        value: cert.id,
        label: cert.name,
      }))
    : [];

  const filtersData = [
    {
      value: aFilters?.certificate
        ? currectCertsArray.find((i) => i.value === aFilters.certificate).label
        : "",
      label: "Certificado",
    },
    {
      value: aFilters?.start ? getDateToShowFilter(aFilters?.start) : null,
      label: "Data inicial",
    },
    {
      value: aFilters?.end ? getDateToShowFilter(aFilters?.end) : null,
      label: "Data final",
    },
  ];

  return (
    <Box sx={filterStyles.container}>
      <ActiveFilters onDeleteFilters={onClearFilters} filtersData={filtersData} />
      <Grid container spacing={1} sx={{ mt: 1, alignItems: "start" }}>
        <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
          <Input
            title="Certificado digital"
            style={styles.dateInput}
            type="select"
            value={currectCertsArray.find((i) => i.value === filter.certificate)}
            onChange={(cert) => onChangeFilter({ certificate: cert.value })}
            options={currectCertsArray}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
          <Input
            title="Data início"
            sx={styles.dateInput}
            minDate={minDate}
            maxDate={filter?.end || maxDate}
            type="date"
            viewType="month-year"
            value={filter?.start}
            onChange={(date) => onChangeFilter({ start: date })}
            error={isDateWrong}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
          <Input
            title="Data Final"
            sx={styles.dateInput}
            minDate={filter?.start || minDate}
            maxDate={maxDate}
            type="date"
            viewType="month-year"
            value={filter?.end}
            onChange={(date) => onChangeFilter({ end: date })}
            error={isDateWrong}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 3 }} sx={{ marginTop: "1.3rem" }}>
          <RButton disabled={isDateWrong} onClick={onFilters}>
            APLICAR
          </RButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Filters;

const styles = {
  dateInput: {
    backgroundColor: "#DCDCDC",
    borderRadius: "5px",
    height: "40px",
  },
};
