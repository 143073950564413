import { Box } from "@mui/material";
import React from "react";
import { List, ListItem, ListItemIcon } from "~/presentation/components/SelectableList/styles";

/**
 * Selectable list of items with icon and label.
 * "Selectable" means that the ListItem can appear
 * as "selected" depending on the props that we pass
 * in the "selectedItem" field.
 */

const SelectableList = ({ items, selectedItem, onClickItem, value = "alias" }) => (
  <List>
    {items.map((item, index) => (
      <ListItem
        button
        key={index}
        selected={item === selectedItem}
        onClick={() => onClickItem(item)}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          borderRadius: "12px",
          cursor: "pointer",
        }}
      >
        <Box sx={{ pt: 1 }}>
          <item.icon fontSize="small" />
        </Box>
        <Box>
          <p>{item[value]}</p>
        </Box>
      </ListItem>
    ))}
  </List>
);

export default SelectableList;
