import React from "react";
import QSOTable from "~/presentation/components/QSOTable";
import TextEditor from "~/presentation/components/TextEditor";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";
import MyDropzone from "~/presentation/components/DropUploadZone";
import Grid from "@mui/material/Grid2";

const ContentForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  categories,
  types,
  changeHandler,
}) => {
  const isLegislation = values?.type?.id === 3;
  const isMultimidia = values?.type?.id === 4;

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Dados da Publicação</Typography>
        <Grid container spacing={1}>
          <Grid size={12}>
            <Input
              type="select"
              options={types}
              onChange={(item) => onChangeValue("type", item.value)}
              name="type"
              title="Tipo da publicação"
              value={types.find((item) => item?.value?.id === values?.type?.id)}
              error={errors.type}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Input
              type="text"
              onChange={(e) => onChangeValue("title", e.target.value)}
              value={values.title}
              name="title"
              title="Título"
              error={errors.title}
            />
          </Grid>
          {isMultimidia ? (
            <Grid size={{ xs: 12, md: 6 }}>
              <Input
                type="text"
                onChange={(e) => onChangeValue("videoUrl", e.target.value)}
                name="videoUrl"
                value={values.videoUrl}
                title="Url do Vídeo"
                error={errors.videoUrl}
              />
            </Grid>
          ) : (
            <>
              <Grid size={{ xs: 12, md: 6 }}>
                <Input
                  type="text"
                  onChange={(e) => onChangeValue("source", e.target.value)}
                  value={values.source}
                  placeholder="Fonte"
                  name="source"
                  title="Fonte"
                  error={errors.source}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Input
                  type="date"
                  getHours
                  onChange={(value) => onChangeValue("publishDate", value)}
                  value={values.publishDate}
                  name="publishDate"
                  title="Data da publicação "
                  error={errors.publishDate}
                />
              </Grid>
              {isLegislation && (
                <>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Input
                      type="select"
                      options={categories}
                      onChange={(cat) => onChangeValue("category", cat)}
                      name="category"
                      title="Categoria da Legislação"
                      value={categories.find((cat) => cat.id === values?.category?.id)}
                      getOptionLabel={(option) => option.name}
                      error={errors.category}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Typography sx={formStyles.subtitle}>Áudio</Typography>
                    {!values.audioUrl ? (
                      <Box>
                        <MyDropzone
                          handleChange={(file) => {
                            changeHandler(file);
                            onChangeValue("audioUrl", file.name);
                          }}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            ...formStyles.subtitle,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <FileCopyIcon key={1} />
                          Arquivo Selecionado:
                        </Box>
                        <Typography
                          sx={{
                            ...formStyles.subtitle,
                            fontWeight: 700,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {values.audioUrl}
                          <IconButton>
                            <Tooltip title="Eliminar áudio">
                              <CloseIcon
                                color="error"
                                onClick={() => onChangeValue("audioUrl", null)}
                              />
                            </Tooltip>
                          </IconButton>
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Input
                      type="textarea"
                      name="summary"
                      title="Resumo"
                      minRows={2}
                      value={values.summary}
                      onChange={(e) => onChangeValue("summary", e.target.value)}
                      error={errors.summary}
                    />
                  </Grid>
                </>
              )}
              <Grid size={{ xs: 12, md: 6 }}>
                <Input
                  type="text"
                  name="image"
                  title="URL da imagem"
                  value={values.image}
                  onChange={(e) => onChangeValue("image", e.target.value)}
                  error={errors.image}
                />
              </Grid>
              <Grid size={12}>
                <Typography sx={formStyles.subtitle}>Quadro QSO</Typography>
                <QSOTable
                  handleChange={(table) => onChangeValue("qsoTable", table)}
                  values={values.qsoTable}
                />
              </Grid>
            </>
          )}
          <Grid size={12}>
            <Typography sx={{ ...formStyles.subtitle, color: errors.text && "#d32f2f" }}>
              Texto
            </Typography>
            <TextEditor
              initialValue={values.text}
              name="text"
              height={400}
              handleEditorChange={(content) => {
                onChangeValue("text", content);
              }}
              error={errors.text}
            />
            {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
          </Grid>
        </Grid>
      </Box>

      <Box sx={formStyles.doubleButtonContainer}>
        <ButtonCommon
          sx={formStyles.button}
          color="secondary"
          onClick={() => handleSubmit({ ...values, isDraft: true })}
        >
          Salvar como rascunho
        </ButtonCommon>
        <ButtonCommon
          sx={formStyles.button}
          onClick={() => handleSubmit({ ...values, isDraft: false })}
        >
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default ContentForm;
