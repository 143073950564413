import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../api";
import { adminPerms, clientPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";

export class ApiUserProfilesUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getProfiles({ companyId, chechPermission = true }) {
    if (chechPermission) {
      if (!hasPermission([adminPerms.seeUserProfile, clientPerms.seeUserProfile])) {
        throw new Error(securityErrorMessage("visualizar perfis de usuário"));
      }
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/UserProfile?companyId=${companyId}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data);
    }
  }

  async getProfileById(id) {
    if (!hasPermission([adminPerms.seeUserProfile, clientPerms.seeUserProfile])) {
      throw new Error(securityErrorMessage("visualizar perfis de usuário"));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/UserProfile/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async createProfile(data) {
    if (!hasPermission([adminPerms.addUserProfile, clientPerms.addUserProfile])) {
      throw new Error(securityErrorMessage("registrar perfis de usuário"));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/UserProfile`,
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateProfile(data) {
    if (!hasPermission([adminPerms.editUserProfile, clientPerms.editUserProfile])) {
      throw new Error(securityErrorMessage("editar perfis de usuário"));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/UserProfile`,
        method: "PUT",
        data: data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteProfile(id) {
    if (!hasPermission([adminPerms.deleteUserProfile, clientPerms.deleteUserProfile])) {
      throw new Error(securityErrorMessage("excluir perfis de usuário"));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/UserProfile/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async checkProfilePermissions(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/UserProfile/Permission-Profile/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
