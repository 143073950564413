import React, { useState } from "react";
import { renderTableDate } from "~/presentation/views/Reinf/Utils/utilsTableValues";
import { IconButton, Tooltip } from "@mui/material";
import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  PendingOutlined,
} from "@mui/icons-material";
import ReinfTable from "~/presentation/views/Reinf/components/Table/ReinfTable";
import { useDialog } from "~/presentation/providers/DialogProvider";
import { useEDF } from "~/presentation/views/Reinf/SendEDF/SendEDF";
import SentTableDocs from "./components/SentTableDocs";
import { tableContainer } from "~/presentation/views/Reinf/SendEDF/components/DocsTable/DocsTable";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import {
  makeNfseUseCases,
  makeReinfUseCases,
} from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import SendEvents from "~/presentation/views/Reinf/SendEDF/components/SendEvents/SendEvents";
import { EventDetailsModal } from "~/presentation/views/Reinf/SendEDF/components/SendEvents/components/EventDetailsModal";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import Grid from "@mui/material/Grid2";
import RetificationPayments from "./components/RenderPayment/RetificationPayments";
import TopTableButtons from "./components/TopTableButtons/TopTableButtons";
import { getSafeArray } from "~/presentation/utils/getSafeArray";

function EventsTable({
  currentEvents,
  allValidNfse,
  setCurrentEvents,
  setSelectedRows,
  selectedRows = [],
}) {
  const apiReinf = makeReinfUseCases();
  const apiNfse = makeNfseUseCases();
  const { openDialogBox, closeDialog } = useDialog();
  const { showError, showInfo } = useSnackbar();
  const { openCountDownLoading, closeCountDownLoading } = useBackdrop();
  const { activeFilters, isEventClosed, refreshData, certificates } = useEDF();

  const selectedEvent = activeFilters?.event;

  const [disabledAddDocs, setDisabledAddDocs] = useState(false);
  const anyStatus1 = getSafeArray(currentEvents)?.filter((item) => item?.codeStatus === 1);

  const fetchNotas = async (protocol) => {
    try {
      const response = await apiNfse.getNfseByProtocol(protocol);
      if (response) return response;
    } catch (error) {
      showError(error, "Erro no retorno dos dados");
    }
  };

  const handleSubmit = async () => {
    if (selectedEvent === "r9000") return handleOpenModal("send", selectedRows);

    if (selectedEvent === "r2010" || selectedEvent === "r4020") {
      const eventsToSend = [];
      let numberOfEvents = 0;
      const total = selectedRows?.length;
      openCountDownLoading({
        current: numberOfEvents,
        total: total,
        message: "Carregando os documentos dos protocolos",
      });
      for (const event of selectedRows) {
        if (!event.docs) {
          const nfseData = await fetchNotas(event.protocol);
          setCurrentEvents((prev) =>
            prev.map((i) => (i.protocol === event.protocol ? { ...event, docs: nfseData } : i))
          );
          eventsToSend.push({ ...event, docs: nfseData });
          numberOfEvents++;
          openCountDownLoading({ current: numberOfEvents });
        } else {
          eventsToSend.push(event);
          numberOfEvents++;
          openCountDownLoading({ current: numberOfEvents });
        }
      }
      closeCountDownLoading();
      handleOpenModal("send", eventsToSend);
    }
  };

  const handleOpenModal = (type, data) => {
    openDialogBox({
      fullWidth: true,
      maxWidth: "md",
      customComponent: () => {
        if (type === "send") {
          return (
            <SendEvents
              data={data}
              filter={activeFilters}
              certificates={certificates}
              closeModal={closeDialog}
              refreshData={refreshData}
              operation={2}
            />
          );
        } else if (type === "details") {
          return <EventDetailsModal onClose={closeDialog} eventDetails={data} />;
        }
      },
    });
  };

  const handleProtocolConsult = async () => {
    let protocolsChecked = 0;
    const responseDetails = [];

    openCountDownLoading({
      current: protocolsChecked,
      total: anyStatus1.length,
      message: "Consultando protocolos",
    });

    try {
      for (const event of anyStatus1) {
        const isProduction = event.protocol.startsWith("1");

        const params = {
          DigitalCertId: activeFilters.certificate,
          ProtocolNumber: event.protocol,
          IsProduction: isProduction,
        };

        const result = await apiReinf.getReinfConsult(params);

        if (result) responseDetails.push(result);

        protocolsChecked++;
        openCountDownLoading({ current: protocolsChecked });
      }

      if (responseDetails.length > 0) {
        handleOpenModal("details", responseDetails);
      }
    } catch (error) {
      showError(error, "Erro ao consultar protocolos");
    } finally {
      closeCountDownLoading();
    }
  };

  const eventsWithDocsToAdd = getSafeArray(currentEvents).filter((event) =>
    allValidNfse.some(
      (nfse) =>
        event.receiptNumber &&
        nfse.businessPartnerCNPJ === event.tag &&
        nfse.contractorCNPJ === event.contractorCNPJ
    )
  );

  const handleAddCNPJDocsToEvents = async () => {
    let numberOfEventsAdd = 0;
    let numberOfDocsAdd = 0;

    const updatedEvents = [];
    const notUpdatedEvents = [];

    try {
      openCountDownLoading({
        current: numberOfEventsAdd,
        total: eventsWithDocsToAdd.length,
        message: "Adicionando documentos aptos ao seu respetivo evento",
      });

      for (const event of currentEvents) {
        //Caso não tenha recibo, ignora e continua
        const hasReceipt = event.receiptNumber;
        if (!hasReceipt) {
          notUpdatedEvents.push(event);
          continue;
        }

        //Caso não tenha notas para adicionar, ignora e continua
        const hasDocsToAdd = eventsWithDocsToAdd.some((i) => i.protocol === event.protocol);
        if (!hasDocsToAdd) {
          notUpdatedEvents.push(event);
          continue;
        }

        //Caso o eventos não tenha as notas, vamos buscar
        const nfseData = Array.isArray(event?.docs) ? event.docs : await fetchNotas(event.protocol);

        //Adiconamos as notas possiveis ao evento
        const possibledDocsToAdd = allValidNfse.filter((nfse) => {
          const samePartner = nfse.businessPartnerCNPJ === event.tag;
          const sameContractor = nfse.contractorCNPJ === event.contractorCNPJ;
          return samePartner && sameContractor;
        });

        //Retiramos alguma nota que já faça parte do evento (pelo id da nota)
        const newDocs = possibledDocsToAdd.filter(
          (item) => !nfseData.some((doc) => doc.id === item.id)
        );

        numberOfDocsAdd = numberOfDocsAdd + newDocs.length;

        //Adicinamos as novas notas ao evento
        const newEvent = { ...event, docs: [...nfseData, ...newDocs], hadChanges: true };

        updatedEvents.push(newEvent);
        numberOfEventsAdd++;
        openCountDownLoading({ current: numberOfEventsAdd });
      }

      setCurrentEvents([...updatedEvents, ...notUpdatedEvents]);
      autoSelectEvents(updatedEvents);
      showInfo(
        `${numberOfDocsAdd === 1 ? "Foi adicionado 1 documento" : `Foram adicionados ${numberOfDocsAdd} documentos`} aos eventos.`
      );
    } catch (error) {
      showError(error, "Erro no retorno dos dados");
    } finally {
      setDisabledAddDocs(true);
      closeCountDownLoading();
    }
  };

  const autoSelectEvents = (eventsToSelect = []) => {
    const array = Array.isArray(eventsToSelect) ? eventsToSelect : [eventsToSelect];
    setSelectedRows((prev) => {
      // Filtra apenas os eventos que ainda não estão selecionados
      const newEvents = array.filter((i) => !prev.some((item) => item.protocol === i.protocol));
      return [...prev, ...newEvents];
    });
  };

  const renderStatus = (status, message, hadChanges) => {
    const renderIcon = () => {
      if (hadChanges) return <InfoOutlined sx={{ color: "#DCAD23" }} />;
      if (status > 2) return <ErrorOutlineOutlined color="error" />;
      if (status === 2) return <CheckCircleOutlineOutlined color="success" />;
      return <PendingOutlined color="primary" />;
    };

    const title = () => {
      if (hadChanges) return "Evento com novas atualizações para retificação";
      if (message) return message;
      if (status < 2) return "Aguardando consulta...";
      return "";
    };

    return (
      <Tooltip title={title()}>
        <IconButton>{renderIcon()}</IconButton>
      </Tooltip>
    );
  };

  const handleCheckAll = (selectedEvent, checked) => {
    if (checked) {
      setSelectedRows((prev) => prev.filter((i) => i.reinfEvent !== selectedEvent));
    } else {
      setSelectedRows((prev) => {
        const prevSelected = prev.map((i) => i.protocol);
        const newEventsToSelect = currentEvents.filter(
          (i) => !prevSelected.includes(i.protocol) && i.reinfEvent === selectedEvent
        );
        return [...prev, ...newEventsToSelect];
      });
    }
  };

  const columns = [
    { field: "protocol", headerName: "Protocolo", align: "center" },
    { field: "receiptNumber", headerName: "Nº Recibo", align: "center", hide: "xs" },
    {
      field: "sendingDate",
      headerName: "Data do envio",
      renderCell: ({ value }) => renderTableDate(value),
      align: "center",
      hide: "lg",
    },
    { field: "userSent", headerName: "Email do usuário", hide: "lg" },
    {
      field: "reinfEvent",
      headerName: "Evento",
      align: "center",
      hide: "md",
    },
    {
      headerName: "Situação",
      align: "center",
      sortable: false,
      renderCell: ({ row }) => renderStatus(row.codeStatus, row.status, row.hadChanges),
    },
  ];

  const renderCollapse = (row) => {
    if (selectedEvent === "r4010") {
      return (
        <RetificationPayments
          protocol={row.protocol}
          filter={activeFilters}
          validNfseData={allValidNfse}
          status={row.codeStatus}
          certificateData={certificates}
          receiptNumber={row.receiptNumber}
          refreshData={refreshData}
          isEventClosed={isEventClosed}
          selecetedRowsData={setSelectedRows}
          setEventsData={setCurrentEvents}
        />
      );
    }
    return (
      <SentTableDocs
        item={row}
        filter={activeFilters}
        validNfseData={allValidNfse}
        certificateData={certificates}
        refreshData={refreshData}
        isEventClosed={isEventClosed}
        selecetedRowsData={setSelectedRows}
        currentEvents={currentEvents}
        setEventsData={setCurrentEvents}
        autoSelectEvents={autoSelectEvents}
      />
    );
  };

  return (
    <Grid container spacing={1} sx={tableContainer}>
      <Grid size={12}>
        <ReinfTable
          costumFilter={
            <TopTableButtons
              selectedEvent={selectedEvent}
              currentEvents={currentEvents}
              eventsWithDocsToAdd={eventsWithDocsToAdd}
              disabledAddDocs={disabledAddDocs}
              handleAddCNPJDocsToEvents={handleAddCNPJDocsToEvents}
              handleProtocolConsult={handleProtocolConsult}
              anyStatus1={anyStatus1.length > 0}
              selectedRows={selectedRows}
              handleCheckAll={handleCheckAll}
            />
          }
          columns={columns}
          data={currentEvents}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          collapse={selectedEvent !== "r9000" && renderCollapse}
          disabledCheckBox={(row) => !row?.receiptNumber}
          disabledCheckBoxTooltip=""
          disabledmMargin
          customStyles={{ container: { mb: 0 }, searchInput: { backgroundColor: "#fff" } }}
        />
      </Grid>
      <SecurityPath securePaths={[`reinf\\envio\\${activeFilters.event}`]}>
        <Grid size={12} sx={{ display: "flex", justifyContent: "right", mt: 1 }}>
          <RButton
            sx={{ width: "200px" }}
            onClick={handleSubmit}
            disabled={selectedRows?.length === 0}
          >
            Enviar
          </RButton>
        </Grid>
      </SecurityPath>
    </Grid>
  );
}

export default EventsTable;
