import { styled } from "@mui/material";

export const QuestionContainer = styled("div")(({ vertical }) => ({
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "auto auto",
  gap: "0.375rem",

  "@media (min-width: 600px)": {
    gridTemplateColumns: "55% 45%",
    gridTemplateRows: "auto",
    alignItems: "center",
    margin: 0,

    "> div:last-child": {
      alignSelf: "flex-start",
    },

    ...(vertical && {
      gridTemplateColumns: "100%",
      gridTemplateRows: "auto auto",
      gap: "0.25rem",
    }),
  },
}));
