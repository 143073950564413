import React, { useState } from "react";
import {
  Typography as Text,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  IconButton,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useUploadXml } from "~/presentation/views/Reinf/Hooks/useUploadXml";
import SIAFI from "~/presentation/views/Reinf/components/ModalImport/components/SIAFI";
import NOTA from "~/presentation/views/Reinf/components/ModalImport/components/NOTA";
import SHEET from "~/presentation/views/Reinf/components/ModalImport/components/SHEET";
import Grid from "@mui/material/Grid2";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { reinfPerms } from "~/presentation/security/SecurePaths";

export const radioIconStyle = { color: "#1341A1", "&.Mui-checked": { color: "#1341A1" } };

export const ModalImport = ({ onClose, onRefreshData }) => {
  const { handleNOTAS, handleSHEET, handleSIAFI, handleSIAFIOB, files, setFiles } = useUploadXml();
  const [importType, setImportType] = useState("NOTA");
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO"));

  const handleChangeImportType = (e) => {
    setImportType(e.target.value);
    setFiles([]);
  };

  const handleClose = () => {
    if (Array.isArray(files) && files.length > 0) onRefreshData();
    onClose();
  };

  const renderImportType = () => {
    if (importType === "SIAFI") {
      return (
        <SIAFI
          handleSIAFI={handleSIAFI}
          handleSIAFIOB={handleSIAFIOB}
          files={files}
          setFiles={setFiles}
          handleClose={handleClose}
        />
      );
    }

    if (importType === "NOTA") {
      return (
        <NOTA
          handleNOTAS={handleNOTAS}
          files={files}
          setFiles={setFiles}
          handleClose={handleClose}
        />
      );
    }

    if (importType === "SHEET") {
      return (
        <SHEET
          handleSHEET={handleSHEET}
          files={files}
          setFiles={setFiles}
          handleClose={handleClose}
          QSO={QSO}
        />
      );
    }
  };

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2}>
        <Grid size={12} sx={styles.titleContainer}>
          <Text style={styles.title}>Importar Documentos Fiscais</Text>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>

        <Grid size={12} sx={styles.tabs}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Tipo de arquivo</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={importType}
              onChange={handleChangeImportType}
            >
              <SecurityPath securePaths={[reinfPerms.importXml]}>
                <FormControlLabel
                  value="NOTA"
                  control={<Radio sx={radioIconStyle} />}
                  label="NFS-e/NF-e (xml)"
                />
              </SecurityPath>
              <SecurityPath securePaths={[reinfPerms.importSiafi]}>
                <FormControlLabel
                  value="SIAFI"
                  control={<Radio sx={radioIconStyle} />}
                  label="Documento SIAFI"
                />
              </SecurityPath>
              <SecurityPath securePaths={[reinfPerms.importSpreadsheet]}>
                <FormControlLabel
                  value="SHEET"
                  control={<Radio sx={radioIconStyle} />}
                  label="Planilha Padrão GT (Excel)"
                />
              </SecurityPath>
              {/* <FormControlLabel value=".csv" control={<Radio />} label="Ordens Bancárias (csv)" /> */}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {renderImportType()}
    </Box>
  );
};

const styles = {
  container: {
    p: 4,
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 20,
    fontWeight: 600,
    color: "#404040",
    display: "flex",
    alignItems: "center",
  },
  titleContainer: { display: "flex", alignItems: "center", justifyContent: "space-between" },
  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
};
