import React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Close } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormHelperText } from "@mui/material";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { Box } from "@mui/system";
import { commonColors } from "~/presentation/themes/styles.App";

// ⇣⇣⇣⇣⇣⇣⇣⇣ SOBRE A ESTILIZAÇÃO DOS BOTÕES: ⇣⇣⇣⇣⇣⇣⇣⇣
// basta adicionar a props sx={{css}} no component e escrever as props de css.
function renderButtonComponent(type, props, ref) {
  // ref é necessário para conseguir usar tooltips nos buttons.
  const theme = createTheme({
    palette: {
      primary: {
        main: commonColors.reinf.primary,
      },
      secondary: {
        main: commonColors.reinf.secondary,
      },
      orange: {
        main: commonColors.reinf.contrast,
      },
      red: {
        main: commonColors.error,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: 14,
            width: "100%",
            maxHeight: "40px",
            padding: "10px",
            textTransform: "unset",
            "&:hover": {
              scale: "1.01",
            },
            "&:disabled": {
              color: "#0D0D0D!important",
              background: "#b6b6b6",
              border: "none",
            },
          },
          contained: {
            // Estilos para botões "contained"
            color: "#fff", // Cor do texto
            height: "100%",
            a: {
              color: "#fff", // Cor do texto
              fontWeight: 500,
              "&:hover": {
                color: "#fff", // Cor do texto
                textDecoration: "none",
              },
            },
          },
          outlined: {
            // Estilos para botões "outlined"
            padding: "8px 10px",
          },
        },
      },
    },
  });

  switch (type) {
    case "filterSelect":
      return (
        <Box sx={{ position: "relative" }}>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>{props.title}</p>
          <Select
            sx={FilterButtonStyle(props)}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            variant={props.variant || "outlined"}
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty // Essa prop permite que o placeholder seja mostrado mesmo quando o valor é uma string vazia
          >
            <MenuItem value="">
              <em style={styles.FilterDelete}>
                <Close fontSize="small" /> Apagar filtro
              </em>
            </MenuItem>
            <MenuItem value="" disabled sx={{ display: "none" }}>
              <em>{props.placeholder || "Selecione uma opção"}</em>

              {/*só para ter como "nome" do botão*/}
            </MenuItem>
            {props.children} {/* Sé preciso por <MenuItem> com cada opção*/}
            {props.options &&
              props.options.map(({ value, label }, index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              ))}
          </Select>
          {props.error && <FormHelperText sx={styles.ErrorText}>{props.error}</FormHelperText>}
        </Box>
      );

    case "select":
      return (
        <>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>{props.title}</p>
          <Select
            sx={FilterButtonStyle(props.styles)}
            value={props.value || props.placeholder}
            onChange={props.onChange}
            variant={props.variant || "outlined"}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value="" sx={{ display: "none" }}>
              {/* escolher usar firstTitle ou o title aqui */}
              <em style={{ fontSize: 14 }}>{props.firstTitle || props.title}</em>
            </MenuItem>
            {props.children} {/* Sé preciso por <MenuItem> com cada opção, como o default*/}
          </Select>
        </>
      );

    default:
      return (
        <ThemeProvider theme={theme}>
          <Button
            variant={props.variant || "contained"}
            color={props.color || "primary"}
            onClick={props.onClick}
            ref={ref} // Passa o ref para o botão
            {...props}
          >
            {props.children}
          </Button>
        </ThemeProvider>
      );
  }
}

// ⇡⇡⇡⇡⇡⇡ AVISO SOBRE A ESTILIZAÇÃO DOS BOTÕES NO TOPO DO DOCUMENTO ⇡⇡⇡⇡⇡⇡

function RButton({ type, ...props }) {
  const permission = props?.securePaths ? hasPermission(props?.securePaths) : true;
  if (!permission) {
    return;
  }
  return renderButtonComponent(type, props);
}

export default RButton;

const styles = {
  FilterDelete: {
    fontSize: 14,
    opacity: 0.8,
    fontWeight: "400",
    color: "#d32f2f",
    display: "flex",
    alignItems: "center",
  },
  ErrorText: {
    color: "#d32f2f",
    fontFamily: "Montserrat",
    position: "absolute",
  },
};

const FilterButtonStyle = ({ error, sx }) => ({
  fontFamily: "Montserrat",
  fontSize: 14,
  width: "100%",
  height: "40px",
  backgroundColor: "#DCDCDC",
  boxShadow: "none",
  color: "#222222",
  cursor: "pointer",
  ".MuiOutlinedInput-notchedOutline ": {
    border: error ? "2px solid #d32f2f" : 0,
  },
  ".MuiInputBase-input": {
    minHeight: "0 !important",
    lineHeight: "0 !important",
    backgroundColor: "transparent",
  },
  "&.MuiOutlinedInput-root": {
    border: 0,
  },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    boxShadow: "none",
    border: error ? "2px solid #d32f2f" : 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: error ? "2px solid #d32f2f" : 0,
    boxShadow: "none",
  },
  "&.MuiOutlinedInput-root.Mui-active .MuiOutlinedInput-notchedOutline": {
    border: error ? "2px solid #d32f2f" : 0,
    boxShadow: "none",
  },
  ".MuiSelect-icon": {
    color: "#BBBBBB",
  },
  ...sx,
});
