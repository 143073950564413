import React from "react";
import CommonTable from "~/presentation/components/Table/CommonTable";
import { Box } from "@mui/material";

function AdminTable({ disabledMargin = false, ...props }) {
  const styles = {};
  return (
    <Box sx={{ m: disabledMargin ? 0 : { xs: 1, lg: 3 } }}>
      <CommonTable {...props} costumStyles={{ ...props?.costumStyles, ...styles }} />
    </Box>
  );
}

export default AdminTable;
