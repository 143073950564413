import React, { useState, useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ItemCompany } from "./ItemCompany";
import { SendR1000 } from "../SendR1000/SendR1000";
import TableActions from "~/presentation/components/TableActions";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";
import Table from "~/presentation/views/Reinf/components/Table/ReinfTable";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";
import { reinfPerms } from "~/presentation/security/SecurePaths";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { makeItemCompanyUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";

export const ListItemsR1000 = ({ matrizCompany, onGetItemCompanySuccess }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //TEMS R1000
  const itemCompanyUseCases = makeItemCompanyUseCases();
  const [itemCompany, setItemCompany] = useState([]);

  const [loading, setLoading] = useState(true);

  const handleOpenModal = (type, item = null, operationNumber) => {
    openDialogBox({
      maxWidth: "md",
      customComponent: () => {
        if (type === "r1000") {
          return (
            <SendR1000
              closeModal={closeDialog}
              ItemCompanyId={item?.id || null}
              refreshData={() => {
                getItemCompany();
                onGetItemCompanySuccess?.();
              }}
              {...getModalSendR1000Props(operationNumber)}
            />
          );
        }
        if (type === "modal") {
          return (
            <ItemCompany
              closeModal={closeDialog}
              itemCompanyId={item?.id || null}
              refreshData={getItemCompany}
              item={item || null}
            />
          );
        }
      },
    });
  };

  const getItemCompany = async () => {
    setLoading(true);
    try {
      const result = await itemCompanyUseCases.listItemCompany();
      setItemCompany(result);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro carregar informações," + error,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItemCompany();
  }, []);

  const handleDeleteItem = (item, isR1000) => {
    //caso isR1000 false vai direto delete no back
    if (!isR1000) {
      handleDelete(item.id);
    } else {
      //caso seja true ele abre modal envio R1000 exclusão
      // + delete no back após como callback
      handleOpenModal("r1000", item, 3);
    }
  };

  const handleDelete = async (itemId) => {
    openBackdrop(true, "Excluindo");
    try {
      await itemCompanyUseCases.deleteItemCompany(itemId);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Deletado com sucesso!",
        type: "success",
      });
      setItemCompany((prev) => prev.filter((item) => item.id !== itemId));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao deletar: " + (error.message || "Erro desconhecido"),
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  // Objetivo: chamar o SendR1000 e após o envio bem sucedido
  // fazer a alteração no back da informação com a função enviada na callBackFnc
  const getModalSendR1000Props = (operationNumber) => {
    switch (operationNumber) {
      case 1:
        return { operationNumber: 1, callBackFnc: false };
      case 3:
        return { operationNumber: 3, callBackFnc: (operation) => handleDelete(operation) };
      default:
        return { operationNumber: 1, callBackFnc: false };
    }
  };

  const columns = [
    {
      field: "startDate",
      headerName: "Início da Validade",
      renderCell: ({ value }) => formatDateToBrazilian(value) || "-",
      align: "center",
    },
    {
      field: "endDate",
      headerName: "Fim da Validade",
      renderCell: ({ value }) => formatDateToBrazilian(value) || "-",
      align: "center",
    },

    {
      field: "",
      headerName: "Estabelecimento",
      align: "center",
      renderCell: () => matrizCompany?.name,
      hide: "md",
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenModal("modal", row)}
          handleSend={() => handleOpenModal("r1000", row, 1)}
          sendSecurePath={[reinfPerms.sendR1000]}
          handleDelete={() => handleDeleteItem(row, row.isR1000)}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderSectionPage
        title="Evento R-1000"
        handleAdd={() => handleOpenModal("modal")}
        sx={{ backgroundColor: "transparent", paddingInLine: 3, paddingTop: 3, paddingBottom: 0 }}
      />
      <Table data={itemCompany} columns={columns} loading={loading} />
    </>
  );
};
