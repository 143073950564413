import React, { useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  ListItemIcon,
  Collapse,
  List,
  ListItemButton,
  Box,
  Typography as Text,
} from "@mui/material";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { Link } from "react-router-dom";

const DashboardDrawer = ({
  open,
  setOpen,
  menuItems,

  mobile = false,
  enableMouseEvents = true,
}) => {
  const [subMenuOpen, setSubMenuOpen] = useState(null);

  const toggleSubMenu = (menuId) => {
    if (subMenuOpen === menuId) setSubMenuOpen(null);
    else setSubMenuOpen(menuId);
  };

  const getSmallLogo = "/assets/logo_gt_mini.png";
  const getLogo = "/assets/logo_gt.png";

  return (
    <Box
      sx={styles.container}
      onMouseEnter={() => enableMouseEvents && setOpen(true)}
      onMouseLeave={() => enableMouseEvents && [setOpen(false), setSubMenuOpen(null)]}
    >
      <ListItemButton
        component={Link}
        to="/cliente"
        onClick={() => {
          if (mobile) setOpen(false);
        }}
        sx={styles.toolbar}
        className="scrollbar-white"
      >
        <Box
          component="img"
          sx={{ ...styles.logo, opacity: !open && !mobile ? 1 : 0 }}
          src={getSmallLogo}
          alt="logo GT"
        />
        <Box
          component="img"
          sx={{ ...styles.logo, opacity: open || mobile ? 1 : 0 }}
          src={getLogo}
          alt="logo GT"
        />
      </ListItemButton>
      <List className="scrollbar-gray" sx={styles.list}>
        {menuItems.map((item, index) => {
          const securePaths = item?.securePaths ?? [];
          if (securePaths.length > 0) {
            if (!hasPermission(securePaths)) {
              return null;
            }
          }
          if (item.link) {
            return (
              <ListItemButton
                component={Link}
                to={item.link}
                key={index}
                sx={styles.button}
                onClick={() => {
                  if (mobile) setOpen(false);
                }}
              >
                <ListItemIcon sx={{ color: "inherit" }}>
                  {item.icon ? <>{item.icon}</> : <FiberManualRecordIcon fontSize="small" />}
                </ListItemIcon>
                <Text sx={styles.listItemText}>{item.name}</Text>
              </ListItemButton>
            );
          } else {
            return [
              <ListItemButton sx={styles.button} onClick={() => toggleSubMenu(item.id)} key={index}>
                <ListItemIcon sx={{ color: "inherit" }}>
                  {item.icon ? <>{item.icon}</> : <FiberManualRecordIcon fontSize="small" />}
                </ListItemIcon>
                <Text sx={styles.listItemText}>{item.name}</Text>
              </ListItemButton>,

              <Collapse
                in={subMenuOpen === item.id}
                timeout="auto"
                unmountOnExit
                key={index + "-collapse"}
              >
                <List component="div" disablePadding sx={styles.list}>
                  {item.subItems.map((subItem, index) => {
                    const securePaths = subItem?.securePaths ?? [];
                    if (securePaths.length > 0) {
                      if (!hasPermission(securePaths)) {
                        return null;
                      }
                    }
                    return (
                      <ListItemButton
                        component={Link}
                        to={subItem.link}
                        key={index}
                        sx={{ ...styles.button, paddingLeft: 3.5 }}
                        onClick={() => {
                          if (mobile) setOpen(false);
                        }}
                      >
                        <ListItemIcon sx={styles.drawerListIcon}>
                          {item.icon ? (
                            <>{subItem.icon}</>
                          ) : (
                            <FiberManualRecordIcon sx={styles.drawerListIcon} />
                          )}
                        </ListItemIcon>
                        <Text sx={styles.listItemText}>{subItem.name}</Text>
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>,
            ];
          }
        })}
      </List>
    </Box>
  );
};

export default DashboardDrawer;

const styles = {
  drawerListIcon: {
    color: "#000",
    fontSize: 5,
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    alignItems: "center",
    paddingInLine: 1,
    minHeight: 65,
    marginTop: 8,
    overflowX: "hidden",
    overflowY: "auto",
    "@media (min-width: 600px)": {
      marginTop: 0,
    },
  },
  logo: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    height: "34px",
    MozTransition: "opacity .2s ease-out",
    WebkitTransition: "opacity .2s ease-out",
    OTransition: "opacity .2s ease-out",
    transition: "opacity .2s ease-out",
  },
  container: {
    height: {
      xs: "calc(100% - 120px)",
      md: "calc(100% - 72px)",
    },
  },
  button: {
    height: "50px",
    paddingLeft: 3,
    "&:hover": {
      transform: "scale(1.02)",
      background: "transparent",
    },
  },
  listItemText: {
    maxWidth: "calc(100% - 56px)",
    whiteSpace: "pre-wrap",
    fontFamily: "Montserrat, sans-serif",
    fontSize: 13,
    fontWeight: 500,
    minWidth: "40ch",
  },
  list: {
    maxHeight: "100%",
    overflowX: "hidden",
  },
};
