import React from "react";
import { makeIssAnswerUseCases } from "~/main/factories/use-cases/iss-answers";
import { makeTypeAnswerUseCases } from "~/main/factories/use-cases/types-answer";
import IssAnswer from "~/presentation/views/Admin/IssAnswer/IssAnswer";

export const makeIssAnswerTable = (params) => (
  <IssAnswer
    {...params}
    makeIssAnswerUseCases={makeIssAnswerUseCases()}
    typesAnswerUseCases={makeTypeAnswerUseCases()}
  />
);
