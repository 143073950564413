import { adminPerms, clientPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
import { apiURL } from "../../usecases/api";
import { hasPermission } from "~/presentation/security/SecurityPath";

export class ApiBlogCategoryUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getBlogCategories({ checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeBlogCategory, clientPerms.seeBlog])) {
        return new Error(securityErrorMessage("visualizar categorias de blog."));
      }
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/category`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getPostsByCategory(categoryId) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/category/${categoryId}/posts`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteBlogCategory(id) {
    if (!hasPermission([adminPerms.deleteBlogCategory])) {
      return new Error(securityErrorMessage("excluir categorias de blog."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/category/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async createBlogCategory(data) {
    if (!hasPermission([adminPerms.addBlogCategory])) {
      return new Error(securityErrorMessage("criar categorias de blog."));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/category`,
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateBlogCategory(data) {
    if (!hasPermission([adminPerms.editBlogCategory])) {
      return new Error(securityErrorMessage("editar categorias de blog."));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/category/`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getBlogCategoryById(id) {
    if (!hasPermission([adminPerms.seeBlogCategory])) {
      return new Error(securityErrorMessage("visualizar categorias de blog."));
    }
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/category/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getBlogSeries() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/CategorySerie`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
