import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import ContentForm from "./Form";
import { getRealTimeZoneDate } from "~/presentation/utils/getTimeZoneData";
import validationSchema from "./Form/validationSchema";
import randomString from "~/presentation/utils/randomString";
import PageListHeader from "../../components/PageListHeader";
import { useUploadFile } from "~/presentation/hooks/useUploadFile";

const initialValues = {
  id: 0,
  title: "",
  type: {
    id: 1,
    name: "Notícia",
  },
  category: null,
  publishDate: new Date(),
  image: "",
  source: "",
  videoUrl: "",
  text: "",
  summary: "",
  audioUrl: null,
  isDraft: false,
  qsoTable: {
    inss1: [],
    inss2: [],
    inss3: [],
    irrf1: [],
    irrf2: [],
    csllPisCofins: [],
    iss1: [],
    iss2: [],
  },
};

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  categories,
  types,
  getTypes,
  getCategories,
  contentUseCases,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { uploadFile } = useUploadFile();
  const isCreate = !data;

  //input value and errors
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    if (data?.id) {
      setValues({
        ...data,
        publishDate: getRealTimeZoneDate(data.publishDate),
      });
    }
  }, [data]);

  useEffect(() => {
    if (categories.length === 0) {
      getCategories();
    }
    if (types.length === 0) {
      getTypes();
    }
  }, []);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const changeHandler = (file) => {
    const renamedFile = new File([file], `${randomString()}-${file.name}`);
    setSelectedFile(renamedFile);
  };

  const handleValidation = (values) => {
    const submitValues = {
      ...values,
      category: values?.type?.id != 3 ? null : values.category?.id ? values.category : {},
    };
    validationSchema(values?.type?.id)
      .validate(submitValues, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(submitValues);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };
  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} conteúdo`);

    let submitValues = {
      ...values,
      publishDate: values.publishDate.toISOString(),
      summary: values?.type?.id === 3 ? values?.summary : null,
    };

    if (selectedFile || !values.audioUrl) {
      const url = await uploadFile(selectedFile, selectedFile?.name);
      if (url || !values.audioUrl) {
        submitValues.audioUrl = url ? url : values.audioUrl;
      }
    }

    try {
      if (isCreate) {
        await contentUseCases.registerContent(submitValues);
      } else {
        await contentUseCases.updateContent(submitValues);
      }
      showSuccess(`Conteúdo ${isCreate ? "criado" : "editado"} com sucesso!`);
      onCloseForm();
      await refreshData();
    } catch (error) {
      showError(error, `Erro ao ${isCreate ? "criar" : "editar"} conteúdo.`);
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} Conteúdo`}
        handleBack={onCloseForm}
      />
      {values && (
        <ContentForm
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
          categories={categories}
          types={types}
          getCategories={getCategories}
          changeHandler={changeHandler}
        />
      )}
    </>
  );
}

export default CreateEdit;
