import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import randomString from "~/presentation/utils/randomString";

export function useUploadFile() {
  const { showError } = useSnackbar();

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION_BUCKET,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };

  const s3Client = new S3Client({
    region: config.region,
    credentials: {
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
    },
    forcePathStyle: true,
  });

  const importFile = (event, imageRef) => {
    const [file] = event.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = imageRef;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      const renamedFile = new File([file], `${randomString()}-${file.name}`);
      return renamedFile;
    }
  };

  const uploadFile = async (file, fileName) => {
    if (!file || !fileName) {
      showError(new Error("Ficheiro e nome do ficheiro são obrigatórios"), "Erro ao fazer upload.");
      return null;
    }

    try {
      const arrayBuffer = await file.arrayBuffer();

      const params = {
        Bucket: config.bucketName,
        Key: fileName,
        Body: arrayBuffer,
        ContentType: file.type,
        ACL: "public-read",
      };

      const command = new PutObjectCommand(params);
      const data = await s3Client.send(command);

      if (data.$metadata?.httpStatusCode === 200) {
        const fileUrl = `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${fileName}`;
        return fileUrl;
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      showError(error, "Erro ao fazer upload.");
      return null;
    }
  };

  return { importFile, uploadFile };
}
