import React from "react";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

const typeOptions = [
  { value: 0, label: "Usuário" },
  { value: 1, label: "IF" },
  { value: 2, label: "REINF" },
  { value: 3, label: "GTFacil" },
];

export const AddtionalCompanyPlanForm = ({ values, onChangeValue, handleSubmit, errors }) => {
  return (
    <>
      <Box sx={formStyles.container}>
        <Grid container spacing={1}>
          <Grid size={12}>
            <Input
              type="text"
              title="Empresa"
              name="companyName"
              value={values?.companyName}
              error={errors?.companyName}
              disabled
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Input
              type="number"
              title="Quantidade"
              name="amount"
              placeholder="Quantidade"
              value={values?.amount}
              onChange={(e) => onChangeValue("amount", e.target.value)}
              error={errors?.amount}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Input
              type="amount"
              title="Preço"
              name="price"
              value={values?.price}
              onChange={(value) => onChangeValue("price", value)}
              error={errors?.price}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Input
              type="select"
              title="Tipo de Plano"
              name="type"
              options={typeOptions}
              placeholder={"Selecione o Tipo de Plano..."}
              value={typeOptions.find((item) => item.value === values?.type)}
              onChange={(type) => onChangeValue("type", type.value)}
              height={100}
              error={errors?.type}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Input
              type="date"
              title="Data de validade"
              name="limitDate"
              value={values?.limitDate}
              onChange={(e) => onChangeValue("limitDate", e)}
              error={errors?.limitDate}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};
