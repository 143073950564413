import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import CommonTable from "~/presentation/components/Table/CommonTable";
import { PostAdd } from "@mui/icons-material";

const ReinfTable = ({
  disabledmMargin = false,
  titleTable = null,
  handleAdd = null,
  customStyles = {},
  ...props
}) => {
  const input = {
    background: "#DCDCDC",
    border: "none",
    borderRadius: "5px",
    padding: "0px 10px",
    height: "40px",
    width: "min(300px, 100%)",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontFamily: "Montserrat",
    outline: "none",
    boxShadow: "none",
  };

  const styles = {
    container: { ...customStyles.container },
    table: { backgroundColor: "#D9D9D9" },
    searchInput: {
      ...input,
      ...customStyles.searchInput,
    },
    exportButton: {
      button: {
        ...input,
      },
    },
    tableHeader: {
      backgroundColor: "#1341A1",
    },
    headerCell: {
      color: "#ffffff",
      position: "relative",
      height: 60,
      "&:not(:first-of-type)::after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: "10%",
        bottom: "10%",
        left: 0,
        width: "2px",
        backgroundColor: "#142F68",
      },
    },
    sortIcon: {
      color: "#fff",
    },
    checkboxIcon: {
      color: "#1341A1",
    },
    bodyRow: {
      borderBlock: "2px solid #f2f2f2",
      borderInLine: "2px solid #D9D9D9",
      "&:hover": {
        backgroundColor: "#C9C9C9",
      },
      "&.changed": {
        backgroundColor: "#FAE4A2",
        border: "2px solid #F3B80C",
      },
    },
    collapseRow: {
      backgroundColor: "#F2F2F2",
      ...customStyles.collapseRow,
    },
    bodyCell: {
      py: 1,
      px: 2,
    },
    pagination: {
      backgroundColor: "#f2f2f2",
    },
  };

  return (
    <>
      <Box sx={[disabledmMargin ? {} : { mx: { xs: 1, md: 3 }, my: 1 }]}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {titleTable && (
            <Typography variant="h6" component="div" sx={{ pr: 1 }}>
              {titleTable}
            </Typography>
          )}
          {handleAdd && (
            <Box
              sx={{
                backgroundColor: "#1341A1",
                padding: "2px",
                borderRadius: "12px",
              }}
            >
              <Tooltip title={"Cadastrar novo"}>
                <IconButton onClick={handleAdd}>
                  <PostAdd sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        <CommonTable {...props} costumStyles={styles} />
      </Box>
    </>
  );
};

export default ReinfTable;
