import React from "react";
import { makeContactUseCases } from "~/main/factories/use-cases/contact";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";
import Dash from "~/presentation/views/Client/ClientHome/index";

export const makeClientHome = (params) => (
  <Dash
    contactUseCases={makeContactUseCases()}
    companyUseCases={makeCompanyUseCases()}
    {...params}
  />
);
