export const getReinfTableFiltersDefinition = (event) => {
  const filtersMap = {
    r2010: {
      event: "r2010",
      filterFields: [
        {
          type: "select",
          title: "Retenção de INSS",
          placeholder: "Selecione o filtro",
          fieldValue: "retInss",
          fieldOnChange: (eventValue) => ({ retInss: eventValue }),
          filterOptions: [
            { value: "containsRetInss", label: "Com retenção" },
            { value: "doesNotContainRetInss", label: "Sem retenção" },
          ],
        },
      ],
      initialValues: {
        retInss: "",
      },
    },
    r4020: {
      event: "r4020",
      filterFields: [
        {
          type: "select",
          title: "Retenções",
          placeholder: "Selecione o filtro",
          fieldValue: "retention",
          fieldOnChange: (eventValue) => ({ retention: eventValue }),
          filterOptions: [
            { value: "containsRetentions", label: "Com retenções" },
            { value: "doesNotContainRetentions", label: "Sem retenções" },
          ],
        },
      ],
      initialValues: {
        retention: "",
      },
    },
  };

  return filtersMap[event];
};

export const handleR2010Filters = (retention, data) => {
  return data.filter((item) => {
    if (!retention) return true;
    return retention === "contains" ? item.amountINSS > 0 : item.amountINSS === 0;
  });
};

export const handleR4020Filters = (retention, data) => {
  return data.filter((item) => {
    if (!retention) return true;

    const retentionValues = [
      item.amountIR,
      item.amountCSLL,
      item.amountPIS,
      item.amountCOFINS,
      item.amountAggregate,
    ];

    return retention === "contains"
      ? retentionValues.some((value) => value > 0)
      : retentionValues.every((value) => value === 0);
  });
};
