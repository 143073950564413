import React from "react";
import { FileOpenOutlined, InfoOutlined, Update } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import {
  renderTableCNPJ,
  renderTableCurrency,
  renderTableDate,
} from "~/presentation/views/Reinf/Utils/utilsTableValues";
import { getDocType } from "~/presentation/views/Reinf/Utils/utilsReinf";

const renderFileIcon = (R2010, R4020, nfseChanged) => {
  const sent = R2010 || R4020;

  if (nfseChanged) {
    return (
      <Tooltip title="Existem dados que ainda não foram salvos">
        <InfoOutlined sx={{ color: "#DCAD23" }} />
      </Tooltip>
    );
  }
  if (sent) {
    return (
      <Tooltip title={`Doc. Fiscal já enviado no ${R2010 ? "R-2010" : "R-4020"}.`}>
        <FileOpenOutlined color="action" fontSize="small" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Doc. Fiscal ainda não foi enviado.">
        <Update color="action" fontSize="small" />
      </Tooltip>
    );
  }
};

export const legalPersonColumns = [
  {
    headerName: "Status",
    field: "status",
    sortable: false,
    align: "center",
    renderCell: ({ row }) => renderFileIcon(row.isR2010, row.isR4020, row?.hadChanges),
    hide: "xs",
  },
  { headerName: "NºDoc", field: "nFseNumber", align: "center", hide: "md" },
  {
    headerName: "CNPJ do Contratado",
    field: "businessPartnerCNPJ",
    align: "center",
    renderCell: ({ value }) => renderTableCNPJ(value),
    hide: "md",
  },
  { headerName: "Nome do Contratado", field: "businessPartnerName" },
  {
    headerName: "Data de emissão",
    field: "dateOfIssue",
    align: "center",
    renderCell: ({ value }) => renderTableDate(value),
    hide: "md",
  },
  {
    headerName: "Data de pagamento",
    field: "dateOfPayment",
    align: "center",
    renderCell: ({ value }) => renderTableDate(value),
    hide: "md",
  },
  {
    headerName: "Valor da Operação",
    field: "amountBC",
    align: "center",
    renderCell: ({ value }) => renderTableCurrency(value),
    hide: "lg",
  },
  // {
  //   headerName: "Ret. INSS",
  //   field: "amountINSS",
  //   align: "center",
  //   renderCell: ({ value }) => renderTableCurrency(value),
  //   hide: "xl",
  // },
  // {
  //   headerName: QSOAB ? "Ret. Agregada" : "Ret. IR",
  //   field: QSOAB ? "amountAggregate" : "amountIR",
  //   align: "center",
  //   renderCell: ({ value }) => renderTableCurrency(value),
  //   hide: "xl",
  // },
  // ...(QSOD
  //   ? [
  //       {
  //         headerName: "Ret. CSRF",
  //         field: "amountCSRF",
  //         renderCell: ({ value }) => renderTableCurrency(value),
  //         align: "center",
  //         hide: "xl",
  //       },
  //     ]
  //   : []),
  {
    headerName: "CNPJ do Contratante",
    field: "contractorCNPJ",
    renderCell: ({ value }) => renderTableCNPJ(value),
    hide: "lg",
    align: "center",
  },
  {
    headerName: "Tipo de Doc.",
    field: "typeDoc",
    sortable: false,
    renderCell: ({ value }) => getDocType(value),
    hide: "lg",
    align: "center",
  },
];

export const phisicalPersonColumns = [
  {
    field: "businessPartnerCNPJ",
    headerName: "CPF beneficiário",
    renderCell: ({ value }) => renderTableCNPJ(value),
    hide: "xs",
  },
  { field: "businessPartnerName", headerName: "Beneficiário" },
  {
    field: "dateRegister",
    headerName: "Período de apuração",
    align: "center",
    renderCell: ({ value }) => renderTableDate(value),
    hide: "md",
  },
  {
    field: "datePayment",
    headerName: "Data do fato gerador",
    align: "center",
    renderCell: ({ value }) => renderTableDate(value),
    hide: "md",
  },
  {
    field: "totalAmount",
    headerName: "Valor bruto",
    align: "center",
    renderCell: ({ value }) => renderTableCurrency(value),
    hide: "md",
  },
  {
    field: "taxedAmount",
    headerName: "Valor rend. tributável",
    align: "center",
    renderCell: ({ value }) => renderTableCurrency(value),
    hide: "lg",
  },
  {
    field: "amountIR",
    headerName: "Valor IRRF",
    align: "center",
    renderCell: ({ value }) => renderTableCurrency(value),
    hide: "lg",
  },
  {
    field: "companyCNPJ",
    align: "center",
    headerName: "CPNJ contratante",
    renderCell: ({ value }) => renderTableCNPJ(value),
    hide: "lg",
  },
];
