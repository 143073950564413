import React, { useContext, useState } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import PageListHeader from "../../../components/PageListHeader";
import TableActions from "~/presentation/components/TableActions";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import Grid from "@mui/material/Grid2";
import InBulkHandler, {
  onInBulkDelete,
} from "~/presentation/components/ListComponent/components/InBulkHandler";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import AdminTable from "../../../components/AdminTable/AdminTable";

const ServiceForm = ({ values, onChangeValue, handleSubmit, errors, cnaes }) => {
  const { showInfo } = useContext(SnackbarContext);
  const groups = [
    { label: "RG", value: "RG" },
    { label: "EMT1", value: "EMT1" },
    { label: "EMT2", value: "EMT2" },
    { label: "ERF", value: "ERF" },
    { label: "ERO", value: "ERO" },
    { label: "ELO", value: "ELO" },
  ];

  //MODAL Lista de CNAE
  const [openCnaeList, setOpenCnaeList] = useState(false);
  const handleOpenCnaeList = () => setOpenCnaeList(true);
  const handleCloseCnaeList = () => setOpenCnaeList(false);

  const handleDeleteInBulk = (codes = []) => {
    onInBulkDelete({
      codes: codes,
      data: values?.cnaes,
      onChange: (values) => onChangeValue("cnaes", values),
      showUpdateCount: (items) =>
        showInfo(items === 1 ? "Foi removido 1 CNAE" : `Foram removidos ${items} CNAES`),
    });
  };

  const handleDeleteCnae = (params) => {
    if (values.cnaes.length === 1) {
      onChangeValue("cnaes", []);
    } else {
      const filtedValue = values?.cnaes?.filter((item) => item.id !== params.row.id);
      onChangeValue("cnaes", filtedValue);
    }
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Serviço</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 4, md: 3 }}>
            <Input
              type="text"
              name="code"
              title="Código"
              value={values.code}
              onChange={(e) => onChangeValue("code", e.target.value)}
              error={errors.code}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 8, md: 9 }}>
            <Input
              type="text"
              name="description"
              title="Descrição"
              value={values.description}
              onChange={(e) => onChangeValue("description", e.target.value)}
              error={errors.description}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="select"
              name="group"
              title="Grupo"
              options={groups}
              value={groups.find((item) => item.value === values.group)}
              onChange={(option) => onChangeValue("group", option.value)}
              error={errors.group}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }} sx={formStyles.checkBoxContainer}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values.isDefaultISS}
                  onChange={() => onChangeValue("isDefaultISS", !values.isDefaultISS)}
                  name="isDefaultISS"
                />
              }
              label="Resposta padrão do ISS"
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={formStyles.container}>
        <PageListHeader
          title="Cnaes"
          titleAdd="Adicionar Cnaes"
          handleAdd={handleOpenCnaeList}
          fontSize={22}
        />
        <AdminTable
          columns={[
            { field: "code", headerName: "Código", flex: 1, align: "center" },
            { field: "detailCode", headerName: "Detalhe", flex: 1, align: "center" },
            { field: "description", headerName: "Descrição", flex: 6, hide: "md" },
            {
              field: "actions",
              headerName: "Ações",
              sortable: false,
              filterable: false,
              align: "center",
              flex: 1,
              renderCell: (params) => (
                <TableActions handleDelete={() => handleDeleteCnae(params)} />
              ),
            },
          ]}
          costumFilter={
            <Box sx={{ minWidth: { sm: 360 } }}>
              <InBulkHandler
                onChange={handleDeleteInBulk}
                data={values?.cnaes}
                action="delete"
                field="code"
                nameField="CNAES"
              />
            </Box>
          }
          data={values?.cnaes}
        />
        <ModalBox open={openCnaeList} onClose={handleCloseCnaeList} bg="white" maxWidth={600}>
          <ListComponent
            disablePadding={false}
            data={cnaes}
            checkedData={values?.cnaes}
            title="Cnaes"
            onClose={handleCloseCnaeList}
            handleAdd={(values) => onChangeValue("cnaes", values)}
            itemsPerPage={5}
            costumFilter={{
              title: "InBulk",
              field: "code",
              nameField: "CNAES",
            }}
          />
        </ModalBox>
      </Box>

      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default ServiceForm;
