import React from "react";
import Grid from "@mui/material/Grid2";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";

function TableTabs({
  tableType = "",
  setTableType = () => {},
  allInvalidNfse = [],
  allEvents = [],
  allValidNfse = [],
  setSelectedRows = () => {},
  isR4010 = false,
}) {
  const tabsData = [
    {
      value: "sendDoc",
      label: `${isR4010 ? "PAGAMENTOS" : "ENVIAR DOCUMENTO"} (${allValidNfse.length})`,
    },
    {
      value: "correctDoc",
      label: `${isR4010 ? "PAGAMENTOS INAPTOS" : "CORRIGIR INAPTAS"} (${allInvalidNfse.length})`,
    },
    {
      value: "events",
      label: `${isR4010 ? "PAGAMENTOS ENVIADOS" : "EVENTOS ENVIADOS"} (${allEvents.length})`,
    },
  ];

  return (
    <Grid container spacing={2} sx={{ py: 1, px: { xs: 1, md: 3 } }}>
      {tabsData.map(({ value, label }, index) => {
        const isActive = tableType === value;
        return (
          <Grid key={index} size={{ xs: 12, md: 4 }}>
            <RButton
              onClick={() => {
                setSelectedRows([]);
                setTableType(value);
              }}
              color={isActive ? "primary" : "secondary"}
              variant={isActive ? "contained" : "outlined"}
              sx={{
                p: 3,
                border: isActive ? "1px solid #142F68" : "1px solid #CCCCCC",
                transition: "all 0.3s ease-in-out",
              }}
            >
              {label}
            </RButton>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default TableTabs;
