import React from "react";
import { makeAdditionalCompanyPlanUseCases } from "~/main/factories/use-cases/additional-company-plan";
import { AditionalCompanyPlan } from "~/presentation/views/Admin/AdditionalCompanyPlan/AditionalCompanyPlan";

export const makeAdditionalCompanyPlanList = (params) => {
  return (
    <AditionalCompanyPlan
      {...params}
      additionalCompanyPlanUseCases={makeAdditionalCompanyPlanUseCases()}
    />
  );
};
