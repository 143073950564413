import { Undo } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { makeItemAnswerUseCases } from "~/main/factories/use-cases/item-answer";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

function LinkedAnswers({ answerId, onClose }) {
  const itemAnswerUseCases = makeItemAnswerUseCases();
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showError } = useContext(SnackbarContext);

  useEffect(() => {
    if (answerId) getAnswers();
  }, [answerId]);

  const getAnswers = async () => {
    setLoading(true);
    try {
      const result = await itemAnswerUseCases.getLinkedAnswers(answerId);
      if (result) setAnswers(result);
    } catch (error) {
      showError(error, "Erro ao carregar respostas vinculadas");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleContainer}>
        <Typography sx={formStyles.title}>Respostas vinculadas</Typography>
        <IconButton onClick={onClose}>
          <Undo sx={formStyles.icon} />
        </IconButton>
      </Box>
      {loading ? (
        <Skeleton height={300} variant="rounded" />
      ) : (
        <Box sx={styles.listContainer}>
          {answers.length === 0 ? (
            <Typography sx={[formStyles.subtitle, { textTransform: "none" }]}>
              Este item não possui respostas vinculadas.
            </Typography>
          ) : (
            answers.map(({ nome = "", descricao = "" }, i) => (
              <Typography key={i} sx={formStyles.subtitle}>
                <strong>{nome}</strong> - {descricao}.
              </Typography>
            ))
          )}
        </Box>
      )}
    </Box>
  );
}

export default LinkedAnswers;

const styles = {
  container: {
    padding: 3,
    backgroundColor: "#E6E6E6",
    borderRadius: "12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 2,
    mb: 1,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    minHeight: 300,
    maxHeight: 500,
    overflowY: "auto",
    pr: 1,
  },
};
