import React from "react";
import useWindowSize from "~/presentation/hooks/useWindowSize";
import { Box, Typography } from "@mui/material";

const Logo = () => (
  <Box component="img" sx={styles.img} src="/assets/logo_gt_branco_mini.png" alt="logo gt" />
);

const Text1 = () => (
  <Box sx={styles.textWrapper}>
    <Typography sx={{ ...styles.text, fontWeight: 200 }}>Este é um serviço da </Typography>
    <Typography sx={styles.text}> Open Soluções Tributárias</Typography>
  </Box>
);

const Text2 = () => (
  <Box sx={styles.textWrapper}>
    <Typography sx={styles.text}>2020 © Gestão Tributária </Typography>
    <Typography sx={{ ...styles.text, fontWeight: 200 }}> Todos os direitos reservados</Typography>
  </Box>
);

const Footer = () => {
  const { width: windowWidth } = useWindowSize();
  if (windowWidth < 768) {
    return (
      <Box sx={styles.container}>
        <Logo />
        <Box>
          <Text1 />
          <Text2 />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={styles.container}>
        <Text1 />
        <Logo />
        <Text2 />
      </Box>
    );
  }
};

export default Footer;

const styles = {
  container: {
    minHeight: "80px",
    height: "fit-content",
    background: "linear-gradient(180deg, #1341a1 0%, #000 100%)",
    padding: "32px 6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    "@media (min-width: 768px)": {
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
  },
  textWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "0 40px",
    gap: "8px",
  },
  text: {
    fontFamily: "Montserrat",
    color: "#fafafa",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
  },
  img: {
    width: "36px",
    height: "37px",
    margin: "0 36px",
  },
};
