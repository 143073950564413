import { Box } from "@mui/material";
import React from "react";
import { painelStyles } from "~/presentation/views/Client/MyPanel";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";

function Filter({ values, onChangeValue, errors, onSubmit }) {
  return (
    <Box sx={{ ...painelStyles.flex, justifyContent: "end", alignItems: "end" }}>
      <Box sx={{ width: { xs: "100%", md: "200px" } }}>
        <Input
          type="date"
          title="Data Inicial"
          name="start"
          value={values?.start}
          onChange={(date) => onChangeValue("start", date)}
          error={errors?.start}
          sx={{ ...painelStyles.input, border: "none" }}
        />
      </Box>
      <Box sx={{ width: { xs: "100%", md: "200px" } }}>
        <Input
          type="date"
          title="Data Final"
          name="end"
          value={values?.end}
          onChange={(date) => onChangeValue("end", date)}
          error={errors?.end}
          sx={{ ...painelStyles.input, border: "none" }}
        />
      </Box>
      <ButtonCommon
        sx={{
          borderRadius: 3,
          height: "54px",
          width: { xs: "100%", md: "200px" },
        }}
        onClick={onSubmit}
      >
        Aplicar
      </ButtonCommon>
    </Box>
  );
}

export default Filter;
