import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";

function BackButton() {
  const navigate = useNavigate();
  return (
    <Button sx={styles.backButton} onClick={() => navigate(-1)}>
      <ArrowBackIosNew sx={{ fontSize: 18 }} /> Voltar
    </Button>
  );
}

export default BackButton;

const styles = {
  backButton: {
    whiteSpace: "nowrap",
    borderRadius: 4,
    color: "#444",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    paddingBlock: 0.2,
    paddingInline: 1.2,
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    gap: 1,
    "&:hover": {
      background: "#444",
      color: "#fff",
    },
  },
};
