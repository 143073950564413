import { formatDateToTable } from "~/presentation/functions/date";

export const statsTableColumns = {
  search: [
    {
      field: "date",
      headerName: "Data",
      filterable: false,
      renderCell: ({ value }) => (value ? formatDateToTable(value) : "-"),
      flex: 1,
      align: "center",
      style: { xs: "hidden" },
    },
    { field: "userName", flex: 1, align: "center", headerName: "Usuário" },
    {
      field: "companyType",
      flex: 1,
      align: "center",
      headerName: "Tipo de Empresa",
      hide: "md",
    },
    { field: "cnae", flex: 1, align: "center", headerName: "CNAE" },
    // { field: "term", flex: 1, align: "center", headerName: "Termo", hide: "lg" },
    {
      field: "categoryName",
      flex: 1,
      align: "center",
      headerName: "Categoria",
      hide: "xs",
    },
    // { field: "branch", flex: 1, align: "center", headerName: "Filial", hide: "md" },
    { field: "simples", flex: 1, align: "center", headerName: "Simples", hide: "md" },
    {
      field: "cityHire",
      flex: 1,
      align: "center",
      headerName: "Cidade Contratante",
      hide: "lg",
    },
    {
      field: "cityService",
      flex: 1,
      align: "center",
      headerName: "Cidade Prestadora",
      hide: "lg",
    },
    {
      field: "cityContractor",
      flex: 1,
      align: "center",
      headerName: "Cidade Contratada",
      hide: "lg",
    },
  ],
  "search-summary": [
    { field: "userName", flex: 1, align: "center", headerName: "Usuário" },
    {
      field: "fisrtSearch",
      flex: 1,
      align: "center",
      headerName: "Primeira busca",
      filterable: false,
      renderCell: ({ value }) => (value ? formatDateToTable(value) : "-"),
    },
    {
      field: "lastSearch",
      flex: 1,
      align: "center",
      headerName: "Última busca",
      filterable: false,
      renderCell: ({ value }) => (value ? formatDateToTable(value) : "-"),
      hide: "xs",
    },
    { field: "companyName", flex: 1, align: "center", headerName: "Empresa", hide: "md" },
    { field: "amount", flex: 1, align: "center", headerName: "Quantidade" },
    { field: "percent", flex: 1, align: "center", headerName: "Porcentagem", hide: "md" },
  ],
  "content-access": [
    { field: "title", flex: 1, align: "center", headerName: "Título" },
    { field: "amount", flex: 1, align: "center", headerName: "Quantidade" },
  ],
};
