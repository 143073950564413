import * as Yup from "yup";

export default Yup.object({
  isLegislation: Yup.boolean(),
  isDispensedEROS: Yup.boolean(),
  isSpecificSubstituition: Yup.boolean(),
  isDispensedCPOM: Yup.boolean(),
  cpom: Yup.string()
    .test("cpom", "Selecione um cpom", (value) => typeof value !== "number")
    .required("Selecione o CPOM"),
  cityId: Yup.number(),
  items: Yup.array()
    .required("Selecione ao menos um item de resposta")
    .min(1, "Selecione ao menos um item de resposta"),
});
