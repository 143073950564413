import * as Yup from "yup";
import removeMask from "~/presentation/functions/removeMask";
import { validarEmail } from "~/presentation/functions/emailValidator";

export default Yup.object({
  categoryId: Yup.string().required("Campo obrigatório!"),
  name: Yup.string().required("Campo obrigatório!"),
  cnpj: Yup.string().min(14).required("Campo obrigatório!"),
  address: Yup.string().required("Campo obrigatório!"),
  cep: Yup.string().min(8, "Digite um CEP válido!").required("Campo obrigatório!"),
  city: Yup.string().required("Campo obrigatório!"),
  state: Yup.string().required("Campo obrigatório!"),
  email: Yup.string()
    .test("email", "Email inválido!", (value) => validarEmail(value))
    .required("Campo obrigatório!"),
  phone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .required("Campo obrigatório!")
    .min(10, "Digite um número válido"),
  mobilePhone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(11, "Digite um número válido"),
  plan: Yup.string().required("Campo obrigatório!"),
  status: Yup.boolean(),
});
