import React from "react";
import Grid from "@mui/material/Grid2";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { IconButton, Typography as Text } from "@mui/material";
import { Close } from "@mui/icons-material";

function RenderEventForm({
  fields = [],
  closeModal,
  eventName,
  handleSubmit,
  numberOfDocs = null,
  costumNumberOfDocsText = null,
  sendButtonLabel = null,
}) {
  return (
    <Grid container spacing={2}>
      <Grid
        size={12}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Text sx={[styles.text, styles.title]}>DADOS DO EVENTO {eventName}</Text>
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </Grid>
      {fields.map((props, index) => (
        <Grid key={index} size={{ xs: 12, sm: 6 }}>
          <Input {...props} />
        </Grid>
      ))}
      {costumNumberOfDocsText ? (
        costumNumberOfDocsText
      ) : numberOfDocs ? (
        <Grid size={12}>
          <Text sx={styles.text}>
            Foram selecionados {numberOfDocs} documento(s) fiscais para envio.
          </Text>
        </Grid>
      ) : null}
      <Grid size={12} sx={{ display: "flex", justifyContent: "end", gap: 1, mt: 2 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={closeModal}>
          Cancelar
        </RButton>
        <RButton sx={{ width: "150px" }} onClick={handleSubmit}>
          {sendButtonLabel ? sendButtonLabel : `Enviar ${eventName}`}
        </RButton>
      </Grid>
    </Grid>
  );
}

export default RenderEventForm;

const styles = {
  title: {
    fontSize: "24px",
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
};
