import { Search } from "@mui/icons-material";
import { Input } from "@mui/material";
import React, { useRef } from "react";
import { renderText } from "./HelperFunctions";
import { inputStyles } from "../ListComponent";

function InputSearch({ setFilteredData, setPage, data = [], sx = {} }) {
  const searchRef = useRef();

  const handleSearch = (searchText) => {
    setPage(1);
    const lowercasedSearchText = searchText.toLowerCase();
    setFilteredData(() => {
      return data.filter((value) => {
        const searchableString = renderText(value).toLowerCase();
        return searchableString.includes(lowercasedSearchText);
      });
    });
  };

  return (
    <Input
      type="text"
      placeholder="Pesquisar..."
      ref={searchRef}
      onChange={(event) => handleSearch(event.target.value)}
      disableUnderline
      endAdornment={<Search />}
      sx={{ ...inputStyles, ...sx }}
    />
  );
}

export default InputSearch;
