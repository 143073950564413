import { apiURL } from "../../usecases/api";
export class ApiDependentUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getDependent(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Dependent?businessPartnerId=${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDependentTypes() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Dependent/Types`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDependentById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Dependent/${id}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Falhou: " + response.status);
      }
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  }

  async updateDependent(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Dependent`,
        method: "PUT",
        data, 
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async createDependent(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Dependent`,
        method: "POST",
        data,
      });

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async deleteDependent(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Dependent/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
