import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { formatDateToTable } from "~/presentation/functions/date";
import CreateEdit from "./CreateEdit/CreateEdit";
import { adminPerms } from "~/presentation/security/SecurePaths";
import AdminTable from "../components/AdminTable/AdminTable";

const Content = ({ contentUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);

  //Data
  const [contents, setContents] = useState([]);

  //Select
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "lg",
      customComponent: () => (
        <CreateEdit
          data={data}
          onCloseForm={closeDialog}
          refreshData={fetchData}
          contentUseCases={contentUseCases}
          categories={categories}
          types={types}
          getTypes={getTypes}
          getCategories={getCategories}
        />
      ),
    });
  };

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando o conteúdo");
      const fetchedContents = await contentUseCases.getAllContents();
      setContents(fetchedContents);
    } catch (error) {
      showError(error, "Erro ao carregar conteúdos");
    } finally {
      openBackdrop(false);
    }
  };

  const columns = [
    {
      field: "type",
      headerName: "Tipo",
      renderCell: ({ value }) => value?.name || "",
      flex: 1,
      hide: "md",
    },
    {
      field: "title",
      headerName: "Título",
      flex: 4,
    },
    {
      field: "publishDate",
      headerName: "Data",
      filter: false,
      renderCell: ({ value }) => (value ? formatDateToTable(value) : "-"),
      flex: 1,
      align: "center",
      hide: "md",
    },
    {
      field: "isDraft",
      headerName: "Rascunho",
      renderCell: ({ value }) => (value ? "Sim" : "Não"),
      flex: 1,
      align: "center",
      hide: "md",
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenForm(row)}
          editSecurePath={[adminPerms.editContent]}
          handleDelete={() => deleteDialog(row.id)}
          deleteSecurePath={[adminPerms.deleteContent]}
        />
      ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo conteúdo");

    try {
      await contentUseCases.deleteContent(id);
      showSuccess("Conteúdo excluído!");
      setContents((prev) => prev.filter((item) => item.id !== id)); //eliminar conteúdo da tabela
    } catch (error) {
      showError(error, "Erro ao excluir o conteúdo");
    }

    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este conteúdo?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getTypes = async () => {
    try {
      openBackdrop(true, "Carregando tipos de conteúdo");
      const typ = await contentUseCases.getContentTypes();
      const types = typ.map((type) => ({
        value: type,
        label: type.name,
      }));
      setTypes(types);
    } catch (error) {
      showError(error, "Erro ao carregar tipos de conteúdo");
    } finally {
      openBackdrop(false);
    }
  };

  const getCategories = async () => {
    try {
      openBackdrop(true, "Carregando categorias");
      const categories = await contentUseCases.getLegislationCategories();
      setCategories(categories);
    } catch (error) {
      showError(error, "Erro ao carregar categorias");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title="Conteúdo"
        handleAdd={() => handleOpenForm()}
        addSecurePath={[adminPerms.addContent]}
      />
      <AdminTable tableExport columns={columns} data={contents} exportFileName="conteudo" />
    </>
  );
};

export default Content;
