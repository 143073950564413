import React, { useState } from "react";
import { makeReinfUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import useCreateLotes from "~/presentation/views/Reinf/Hooks/useCreateLotes";
import RenderEventForm from "~/presentation/views/Reinf/SendEDF/components/SendEvents/components/RenderEventForm";
import {
  isOperationoptions,
  isProductionoptions,
} from "~/presentation/views/Reinf/SendEDF/components/SendEvents/components/Options";

function SendNfseEvents({
  companyId,
  companyInfor,
  data,
  certificateObj,
  operation,
  competence,
  isProduction,
  openDetailsModal,
  closeModal,
  event,
  month,
  year,
  eventName,
}) {
  const apiSendReinf = makeReinfUseCases();
  const { openCountDownLoading, closeCountDownLoading } = useBackdrop();
  const { showError } = useSnackbar();
  const { getLotes } = useCreateLotes();

  const isOperation2 = operation === 2; //retificação

  const [eventData, setEventData] = useState({
    companyId: companyId,
    digitalCertId: certificateObj?.id,
    isProduction: isProduction,
    operation: operation,
    calculationDate: competence,
  });

  const onChangeValue = (field, value) => setEventData((prev) => ({ ...prev, [field]: value }));

  //caso seja retificação não criamos lotes, porém o data será um array de eventos
  const allLotes = isOperation2 ? data : getLotes(data, ["businessPartnerCNPJ", "contractorCNPJ"]);
  const allDocsLength = data?.length || 0;

  const handleSubmit = async () => {
    let responseDetails = [];
    let docsSent = 0;

    openCountDownLoading({
      current: docsSent,
      total: allDocsLength,
      message: `Enviando ${eventName}`,
    });

    for (const lote of allLotes) {
      const receipt = isOperation2 ? { receiptNumber: lote.receiptNumber } : {}; //só para retificação

      //no caso de retificação (operation 2) cada evento (lote) tem um array docs com as suas nfses
      //no caso de inclusão (operation 1) cada lote é um array de nfses para criar evento
      const nfseArray = isOperation2 ? lote?.docs : lote;

      const updatedEventData = {
        ...eventData,
        ...receipt,
        docs: nfseArray.map((nfse) => ({
          id: nfse.id,
          serviceProviderCnpj: nfse.businessPartnerCNPJ,
        })),
      };

      docsSent = docsSent + nfseArray.length;

      try {
        const response = await apiSendReinf.sendEvent(updatedEventData, event);
        openCountDownLoading({ current: docsSent });
        responseDetails = [...responseDetails, ...response];
      } catch (error) {
        showError(error, "Erro ao efectuar envio");
      }
    }

    closeCountDownLoading();

    if (responseDetails.length > 0) openDetailsModal(responseDetails);
  };

  const eventInputs = [
    { type: "text", field: "companyName", value: companyInfor?.name, title: "Estabelecimento" },
    {
      type: "text",
      field: "digitalCertId",
      value: certificateObj?.name,
      title: "Certificado Digital",
    },
    {
      type: "select",
      title: "Tipo de ambiente",
      name: "isProduction",
      options: isProductionoptions,
      onChange: (option) => onChangeValue("isProduction", option.value),
      value: isProductionoptions.find((option) => option.value === eventData.isProduction),
      height: 100,
    },
    ...(eventData.operation !== 1
      ? [
          {
            type: "select",
            title: "Tipo de Operação",
            name: "operation",
            options: isOperationoptions,
            value: isOperationoptions.find((option) => option.value === eventData.operation),
            disabled: true,
          },
        ]
      : []),
    {
      type: "text",
      title: "Data da competência",
      value: `${month}/${year}`,
    },
  ];

  return (
    <RenderEventForm
      fields={eventInputs}
      closeModal={closeModal}
      eventName={eventName}
      handleSubmit={handleSubmit}
      numberOfDocs={allDocsLength}
    />
  );
}

export default SendNfseEvents;
