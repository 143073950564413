import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import pdfMake from "pdfmake/build/pdfmake";
import { formatDateToTable } from "../functions/date";

const dateArray = ["fisrtSearch", "lastSearch", "date"];

export const exportCSV = (data, columns, fileName) => {
  if (!data || !columns) return;
  let csvContent = columns?.map((col) => col.headerName).join(",") + "\n";

  data?.forEach((row) => {
    let rowContent = columns
      ?.map((col) => {
        const cellValue = row[col.field];
        return cellValue
          ? dateArray.includes(col.field)
            ? formatDateToTable(cellValue)
            : String(cellValue)
          : "";
      })
      .join(",");
    csvContent += rowContent + "\n";
  });

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `${fileName}.csv`);
};

export const exportXLSX = (data, columns, fileName) => {
  if (!data || !columns) return;
  const workSheet = XLSX.utils.json_to_sheet(
    data?.map((row) => {
      let rowData = {};
      columns.forEach((col) => {
        const cellValue = row[col.field];
        rowData[col.field] = cellValue
          ? dateArray.includes(col.field)
            ? formatDateToTable(cellValue)
            : String(cellValue)
          : "";
      });
      return rowData;
    })
  );

  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "Página 1");
  const wbout = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const blob = new Blob([wbout], { type: "application/octet-stream" });
  saveAs(blob, `${fileName}.xlsx`);
};

export const exportPDF = (data, columns, fileName) => {
  if (!data || !columns) return;

  const tableBody = [];

  // Cabeçalho da tabela
  tableBody.push(
    columns.map((col) => ({ text: col.headerName, bold: true, fillColor: "#dddddd" }))
  );

  // Linhas da tabela
  data.forEach((row) => {
    tableBody.push(
      columns.map((col) => {
        const cellValue = row[col.field];
        return {
          text: cellValue
            ? dateArray.includes(col.field)
              ? formatDateToTable(cellValue)
              : String(cellValue)
            : "",
          alignment: typeof row[col.field] === "number" ? "right" : "left",
          noWrap: false,
        };
      })
    );
  });

  const docDefinition = {
    pageOrientation: "landscape", // Deixa o PDF na horizontal para mais espaço
    pageSize: "A4", // Mantém o PDF no tamanho correto
    content: [
      { text: "Relatório de Dados", style: "header", margin: [0, 0, 0, 10] },
      {
        table: {
          headerRows: 1,
          widths: columns.map(() => "auto"), // Deixa as colunas ajustáveis
          body: tableBody,
        },
        layout: {
          hLineWidth: () => 0.5,
          vLineWidth: () => 0.5,
        },
      },
    ],
    styles: {
      header: { fontSize: 14, bold: true, alignment: "center" },
      tableHeader: { bold: true, fontSize: 10, fillColor: "#dddddd" },
      tableCell: { fontSize: 9 },
    },
    defaultStyle: {
      fontSize: 9,
    },
  };

  pdfMake.createPdf(docDefinition).download(`${fileName}.pdf`);
};
