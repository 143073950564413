import React, { useContext, useEffect, useState } from "react";
import { SecurityPermissionForm } from "./Form";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";
import { makeSecurityPermissionUseCases } from "~/main/factories/use-cases/security-permission";

const initialValues = {
  id: 0,
  permission: "",
  description: "",
};

const RegisterEdit = ({ data, onCloseForm, refreshData }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const securityPermisionUseCases = makeSecurityPermissionUseCases();
  const isCreate = !data;

  //input value and errors
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (data?.id) {
      setValues(() => {
        return {
          id: data.id,
          permission: data.permission,
          description: data.description,
        };
      });
    }
  }, [data]);

  const handleValidation = (values) => {
    const submitValues = { ...values };

    validationSchema
      .validate(submitValues, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(submitValues);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} permissão`);
    try {
      if (isCreate) {
        await securityPermisionUseCases.createSecurityPermission(values);
      } else {
        await securityPermisionUseCases.updateSecurityPermission(values);
      }
      showSuccess(`Permissão ${isCreate ? "cadastrado" : "editado"} com sucesso!`);
      onCloseForm();
      await refreshData();
    } catch (error) {
      showError(error, "Erro");
    } finally {
      openBackdrop(false);
    }
  };
  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Cadastrar" : "Editar"} Permissão`}
        handleBack={onCloseForm}
        titleBack="Voltar para permissões de segurança"
      />
      {values && (
        <SecurityPermissionForm
          handleSubmit={handleValidation}
          values={values}
          onChangeValue={handleChangeValue}
          errors={errors}
          isCreate={isCreate}
        />
      )}
    </>
  );
};

export default RegisterEdit;
