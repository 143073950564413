import { styled } from "@mui/material/styles";

export const InputWrapper = styled("div")(() => ({
  background: "#e0e0e0",
  display: "flex",
  borderRadius: "4px",
  alignItems: "center",
  overflow: "hidden",
  paddingLeft: "max(16px, 2vw)",
  cursor: "text",
  height: "4rem",
  width: "24rem",
  position: "relative",
  marginLeft: "0.5rem",

  "& input": {
    border: "none",
    outline: "none",
    background: "transparent",
    width: "75%",
    fontSize: "clamp(0.8rem, 1.4vw, 40px)",
  },

  "& svg": {
    position: "absolute",
    width: "max(2vw, 20px)",
    right: "30%",
    height: "auto",
    zIndex: 2,
    cursor: "pointer",
  },

  "& button": {
    outline: "none",
    border: "none",
    background: "#ff6829",
    color: "#fafafa",
    width: "25%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
  },
}));

export const ImgSearch = styled("img")(() => ({
  position: "absolute",
  width: "max(2vw, 10px)",
  right: "30%",
  height: "auto",
  zIndex: 2,
  cursor: "pointer",
}));
