import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { resetScroll } from "~/presentation/functions/resetScroll";
import { usePaginatedState } from "~/presentation/hooks/usePaginatedState";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
import { Box, CircularProgress, Container } from "@mui/material";
import { Filters } from "./Sections/ContentSection/components/Filters/Filters";
import CardsList from "~/presentation/components/CardsList";
import { MostAccessed } from "./Sections/MostAccessedSection/MostAccessedSection";
import { ContentCard } from "~/presentation/views/Client/Legislation/Home/Sections/ContentSection/components/ContentCard/ContentCard";
import Grid from "@mui/material/Grid2";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

const DEFAULT_IMAGE = "/assets/bglegislacao.png";
const TELEGRAM_IMG = "/assets/canalTelegram.jpg";
const ITEMS_PER_PAGE = 9;

const Legislation = ({ contentUseCases }) => {
  const navigate = useNavigate();
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [mostAccessedLegislation, setMostAccessedLegislation] = useState([]);

  const getContent = (queryParams) => {
    return contentUseCases.getLegislation({
      IsSummaryOnly: false,
      ...queryParams,
    });
  };

  const {
    handleFilter,
    handleSearch,
    handlePage,
    numberOfPages,
    isLoading,
    content,
    page,
    scrollToRef,
  } = usePaginatedState({
    getData: getContent,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const saveData = async () => {
    try {
      const response = await contentUseCases.getMostAccessedLegislation({
        limit: 3,
        pageIndex: 1,
        fieldOrder: "views",
        orderBy: "desc",
        IsSummaryOnly: true,
      });

      setMostAccessedLegislation(response.contents);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar as publicações mais acessadas.",
        type: "error",
      });
    }
  };

  const goToPost = (id) => {
    navigate("/cliente/conteudo-selecionado/legislacao/post/" + id);
  };

  useEffect(() => {
    saveData();
    resetScroll();
  }, []);

  const getFilters = async () => {
    return contentUseCases.getLegislationCategories();
  };

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {({ mobile }) => (
        <>
          <SectionTitlePost
            redirectURL="/cliente/conteudo-selecionado/legislacao"
            title="Legislação"
            onSearch={handleSearch}
            image={DEFAULT_IMAGE}
            searchPlaceholder="Pesquisar Norma"
            blur={0.65}
          />

          <Container maxWidth="lg" ref={scrollToRef}>
            {!content ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <>
                <Filters onFilter={handleFilter} getFilters={getFilters} />
                <Grid container spacing={3}>
                  <Grid size={{ xs: 12, md: 8 }}>
                    <CardsList
                      content={content}
                      numberOfPages={numberOfPages}
                      goToPost={goToPost}
                      goToPage={handlePage}
                      page={page}
                      dataLoaded={!isLoading}
                      headerTitle="CONFIRA AS ATUALIZAÇÕES"
                      notFoundMessage="Não foram encontrados Posts de Legislação correspondentes à pesquisa."
                      cardComponent={ContentCard}
                      itemsPerPage={ITEMS_PER_PAGE}
                      defaultImage={DEFAULT_IMAGE}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }} sx={styles.MostAccessed}>
                    <MostAccessed
                      mobile={mobile}
                      mostAccessedPosts={mostAccessedLegislation}
                      goToPost={goToPost}
                      defaultImage={DEFAULT_IMAGE}
                    />
                    <Box
                      component="a"
                      href="https://t.me/+j00d6m6nZ1tkMGYx"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: "block",
                        cursor: "pointer",
                        margin: "0 auto 3rem auto",
                      }}
                    >
                      <Box component="img" src={TELEGRAM_IMG} sx={styles.flyer} />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
        </>
      )}
    </HomeLayout>
  );
};

export default Legislation;

const styles = {
  MostAccessed: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  flyer: {
    justifySelf: "center",
    height: 500,
    borderRadius: "6px",
    objectFit: "cover",
    transition: "transform 0.3s ease-out, background-color 0.3s ease-out",
    "&:hover": {
      transform: "scale(1.01)",
    },
  },
};
