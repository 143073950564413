import React, { useState, useEffect } from "react";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Box, IconButton, Stack, Typography as Text } from "@mui/material";
import { CompanyForm } from "./components/CompanyForm/CompanyForm";
import { Close, Person, Store } from "@mui/icons-material";
import { CreatePayPerson } from "./components/PersonForm/CreatePayPerson";
import { makeBusinessPartnerUseCases, makeIncomeNatureUseCases } from "../../Utils/utilsApiCalls";

export const CreateNfse = ({ onClose, onRefreshData }) => {
  const apiBusinessPartner = makeBusinessPartnerUseCases();
  const apiIncomeNature = makeIncomeNatureUseCases();
  const [perfilType, setPerfilType] = useState("CompanyForm");
  const isCompanyForm = perfilType === "CompanyForm";
  //options
  const [partners, setPartners] = useState([]);
  const [incomeNatureOptions, setIncomeNatureOptions] = useState([]);

  const user = JSON.parse(localStorage.getItem("GT_user"));

  const getData = () => {
    getIncomeNatures();
    getPartners();
  };

  const getPartners = async () => {
    const result = await apiBusinessPartner.listBusinessPartner();
    setPartners(result);
  };

  const getIncomeNatures = async () => {
    const result = await apiIncomeNature.listIncomeNature();
    setIncomeNatureOptions(result);
  };

  const handleFormSelect = (type) => {
    setPerfilType(type);
  };

  useEffect(() => getData(), []);

  const renderFormAddDoc = () => {
    if (isCompanyForm) {
      return (
        <CompanyForm
          partners={partners}
          handleReturn={onClose}
          refreshData={getPartners}
          onRefreshDocsData={onRefreshData}
          allPartners={partners}
        />
      );
    } else {
      return (
        <CreatePayPerson
          partners={partners}
          getPartners={getPartners}
          handleReturn={onClose}
          refreshData={getPartners}
          incomeNatureOptions={incomeNatureOptions}
          onRefreshDocsData={onRefreshData}
        />
      );
    }
  };

  return (
    <Box sx={{ p: { xs: 1, sm: 3 } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 1 }}>
        <Text sx={styles.title}>Adicionar documentos fiscais</Text>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Stack direction={{ xs: "column", sm: "row" }} sx={{ my: 2 }} spacing={2}>
        <RButton
          sx={{ width: { xs: "100%", sm: "min(300px)" } }}
          variant={!isCompanyForm ? "contained" : "outlined"}
          color={!isCompanyForm ? "primary" : "secondary"}
          onClick={() => handleFormSelect("PersonForm")}
          startIcon={<Person />}
          disabled={!["admin", "superAdmin"].includes(user?.role)} //permissão até finalizar desenvolvimento
        >
          Pessoa física
        </RButton>
        <RButton
          sx={{ width: { xs: "100%", sm: "min(300px)" } }}
          variant={isCompanyForm ? "contained" : "outlined"}
          color={isCompanyForm ? "primary" : "secondary"}
          onClick={() => handleFormSelect("CompanyForm")}
          startIcon={<Store />}
        >
          Pessoa jurídica
        </RButton>
      </Stack>
      {renderFormAddDoc()}
    </Box>
  );
};

const styles = {
  title: {
    display: "flex",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    color: "#242424",
  },
};

export const addManualStyles = {
  container: {
    backgroundColor: "#E6E6E6",
    borderRadius: "12px",
    p: { xs: 2, md: 3 },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "end",
    gap: 1,
    pt: 3,
  },
  cancelButton: {
    width: "min(150px, 100%)",
  },
  saveButton: {
    width: "min(175px, 100%)",
  },
};
