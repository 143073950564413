import { apiURL } from "../../usecases/api";
export class ApiIncomeNature {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async listIncomeNature() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/IncomeNature`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  // async getServiceCodeById(id) {
  //     try {
  //         const response = await this.httpClient.request({
  //             url: `/servicecode/${id}`,
  //             method: 'GET'
  //         })

  //         return response.data
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  // async createService(data) {
  //     try {
  //         const response = await this.httpClient.request({
  //             url: `/servicecode`,
  //             method: 'POST',
  //             data
  //         })

  //         return response.data
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  // async updateServiceCode(data){
  //     try {
  //         const response = await this.httpClient.request({
  //             url: `/servicecode`,
  //             method: 'PUT',
  //             data
  //         })

  //         return response.data
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  // async deleteServiceCode(id) {
  //     try {
  //         const response = await this.httpClient.request({
  //             url: `/servicecode/${id}`,
  //             method: 'DELETE'
  //         })

  //         return response.data
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }
}
