import React from "react";
import { DeleteOutline, FilterAltOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import Popover from "~/presentation/components/Popover/Popover";
import { commonColors } from "~/presentation/themes/styles.App";

function ActiveFilters({ onDeleteFilters = null, filtersData = [], disabledDeleteAll = false }) {
  const filtersArray = Array.isArray(filtersData) && filtersData.length > 0 ? filtersData : [];

  const activeFiltersArray = filtersArray.filter((i) => Boolean(i.value));

  const ActiveFilteresComp = () =>
    filtersArray.map(({ value, label = null }, index) => {
      if (!value) return;
      return (
        <Box key={index} sx={styles.filter}>
          <strong>{label}:</strong>
          {value}
        </Box>
      );
    });

  return (
    <Box sx={styles.container}>
      {onDeleteFilters && (
        <Tooltip title="Apagar filtros ativos">
          <span>
            <IconButton
              disabled={
                disabledDeleteAll ? disabledDeleteAll : Boolean(activeFiltersArray.length === 0)
              }
              onClick={onDeleteFilters}
            >
              <DeleteOutline sx={{ color: "#000000" }} fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Popover
        icon={FilterAltOutlined}
        tooltipTitle="Filtros ativos"
        badgeNumber={activeFiltersArray.length}
        disabled={activeFiltersArray.length === 0}
        styles={{
          popover: { display: { md: "none" } },
          icon: { color: "#000" },
          content: styles.popover,
          badge: { backgroundColor: commonColors.reinf.primary },
        }}
      >
        <ActiveFilteresComp />
      </Popover>
      <Box sx={styles.filtersContainer}>
        <ActiveFilteresComp />
      </Box>
    </Box>
  );
}

export default ActiveFilters;

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    wdith: "100%",
  },
  filtersContainer: {
    display: { xs: "none", md: "flex" },
    justifyContent: "start",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: 1,
    flex: { md: 1 },
    mt: 0.4,
  },
  filter: {
    backgroundColor: { md: "#E6E6E6" },
    color: "black",
    fontSize: 12,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    gap: 1,
    py: 0.4,
    px: 1,
  },
  popover: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
};
