import React from "react";
import { Typography as Text } from "@mui/material";
import { DescriptionPost } from "~/presentation/views/Blog/Post/Sections/MostAccessedSection/components/DescriptionPost/DescriptionPost";
import { Box } from "@mui/material";
const MostAccessed = ({ mostAccessedPosts, goToPost }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
      <Text
        sx={{
          fontSize: 22,
          fontWeight: 600,
          color: "#3A3A3A",
          fontFamily: "Montserrat",
          paddingBottom: 2,
        }}
      >
        Mais Acessados
      </Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {mostAccessedPosts?.map((post) => (
          <DescriptionPost
            title={post.title}
            key={post.id}
            publishDate={`${new Date(post.publishDate).toLocaleDateString()}`}
            handleOnClick={() => goToPost(post.id)}
            post={post}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MostAccessed;
