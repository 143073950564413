import React from "react";
import { Box, Typography as Text, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";

export const EventDetailsModal = ({ onClose, eventDetails }) => {
  const normalizedDetails = Array.isArray(eventDetails) ? eventDetails : [eventDetails];

  const handleDownloadXml = (xmlContent) => {
    if (!xmlContent) return;

    const blob = new Blob([xmlContent], { type: "text/xml" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = "ProtocoloEfdReinf.xml";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  return (
    <Box sx={styles.content}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Text style={styles.Title}>Detalhes do protocolo de Envio</Text>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={styles.responseContainer}>
        {normalizedDetails?.map((detail, responseIndex) => (
          <Box sx={normalizedDetails.length > 0 && styles.response} key={responseIndex}>
            <Box>
              <Text sx={styles.subtitle}>Descrição da resposta</Text>
              <Text
                sx={styles.description}
              >{`${detail.codeResponse} - ${detail.descriptionResponse}`}</Text>
            </Box>
            {detail?.events?.map((event, index) => (
              <Box key={index}>
                <Text sx={styles.subtitle}>Evento</Text>
                <Text sx={styles.description}>{`Protocolo: ${event.protocolNumber}, N. do Recibo: ${
                  event.receiptNumber || "Não disponível"
                }`}</Text>
              </Box>
            ))}
            {detail.occurrences?.map((occ, index) => (
              <Box key={index}>
                <Text sx={styles.subtitle}>Ocorrências</Text>
                <Text sx={styles.description}>{`${occ.description} - ${occ.text}`}</Text>
              </Box>
            ))}
            {detail.xml && (
              <RButton
                sx={{ width: 150 }}
                color="secondary"
                onClick={() => handleDownloadXml(detail.xml)}
              >
                Baixar XML
              </RButton>
            )}
          </Box>
        ))}
      </Box>
      <RButton sx={{ width: 150, ml: "auto" }} variant="outlined" onClick={onClose}>
        Fechar
      </RButton>
    </Box>
  );
};

const styles = {
  content: {
    p: { xs: 1, md: 3 },
    maxHeight: "80vh",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  responseContainer: {
    flex: 1,
    overflowY: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  response: {
    backgroundColor: "#e6e6e6",
    borderRadius: 3,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  Title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 24,
    fontWeight: 700,
    color: "#404040",
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: "#404040",
  },
  description: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "justify",
    overflow: "auto",
  },
};
