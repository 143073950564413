import React from "react";
import PrimaryButton from "~/presentation/components/PrimaryButton";

export const Button = ({ children, mt, ...props }) => (
  <PrimaryButton
    sx={{
      "&.MuiButton-root": {
        backgroundColor: "#1341a1",
        alignSelf: "center",
        width: "min(200px, 100%)",
        margin: "0 auto",
        marginTop: mt ? "40px" : "",

        "@media (min-width: 600px)": {
          maxWidth: "fit-content",
        },
      },

      ".MuiButton-label": {
        display: "flex",
        gap: "5px",
        alignItems: "center",
      },
    }}
    size="small"
    type="submit"
    variant="contained"
    {...props}
  >
    {children}
  </PrimaryButton>
);
