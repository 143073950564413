import React from "react";
import { ContentSmallCard } from "~/presentation/components/ContentSmallCard/ContentSmallCard";
import { Box, Skeleton, Typography } from "@mui/material";

const DEFAULT_IMAGE = "/assets/bglegislacao.png";

export const MostAccessed = ({ mostAccessedPosts, goToPost, notShowImages = false }) => {
  const skeletonArray = Array.from({ length: 3 });
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Mais Acessados</Typography>
      {mostAccessedPosts.length > 0 ? (
        mostAccessedPosts?.map((post) => [
          <ContentSmallCard
            key={"most-accessed-line-" + post.id}
            imageSrc={post.image}
            defaultImage={DEFAULT_IMAGE}
            title={post.title}
            publishDate={post.publishDate}
            onClick={() => goToPost(post.id)}
            notShowImages={notShowImages}
            autor={post.autor}
          />,
        ])
      ) : (
        <Box>
          {skeletonArray.map((_, index) => (
            <Skeleton sx={styles.skeleton} key={index} variant="rounded" height={130} />
          ))}
        </Box>
      )}
    </Box>
  );
};

const styles = {
  skeleton: {
    marginBlock: 4,
  },
  title: {
    fontFamily: "Montserrat",
    letterSpacing: "-0.03em",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
};
