import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { PostContent } from "~/presentation/components/PostContent/PostContent";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";

const DEFAULT_IMAGE = "/assets/bgartigos.png";

export const ArticlePost = ({ contentUseCases }) => {
  const { id: postId } = useParams();
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);

  const getPost = async () => {
    setLoading(true);
    const response = await contentUseCases.getContentById({ id: postId, checkPermission: true });
    setPost(response);
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
    getPost();
  }, [postId]);

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <SectionTitlePost
            image={DEFAULT_IMAGE}
            title="Artigos"
            redirectURL="/cliente/conteudo-selecionado/artigo"
            searchButtonBg="#2699DA"
            searchPlaceholder="Pesquisar Artigo"
          />
          <PostContent
            redirectURL="/cliente/conteudo-selecionado/artigo"
            type="ARTIGOS"
            post={post}
            defaultImg={"/assets/judge-table.jpeg"}
            loading={loading}
          />
        </>
      )}
    </HomeLayout>
  );
};
