import React, { useContext, useEffect, useState } from "react";
import { UserTable } from "./components/Users/UserTable";
import { Box, Button } from "@mui/material";
import { UserProfile } from "./components/UserProfile/UserProfile";
import { hasPermission } from "~/presentation/security/SecurityPath";
import InfoPlan from "./components/InfoPlan";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { painelStyles } from "../..";

export const UserPlan = ({
  userUseCases,
  userProfilesUseCases,
  planDetails = null,
  onGetPlanDetails,
}) => {
  const { companyId, profileId } = JSON.parse(localStorage.getItem("GT_user"));
  const { showError } = useContext(SnackbarContext);
  const [tab, setTab] = useState(1);

  const [users, setUsers] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);

  const [loading, setLoading] = useState({ users: true, userProfiles: true });

  useEffect(() => {
    if (!planDetails) {
      onGetPlanDetails();
    }
  }, []);

  const getUsers = async () => {
    try {
      setLoading((prev) => ({ ...prev, users: true }));
      const result = await userUseCases.getUsers({
        checkPermission: true,
        companyId: companyId,
      });
      result.forEach((u) => {
        u.value = u?.userId;
        u.label = u?.name;
      });
      setUsers(result);
    } catch (error) {
      showError(error, "Erro ao carregar usuários");
    } finally {
      setLoading((prev) => ({ ...prev, users: false }));
    }
  };

  const getUserProfiles = async () => {
    try {
      setLoading((prev) => ({ ...prev, userProfiles: true }));
      const response = await userProfilesUseCases.getProfiles({
        companyId: companyId,
        chechPermission: true,
      });
      if (Array.isArray(response)) {
        const filteredData = response.filter((item) => !item.isInternalUse);
        if (filteredData.length > 0) {
          setUserProfiles(filteredData);
        }
      }
    } catch (error) {
      showError(error, "Erro ao carregar perfis de usuário");
    } finally {
      setLoading((prev) => ({ ...prev, userProfiles: false }));
    }
  };

  const renderUsersAndProfiles = () => {
    const permissionToSeeUsers = hasPermission([clientPerms.seeUsers]);
    const permissionToSeeProfiles = hasPermission([clientPerms.seeUserProfile]);

    const usersComp = (
      <UserTable
        userUseCases={userUseCases}
        companyId={companyId}
        users={users}
        refreshUsers={getUsers}
        userProfiles={userProfiles}
        refreshProfiles={getUserProfiles}
        userProfilesUseCases={userProfilesUseCases}
        loading={loading.users}
      />
    );
    const profilesComp = (
      <UserProfile
        companyId={companyId}
        profileId={profileId}
        userProfiles={userProfiles}
        refreshData={getUserProfiles}
        userProfilesUseCases={userProfilesUseCases}
        loading={loading.userProfiles}
      />
    );

    if (permissionToSeeProfiles && permissionToSeeUsers) {
      return (
        <>
          <Box sx={styles.tabsContainer}>
            <Button onClick={() => setTab(1)} sx={styles.tabButton(tab === 1, true)}>
              Usuários
            </Button>
            <Button onClick={() => setTab(2)} sx={styles.tabButton(tab === 2)}>
              Perfil de Usuário
            </Button>
          </Box>
          <Box sx={{ minHeight: 800 }}>{tab === 1 ? usersComp : profilesComp}</Box>
        </>
      );
    }

    if (permissionToSeeUsers && !permissionToSeeProfiles) {
      return usersComp;
    }

    if (!permissionToSeeUsers && permissionToSeeProfiles) {
      return profilesComp;
    }

    return null;
  };

  return (
    <>
      <InfoPlan planDetails={planDetails} />
      <Box sx={{ p: 0, my: 6 }}>{renderUsersAndProfiles()}</Box>
    </>
  );
};
const styles = {
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 3,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  text: {
    color: "#021148",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  planDetailsText: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    mb: 2,
  },
  tabsContainer: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: "center",
    mb: { xs: 2, sm: 4 },
    gap: { xs: 1, sm: 0 },
  },
  tabButton: (active, first) => ({
    padding: "12px 40px",
    border: "1px solid #081445",
    borderRight: { sm: first && "none" },
    borderRadius: { xs: "12px", sm: first ? "12px 0px 0px 12px" : "0px 12px 12px 0px" },
    background: active ? "#081445" : painelStyles.bgColor,
    color: active ? "#fff" : "#222",
    width: { xs: "100%", sm: "auto" },
    fontFamily: "Montserrat",
    fontSize: "0.9rem",
    fontWeight: active ? 700 : 500,
    "&:hover": {
      background: active ? "#1e2b60" : painelStyles.hoverColor,
    },
  }),
};
