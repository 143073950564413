import React, {useContext, useEffect, useRef, useState} from "react";
import { Formik, Form } from "formik";

import {
  Button,
  FormWrapper,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { ISSCalculator } from "~/presentation/views/GTFacil/Simulator/entities/iss-calculator";
import { validationSchema } from "./validationSchema.js";
import {
  BaseRetentionCalcValue,
  HasMaterialExclusion,
  IsAliquotHighlighted,
  ISSResultCard,
  MaterialExclusionValue,
  RetentionAliquotPercentage,
} from "~/presentation/views/GTFacil/Simulator/forms/common/iss/questions";
import { reportMapper } from "./reportMapper.js";

const initialValues = {
  hasExclusions: false,
  exclusionValue: 0,
  isAliquotHighlighted: false,
  retentionAliquotPercentage: 5,
  isSimples: true
};

export const IssPJSimples = () => {
  const resultCardRef = useRef();
  const { simulatorValue, setInvalidSimulatorValue, handleGenerateReport, handleCacheReport } = useContext(SimulatorContext);
  const [result, setResult] = useState();
  const [lastSubmitValues, setLastSubmitValues] = useState("");

  const issCalculatorFactory = (values) =>
    new ISSCalculator({
      simulatorValue,
      exclusionValue: values.exclusionValue,
      retentionAliquotPercentage: values.retentionAliquotPercentage,
    });

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  
  const handleGenerateReportClick = (values) => {
    const response = reportMapper(values, result)
    handleGenerateReport(response)
  }

  useEffect(() => {
    if(result && resultCardRef.current) {
      resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [result, resultCardRef]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }

        action.setSubmitting(true);
        setLastSubmitValues(values);

        const issCalculator = issCalculatorFactory(values);

        setResult(issCalculator.issRetentionValue);

        action.setSubmitting(false);

        const response = reportMapper(issCalculator, issCalculator.issRetentionValue);
        
        handleCacheReport(response);         
      }}
    >
      {({ setFieldValue, values }) => {
        const issCalculator = issCalculatorFactory(values);

        return (
          <Form>
            <FormWrapper>
              <HasMaterialExclusion
                onChange={() => {
                  setFieldValue("exclusionValue", initialValues.exclusionValue);
                }}
              />

              {values.hasExclusions === true ? (
                <MaterialExclusionValue />
              ) : null}
              <BaseRetentionCalcValue
                value={issCalculator.baseRetentionCalcValue}
              />

              <IsAliquotHighlighted
                onChange={() => {
                  setFieldValue(
                    "retentionAliquotPercentage",
                    initialValues.retentionAliquotPercentage
                  );
                }}
              />

            {/*values.isAliquotHighlighted ? (*/
                <RetentionAliquotPercentage />
              /*) : null*/}

              <Button>Ver Resultado</Button>
              {result && valuesNotChangedSinceLastSubmit(values) ? (
                <ISSResultCard
                  value={floatToReais(result)}
                  componentRef={resultCardRef}
                  handleGenerateReportClick={() => handleGenerateReportClick(issCalculator)}
                />
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
