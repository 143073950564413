import React from "react";
import Grid from "@mui/material/Grid2";
import ActiveFilters from "~/presentation/views/Reinf/components/ActiveFilters/ActiveFilters";
import { Box } from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { hasPermission } from "~/presentation/security/SecurityPath";
import {
  currentMonth,
  currentYear,
  getMonthInPortuguese,
} from "~/presentation/views/Reinf/Utils/utilsDate";
import {
  getEFDReinfEvents,
  getMonths,
  getYears,
} from "~/presentation/views/Reinf/Utils/utilsReinf";

function PageFilters({
  filters: cFilters = {},
  activeFilters: aFilters = {},
  onChangeFilter = () => {},
  onPageFilters = () => {},
  onClearAllFilters = () => {},
  allCertificates = [],
}) {
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const eventsOptions = getEFDReinfEvents(user);
  const yearsOptions = getYears(currentYear - 5);
  const monthsOptions = getMonths();

  const isSelectedYearCurrent = cFilters?.year === currentYear;
  const isDateInTheFuture = isSelectedYearCurrent && cFilters?.month > currentMonth;

  const findedCert = aFilters?.certificate
    ? allCertificates?.find((item) => item.id == aFilters?.certificate)
    : "";
  const event = aFilters?.event ? `R-${aFilters?.event?.slice(1, 5)}` : "";

  const activeFiltersData = [
    {
      value: findedCert?.name,
      label: "Certificado",
    },
    {
      value: aFilters?.month ? getMonthInPortuguese(aFilters?.month) : "",
      label: "Mês",
    },
    {
      value: aFilters?.year,
      label: "Ano",
    },
    {
      value: event?.toUpperCase(),
      label: "Evento",
    },
  ];

  const disabledFilterButton =
    !cFilters?.year || !cFilters?.month || !cFilters?.event || isDateInTheFuture;

  return (
    <Box sx={{ pt: 1, pb: 2, px: { xs: 1, md: 3 } }}>
      <ActiveFilters onDeleteFilters={onClearAllFilters} filtersData={activeFiltersData} />
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "end",
          mt: 1,
        }}
      >
        <Grid size={{ xs: 12, sm: 4, md: 2.5 }}>
          <RButton
            type="filterSelect"
            title="Selecionar certificado"
            placeholder="Selecione o certificado digital"
            value={cFilters?.certificate}
            onChange={(e) => onChangeFilter({ certificate: e.target.value })}
            options={allCertificates.map((cert) => ({ value: cert.id, label: cert.name }))}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 2.5 }}>
          <RButton
            type="filterSelect"
            title="Mês"
            placeholder="Selecione o mês"
            value={cFilters?.month}
            onChange={(e) => onChangeFilter({ month: e.target.value })}
            error={isDateInTheFuture && "Escolha uma data no presente!"}
            options={monthsOptions.map(({ value, label }) => ({
              value: value,
              label: `${value} - ${label}`,
            }))}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 2.5 }}>
          <RButton
            type="filterSelect"
            title="Ano"
            placeholder="Selecione o ano"
            value={cFilters?.year}
            onChange={(e) => onChangeFilter({ year: e.target.value })}
            options={yearsOptions.map(({ value, label }) => ({ value: value, label: label }))}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 2.5 }}>
          <RButton
            type="filterSelect"
            title="Evento a enviar"
            placeholder="Selecione o evento"
            value={cFilters?.event}
            onChange={(e) => onChangeFilter({ event: e.target.value })}
            options={eventsOptions?.map(({ value, label }) => {
              const permission = hasPermission([`reinf\\envio\\${value}`]);
              if (permission) return { value: value, label: label };
            })}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4, md: 2 }}>
          <RButton disabled={disabledFilterButton} onClick={onPageFilters}>
            APLICAR
          </RButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageFilters;
