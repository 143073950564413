import React, { createContext, useContext, useMemo, useState } from "react";
import DialogBox from "~/presentation/components/DialogBox";

export const DialogContext = createContext();
export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [showDialogBox, setShowDialogBox] = useState({ open: false });
  const [showSecondDialog, setShowSecondDialog] = useState({ open: false });
  // message, callback(), negativeCallback(), confirmText, denyText, customComponent,

  const openDialogBox = (props) => setShowDialogBox({ open: true, ...props });
  const closeDialog = () => setShowDialogBox({ ...showDialogBox, open: false });

  const openSecondDialog = (props) => setShowSecondDialog({ open: true, ...props });
  const closeSecondDialog = () => setShowSecondDialog({ ...showSecondDialog, open: false });

  const value = {
    openDialogBox,
    closeDialog,
    openSecondDialog,
    closeSecondDialog,
  };

  return (
    <DialogContext.Provider value={useMemo(() => value, [])}>
      {children}
      <DialogBox config={showDialogBox} setDialogBox={setShowDialogBox} onClose={closeDialog} />
      <DialogBox
        config={showSecondDialog}
        setDialogBox={setShowSecondDialog}
        onClose={closeSecondDialog}
      />
    </DialogContext.Provider>
  );
};
