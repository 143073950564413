import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ModalContentWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 2,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  overflowY: "auto",
  padding: "7.4vh 10vw",
  borderRadius: "13px",
  [theme.breakpoints.up("md")]: {
    padding: "7.4vh 13.1vw 7.4vh 9.1vw",
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "linear-gradient(180deg, #e97c03 48%, #6a4113 100%)",
  borderRadius: "18px",
  zIndex: 2,
  color: "white",
  fontSize: "2.625rem",
  fontFamily: "Montserrat",
  wordWrap: "break-word",
  fontWeight: 400,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  padding: "2rem 3rem",
  "& span": {
    fontWeight: 700,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.625rem",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const Button = styled("button")({
  background: "linear-gradient(180deg, #2455b2 0%, #102342 100%)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "18px",
  color: "white",
  fontSize: "30px",
  fontFamily: '"Bebas Neue"',
  fontWeight: 400,
  wordWrap: "break-word",
  width: "20rem",
  height: "3.5rem",
  cursor: "pointer",
});

export const ContainerTextHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    textAlign: "center",
  },
}));

export const Description = styled(Box)({
  zIndex: 2,
  padding: "2rem 3rem",
  "& span": {
    fontWeight: 700,
  },
});

export const VideoContainer = styled(Box)({
  gridArea: "video",
  position: "relative",
  width: "60rem",
  height: "10rem",
  overflow: "hidden",
  zIndex: 999,
  "& iframe": {
    border: 0,
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
});

export const ContainerDescriptionWithVideo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const Icon = styled("img")({});

export const WrapperContent = styled("div")({
  padding: "1rem",
});

export default ModalContentWrapper;
