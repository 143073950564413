import React, { useState } from "react";
import {
  isCloseOptions,
  isProductionoptions,
} from "~/presentation/views/Reinf/SendEDF/components/SendEvents/components/Options";
import RenderEventForm from "~/presentation/views/Reinf/SendEDF/components/SendEvents/components/RenderEventForm";
import { makeReinfUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";

const SendOpenCloseEvents = ({
  certificateObj,
  companyId,
  companyInfor,
  competence,
  closeModal,
  isProduction,
  event,
  eventName,
  openDetailsModal,
}) => {
  const apiSendReinf = makeReinfUseCases();
  const { openBackdrop } = useBackdrop();
  const { showError } = useSnackbar();

  const isR4099 = event === "r4099";

  const initalValues2098 = {
    companyId: companyId,
    digitalCertId: certificateObj?.id,
    isProduction: isProduction,
    calculationDate: competence,
  };

  const initialValues4099 = {
    ...initalValues2098,
    isClose: true,
  };

  const [eventData, setEventData] = useState(isR4099 ? initialValues4099 : initalValues2098);

  const onChangeValue = (field, value) => setEventData((prev) => ({ ...prev, [field]: value }));

  const handleSubmit = async () => {
    openBackdrop(true, `Enviando dados do Evento ${eventName}...`);
    try {
      const response = await apiSendReinf.sendEvent(eventData, event);
      if (response) openDetailsModal(response);
    } catch (error) {
      showError(error, "Erro");
    } finally {
      openBackdrop(false);
    }
  };

  const fieldsArray = [
    { type: "text", field: "companyName", value: companyInfor?.name, title: "Estabelecimento" },
    {
      type: "text",
      field: "digitalCertId",
      value: certificateObj?.name,
      title: "Certificado Digital",
    },
    {
      type: "select",
      title: "Tipo de ambiente",
      name: "isProduction",
      options: isProductionoptions,
      onChange: (option) => onChangeValue("isProduction", option.value),
      value: isProductionoptions.find((option) => option.value === eventData.isProduction),
    },
    ...(isR4099
      ? [
          {
            type: "select",
            title: "Tipo de evento",
            name: "isClose",
            options: isCloseOptions,
            onChange: (option) => onChangeValue("isClose", option.value),
            value: isCloseOptions.find((option) => option.value === eventData.isClose),
          },
        ]
      : []),
  ];

  return (
    <RenderEventForm
      fields={fieldsArray}
      sendButtonLabel={
        isR4099 ? `${eventData.isClose ? "Fechar" : "Reabrir"} evento` : "Reabrir evento"
      }
      eventName={eventName}
      closeModal={closeModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default SendOpenCloseEvents;
