import React, {useContext, useRef, useEffect} from "react";
import { Form, Formik } from "formik";
import {
  Button,
  FormWrapper,
  MoneyQuestion,
  SimulatorResultCard,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { reportMapper } from "./reportMapper";

const initialValues = {
  aliquotRetention: 0.0465,
};

export const CsllPisPasepCofinsCooperative = ({ contractorCategory }) => {
  const resultCardRef = useRef();
  const { simulatorValue, setInvalidSimulatorValue, handleCacheReport, cacheReportValues } = useContext(SimulatorContext);

  const getResult = (values) => {
    return simulatorValue * values.aliquotRetention;
  };

  if (contractorCategory.inRange(6, 11)) {
    return <SimulatorResultCard noTitleOrValue description="Não há retenção das contribuições." />;
  }

  const result = cacheReportValues.length > 0 ? cacheReportValues?.filter(report => report.tax === 2)[0]?.retentionValue : false;

  useEffect(() => {
    if(result) {
      resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }

        action.setSubmitting(true);

        const resultValue = getResult(values);

        action.setSubmitting(false);

        const response = reportMapper(values, resultValue);

        handleCacheReport(response);
      }}
    >
      {({ values }) => {
        return (
          <Form>
            <FormWrapper>
              <MoneyQuestion
                text="Alíquota da retenção:"
                excerpt="A alíquota de retenção da CSLL, da COFINS e da contribuição para o PIS/Pasep é de 4,65%, correspondente à soma das alíquotas de 1%, 3% e 0,65%, respectivamente, conforme art. 31 da Lei n° 10.833/2003"
                value={values.aliquotRetention * 100}
                prefix="%"
                disabled
                allowNegative
              />
              <Button>Ver Resultado</Button>

              {result ? (
                <SimulatorResultCard
                  componentRef={resultCardRef}
                  title="Valor da retenção da CSLL, PIS/Pasep e COFINS:"
                  value={floatToReais(result)}
                  description="O valor da retenção da CSLL, PIS/Pasep e COFINS corresponde ao resultado da multiplicação da alíquota pela base de cálculo."
                />
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
