import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiServiceCode {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async listService({ checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeServiceCodes])) {
        throw new Error(securityErrorMessage("visualizar códigos de serviço"));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getServiceCodeById(id) {
    if (!hasPermission([adminPerms.seeServiceCodes])) {
      throw new Error(securityErrorMessage("visualizar códigos de serviço"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async createService(data) {
    if (!hasPermission([adminPerms.addServiceCodes])) {
      throw new Error(securityErrorMessage("adicionar códigos de serviço"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateServiceCode(data) {
    if (!hasPermission([adminPerms.editServiceCodes])) {
      throw new Error(securityErrorMessage("editar códigos de serviço"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteServiceCode(id) {
    if (!hasPermission([adminPerms.deleteServiceCodes])) {
      throw new Error(securityErrorMessage("excluir códigos de serviço"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
