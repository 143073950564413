import React, { useEffect, useRef } from "react";

function Fade({ children, duration = "1s", ...props }) {
  if (!children) return null;

  const elementRef = useRef(null);
  const hasAnimated = useRef(false);

  useEffect(() => {
    const element = elementRef.current;

    const observer = new IntersectionObserver(
      ([entry], observerInstance) => {
        if (entry.isIntersecting && !hasAnimated.current) {
          element.classList.add("visible");
          hasAnimated.current = true;
          observerInstance.unobserve(element);
        }
      },
      { threshold: 0.1 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <>
      <div ref={elementRef} className="fade" {...props}>
        {children}
      </div>

      <style>
        {`
          .fade {
            opacity: 0;
          }

          .fade.visible {
            animation: fade-in ${duration} ease-in-out forwards ;
          }

          @keyframes fade-in {
            to {
              opacity: 1;
            }
          }
        `}
      </style>
    </>
  );
}

export default Fade;
