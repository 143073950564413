import React, { useState } from "react";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import { makeReinfUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import { isProductionoptions } from "~/presentation/views/Reinf/SendEDF/components/SendEvents/components/Options";
import RenderEventForm from "~/presentation/views/Reinf/SendEDF/components/SendEvents/components/RenderEventForm";
import { getEventType } from "~/presentation/views/Reinf/Utils/utilsReinf";

const SendR9000 = ({
  docs,
  certificateObj,
  companyId,
  companyInfor,
  competence,
  year,
  month,
  closeModal,
  openDetailsModal,
  isProduction,
}) => {
  const apiSendReinf = makeReinfUseCases();
  const { showError } = useSnackbar();
  const { openBackdrop } = useBackdrop();

  const [eventData, setEventData] = useState({
    companyId: companyId,
    digitalCertId: certificateObj?.id,
    isProduction: isProduction,
    calculationDate: competence,
  });

  const onChangeValue = (field, value) => setEventData((prev) => ({ ...prev, [field]: value }));

  const handleSubmit = async () => {
    openBackdrop(true, "Enviando dados do Evento R-9000");

    const updatedEventData = {
      ...eventData,
      infoEvents: docs.map((item) => ({
        event: getEventType(item.reinfEvent),
        receiptNumber: item.receiptNumber,
      })),
    };

    try {
      const response = await apiSendReinf.sendEvent(updatedEventData, "r9000");
      if (response) openDetailsModal(response);
    } catch (error) {
      showError(error, "Erro ao enviar o evento R-9000");
    } finally {
      openBackdrop(false);
    }
  };

  const fieldsArray = [
    { type: "text", field: "companyName", value: companyInfor?.name, title: "Estabelecimento" },
    {
      type: "text",
      field: "digitalCertId",
      value: certificateObj?.name,
      title: "Certificado Digital",
    },
    {
      type: "select",
      title: "Tipo de ambiente",
      name: "isProduction",
      options: isProductionoptions,
      onChange: (option) => onChangeValue("isProduction", option.value),
      value: isProductionoptions.find((option) => option.value === eventData.isProduction),
    },
    {
      type: "text",
      title: "Data da competência",
      value: `${month}/${year}`,
      disabled: true,
    },
  ];

  return (
    <RenderEventForm
      fields={fieldsArray}
      eventName="R-9000"
      closeModal={closeModal}
      handleSubmit={handleSubmit}
      costumNumberOfDocsText={
        docs.length === 1
          ? `O evento selecionado será excluído`
          : `Os ${docs.length} eventos selecionados serão excluídos` + "ao enviar o evento R-9000."
      }
    />
  );
};

export default SendR9000;
