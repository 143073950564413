import React, { useContext, useState } from "react";
import { HeaderSectionPage } from "../components/HeaderSectionPage";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { Box, Checkbox, FormControlLabel, Radio, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { reportModels, reportTypes } from "./ReportsData";
import useHandleDates from "../Hooks/useHandleDates";
import { makeReinfUseCases } from "../Utils/utilsApiCalls";

function Reports() {
  const apiReinf = makeReinfUseCases();
  const { showError } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { currentDay, sixMonthsAgo, sixYearsAgo, handleDateErrors } = useHandleDates();

  // Params
  const [searchParams, setSearchParams] = useState({
    start: sixMonthsAgo,
    end: currentDay,
    report: { value: null, filters: null },
    model: "pdf",
  });

  const dateErrors = handleDateErrors(searchParams.start, searchParams.end);

  const handleChangeFilter = (field, newValue) => {
    setSearchParams((prevFilter) => ({
      ...prevFilter,
      [field]: newValue,
    }));
  };

  const handleChangeOtherFilers = (newValue, index) => {
    const updatedFilters = searchParams.report.filters.map((f, i) =>
      i === index ? { ...f, value: newValue } : f
    );
    handleChangeFilter("report", {
      ...searchParams.report,
      filters: updatedFilters,
    });
  };

  const handleGenarateReport = async () => {
    const dataToSend = {
      report: searchParams.report.value,
      params: {
        dateI: searchParams.start.toISOString().split("T")[0],
        dateF: searchParams.end.toISOString().split("T")[0],
        isXlsx: searchParams.model === "xlsx",
      },
    };

    //caso seja "resumo"
    if (searchParams?.report?.value?.includes("resume")) {
      dataToSend.params.isSummary = true;
      dataToSend.report = dataToSend.report.split("-")[1];
    } else if (searchParams.report?.filters?.length > 0) {
      //se nao for resumo porém tiver params, criamos os params
      searchParams.report.filters.map((f) => (dataToSend.params[f.param] = f.value));
    }

    try {
      openBackdrop(true, "Carregando informação dos eventos");
      const response = await apiReinf.generateReport(dataToSend);
      if (dataToSend.params.isXlsx && response) {
        //se for excell, fazemos download
        const link = document.createElement("a");
        link.href = response;
        link.download = "relatorio.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (response && isValidUrl(response)) {
        //se for pdf abrimos nova janela
        window.open(response, "Relatório", "width=1200,height=600,scrollbars=yes,resizable=yes");
      } else {
        showError({}, "Erro ao gerar relatório.");
      }
    } catch (error) {
      showError(error, "Erro na geração do relatório");
    } finally {
      openBackdrop(false);
    }
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  //INVALIDAR BOTÃO APLICAR
  const disabledBtn = dateErrors || !searchParams.report?.value;

  return (
    <>
      <HeaderSectionPage title="Relatórios" />
      <Box
        sx={{
          padding: { xs: 1, md: 3 },
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Grid container spacing={1} sx={styles.reportContainer}>
          <Grid size={12} sx={{ mb: 1 }}>
            <Typography sx={styles.title}>Tipo de Relatório</Typography>
            <Typography sx={styles.text}>Escolha um dos tipos de relatório</Typography>
          </Grid>
          {reportTypes.map((type, index) => (
            <Grid size={{ xs: 12, md: 4 }} key={index} sx={styles.radioGroup}>
              <Typography sx={styles.radioGroup.title}>{type.title}</Typography>
              {type.reports.map((report, index) => (
                <FormControlLabel
                  key={index}
                  sx={styles.controlLabel()}
                  control={
                    <Radio
                      sx={styles.icon()}
                      checked={searchParams.report.value === report.value}
                      onChange={() => {
                        handleChangeFilter("report", {
                          value: report?.value || "",
                          filters: report?.filters || null,
                        });
                      }}
                      name={report.value}
                    />
                  }
                  label={report.label}
                />
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 8 }} sx={styles.reportContainer}>
            <Typography sx={styles.title}>Filtros</Typography>
            <Box sx={styles.filterContainer}>
              <Box sx={{ width: { xs: "100%", md: "min(300px, 100%)" } }}>
                <Input
                  title="Data inicial"
                  styles={styles.dateInput}
                  minDate={sixYearsAgo}
                  maxDate={searchParams?.end || currentDay}
                  type="date"
                  value={searchParams?.start}
                  onChange={(date) => {
                    handleChangeFilter("start", date);
                  }}
                  error={dateErrors}
                />
              </Box>
              <Box sx={{ width: { xs: "100%", md: "min(300px, 100%)" } }}>
                <Input
                  title="Data final"
                  styles={styles.dateInput}
                  minDate={searchParams?.start || sixYearsAgo}
                  maxDate={currentDay}
                  type="date"
                  value={searchParams?.end}
                  onChange={(date) => {
                    handleChangeFilter("end", date);
                  }}
                  error={dateErrors}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {searchParams.report?.filters?.length > 0 &&
                  searchParams.report?.filters?.map((filter, index) => (
                    <Box size={{ xs: 12, md: 6, lg: 4 }} key={index}>
                      <FormControlLabel
                        sx={styles.controlLabel(14)}
                        key={index}
                        control={
                          <Checkbox
                            checked={filter.value}
                            sx={styles.icon(20)}
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              handleChangeOtherFilers(newValue, index);
                            }}
                            name={filter.param}
                          />
                        }
                        label={filter.label}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }} sx={styles.reportContainer}>
            <Typography sx={styles.title}>Modelo</Typography>
            {reportModels.map(({ value, title }, index) => (
              <FormControlLabel
                sx={styles.controlLabel()}
                key={index}
                control={
                  <Radio
                    checked={value === searchParams.model}
                    sx={styles.icon()}
                    onChange={() => handleChangeFilter("model", value)}
                    name={value}
                  />
                }
                label={title}
              />
            ))}
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RButton
            disabled={disabledBtn}
            onClick={handleGenarateReport}
            sx={{
              width: "min(100%,300px)",
              marginBlock: 1,
              borderRadius: "8px",
            }}
          >
            GERAR RELATÓRIO
          </RButton>
        </Box>
      </Box>
    </>
  );
}

export default Reports;

const styles = {
  title: {
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "Montserrat",
    color: "#000000",
  },
  text: {
    fontSize: 14,
    fontFamily: "Montserrat",
    color: "#000000",
  },
  filterContainer: {
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: 1,
    alignItems: { xs: "center", md: "end" },
  },
  reportContainer: {
    backgroundColor: "#E6E6E6",
    padding: 3,
    borderRadius: "12px",
  },
  radioGroup: {
    title: {
      position: "absolute",
      background: "#e6e6e6",
      pl: "10px",
      pr: "10px",
      top: "-12px",
      left: "10px",
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: 1,
    border: "1px solid #C1C1C1",
    borderRadius: "8px",
    padding: 2,
  },
  controlLabel: (size = 16) => ({
    "& .MuiFormControlLabel-label": {
      fontSize: size,
      fontWeight: 500,
      textAlign: "left",
      fontFamily: "Montserrat",
      color: "#000000",
      textOverflow: "ellipsis",
    },
  }),
  icon: (size = 24) => ({
    color: "#000", // Cor padrão do checkbox
    "&.Mui-checked": {
      color: "#1341A1", // Cor do checkbox quando está marcado
    },
    "& .MuiSvgIcon-root": {
      fontSize: size,
    },
  }),
  dateInput: {
    width: "100%",
    borderRadius: "8px",
  },
};
