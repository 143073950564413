import React from "react";
import useWindowSize from "~/presentation/hooks/useWindowSize";
import ModalBackground, {
  handleColorByWindowSize,
} from "~/presentation/views/LandingPage/Home/Modals/components/Bg";
import ModalContainer from "~/presentation/components/ModalContainer";
import {
  Header,
  Icon,
  Button,
  ContainerTextHeader,
  Description,
  VideoContainer,
  ContainerDescriptionWithVideo,
} from "~/presentation/views/LandingPage/Home/Modals/components/Wrappers";

import calendarIcon from "~/presentation/views/LandingPage/Home/Modals/icons/calendar.webp";

const GTFacilModal = () => {
  const { width: screenWidth } = useWindowSize();

  const bgColorSelector = () => {
    return handleColorByWindowSize(screenWidth);
  };

  const handleOnClickContactButton = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5571992084907&text=Ol%C3%A1!%20Vim%20do%20site%20Gest%C3%A3o%20Tribut%C3%A1ria%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20ferramenta%20GT-Reinf%20junto%20a%20um%20representante.",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <ModalContainer backgroundUrl="/assets/sample_photos/magnifying-glass-pc2.jpg">
      <ModalBackground color={bgColorSelector()} />
      <Header>
        <ContainerTextHeader>
          <Icon src={calendarIcon} alt="Modal Icon" />
          <div>
            <span>GT-FÁCIL</span>: RÁPIDO, OBJETIVO, PRECISO E INOVADOR.
          </div>
        </ContainerTextHeader>
        <Button onClick={handleOnClickContactButton}>CONTRATE</Button>
      </Header>
      <ContainerDescriptionWithVideo>
        <Description>
          <p>
            O <span>GT-Fácil</span> é um poderoso recurso de pesquisa desenvolvido para orientar
            empresas e entidades públicas e privadas na tomada de decisões em relação à tributação
            de seus contratos.
          </p>
          <p>
            A ferramenta oferece informações relevantes, de maneira objetiva, sobre a incidência das
            retenções e encargos relativos ao <span>INSS</span>,<span>IRRF</span>,{" "}
            <span>Contribuições Sociais</span> (CSLL, PIS/Pasep e Cofins) e <span>ISS</span>.
          </p>
        </Description>
        <VideoContainer>
          <iframe
            src="https://player.vimeo.com/video/854776356?title=0&byline=0&portrait=0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </VideoContainer>
      </ContainerDescriptionWithVideo>
      <Description>
        <p>
          É só digitar a descrição do serviço tomado em um dos três tipos de pesquisa disponíveis:{" "}
          <span>pesquisa livre</span>, <span>pesquisa por CNAE</span> (baseado na Classificação
          Nacional de Atividades Econômicas) e{" "}
          <span>pesquisa pela lista da Lei Complementar nº 116/2003</span> (que trata do ISS).
          Selecionando a opção que se adequa ao seu caso concreto, você verá de imediato quais
          tributos deve reter e recolher, assim como outros detalhes relacionados à operação.
        </p>
        <p>
          Se desejar, é possível também realizar o cálculo do que deve ser retido em nosso simulador
          e gerar um relatório em PDF para salvar ou imprimir a consulta.
        </p>
        <p>
          Assista o vídeo ao lado e solicite o contato de um de nossos consultores para fazer uma
          apresentação online. Você se surpreenderá com tanta inovação!
        </p>
        <p>
          <small>*Não disponível em todos os planos</small>
        </p>
      </Description>
    </ModalContainer>
  );
};

export default GTFacilModal;
