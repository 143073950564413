import { Backdrop, Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { backdropStyles } from "~/presentation/components/BackdropLoading";
import React from "react";
import { Close } from "@mui/icons-material";

function CountDown({ open, handleClose, countDown }) {
  const { current, total, message } = countDown;

  const progress = total > 0 ? Math.round((current / total) * 100) : 0;

  return (
    <Backdrop open={open} onClick={handleClose} sx={backdropStyles.backdrop}>
      <Box sx={{ ...backdropStyles.content, gap: 2 }}>
        <Box sx={styles.progressContainer}>
          <CircularProgress
            variant="determinate"
            sx={styles.underCircularProgress}
            thickness={4}
            value={100}
            size={60}
          />
          <CircularProgress
            thickness={4}
            size={60}
            variant="determinate"
            value={progress}
            sx={{ color: "#fff" }}
          />
          <Box sx={styles.textContainer}>
            <Typography sx={[backdropStyles.text, { fontSize: 18, lineHeight: "18px" }]}>
              {progress}%
            </Typography>
          </Box>
        </Box>
        <Typography sx={backdropStyles.text} className="pulse-opacity">
          Por favor aguarde... {current}/{total} concluído.
        </Typography>
        {message && <Typography sx={backdropStyles.text}>{message}...</Typography>}
      </Box>
      <IconButton title="Fechar" sx={backdropStyles.close} onClick={handleClose}>
        <Close />
      </IconButton>
    </Backdrop>
  );
}

export default CountDown;

const styles = {
  progressContainer: {
    position: "relative",
    display: "inline-flex",
  },
  underCircularProgress: {
    color: "rgb(183, 183, 183, 0.4)",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
  },
  textContainer: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
};
