import React, { useContext, useState } from "react";
// controller
import MessagesController from "~/presentation/views/Admin/components/MessagesController";
// data
import filtersList from "~/presentation/views/Admin/TaxInformation/List/FilterList.js";
// components
import ListActions from "~/presentation/views/Admin/components/MessageListActions";
// material ui
import { FilterList as FilterListIcon } from "@mui/icons-material";
import MessageInfoList from "../../components/MessageInfoList";
import OpenPopover from "~/presentation/components/Popover";
import PageListHeader from "../../components/PageListHeader";
import { Box, Pagination, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PeriodFilter from "./PeriodFilter";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { firstDayOfMonth, today } from "~/presentation/utils/constants";

const TaxInformation = ({ taxInformationUseCases }) => {
  const { showError } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  //paginação
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const handleChangePage = (_, newPage) => setPage(newPage);

  const [period, setPeriod] = useState({
    start: firstDayOfMonth,
    end: today,
  });

  const handleChange = (field, value) => setPeriod((prev) => ({ ...prev, [field]: value }));

  const isDateWrong = (date) => !date || date === null || date == "Invalid Date";

  const getTaxInformation = async () => {
    let params = null;

    const startDate = !isDateWrong(period.start) ? period.start.toISOString().split("T")[0] : null;
    const dateEnd = !isDateWrong(period.end) ? period.end.toISOString().split("T")[0] : null;

    if (startDate && dateEnd) {
      params = {
        DateStart: startDate,
        DateEnd: dateEnd,
      };
    }

    return await taxInformationUseCases.getAllTaxInfo(params);
  };

  const {
    mobile,
    selectedMessages,
    messages,
    setSelectedMessages,
    handleRefresh,
    CustomFilterList,
    handleSelectMessage,
    handleClickMessage,
    setMessages,
    filter,
  } = MessagesController({
    getMessages: getTaxInformation,
    filtersList,
    replyMessagePath: "/admin/informacoes-fiscais/responder/",
  });

  const handleDelete = async (ids = []) => {
    openBackdrop(true, "Eliminado IF");
    for (const id of ids) {
      try {
        await taxInformationUseCases.deleteTaxInformation(id);
        setMessages((prevContent) => prevContent.filter((item) => item.id !== id));
      } catch (error) {
        showError(error, `Erro ao eliminar IF com ID: ${id}`);
      }
    }
    openBackdrop(false);
  };

  const paginatedData = messages?.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const Filter = () => (
    <>
      <PeriodFilter period={period} onChange={handleChange} onSubmit={handleRefresh} />
      <Typography sx={{ ...formStyles.title, pl: 2 }}>Filtros</Typography>
      <CustomFilterList />
    </>
  );

  return (
    <Box>
      <PageListHeader title="Informações Fiscais" />
      <Box sx={{ margin: 1 }}>
        <Grid container spacing={1}>
          {!mobile && (
            <Grid size={{ md: 4, xl: 3 }}>
              <Box sx={styles.box}>
                <Filter />
              </Box>
            </Grid>
          )}
          <Grid
            size={{ xs: 12, md: 8, xl: 9 }}
            sx={{
              ...styles.box,
              height: "calc(100vh - 170px)",
            }}
          >
            <Typography sx={{ ...formStyles.title, pt: 2, px: 2 }}>Informações Fiscais</Typography>
            <Box sx={{ px: 1 }}>
              <ListActions
                selectedItems={selectedMessages}
                items={paginatedData}
                onChange={setSelectedMessages}
                handleDelete={handleDelete}
                handleRefresh={handleRefresh}
                renderAfter={
                  mobile &&
                  (() => (
                    <OpenPopover icon={FilterListIcon} label={filter.alias}>
                      {() => <Filter />}
                    </OpenPopover>
                  ))
                }
              />
            </Box>
            <MessageInfoList
              messages={paginatedData}
              selectedMessages={selectedMessages}
              handleSelectMessage={handleSelectMessage}
              disableGutters={mobile}
              onClick={handleClickMessage}
            />
            {messages?.length > itemsPerPage && (
              <Pagination
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
                count={Math.ceil(messages.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TaxInformation;

const styles = {
  box: {
    backgroundColor: "#E6E6E6",
    borderRadius: 2,
    height: "auto",
    p: 1,
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
};
