export const formStyles = {
  container: {
    margin: { xs: 1, md: 3 },
    padding: 3,
    backgroundColor: "#E6E6E6",
    borderRadius: "12px",
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    fontFamily: "Montserrat",
    paddingBottom: 1,
    color: "#000000",
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: "left",
    fontFamily: "Montserrat",
    textTransform: "capitalize",
    color: "#000000",
    paddingLeft: "2px",
    "& .MuiFormControlLabel-label": {
      color: "#000000",
    },
  },
  radioContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "end",
    justifyContent: "start",
    margin: 0.5,
  },
  controlLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      fontWeight: 500,
      textAlign: "left",
      fontFamily: "Montserrat",
      color: "#000000",
      maxWidth: "100%",
      paddingLeft: "2px",
    },
  },
  icon: {
    color: "#222222", // Cor padrão do checkbox
    "&.Mui-checked": {
      color: "#222222", // Cor do checkbox quando está marcado
    },
    "& .MuiSvgIcon-root": {
      fontSize: 24, // Tamanho do ícone do checkbox
    },
  },
  errorMsg: {
    fontSize: 12,
    color: "#d32f2f",
    fontFamily: "Montserrat",
    paddingLeft: 1.4,
  },
  doubleButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    marginBottom: 4,
    gap: 1,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBlock: 3,
  },
  button: {
    width: "min(300px, 90%)",
  },
  addIcon: {
    backgroundColor: "#001A50",
    borderRadius: "12px",
    transition: "opacity 0.5s ease-in-out",
    "&:hover": {
      backgroundColor: "#001A50",
    },
    "&:disabled": {
      backgroundColor: "#001A50",
      opacity: 0.6,
    },
    icon: {
      color: "#fff",
      height: "22px",
      width: "22px",
    },
  },
};
