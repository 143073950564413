import { Box, Button, IconButton, Typography as Text } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { Close, Download } from "@mui/icons-material";

function DetailsIF({ selectedTaxInfo, statusTranslations, handleClose }) {
  return (
    <Box sx={styles.Modal}>
      <Grid container spacing={2}>
        <Grid
          size={12}
          sx={{ display: "flex", justifyContent: "space-between", gap: 1, alignItems: "center" }}
        >
          <Text sx={styles.title}>Detalhes da IF</Text>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>

        {selectedTaxInfo && (
          <>
            <Grid size={{ xs: 12, sm: 2 }}>
              <Text sx={styles.titleModal}>Data:</Text>
              <Box sx={styles.containerFields}>
                <Text>{new Date(selectedTaxInfo.publishDate).toLocaleDateString("pt-BR")}</Text>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <Text sx={styles.titleModal}>Status:</Text>
              <Box sx={styles.containerFields}>
                <Text>
                  {selectedTaxInfo
                    ? statusTranslations[selectedTaxInfo.statusText] || selectedTaxInfo.statusText
                    : ""}
                </Text>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Text sx={styles.titleModal}>Autor:</Text>
              <Box sx={styles.containerFields}>
                <Text>{selectedTaxInfo.authorName}</Text>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Text sx={styles.titleModal}>e-mail:</Text>
              <Box sx={styles.containerFields}>
                <Text>{selectedTaxInfo.email}</Text>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Text sx={styles.titleModal}>Assunto:</Text>
              <Box sx={styles.containerFields}>
                <Text>{selectedTaxInfo.subject}</Text>
              </Box>
            </Grid>
            {/* Renderiza a lista de arquivos, se houver */}
            {selectedTaxInfo.files && selectedTaxInfo.files.length > 0 && (
              <Grid size={{ xs: 12, sm: 4 }}>
                <Text sx={styles.titleModal}>Arquivos:</Text>
                <Box sx={styles.containerFields}>
                  {selectedTaxInfo.files.map((file, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<Download />}
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.filename}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
            <Grid size={12}>
              <Text sx={styles.titleModal}>Mensagem:</Text>
              <Box sx={styles.containerFields}>
                <div dangerouslySetInnerHTML={{ __html: selectedTaxInfo.message }}></div>
              </Box>
            </Grid>
            {/* Renderiza as respostas da IF, se houver */}
            {selectedTaxInfo.responses && selectedTaxInfo.responses.length > 0 && (
              <Grid container spacing={2} sx={{ mt: 1, p: 2 }}>
                <Grid size={12}>
                  <Text variant="h3" sx={styles.title}>
                    Resposta da IF
                  </Text>
                </Grid>
                {selectedTaxInfo.responses.map((response, index) => (
                  <React.Fragment key={index}>
                    <Grid size={{ xs: 12, sm: 4 }}>
                      <Text sx={styles.titleModal}>Data da Resposta:</Text>
                      <Box sx={styles.containerFields}>
                        <Text>{new Date(response.responseDate).toLocaleDateString("pt-BR")}</Text>
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                      <Text sx={styles.titleModal}>Respondido por:</Text>
                      <Box sx={styles.containerFields}>
                        <Text>{response.authorName}</Text>
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                      <Text sx={styles.titleModal}>e-mail:</Text>
                      <Box sx={styles.containerFields}>
                        <Text>{response.email}</Text>
                      </Box>
                    </Grid>
                    <Grid size={12}>
                      <Text sx={styles.titleModal}>Mensagem:</Text>
                      <Box sx={styles.containerFields}>
                        <div dangerouslySetInnerHTML={{ __html: response.message }}></div>
                      </Box>
                    </Grid>
                    {/* Renderiza os arquivos da resposta, se houver */}
                    {response.files && response.files.length > 0 && (
                      <Grid size={12}>
                        <Text sx={styles.titleModal}>Arquivos da Resposta:</Text>
                        {response.files.map((file, fileIndex) => (
                          <Box key={fileIndex} sx={styles.containerFields}>
                            <Text sx={styles.titleModal}>{file.filename}</Text>
                            <Button
                              variant="contained"
                              startIcon={<Download />}
                              href={file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download da IF
                            </Button>
                          </Box>
                        ))}
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}

export default DetailsIF;

const styles = {
  box: {
    border: "2px solid #EFF2F4",
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  containerInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
    width: "100%",
  },

  text: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    fontFamily: "Montserrat, sans-serif",
    mt: 0,
    mb: 0,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  headerText: {
    fontSize: 70,
    fontWeight: 800,
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
    lineHeight: 1,
  },

  //Table styles
  TableHead: {
    justifyContent: "flex-start",
    fontWeight: 500,
    backgroundColor: "#EFF2F4",
  },
  TableCell: {
    fontSize: "0.9rem",
    padding: "4px",
    textAlign: "left",
    backgroundColor: "#EFF2F4",
    color: "#021148",
    fontFamily: "Montserrat",
    "&:hover": {
      color: "#0e4292",
    },
  },
  TableRow: {
    backgroundColor: "#EFF2F4",
    "&:hover": {
      backgroundColor: "#ccc",
    },
    height: "70px",
  },
  Button: {
    background: "inherit",
    paddingInline: 4,
    paddingBlock: 1,
    color: "#1976d2",
  },
  Actions: {
    "&:hover": {
      color: "#0e4292",
    },
  },
  //modal details
  Modal: {
    p: { xs: 1, md: 3, lg: 5 },
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: 500,
    width: "60%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  searchField: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    margin: 0,
    border: "none",
  },
};
