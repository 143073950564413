import { useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import AppContext from "../AppContext";

export const useTokenCheck = () => {
  const token = localStorage.getItem("GT_token");
  const [timeLeft, setTimeLeft] = useState(null);
  const { didLogin } = useContext(AppContext);

  const authenticationService = () => {
    if (token) {
      const user = jwtDecode(token);
      return token && user;
    }
    return null;
  };

  const currentUser = authenticationService();
  const expiration = currentUser?.exp;

  const getTimeLeft = () => {
    const currentTime = Math.floor(new Date().getTime() / 1000);

    if (currentTime < expiration) {
      const time = Math.ceil((expiration - currentTime) / 60);
      return setTimeLeft(time);
    }
    return setTimeLeft(0);
  };

  const handleTokenValidation = () => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    if (!token) return false;
    try {
      if (!expiration) return false;
      return currentTime < expiration;
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  };

  useEffect(() => {
    getTimeLeft();

    const intervalId = setInterval(() => {
      getTimeLeft();
    }, 60000); // Atualiza a cada 1 minuto

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente desmonta
  }, [token, didLogin]);

  const isTokenValid = handleTokenValidation();

  return {
    currentUser,
    isTokenValid,
    timeLeft,
  };
};
