import React, { useEffect, useState } from "react";
import { Alert, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import {
  validationR2010,
  validationR4020AB,
  validationR4020C,
  validationR4020D,
} from "./ValidationSchema/ValidationSchema";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AddCircleOutline,
  ErrorOutline,
  FileOpenOutlined,
  Info,
  InfoOutlined,
  Update,
  Warning,
} from "@mui/icons-material";
import AddMoreNfse from "./AddMoreNfse/AddMoreNfse";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import { reinfPerms } from "~/presentation/security/SecurePaths";
import { makeNfseUseCases } from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import { getColumns } from "../../DocsTable/components/Columns";
import ReinfTable from "~/presentation/views/Reinf/components/Table/ReinfTable";
import { useDialog } from "~/presentation/providers/DialogProvider";
import SendEvents from "../../SendEvents/SendEvents";
import { useBackdrop } from "~/presentation/providers/BackdropProvider";
import useHandleErrors from "~/presentation/views/Reinf/Hooks/useHandleErrors";
import { getSafeArray } from "~/presentation/utils/getSafeArray";
import RenderPaymentLayout from "~/presentation/views/Reinf/components/Layouts/paymentLayout/RenderPaymentLayout";
import { useEDF } from "~/presentation/views/Reinf/SendEDF/SendEDF";
import RenderLayout from "~/presentation/views/Reinf/components/Layouts/nfseLayout/RenderLayout";

function SentTableDocs({
  item,
  filter,
  validNfseData,
  certificateData,
  refreshData,
  isEventClosed,
  setEventsData,
  autoSelectEvents,
}) {
  const apiNfse = makeNfseUseCases();
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const R2010 = filter?.event === "r2010";
  const R4020AB = filter?.event === "r4020" && (QSO === "A" || QSO === "B");
  const R4020C = filter?.event === "r4020" && QSO === "C";
  const R4020D = filter?.event === "r4020" && QSO === "D";
  const R4010 = filter?.event === "r4010";

  const {
    refreshPartnersOptions,
    refreshIncomeNatureOptions,
    serviceClassificationOptions,
    incomeNatureOptions,
    businessPartnerOptions,
    fetchDataOptions,
  } = useEDF();

  const { openBackdrop } = useBackdrop();
  const { showError, showWarning, showSuccess } = useSnackbar();
  const { openDialogBox, closeDialog } = useDialog();

  const { handleClearError } = useHandleErrors();

  const [loading, setLoading] = useState(true);

  //DATA
  const [nfseDataStorage, setNfseDataStorage] = useState([]);
  const [nfseData, setNfseData] = useState([]);

  //CAMPOS
  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [errors, setErrors] = useState({});

  //LINHAS SELECIONADAS
  const [selectedRows, setSelectedRows] = useState([]);

  const handleChangeItem = ({ newItem, id, field }) => {
    setNfseData((prev) => prev.map((doc) => (doc.id === id ? { ...doc, ...newItem } : doc)));
    if (field) handleClearError(`[${id}].${field}`, setErrors);
  };

  const handleChangeINSSitem = ({ newItem, id, nestedIndex, field }) => {
    const currentNFse = nfseData.find((i) => i.id === id);
    const updatedItemINSS = [...currentNFse.itemsNFse];
    updatedItemINSS.splice(nestedIndex, 1, newItem);
    handleChangeItem({ newItem: { itemsNFse: updatedItemINSS }, id: id });
    if (field) handleClearError(`[${id}].itemsNFse[${nestedIndex}].${field}`, setErrors);
  };

  useEffect(() => {
    const fetchNotas = async () => {
      try {
        const response = await apiNfse.getNfseByProtocol(item.protocol);
        if (response) {
          setNfseData(response);
          setNfseDataStorage(response);
          setEventsData((prev) =>
            prev.map((i) => (i.protocol === item.protocol ? { ...i, docs: response } : i))
          );
        }
      } catch (error) {
        showError(error, "Erro no retorno dos dados");
      } finally {
        setLoading(false);
      }
    };

    if (getSafeArray(item.docs).length > 0) {
      setNfseData(item.docs);
      setNfseDataStorage(item.docs);
      setLoading(false);
    } else if (item.protocol) {
      fetchNotas();
    }
  }, [item.docs]);

  const updateFullDoc = (doc) => {
    setNfseData((prev) => prev.map((i) => (i.id === doc.id ? doc : i)));
    setNfseDataStorage((prev) => prev.map((i) => (i.id === doc.id ? doc : i)));
  };

  const handleAddData = (newDocs) => setNfseData((prev) => [...prev, ...newDocs]);

  //Validação dos campos consoante o evento e qso
  const handleValidation = async (data) => {
    openBackdrop(true, "Atualizando o evento");

    //Filtra os documentos que foram selecionados para ter os dados atualizados
    const dataToValidate = nfseData.filter((doc) => data.some((i) => i.id === doc.id));

    if (R2010) {
      const R2010Docs = await Promise.all(
        dataToValidate.map(async (doc) => {
          if (!doc.itemsNFse) {
            // Caso o documento não tenha sido aberto
            const fullDoc = await apiNfse.getNfseById(doc.id);
            return fullDoc;
          }
          return doc;
        })
      );

      return onValidate(validationR2010, R2010Docs);
    }
    if (R4020AB) return onValidate(validationR4020AB, dataToValidate);
    if (R4020C) return onValidate(validationR4020C, dataToValidate);
    if (R4020D) return onValidate(validationR4020D, dataToValidate);
  };

  const onValidate = (validation, data) => {
    validation
      .validate(data, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleUpdateNotasEventos(data);
      })
      .catch((errors) => {
        const docsWithErrors = [];
        setErrors(
          errors.inner.reduce((acc, error) => {
            const path = error.path.split(".");
            const index = parseInt(path[0].replace("[", "").replace("]", ""));
            const id = data[index]?.id;
            docsWithErrors.push(data[index]?.nFseNumber);
            const field = path.slice(1).join(".");
            acc[`[${id}].${field}`] = error.message;
            return acc;
          }, {})
        );
        const formattedDocs = docsWithErrors.reduce((acc, doc, index) => {
          if (index === docsWithErrors.length - 1) return acc + doc + "";
          if (index === docsWithErrors.length - 2) return acc + doc + " e ";
          return acc + doc + ", ";
        }, "");
        showWarning(
          docsWithErrors.length === 1
            ? `Erro no docuemnto ${formattedDocs}`
            : `Erros nos documentos ${formattedDocs}!`
        );
        openBackdrop(false);
      });
  };

  //update de todas as documentos do evento + re-envio
  const handleUpdateNotasEventos = async (data) => {
    openBackdrop(true, "Atualizando documentos");
    setSelectedRows([]);

    try {
      await Promise.all(data.map(async (nfse) => await apiNfse.updateNfse(nfse)));
      setEventsData((prev) =>
        prev.map((event) => {
          if (event.protocol === item.protocol) {
            autoSelectEvents([event]);
            return { ...event, docs: data, hadChanges: true };
          }
          return event;
        })
      );
      showSuccess("Documentos atualizados com sucesso!");
      setInputsDisabled(true);
    } catch (error) {
      showError(error, "Erro ao atualizar documentos!");
    } finally {
      openBackdrop(false);
    }
  };

  //"Botão CANCELAR --> tudo volta ao estado inicial"
  const handleCancel = () => {
    setNfseData(nfseDataStorage);
    setInputsDisabled(true);
    setSelectedRows([]);
    setErrors({});
  };

  const openModal = (type) => {
    openDialogBox({
      fullWidth: true,
      maxWidth: type === "addDocs" ? "lg" : "sm",
      customComponent: () => {
        if (type === "send") {
          return (
            <SendEvents
              data={selectedRows}
              filter={filter}
              certificates={certificateData}
              closeModal={closeDialog}
              refreshData={refreshData}
              receiptNumber={item.receiptNumber}
              operation={2}
            />
          );
        } else if (type === "addDocs") {
          return (
            <AddMoreNfse
              onClose={closeDialog}
              protocol={item.protocol}
              validNfse={validNfseData}
              currentNfse={nfseData}
              setData={handleAddData}
              QSO={QSO}
              selectedEvent={filter.event}
              columns={columns}
            />
          );
        }
      },
    });
  };

  const renderCollapse = (row) => {
    if (inputsDisabled) return null;
    if (R4010) {
      return (
        <RenderPaymentLayout
          data={row}
          businessPartnerOptions={businessPartnerOptions}
          incomeNatureOptions={incomeNatureOptions}
          refreshPartnersOptions={refreshPartnersOptions}
          refreshIncomeNatureOptions={refreshIncomeNatureOptions}
          refreshDatePay={refreshData}
        />
      );
    } else {
      return (
        <RenderLayout
          nfseData={row}
          serviceClassificationOptions={serviceClassificationOptions}
          incomeNatureOptions={incomeNatureOptions}
          businessPartnerOptions={businessPartnerOptions}
          handleChangeFieldValue={handleChangeItem}
          handleChangeINSSfield={handleChangeINSSitem}
          errors={errors}
          setErrors={setErrors}
          fetchOptions={fetchDataOptions}
          onUpdateStateDocs={updateFullDoc}
          isProtocolsTable={true}
          selectedEvent={filter.event}
        />
      );
    }
  };

  const getErrorsIds = () => {
    const ids = new Set();
    Object.keys(errors).forEach((key) => {
      const match = key.match(/\[(\d+)\]/);
      if (match) {
        ids.add(match[1]);
      }
    });
    return Array.from(ids);
  };

  const errorsIds = getErrorsIds();

  const renderFileIcon = (docId) => {
    const hasError = errorsIds.includes(docId.toString());

    const TooltipTitle = hasError ? "Documento com erros." : "Documento pronto para retificação.";

    const icon = hasError ? (
      <Warning sx={{ color: "#DCAD23", fontSize: 26 }} />
    ) : (
      <Info sx={{ color: "gray" }} />
    );

    return <Tooltip title={TooltipTitle}>{icon}</Tooltip>;
  };

  //usado também na tabela do modal de adicionar docs fiscais
  const columnsByQSO = getColumns({
    R2010: R2010,
    R4020AB: R4020AB,
    R4020C: R4020C,
    R4020D: R4020D,
  })?.filter((i) => i.headerName !== "Status");

  const columns = [
    ...columnsByQSO,
    {
      field: "id",
      headerName: "Status",
      sortable: false,
      align: "center",
      renderCell: ({ value }) => renderFileIcon(value), //mostrar quais notas tem erros
    },
  ];

  return (
    <Box sx={{ p: { xs: 1, md: 3 } }}>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={35} color="inherit" />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {item.codeStatus === 2 && (
            <Grid size={12} sx={styles.buttonContainer}>
              <RButton
                securePaths={[reinfPerms.sendRectification]}
                disabled={!inputsDisabled || isEventClosed}
                sx={styles.button}
                onClick={() => setInputsDisabled(false)}
              >
                {inputsDisabled ? "Retificar Evento" : "Retificando..."}
              </RButton>
            </Grid>
          )}
          <Grid size={12}>
            <ReinfTable
              disabledmMargin
              columns={columns}
              data={nfseData}
              collapse={renderCollapse}
              customStyles={{ container: { mb: 0 }, collapseRow: { backgroundColor: "#fff" } }}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              disabledCheckBox={() => inputsDisabled}
              blockCollapse={() => inputsDisabled}
              tableSearch={false}
            />
          </Grid>
          {!inputsDisabled && (
            <>
              <Grid size={{ xs: 12, sm: 12, md: 4, lg: 3, xl: 2.5 }}>
                <RButton onClick={() => openModal("addDocs")}>
                  Adicionar Docs. Fiscais
                  <AddCircleOutline />
                </RButton>
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 1.5 }} sx={{ ml: "auto" }}>
                <RButton variant="outlined" onClick={handleCancel}>
                  CANCELAR
                </RButton>
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
                <Tooltip title="Para confirmar as alterações, selecione os documentos fiscais que deseja atualizar. Para retirar um documento do evento, não o selecione no ato da retificação.">
                  <span style={{ cursor: "not-allowed" }}>
                    <RButton
                      disabled={selectedRows.length === 0}
                      onClick={() => handleValidation(selectedRows)}
                    >
                      CONFIRMAR
                    </RButton>
                  </span>
                </Tooltip>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Box>
  );
}

export default SentTableDocs;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  button: {
    width: "fit-content",
    maxWidth: "100%",
    ml: "auto",
    px: 4,
  },
};
