import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ExitToApp,
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import usePageTopOffset from "~/presentation/hooks/usePageTopOffset";
import AppContext from "~/presentation/AppContext";
import { Container, Button, Box, Stack, Link as MUILink } from "@mui/material";
import CountDownToken from "../CountDownToken/CountDownToken";
import { hasPermission } from "~/presentation/security/SecurityPath";

const Navbar = ({
  toggleMobileDrawer,
  openLoginDialog,
  parentRef,
  navbarItems,
  mobile,
  isLogged,
}) => {
  const [itemHover, setItemHover] = useState((item) => (item ? item : null));
  const location = useLocation();
  const [topOffset] = usePageTopOffset(parentRef);
  const { handleLogout } = useContext(AppContext);

  const hoverEvent = (target) => {
    if (mobile) return;

    return {
      onMouseEnter: () => setItemHover(target),
      onMouseLeave: () => setItemHover(),
    };
  };

  const isItemActive = (name) => {
    const { hash, pathname } = location;

    if (pathname === "/" + name) {
      return true;
    }

    if (name === "blog" && pathname.split("/").includes("blog")) {
      return true;
    }

    if (name === "conteudo-selecionado") {
      return pathname.includes("conteudo-selecionado");
    }

    if (name === "outros") {
      return ["meu-painel", "qso", "ajuda"].includes(pathname.split("/").pop());
    }

    if (hash !== "") {
      return hash.includes(name);
    }
  };

  return (
    <Box sx={styles.container} className={`${topOffset && "collapse"} ${mobile && "mobile"}`}>
      <Container maxWidth="lg" disableGutters>
        {mobile ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{ paddingInline: 2 }}
          >
            <Link to="/cliente">
              <Box component="img" src="/assets/logo_gt.png" alt="company logo" sx={styles.logo} />
            </Link>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isLogged && <CountDownToken color="#1341a1" />}
              <Button sx={styles.mobileMenuButton} onClick={() => toggleMobileDrawer()}>
                <MenuIcon style={{ color: "#000" }} fontSize="large" />
              </Button>
            </Box>
          </Stack>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <Link to="/cliente">
              <Box component="img" src="/assets/logo_gt.png" alt="company logo" sx={styles.logo} />
            </Link>
            {navbarItems.map((item, index) => {
              const isActive = isItemActive(item.name);
              const isHover = itemHover === item.id;
              const securePaths = item?.securePaths ?? [];
              if (securePaths.length > 0) {
                if (!hasPermission(securePaths)) {
                  return null;
                }
              }
              return (
                <MUILink
                  key={"item" + index}
                  href={item.link}
                  sx={styles.listItemLink}
                  className={isActive && "active"}
                  {...hoverEvent(item.id)}
                >
                  {item.label}
                  {item.subLinks && (
                    <Box sx={styles.subLinksWrapper} className={isHover && "show"}>
                      {item.subLinks.map((subLink) => {
                        const securePaths = subLink?.securePaths ?? [];
                        if (securePaths.length > 0) {
                          if (!hasPermission(securePaths)) {
                            return null;
                          }
                        }
                        return (
                          <MUILink
                            key={"sub-link-" + subLink.id}
                            href={subLink.link}
                            sx={styles.subLink}
                          >
                            {subLink.label}
                          </MUILink>
                        );
                      })}
                    </Box>
                  )}
                </MUILink>
              );
            })}
            {isLogged && <CountDownToken color="#1341a1" />}
            {isLogged ? (
              <Button
                sx={styles.ButtonLogin}
                onClick={handleLogout}
                startIcon={<ExitToApp style={{ fontSize: 22 }} color="#0d2338" />}
              >
                Sair
              </Button>
            ) : (
              <Button
                sx={styles.ButtonLogin}
                onClick={openLoginDialog}
                startIcon={<AccountCircleIcon style={{ fontSize: 25 }} color="#0d2338" />}
              >
                Área do Cliente
              </Button>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Navbar;

const styles = {
  container: {
    position: "fixed",
    top: 0,
    zIndex: 1201,
    display: "flex",
    alignItems: "center",
    height: "70px",
    width: "100%",
    transition: "all 0.3s ease-in-out",
    borderBottom: "2px solid #e6e6e6",
    background: "#fff",
    color: "#fafafa",
    "&.collapse, &.mobile": {
      height: "75px",
    },
  },
  logo: {
    height: "50px",
    maxHeight: "100px",
    display: "flex",
    alignItems: "center",
    img: {
      height: "70%",
      objectFit: "contain",
    },
  },
  listItemLink: {
    position: "relative",
    width: "min-content",
    listStyle: "none",
    cursor: "pointer",
    display: "grid",
    gridTemplateRows: "auto 3px",
    alignItems: "center",
    color: "#0d2338",
    textDecoration: "none",
    letterSpacing: "0.02em",
    textTransform: "capitalize",
    lineHeight: "16px",
    whiteSpace: "nowrap",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    "&:hover": {
      color: "#ff6829",
    },
    "&.active": {
      color: "#000",
      fontWeight: 700,
    },
  },
  mobileMenuButton: {
    border: "none",
    outline: "none",
    height: "36px",
    width: "36px",
    color: "#fafafa",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all 0.1s ease-in-out",
    "&:active": {
      transform: "scale(0.96)",
    },
  },
  loginWrapper: {
    background: "#fafafa",
    borderRadius: "4px",
  },
  arrow: {
    position: "absolute",
    marginTop: "-6px",
    width: "0",
    height: "0",
    borderLeft: "7px solid transparent",
    borderRight: "7px solid transparent",
    color: "#fafafa",
    borderBottom: "7px solid #fafafa",
  },
  subLinksWrapper: {
    zIndex: 0,
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    minWidth: "max-content",
    backgroundColor: "#fff",
    borderRadius: "8px",
    overflow: "hidden",
    display: "none",
    "&.show": {
      display: "block",
    },
  },
  subLink: {
    display: "block",
    padding: 1.5,
    color: "#0d2338",
    fontWeight: 500,
    background: "#e6e6e6",
    fontFamily: "Montserrat, sans-serif",
    textDecoration: "none",
    "&:hover": {
      background: "#1341a1",
      color: "#fff",
    },
  },
  ButtonLogin: {
    whiteSpace: "nowrap",
    borderRadius: "26px",
    background: "#1341a1", //#0d62a5
    color: "#fff",
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 700,
    paddingBlock: "8px",
    paddingInline: "18px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: "#1341a1",
    },
  },
};
