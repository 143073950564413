import React from "react";
import Select from "react-select";

const SelectField = ({
  options,
  field,
  placeholder,
  form,
  onChange,
  padding,
  label,
  defaultValue,
  ...rest
}) => {
  const getValue = () => {
    if (!options) return "";
    const foundOption = options.find(
      (option) => JSON.stringify(option.value) === JSON.stringify(field.value)
    );
    return foundOption;
  };

  return (
    <Select
      placeholder={placeholder}
      label={label}
      defaultValue={defaultValue}
      options={options}
      value={getValue()}
      onChange={(option) => {
        if (onChange) {
          onChange(option);
        } else {
          form.setFieldValue(field.name, option.value);
        }
      }}
      styles={styleSelectInput(padding)}
      noOptionsMessage={() => "Não há opções"}
      isDisabled={rest.disabled}
      maxMenuHeight={200}
      menuPosition={"fixed"}
      {...rest}
    />
  );
};

export default SelectField;

const styleSelectInput = (padding) => ({
  control: () => ({
    width: "100%",
    border: "1px solid #7D7D7D",
    fontSize: 14,
    fontFamily: "Montserrat",
    height: "38px",
    backgroundColor: "#FFFFFF",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    background: "#FBFBFB",
    borderRadius: "3px",
    border: "1px solid #7D7D7D",
    fontSize: 14,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: padding || "12px",
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "12px",
    },
    "::-webkit-scrollbar-track": {
      background: "#c1c1c1",
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
  }),
  option: (provided) => ({
    ...provided,
    background: "transparent",
    color: "#000",
    textAlign: "left",
    ":hover": {
      background: "#CDCDCD",
      cursor: "pointer",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000",
    padding: "6px 10px",
  }),
  dropdownIndicator: () => ({
    color: "#000000",
    cursor: "pointer",
    padding: "6px 10px 0px 6px",
  }),
});
