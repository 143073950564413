import * as Yup from "yup";
import removeMask from "~/presentation/functions/removeMask";

export default Yup.object({
  name: Yup.string().required("Campo Obrigatório!"),
  email: Yup.string().email("Digite um email válido").required("Campo Obrigatório!"),
  phone: Yup.string().transform((value, originalValue) => originalValue && removeMask(value)),
  mobilePhone: Yup.string().transform((value, originalValue) => originalValue && removeMask(value)),
  password: Yup.string()
    .required("Campo Obrigatório!")
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/,
      "A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial."
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "As senhas devem ser iguais.")
    .required("Campo Obrigatório!"),
});
