import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { SectionHeader } from "~/presentation/views/Client/ClientHome/components/SectionHeader";
import { Box, Paper, Stack } from "@mui/material";
import {
  CalculatorIcon,
  DialogIcon,
  TextIcon,
  TaskIcon,
} from "~/presentation/views/Client/ClientHome/Sections/Features/icons";
import Grid from "@mui/material/Grid2";
import { hasPermission, hasReinfPermission } from "~/presentation/security/SecurityPath";
import { clientPerms } from "~/presentation/security/SecurePaths";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

const features = [
  {
    id: 1,
    icon: CalculatorIcon,
    title: "GT-Fácil",
    text: "Digite o tipo serviço e veja sua tributação em relação ao INSS, IRRF, CSLL, PIS/Pasep, COFINS e ISS.",
    link: "/gt-facil",
    main: false,
    errorMessage: "Você não tem permissão para acessar ao GT-Fácil.",
    securePaths: [
      clientPerms.seeCnaeResearch,
      clientPerms.seeLcResearch,
      clientPerms.seeFreeSearch,
    ],
  },
  {
    id: 2,
    icon: TaskIcon,
    title: "GT-Reinf",
    text: "Envio da EFD-Reinf de forma simplificada com a segurança que você precisa.",
    link: "/reinf",
    main: true,
  },
  {
    id: 3,
    icon: TextIcon,
    title: "Conteúdo Selecionado",
    text: "Leia artigos, assista vídeos e veja a legislação específica sobre a tributação dos seus contratos.",
    main: false,
  },
  {
    id: 4,
    icon: DialogIcon,
    title: "Blog",
    text: "Fique atualizado com o que acontece de mais recente acerca das retenções incidentes na fonte.",
    link: "/cliente/blog",
    main: false,
    errorMessage: "Você não tem permissão para acessar ao Blog.",
    securePaths: [clientPerms.seeBlog],
  },
];

export const Features = ({ onExpand, openModal, user }) => {
  const { showError } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const handleClick = (link) => {
    if (link) {
      navigate(link);
      return;
    }
    onExpand();
  };

  let name = !user ? "" : user?.name.split(" ")[0];

  const openMarketingModal = (link) => {
    if (link === "/reinf") {
      return openModal();
    }
    return;
  };

  return (
    <Box
      sx={{
        background: "#fff",
        position: "relative",
        height: {
          xs: "2400px",
          sm: "1364px",
          md: "160vh",
          lg: "110vh",
        },
      }}
    >
      <Box
        sx={{
          zIndex: 0,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background:
            "radial-gradient(50% 91.65% at 50% 50%, #ffffff 0%, rgba(238, 238, 238, 0) 100%)",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "2.5rem max(20px, 10vw)",
          rowGap: "max(32px, 5vw)",
          position: "absolute",
          top: 0,
          left: 0,
          height: "80%",
          width: "100%",
          zIndex: 1,
        }}
      >
        <SectionHeader text={`OLÁ ${name?.toUpperCase()}, O QUE VOCÊ DESEJA?`} />
        <Grid container spacing={3}>
          {features.map((card) => {
            let permission = true;
            if (card.link === "/reinf") {
              permission = hasReinfPermission();
            } else {
              permission = card.securePaths ? hasPermission(card.securePaths) : true;
            }
            return (
              <Grid key={card.id} size={{ sx: 12, sm: 6, lg: 3 }}>
                <Paper
                  elevation={3}
                  onClick={() => {
                    if (permission) {
                      return handleClick(card.link);
                    } else if (card.errorMessage) {
                      return showError({}, card?.errorMessage || "Não tem permissão!");
                    } else {
                      return openMarketingModal(card.link);
                    }
                  }}
                  sx={{
                    position: "relative",
                    borderRadius: 5,
                    transition: "transform 0.3s ease-out",
                    cursor: "pointer",
                    height: "25rem",
                    width: "100%",
                    color: card.main ? "#fff" : "#000",
                    backgroundColor: card.main ? "#0d62a5" : "#fff",
                    backgroundImage: card.main ? `url('/assets/icons/tagyellow.png')` : "none",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top left",
                    backgroundSize: "95px 95px",
                    "&:hover": {
                      transform: "scale(1.05)",
                      backgroundColor: card.main ? "#0d62a5" : "#ededed",
                    },
                  }}
                >
                  <Stack
                    spacing={5}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      paddingBlock: 5,
                      paddingInline: 3,
                    }}
                  >
                    <Box
                      component={card.icon}
                      sx={{ width: 60, height: "70px", objectFit: "contain" }}
                    />
                    <Box
                      component="h3"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        textAlign: "center",
                        fontWeight: 500,
                        fontSize: "max(18px, 1.2vw)",
                      }}
                    >
                      {card.title}
                    </Box>
                    <Box
                      component="p"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        textAlign: "center",
                        fontSize: "max(15px, 1.1vw)",
                      }}
                    >
                      {card.text}
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};
