import React from "react";
import { ErrorMessage as ErrMsg } from "formik";
import { styled } from "@mui/material";

const Wrapper = styled("span")(() => ({
  fontFamily: "sans-serif",
  color: "#d32f2f",
  fontSize: "clamp(13px, 0.75vw, 14px)",
}));

export const ErrorMessage = ({ children }) => (
  <Wrapper className="errorMessage">{children}</Wrapper>
);

export const FormikCustomErrorMessage = (props) => (
  <Wrapper className="errorMessage" {...props}>
    <ErrMsg {...props} />
  </Wrapper>
);

export default FormikCustomErrorMessage;
