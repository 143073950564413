import React from "react";
import { Box, Typography as Text, Paper } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Grid from "@mui/material/Grid2";

export const AttentionBanner = ({
  text = (
    <>
      Para visualizar os documentos/eventos, selecione e aplique os <b>filtros</b>!
    </>
  ),
  icon: Icon = ReportProblemIcon,
}) => {
  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Box sx={{ width: "min(700px, 100%)", m: 3 }}>
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
            borderRadius: 4,
          }}
        >
          <Icon sx={{ fontSize: 80, color: "#FFCC00", marginBlock: 1 }} />
          <Text
            sx={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: 400,
              marginBlock: 0,
              paddingBlock: 2,
            }}
          >
            {text}
          </Text>
        </Paper>
      </Box>
    </Grid>
  );
};
