import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import ImageInput from "~/presentation/components/ImageInput";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";

const CategoryForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  uploadedImage,
  handleImageUpload,
}) => {
  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Dados da Categoria</Typography>
        <Grid container spacing={1}>
          <Grid size={12}>
            <Input
              type="text"
              title="Título"
              name="title"
              onChange={(e) => onChangeValue("title", e.target.value)}
              value={values?.title}
              error={errors.title}
            />
          </Grid>
          <Grid size={12} sx={formStyles.checkBoxContainer}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values.isSerie}
                  onChange={() => {
                    if (values.isSerie) {
                      onChangeValue("image", "");
                      onChangeValue("description", "");
                    }
                    onChangeValue("isSerie", !values.isSerie);
                  }}
                  name="isSerie"
                />
              }
              label="Marcar Categoria como Série"
            />
          </Grid>
          {values?.isSerie && (
            <>
              <Grid size={12}>
                <Input
                  type="textarea"
                  title="Descrição"
                  name="description"
                  onChange={(e) => onChangeValue("description", e.target.value)}
                  value={values?.description}
                  error={errors.description}
                  minRows={2}
                  maxRows={2}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 8 }}>
                <Typography sx={{ color: errors.image && "#d32f2f", ...formStyles.subtitle }}>
                  Imagem da Publicação{" "}
                </Typography>
                <ImageInput
                  title="Selecionar imagem"
                  imageFile={values.imageUrl}
                  onChange={(event) => {
                    handleImageUpload(event);
                    onChangeValue("imageUrl", event.target.files[0]);
                  }}
                  error={errors.imageUrl}
                  uploadedImageRef={uploadedImage}
                />
                {errors.imageUrl && (
                  <Typography sx={formStyles.errorMsg}>{errors.imageUrl}</Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default CategoryForm;
