import { hasPermission } from "~/presentation/security/SecurityPath";
import { apiURL } from "../../usecases/api";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiBlogCommentUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAllComments() {
    if (!hasPermission([adminPerms.seeComments])) {
      return new Error(securityErrorMessage("visualizar comentários do blog."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getCommentsByBlogPostId(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment/comments-post/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getCommentById(id) {
    if (!hasPermission([adminPerms.seeComments])) {
      return new Error(securityErrorMessage("visualizar comentário do blog."));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async addNewComment(data) {
    if (!hasPermission([adminPerms.addComments])) {
      return new Error(securityErrorMessage("adicionar comentário do blog."));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment`,
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async submitCommentResponse(data) {
    try {
      const response = await this.httpClient.request({
        url: `/comment`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateCommentResponse(data) {
    if (!hasPermission([adminPerms.editComments])) {
      return new Error(securityErrorMessage("editar comentário do blog."));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/comment`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteComment(id) {
    if (!hasPermission([adminPerms.deleteComments])) {
      return new Error(securityErrorMessage("excluir comentário do blog."));
    }
    try {
      const result = await this.httpClient.request({
        url: `${apiURL}/comment/${id}`,
        method: "DELETE",
      });
      return result;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
