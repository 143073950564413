import React from "react";
import { painelStyles } from "~/presentation/views/Client/MyPanel";
import CommonTable from "~/presentation/components/Table/CommonTable";

const MyPainelTable = (props) => {
  const styles = {
    table: { backgroundColor: painelStyles?.bgColor },
    searchInput: {
      width: "min(300px, 100%)",
      backgroundColor: painelStyles?.bgColor,
      color: painelStyles?.textColor,
      height: 50,
      border: "none",
    },
    exportButton: {
      button: {
        backgroundColor: painelStyles?.bgColor,
        color: painelStyles?.textColor,
        height: 50,
        border: "none",
        "&:hover": {
          backgroundColor: painelStyles?.hoverColor,
        },
      },
    },
    tableHeader: {
      backgroundColor: painelStyles?.bgColor,
    },
    headerCell: {
      color: painelStyles?.textColor,
    },
    sortIcon: {
      color: painelStyles?.textColor,
    },
    bodyRow: {
      borderRadius: 3,
      overflow: "hidden",
    },
    bodyCell: {
      backgroundColor: painelStyles?.bgColor,
      py: 1,
      px: 2,
    },

    pagination: {
      backgroundColor: painelStyles?.hoverColor,
      color: painelStyles?.textColor,
      "& .MuiTablePagination-root, .MuiButtonBase-root, .MuiSvgIcon-root": {
        color: painelStyles.textColor,
      },
    },
  };

  return <CommonTable {...props} costumStyles={styles} tableExport />;
};

export default MyPainelTable;
