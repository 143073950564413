import { handleErrors } from "~/presentation/utils/errorsHandling";
import { apiURL } from "../../usecases/api";
import { hasPermission } from "~/presentation/security/SecurityPath";
import { adminPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
export class ApiTerm {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAll({ checkPermission = true }) {
    if (checkPermission) {
      if (!hasPermission([adminPerms.seeSearchTerms])) {
        throw new Error(securityErrorMessage("visualizar termos de pesquisa"));
      }
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/searchterm`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        throw new Error(err);
      }
    }
  }

  async getTermById(id) {
    if (!hasPermission([adminPerms.seeSearchTerms])) {
      throw new Error(securityErrorMessage("visualizar termos de pesquisa"));
    }

    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/searchterm/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        throw new Error(err);
      }
    }
  }

  async createTerm(data) {
    if (!hasPermission([adminPerms.addSearchTerms])) {
      throw new Error(securityErrorMessage("adicionar termos de pesquisa"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/searchterm`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        throw new Error(err);
      }
    }
  }

  async updateTerm(data) {
    if (!hasPermission([adminPerms.editSearchTerms])) {
      throw new Error(securityErrorMessage("editar termos de pesquisa"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/searchterm`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        throw new Error(err);
      }
    }
  }

  async deleteTerm(id) {
    if (!hasPermission([adminPerms.deleteSearchTerms])) {
      throw new Error(securityErrorMessage("excluir termos de pesquisa"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/searchterm/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        const handledError = handleErrors(err.response.data);
        throw handledError;
      } else {
        throw new Error(err);
      }
    }
  }
}
