import React, { useContext } from "react";
import {
  ExitToApp,
  ForkLeft,
  Logout,
  PowerSettingsNew as PowerSettingsNewIcon,
} from "@mui/icons-material";
import { Avatar, Box, Typography as Text, Button, Link as MuiLink } from "@mui/material";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import AppContext from "~/presentation/AppContext";
import CountDownToken from "~/presentation/components/CountDownToken/CountDownToken";
import Popover from "~/presentation/components/Popover/Popover";
import { Link } from "react-router-dom";
import { commonColors } from "~/presentation/themes/styles.App";

const ReinfToolbar = () => {
  const { handleLogout } = useContext(AppContext);
  const { name, companyInfor } = JSON.parse(localStorage.getItem("GT_user"));
  const userFirstLetter = name.charAt(0) || "";

  return (
    <Box sx={styles.toolbar}>
      <Box sx={styles.titleContainer}>
        <Text sx={[styles.title, { display: { xs: "none", md: "flex" } }]}>Usuário | {name}</Text>
        <Text sx={styles.title}>
          {companyInfor?.name} | {companyInfor?.cnpj ? formatCpfCnpj(companyInfor.cnpj) : ""}
        </Text>
      </Box>
      <Box sx={styles.flex}>
        <MuiLink component={Link} sx={styles.helpLink} to="/cliente/ajuda">
          Instruções de uso do módulo
        </MuiLink>
        <CountDownToken />
        <Popover
          icon={() => (
            <Avatar color={"#254C9F"} sx={styles.avatar}>
              {userFirstLetter}
            </Avatar>
          )}
          styles={{
            popover: {},
            icon: { color: "#000" },
            content: {
              p: 0,
              display: "flex",
              flexDirection: "Column",
              alignItems: "start",
            },
          }}
        >
          <>
            <MuiLink component={Link} sx={[styles.linkButton, styles.flex]} to="/cliente">
              Ir para início
            </MuiLink>
            <MuiLink
              component={Link}
              sx={[styles.linkButton, styles.flex]}
              to="/reinf/configuracoes"
            >
              Configurações
            </MuiLink>
            <Button sx={[styles.linkButton, styles.flex]} onClick={handleLogout}>
              Sair
              <ExitToApp fontSize="small" sx={{ p: 0, color: commonColors.reinf.primary }} />
            </Button>
          </>
        </Popover>
      </Box>
    </Box>
  );
};

export default ReinfToolbar;

const styles = {
  toolbar: {
    width: { xs: "95%", sm: "100%" },
    height: "50px",
    display: "flex",
    alignItems: "center",
    gap: 1,
    justifyContent: "space-between",
  },
  flex: { display: "flex", alignItems: "center", gap: 1 },
  titleContainer: {},
  title: { fontFamily: "Montserrat", color: "#fff", fontSize: 12 },
  avatar: {
    height: 23,
    width: 23,
  },
  helpLink: {
    fontFamily: "Montserrat",
    color: "#fff",
    fontSize: 12,
    "& e": {
      display: { xs: "none", md: "flex" },
    },
  },
  linkButton: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "none",
    textTransform: "none",
    width: "100%",
    height: 40,
    px: 1.6,
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
};
