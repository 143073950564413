import React from "react";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import QuadroQSO from "../components/QuadroQSO";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const url =
  "https://player.vimeo.com/video/267872087?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

const DEFAULT_IMAGE = "/assets/desk.jpg";

const QSO = () => {
  const qsoTable = {
    inss1: [],
    inss2: [],
    inss3: [],
    irrf1: [],
    irrf2: [],
    csllPisCofins: [],
    iss1: [],
    iss2: [],
  };

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <SectionTitlePost title="QSO" image={DEFAULT_IMAGE} blur={0.5} />
          <Container
            maxWidth="lg"
            sx={{
              margin: "3rem auto",
            }}
          >
            <Box
              sx={{
                marginBottom: "3rem",
                padding: "2.5rem 2rem",
                display: "flex",
                flexDirection: "column",
                gap: "2.5rem",
                borderRadius: "10px",
                backgroundColor: "#E6E6E6",
                alignItems: "center",
              }}
            >
              <Grid container spacing={{ xs: 5, md: 9 }}>
                <Grid
                  size={{ xs: 12, md: 6 }}
                  sx={{
                    borderRadius: "10px",
                    minHeight: 310,
                  }}
                >
                  <iframe
                    src={url}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    style={{
                      borderRadius: "10px",
                      height: "100%",
                      width: "100%",
                    }}
                    allowFullScreen
                    title={"Quadro Sinótico de Obrigações ( QSO )"}
                  ></iframe>
                </Grid>
                <Grid
                  size={{ xs: 12, md: 6 }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start", 
                    textAlign: "left",
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 500, pb: 8 }}>
                    Quadro Sinótico de Obrigações ( QSO )
                  </Typography>
                  <Typography sx={{pr: 8}}>
                    O Quadro Sinótico de Obrigações – QSO oferece uma visão panorâmica das
                    obrigações tributárias abrangidas pelo sistema web Gestão Tributária,
                    facilitando a identificação dos conteúdos que estão relacionados a cada tributo
                    examinado e considerando também a natureza jurídica da fonte pagadora. Confira a
                    explicação ao lado acerca de sua estrutura e aplicação!
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                gap: "2.5rem",
              }}
            >
              <Box
                sx={{
                  padding: "0 1rem",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  Visualizar QSO
                </Typography>
              </Box>
              <QuadroQSO data={qsoTable} />
            </Box>
          </Container>
        </>
      )}
    </HomeLayout>
  );
};

export default QSO;
