import React, { useContext, useState } from "react";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";
import typeTax from "~/presentation/utils/typeTax";
import PageListHeader from "../../../components/PageListHeader";
import TableActions from "~/presentation/components/TableActions";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import companyType from "~/presentation/utils/companyType";
import CreateEditCompanyCategory from "../../../CompanyCategory/CreateEdit/CreateEdit";
import CreateEdit from "../../../Term/CreateEdit/CreateEdit";
import CreateEditItemAnswer from "../../../ItemAnswer/CreateEdit/CreateEdit";
import { makeTypeAnswerUseCases } from "~/main/factories/use-cases/types-answer";
import { Tab, TabPanel, Tabs, TabsList } from "~/presentation/components/Tabs/Tabs";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { adminPerms } from "~/presentation/security/SecurePaths";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import AnswerItemsTab from "../ItemTabs/AnswerItemsTab";
import InBulkHandler, {
  onInBulkDelete,
} from "~/presentation/components/ListComponent/components/InBulkHandler";
import AdminTable from "../../../components/AdminTable/AdminTable";

const AnswerForm = ({
  tabsRef,
  values,
  onChangeValue,
  handleSubmit,
  errors,
  fetchTerms,
  fetchItemAnswer,
  fetchCategories,
  cnaes,
  itemsAnswer,
  categories,
  terms,
}) => {
  const typesAnswerUseCases = makeTypeAnswerUseCases();
  const { openDialogBox, closeDialog, closeSecondDialog, openSecondDialog } =
    useContext(DialogContext);
  const { showError, showInfo } = useContext(SnackbarContext);

  // Controlar tabs para não alterar o estado quando existem alterações
  const [openTabs, setOpenTabs] = useState(1);
  const handleChangeTabs = (_, newValue) => setOpenTabs(newValue);

  const handleCategoryList = () => {
    openDialogBox({
      maxWidth: "xs",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          data={categories}
          checkedData={values?.categories}
          title="Categorias"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("categories", values)}
          handleCreate={handleCategoryModal}
          titleAdd="Criar nova categoria"
          securePaths={[adminPerms.addCompanyCategory]}
          costumFilter={{
            title: "QSO",
          }}
        />
      ),
    });
  };

  const handleCategoryModal = () => {
    openSecondDialog({
      customComponent: () => (
        <CreateEditCompanyCategory
          data={null}
          onClose={closeSecondDialog}
          refreshData={fetchCategories}
        />
      ),
    });
  };

  const handleCompanyTypeList = () =>
    openDialogBox({
      maxWidth: "xs",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          data={companyType}
          checkedData={values?.companyTypes}
          title="Tipos de empresa"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("companyTypes", values)}
          setHeightAuto
        />
      ),
    });

  const handleTermsList = () => {
    openDialogBox({
      maxWidth: "xs",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          data={terms}
          checkedData={values?.searchTerms}
          title="Termos de pesquisa"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("searchTerms", values)}
          handleCreate={handleTermsModal}
          titleAdd="Criar novo termo de pesquisa"
          securePaths={[adminPerms.addSearchTerms]}
          setHeightAuto
        />
      ),
    });
  };

  const handleTermsModal = () => {
    openSecondDialog({
      customComponent: () => (
        <CreateEdit data={null} onCloseForm={closeSecondDialog} refreshData={fetchTerms} />
      ),
    });
  };

  const handleCnaeList = () => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          disablePadding={false}
          data={cnaes}
          checkedData={values?.cnaes}
          title="Cnaes"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("cnaes", values)}
          itemsPerPage={5}
          costumFilter={{
            title: "InBulk",
            field: "code",
            nameField: "CNAES",
          }}
        />
      ),
    });
  };

  const handleDeleteCNAE = (codes = []) => {
    onInBulkDelete({
      codes: codes,
      data: values?.cnaes,
      onChange: (values) => onChangeValue("cnaes", values),
      showUpdateCount: (items) =>
        showInfo(items === 1 ? "Foi removido 1 CNAE" : `Foram removidos ${items} CNAES`),
    });
  };

  const handleItemsList = () => {
    openDialogBox({
      maxWidth: "sm",
      fullWidth: true,
      customComponent: () => (
        <ListComponent
          data={itemsAnswer}
          checkedData={values?.gtAnswerItems}
          title="Itens de resposta"
          onClose={closeDialog}
          handleAdd={(values) => onChangeValue("gtAnswerItems", values)}
          handleCreate={handleItemsModal}
          titleAdd="Criar novo item"
          disablePadding={false}
          itemsPerPage={5}
          securePaths={[adminPerms.addAnswerTypes]}
        />
      ),
    });
  };

  const handleItemsModal = () => {
    openSecondDialog({
      maxWidth: "md",
      fullWidth: true,
      customComponent: () => (
        <CreateEditItemAnswer
          data={null}
          onCloseForm={closeSecondDialog}
          refreshData={fetchItemAnswer}
          typesAnswer={typesAnswer}
          fetchOptionsData={getTypesAnswer}
        />
      ),
    });
  };

  const handleDelete = (id, field) => {
    openSecondDialog({
      message: "Tem a certeza que deseja excluir?",
      callback: () => {
        if (values[field]?.length === 1) {
          onChangeValue(`${field}`, []);
        } else {
          const filtedValue = values[field].filter((item) => item.id !== id);
          onChangeValue(`${field}`, filtedValue);
        }
      },
    });
  };

  const [typesAnswer, setTypesAnswer] = useState([]);

  const getTypesAnswer = async () => {
    const result = await loadTypes();
    if (result) {
      setTypesAnswer(result);
    }
  };

  const loadTypes = async () => {
    try {
      const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer({ checkPermission: false });
      return apiTypesAnswer;
    } catch (error) {
      showError(error, "Erro ao carregar tipos de resposta");
    }
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Detalhes da resposta</Typography>
        <Grid container spacing={1}>
          <Grid size={12}>
            <Input
              type="text"
              name="gtAnswerName"
              title="Título"
              value={values.gtAnswerName}
              onChange={(e) => onChangeValue("gtAnswerName", e.target.value)}
              error={errors.gtAnswerName}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 9 }}>
            <Input
              type="text"
              name="description"
              title="Descrição"
              value={values.description}
              onChange={(e) => onChangeValue("description", e.target.value)}
              error={errors.description}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 3 }}>
            <Input
              type="select"
              options={typeTax}
              onChange={(item) => onChangeValue("typeTax", item.value)}
              name="typeTax"
              title="Tipo de taxa"
              value={typeTax.find((item) => item.value === values?.typeTax)}
              error={errors.typeTax}
            />
          </Grid>
        </Grid>
      </Box>

      <Box ref={tabsRef}>
        <Tabs value={openTabs} onChange={handleChangeTabs}>
          <TabsList>
            <Tab value={1} error={errors.cnaes}>
              Cnaes
            </Tab>
            <Tab value={2} error={errors.categories}>
              Categoria
            </Tab>
            <Tab value={3} error={errors.companyTypes}>
              Tipo de empresa
            </Tab>
            <Tab value={4} error={errors.searchTerms}>
              Termos de Pesquisa
            </Tab>
            <Tab value={5} error={errors.gtAnswerItems}>
              Itens de resposta
            </Tab>
          </TabsList>
          <TabPanel value={1} error={errors.cnaes}>
            <PageListHeader
              title="Cnaes"
              titleAdd="Adicionar Cnaes"
              handleAdd={handleCnaeList}
              error={errors.cnaes}
            />
            <AdminTable
              columns={[
                { field: "code", headerName: "Código", flex: 1, align: "center" },
                { field: "detailCode", headerName: "Detalhe", flex: 1, align: "center" },
                { field: "description", headerName: "Descrição", flex: 6, hide: "md" },
                {
                  field: "actions",
                  headerName: "Ações",
                  sortable: false,
                  filterable: false,
                  align: "center",
                  flex: 1,
                  renderCell: (params) => (
                    <TableActions handleDelete={() => handleDelete(params.row.id, "cnaes")} />
                  ),
                },
              ]}
              costumFilter={
                <Box sx={{ minWidth: { sm: 360 } }}>
                  <InBulkHandler
                    onChange={handleDeleteCNAE}
                    data={values?.cnaes}
                    action="delete"
                    field="code"
                    nameField="CNAES"
                  />
                </Box>
              }
              data={values?.cnaes}
            />
            {errors.cnaes && <Typography sx={formStyles.errorMsg}>{errors.cnaes}</Typography>}
          </TabPanel>
          <TabPanel value={2} error={errors.categories}>
            <PageListHeader
              title="Categoria"
              titleAdd="Adicionar Categoria"
              handleAdd={handleCategoryList}
              error={errors.categories}
            />
            <AdminTable
              columns={[
                { field: "letter", headerName: "Letra", flex: 1, align: "center" },
                { field: "name", headerName: "Nome", flex: 6 },
                {
                  field: "actions",
                  headerName: "Ações",
                  sortable: false,
                  filterable: false,
                  align: "center",
                  flex: 1,
                  renderCell: (params) => (
                    <TableActions handleDelete={() => handleDelete(params.row.id, "categories")} />
                  ),
                },
              ]}
              data={values?.categories}
            />
            {errors.categories && (
              <Typography sx={formStyles.errorMsg}>{errors.categories}</Typography>
            )}
          </TabPanel>
          <TabPanel value={3} error={errors.companyTypes}>
            <PageListHeader
              title="Tipo de empresa"
              handleAdd={handleCompanyTypeList}
              error={errors.companyTypes}
            />
            <AdminTable
              columns={[
                { field: "description", headerName: "Descrição", flex: 6 },
                {
                  field: "actions",
                  headerName: "Ações",
                  sortable: false,
                  filterable: false,
                  align: "center",
                  flex: 1,
                  renderCell: (params) => (
                    <TableActions
                      handleDelete={() => handleDelete(params.row.id, "companyTypes")}
                    />
                  ),
                },
              ]}
              data={values?.companyTypes}
            />
            {errors.companyTypes && (
              <Typography sx={formStyles.errorMsg}>{errors.companyTypes}</Typography>
            )}
          </TabPanel>
          <TabPanel value={4} error={errors.searchTerms}>
            <PageListHeader
              title="Termos de pesquisa"
              titleAdd="Adicionar termos de pesquisa"
              handleAdd={handleTermsList}
              error={errors.searchTerms}
            />
            <AdminTable
              columns={[
                { field: "term", headerName: "Termo de busca", flex: 1 },
                {
                  field: "actions",
                  headerName: "Ações",
                  sortable: false,
                  filterable: false,
                  align: "center",
                  renderCell: (params) => (
                    <TableActions handleDelete={() => handleDelete(params.row.id, "searchTerms")} />
                  ),
                },
              ]}
              data={values?.searchTerms}
            />

            {errors.searchTerms && (
              <Typography sx={formStyles.errorMsg}>{errors.searchTerms}</Typography>
            )}
          </TabPanel>
          <TabPanel value={5} error={errors.gtAnswerItems}>
            <PageListHeader
              title="Itens de resposta"
              titleAdd="Adicionar itens de resposta"
              handleAdd={handleItemsList}
              error={errors.gtAnswerItems}
            />
            <AnswerItemsTab
              values={values.gtAnswerItems}
              typesAnswer={typesAnswer}
              getTypesAnswer={getTypesAnswer}
              handleDelete={handleDelete}
              fetchItemAnswer={fetchItemAnswer}
            />
            {errors.gtAnswerItems && (
              <Typography sx={formStyles.errorMsg}>{errors.gtAnswerItems}</Typography>
            )}
          </TabPanel>
        </Tabs>
      </Box>

      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default AnswerForm;
