import React, { useState, useContext } from "react";
import DropUploadZone from "~/presentation/components/DropUploadZone";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { Box } from "@mui/material";
import PageListHeader from "../../components/PageListHeader";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";

const Answers = ({ issUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [file, setFile] = useState();

  const handleFile = (uploadedFile) => {
    setFile(uploadedFile);
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Salvando cidades");
    try {
      await issUseCases.saveCities(file);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar cidades: Missing Endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <Box sx={styles.container}>
      <PageListHeader title="Importar Cidades" />
      <Box sx={styles.uploadZone}>
        <DropUploadZone handleChange={handleFile} />
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={handleSubmit} disabled={!file}>
          Enviar
        </ButtonCommon>
      </Box>
    </Box>
  );
};

export default Answers;

const styles = {
  container: {
    height: "100%",
    display: "flex",
    flexFlow: "column",
  },
  uploadZone: {
    alignSelf: "center",
    margin: "auto 0",
    height: "40%",
    width: "90%",
    textAlign: "center",
    "@media (min-width: 600px)": {
      width: "60%",
    },
  },
};
