import React, { useState } from "react";
import { Badge, Box, Button, IconButton, Popover as MuiPopover, Tooltip } from "@mui/material";

function Popover({
  icon: Icon,
  buttonLabel = null,
  children,
  styles = {},
  tooltipTitle = "",
  badgeNumber = 0,
  disabled = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const badgeProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    badgeContent: badgeNumber,
    sx: {
      "& .MuiBadge-anchorOriginBottomRight": {
        backgroundColor: "#222",
        color: "#fff",
        ...styles?.badge,
      },
    },
  };

  return (
    <Box sx={{ ...popoverStyles.popover, ...styles?.popover }}>
      {!buttonLabel ? (
        <Tooltip title={tooltipTitle}>
          <span>
            <IconButton disabled={disabled} aria-describedby={id} onClick={handleClick}>
              <Badge {...badgeProps}>
                <Icon fontSize="small" sx={{ ...popoverStyles.icon, ...styles?.icon }} />
              </Badge>
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Badge {...badgeProps}>
          <Button
            disabled={disabled}
            sx={{ ...popoverStyles.button, ...styles?.button }}
            aria-describedby={id}
            onClick={handleClick}
          >
            <Icon fontSize="small" sx={{ ...popoverStyles.icon, ...styles?.icon }} /> {buttonLabel}
          </Button>
        </Badge>
      )}
      <MuiPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ ...popoverStyles.content, ...styles?.content }}>{children}</Box>
      </MuiPopover>
    </Box>
  );
}

export default Popover;

const popoverStyles = {
  popover: {
    color: "#000",
  },
  icon: {},
  button: {
    "&.MuiButton-root": {
      color: "#000",
      fontFamily: "Montserrat",
      display: "flex",
      alingItems: "center",
      gap: 1,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "rgba(150,150,150,0.1)",
      },
    },
  },
  content: { p: 1 },
};
