function useHandleDates() {
  //current time
  const currentDay = new Date();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  //valores padrão
  const sixMonthsAgo = new Date(new Date().setMonth(new Date().getMonth() - 6));
  const sixYearsAgo = new Date(currentYear - 10, currentMonth, 1); //6 anos atrás

  const handleDateErrors = (start, end) => {
    //ERRO: Data inválida (FUTURO)
    const isDateMissing = !start || !end;
    if (isDateMissing) return "Selecione um período de datas";
    const isStartDateInTheFuture = start > new Date();
    const isEndDateInTheFuture = end > new Date();
    if (isStartDateInTheFuture || isEndDateInTheFuture) return "Selecione uma data no presente.";
    //ERRO: Data inicial maior do que final
    const isStartDateHigherThanEnd = start > end;
    if (isStartDateHigherThanEnd) return "Data inicial superior à data final.";
    // ERRO: Data inválida (não totalmente preenchida)
    const isStartDateInvalid = start == "Invalid Date";
    const isEndDateInvalid = end == "Invalid Date";
    if (isStartDateInvalid || isEndDateInvalid) return "Ensira datas válidas";
    return false;
  };

  const handleVerifyPeriod = (start, end, numberOfMonths = 2) => {
    if (!start || !end) return;

    const startYear = start.getFullYear();
    const startMonth = start.getMonth();
    const startDay = start.getDate();

    const endYear = end.getFullYear();
    const endMonth = end.getMonth();
    const endDay = end.getDate();

    const monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);

    if (monthDifference < numberOfMonths) {
      return false;
    } else if (monthDifference === numberOfMonths && endDay <= startDay) {
      return false;
    } else {
      return `O período máximo são ${numberOfMonths} ${numberOfMonths === 1 ? "mês" : "meses"}.`;
    }
  };

  return {
    handleDateErrors,
    handleVerifyPeriod,
    currentDay,
    currentYear,
    currentMonth,
    sixMonthsAgo,
    sixYearsAgo,
  };
}

export default useHandleDates;
