export const isOperationoptions = [
  { value: 1, label: "Inclusão" },
  { value: 2, label: "Alteração" },
  { value: 3, label: "Exclusão" },
];

export const isProductionoptions = [
  { value: false, label: "Produção Restrita" },
  { value: true, label: "Produção" },
];

export const isCloseOptions = [
  { value: false, label: "Reabertura" },
  { value: true, label: "Fechamento" },
];
