import React, { useContext, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import removeMask from "~/presentation/functions/removeMask";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import {
  Container,
  Typography as Text,
  Box,
  Checkbox,
  Tooltip,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid2";
import DetailedError from "~/presentation/components/DetailedError";
import { Close } from "@mui/icons-material";

export const AddNewCertificate = ({ handleClose, refreshData }) => {
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());

  const { openBackdrop } = useContext(BackdropContext);
  const { showSuccess } = useContext(SnackbarContext);
  const [isChecked, setIsChecked] = useState(false);
  const [isPerson, setIsPerson] = useState(false);
  const [fileName, setFileName] = useState(null);

  //messagem de erro detalhada
  const [detailedError, setDetailedError] = useState(null);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleIsPerson = (event) => {
    setIsPerson(event.target.checked);
  };

  const initialValues = {
    file: null,
    name: "",
    ownerName: "",
    password: "",
    confirmPassword: "",
    cpfCnpj: "",
  };

  const [values, setValues] = useState(initialValues);

  const onChangeValue = (field, value) => setValues((prev) => ({ ...prev, [field]: value }));
  const clearFile = () => {
    setFileName(null), onChangeValue("file", null);
  };
  const clearAllFields = () => setValues(initialValues);

  const handleSubmit = async () => {
    setDetailedError(null);
    openBackdrop(true, "Enviando dados do certificado...");
    const { file, name, ownerName, password, cpfCnpj } = values;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      const base64FileData = reader.result.split(",")[1];
      const data = {
        id: 0,
        name,
        ownerName,
        password,
        certificateFileData: base64FileData,
        cpfCnpj: removeMask(cpfCnpj),
      };
      try {
        await apiDigitalCert.registerDigitalCert(data);
        showSuccess("Certificado cadastrado com sucesso!");
        handleClose();
        refreshData();
      } catch (error) {
        let errorMessage = error.message || "Erro ao cadastrar certificado: Erro desconhecido.";

        if (/password/.test(errorMessage)) {
          errorMessage = "Senha do certificado incorreta.";
        }

        const errorDetails = [errorMessage];

        setDetailedError({
          title: "Erro ao cadastrar certificado",
          message: "Veja os detalhes do erro abaixo",
          details: errorDetails,
        });
      } finally {
        openBackdrop(false);
      }
    };
  };

  const errorMsg = "Campo Obrigatório";
  const confirmPasswordMandatory = !values?.confirmPassword && errorMsg;
  const isConfirmPasswordEqual = values?.password !== values?.confirmPassword;
  const passwordsNotEqualErrorText = isConfirmPasswordEqual && "As senhas devem ser iguais";

  return (
    <Container sx={styles.Form}>
      <Grid container spacing={1}>
        {detailedError && (
          <Grid sx={{ mb: 1 }} size={12}>
            <DetailedError errorContent={detailedError} />
          </Grid>
        )}
        <Grid size={12} sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
          <Text sx={styles.Title}>Cadastrar novo certificado</Text>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid size={12} sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          {values?.file ? (
            <>
              <RButton color="secondary" style={styles.uploadText} onClick={clearFile}>
                <Close fontSize="small" />
                Remover Certificado
              </RButton>
            </>
          ) : (
            <>
              <label style={{ ...styles.uploadText, background: "#1341A1" }} htmlFor="file">
                <DriveFolderUploadIcon fontSize="small" /> Anexar Certificado
              </label>
              <input
                disabled={fileName}
                style={styles.FileReader}
                accept=".pfx"
                name="file"
                type="file"
                id="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  onChangeValue("file", file);
                  setFileName(file.name);
                }}
              />
            </>
          )}

          {!values?.file && (
            <Text sx={styles.text}>Anexe um certificado. Este campo é obrigatório.</Text>
          )}
          {fileName && <Text>{fileName}</Text>}
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            title="Nome"
            name="name"
            value={values?.name}
            type="text"
            placeholder="Nome do certificado"
            onChange={(e) => onChangeValue("name", e.target.value)}
            error={!values?.name && errorMsg}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            title="Nome do proprietário"
            name="ownerName"
            type="text"
            placeholder="Proprietário do certificado"
            value={values?.ownerName}
            onChange={(e) => onChangeValue("ownerName", e.target.value)}
            error={!values?.ownerName && errorMsg}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            title="Senha"
            name="password"
            type="password"
            placeholder="Senha"
            value={values?.password}
            onChange={(e) => onChangeValue("password", e.target.value)}
            error={!values?.password && errorMsg}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            title="Confirme a senha"
            name="confirmPassword"
            type="password"
            placeholder="Confirme a senha"
            value={values?.confirmPassword}
            onChange={(e) => onChangeValue("confirmPassword", e.target.value)}
            error={confirmPasswordMandatory || passwordsNotEqualErrorText}
          />
        </Grid>
        <Grid size={12}>
          <Tooltip
            title="Marque se o certificado pertence a um CPF/CNPJ procurador que não seja o da sua empresa"
            placement="top"
          >
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
              label={
                <Text sx={{ fontSize: "14px" }}>Meu certificado pertence a um procurador</Text>
              }
            />
          </Tooltip>
          {isChecked && (
            <>
              <FormControlLabel
                control={<Checkbox checked={isPerson} onChange={handleIsPerson} />}
                label={<Text sx={{ fontSize: "14px" }}>Procurador pessoa física</Text>}
              />
              <Input
                type="mask"
                title="CPF/CNPJ do proprietario terceiro "
                mask={isPerson ? "000.000.000-00" : "00.000.000/0000-00"}
                name="cpfCnpj"
                value={values?.cpfCnpj}
                onChange={(e) => onChangeValue("cpfCnpj", e)}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "end",
          gap: 1,
          marginTop: 4,
        }}
      >
        <RButton
          sx={{ width: { xs: "100%", sm: "150px" } }}
          type="button"
          variant="outlined"
          onClick={() => {
            if (values.file == null) handleClose();
            clearAllFields();
          }}
        >
          Cancelar
        </RButton>
        <RButton
          sx={{ width: { xs: "100%", sm: "200px" } }}
          type="submit"
          disabled={
            Object.keys(values)
              .filter((key) => key !== "cpfCnpj")
              .some((key) => values[key] === "" || values[key] === null) ||
            values.password !== values.confirmPassword
          }
          onClick={() => {
            handleSubmit(values);
          }}
        >
          Adicionar
        </RButton>
      </Box>
    </Container>
  );
};

const styles = {
  Form: {
    padding: "2rem",
    backgroundColor: "#E6E6E6",
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  uploadText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    fontWeight: "500",
    fontSize: 14,
    cursor: "pointer",
    padding: "6px 16px",
    borderRadius: "6px",
    color: "#fff",
    fontFamily: "Montserrat",
    width: 215,
    height: 36,
    "&:hover": {},
  },
  FileReader: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
    left: 0,
    fontFamily: "Montserrat",
  },
  text: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Montserrat",
  },
};
