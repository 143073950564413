import React, { useContext, useEffect, useState } from "react";
import TableActions from "~/presentation/components/TableActions";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";
import PageListHeader from "../components/PageListHeader";
import CreateEdit from "./CreateEdit/CreateEdit";
import AdminTable from "../components/AdminTable/AdminTable";

export const AditionalCompanyPlan = ({ additionalCompanyPlanUseCases, company }) => {
  const [additionalCompanyPlanList, setAdditionalCompanyPlanList] = useState([]);
  const { openBackdrop } = useContext(BackdropContext);
  const { showError, showSuccess } = useContext(SnackbarContext);
  const { openDialogBox, closeDialog } = useContext(DialogContext);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    try {
      const response = await additionalCompanyPlanUseCases.getAdditionalCompanyPlans(company.id);
      setAdditionalCompanyPlanList(response);
    } catch (error) {
      setError("Erro ao carregar os adicionais de plano.");
      showError(error, "Erro ao carregar os adicionais de plano");
    }
  };

  const columns = [
    {
      field: "typeDescription",
      headerName: "Tipo",
      renderCell: ({ value }) => (value === "User" ? "Usuário" : value),
      align: "center",
    },
    {
      field: "amount",
      headerName: "Quantidade",
      align: "center",
      hide: "md",
    },
    {
      field: "price",
      headerName: "Preço",
      renderCell: ({ value }) => formatNumberToRealString(value),
      align: "center",
      hide: "md",
    },
    {
      field: "limitDate",
      headerName: "Data de vencimento",
      renderCell: ({ value }) => (value ? formatDateToBrazilian(value) : "-"),
      align: "center",
    },
    {
      field: "actions",
      headerName: "Ações",
      renderCell: ({ row }) => (
        <TableActions
          handleEdit={() => handleOpenForm(row)}
          handleDelete={() => deleteDialog(row.id)}
        />
      ),
      sortable: false,
      filterable: false,
      align: "center",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenForm = (data = null) => {
    openDialogBox({
      maxWidth: "md",
      customComponent: () => (
        <CreateEdit
          data={data}
          additionalCompanyPlanUseCases={additionalCompanyPlanUseCases}
          company={company}
          closeModal={closeDialog}
          refreshData={fetchData}
        />
      ),
    });
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este adicional de plano?`,
      callback: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo adicional de plano");
    try {
      await additionalCompanyPlanUseCases.deleteAdditionalCompanyPlan(id);
      showSuccess("Adicional de plano excluído com sucesso");
      setAdditionalCompanyPlanList((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      showError(error, "Erro ao excluir o adicional de plano");
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title="Adicionais de plano"
        titleAdd="Novo adicional de plano"
        handleAdd={() => handleOpenForm()}
      />
      <AdminTable
        disabledMargin
        columns={columns}
        data={additionalCompanyPlanList}
        errorMsg={error}
      />
    </>
  );
};
