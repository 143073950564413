import fakeTaxInformationsList from "./fake-tax-information-list.json";
import { apiURL } from "../../usecases/api";

export class ApiTaxInformationUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAllTaxInfo(params) {
    const queryString =
      params && Object.keys(params).length ? `?${new URLSearchParams(params).toString()}` : "";

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/TaxInformation${queryString}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/taxinformation/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async sendReply(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/taxinformation/response`,
      method: "post",
      data,
    });
    return response.data;
  }

  async getTaxInformationListByCompany({ companyId, DateStart, DateEnd }) {
    let response = {
      data: fakeTaxInformationsList.filter((ti) => ti.companyId == companyId),
    };

    try {
      response = await this.httpClient.request({
        url: `${apiURL}/taxinformation?companyId=${companyId}&DateStart=${DateStart}&DateEnd=${DateEnd}`,
        method: "GET",
      });
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
    return response.data;
  }

  // async getRemainingTaxInformation() {
  //   try {
  //     const response = await this.httpClient.request({
  //       url: `${apiURL}/taxinformation`,
  //       method: "GET",
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw new Error(error.response?.data || error.message);
  //   }
  // }

  async sendTaxInformation(data) {
    await this.httpClient.request({
      url: `${apiURL}/taxinformation`,
      method: "POST",
      data,
    });
  }

  async deleteTaxInformation(id) {
    try {
      await this.httpClient.request({
        url: `${apiURL}/TaxInformation/${id}`,
        method: "DELETE",
      });
    } catch (error) {
      throw new Error(error?.response?.data || error?.response);
    }
  }

  async reopen(id) {
    await this.httpClient.request({
      url: `${apiURL}/TaxInformation/Reopen/${id}`,
      method: "PUT",
    });
  }

  // CREATE DELETE FUNCTION
}
