import React, { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import {
  Description as DescriptionIcon,
  Dialpad as DialpadIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "~/presentation/hooks/useQuery";
import { Card } from "~/presentation/views/GTFacil/components/Card";
import { GTLayout } from "~/presentation/views/GTFacil/components/GTLayout";
import { GenerateReportModal } from "~/presentation/views/GTFacil/Answer/components/GenerateReportModal";
import { AnswerContext } from "~/presentation/views/GTFacil/Answer/answer-context";
import { AnswerAccordion } from "~/presentation/views/GTFacil/Answer/components/AnswerAccordion/AnswerAccordion";
import { AnswerCard } from "~/presentation/views/GTFacil/Answer/components/AnswerCard";
import { Simulator } from "~/presentation/views/GTFacil/Simulator";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import generateGtFacilReport from "~/reports/gtfacil-report";
import { Box, Button, Dialog, DialogContent, lighten, Typography, Popover } from "@mui/material";
import { InfoIcon } from "../Search/components/SearchForm/styles";
import { companyTypes } from "./AnswerData";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SecurityPath } from "~/presentation/security/SecurityPath";
import { adminPerms } from "~/presentation/security/SecurePaths";
import OpenPopover from "~/presentation/components/Popover";

export const Answer = ({
  gtFacilUseCases,
  companyUseCases,
  cityUseCases,
  valueVariableUseCases,
  dialogUseCases,
  contentUseCases,
}) => {
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));
  const location = useLocation();

  const [currentCompany, setCurrentCompany] = useState({});
  const [contractorCategory, setContractorCategory] = useState();
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState({
    inss: false,
    irrf: false,
    csll: false,
    iss: false,
  });
  const [hiredType, setHiredType] = useState();
  const [answers, setAnswers] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [cities, setCities] = useState();
  const [, setFontSize] = useState(18);
  const [isOpen, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState(null);

  useEffect(() => {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const vwToPixels = viewportWidth * 0.013;
    const calculatedFontSize = Math.max(18, vwToPixels);
    setFontSize(calculatedFontSize);
  }, []);

  const updateFontSize = () => {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const vwToPixels = viewportWidth * 0.013;
    const calculatedFontSize = Math.max(18, vwToPixels);
    setFontSize(calculatedFontSize);
  };

  const query = useQuery();
  const navigate = useNavigate();
  const { showError } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const { backdropLoading, openBackdrop } = useContext(BackdropContext);

  useEffect(() => {
    window.addEventListener("resize", updateFontSize);
    return () => {
      window.removeEventListener("resize", updateFontSize);
    };
  }, []);

  const queryParams = {
    cnaeId: query.get("cnaeId"),
    categoryId: query.get("categoryId"),
    companyType: query.get("companyType"),
    isSimples: query.get("isSimples") === "true",
    cityHireId: query.get("cityHireId"),
    cityServiceId: query.get("cityServiceId"),
    cityContractorId: query.get("cityContractorId"),
    isBranch: query.get("isBranch"),
    searchTermId: query.get("searchTermId"),
  };

  const getCnaeFromLocation = () => {
    setData(location?.state?.data);
  };

  const getAnswers = async () => {
    try {
      openBackdrop(true, "Buscando respostas");
      const { searchTermId } = queryParams;

      let body;

      if (searchTermId) {
        body = !queryParams.searchTermId ? { ...queryParams, searchTermId: data.id } : queryParams;
      } else {
        body = !queryParams.cnaeId ? { ...queryParams, cnaeId: data.id } : queryParams;
      }

      if (!body.cnaeId && !body.searchTermId) {
        return openBackdrop(false);
      }

      const response = await gtFacilUseCases.getAnswerBy(body);
      if (response.data) {
        setAnswers(response?.data);
      } else {
        showError({}, response.response.data);
      }
    } catch (error) {
      showError(error, "Erro ao realizar a pesquisa, tente novamente dentro de alguns minutos.");
    } finally {
      openBackdrop(false);
    }
  };

  const getContractorCategory = async () => {
    if (!queryParams.categoryId) return;
    const result = await companyUseCases.getCompanyCategoryById({
      id: queryParams.categoryId,
      checkPermission: false,
    });
    setContractorCategory(result);
  };

  const getUserCompany = async () => {
    const result = await companyUseCases.getCompanyById({ id: companyId, checkPermission: false });
    setCurrentCompany(result);
  };

  const getHiredType = () => {
    const type =
      queryParams?.companyType &&
      companyTypes.find((item) => item.value === Number(queryParams.companyType));
    setHiredType(type);
  };

  const answerCategories = [
    {
      name: "inss",
      label: "INSS na Cessão de Mão de Obra ou Empreitada",
      color: "#ff8040",
      disabled: disabled.inss,
    },
    {
      name: "irrf",
      label: "IRRF de Pessoas Jurídicas",
      color: "#ffbf40",
      disabled: disabled.irrf,
    },
    {
      name: "csll",
      label: "CSLL, PIS e COFINS",
      color: "#83bf30",
      disabled: disabled.csll,
    },
    {
      name: "iss",
      label: "ISS na Contratação de Pessoas Jurídicas",
      color: "#bf303c",
      disabled: disabled.iss,
    },
  ];

  const getCities = async () => {
    const { cityHireId, cityServiceId, cityContractorId } = queryParams;

    const citiesIds = [cityContractorId, cityHireId, cityServiceId];
    const shouldNotLoadCities = citiesIds.some((city) => !city);

    if (shouldNotLoadCities) {
      setCities([]);
      return;
    } else {
      const result = await Promise.all(
        citiesIds.length > 0
          ? citiesIds.map(
              async (city) => await cityUseCases.getCityById({ id: city, checkPermission: false })
            )
          : []
      );

      setCities([
        {
          label: "Município do Contratante:",
          name: `${result[0].name} - ${result[0].uf}`,
        },
        {
          label: "Município do Contratado:",
          name: `${result[1].name} - ${result[1].uf}`,
        },
        {
          label: "Município da Prestação:",
          name: `${result[2].name} - ${result[2].uf}`,
        },
      ]);
    }
  };

  const renderIsSimples = () => (queryParams.isSimples ? "Sim" : "Não");

  const renderAnswerItems = () => {
    return (
      <SecurityPath securePaths={[adminPerms.seeAnswers]}>
        <>
          <InfoIcon onClick={() => setOpenDialog(true)} />
          <Dialog
            open={isOpen}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              {answers[0]?.items?.map((a, idx) => (
                <Typography key={idx}>{`${a.name} - ${a.description}`}</Typography>
              ))}
            </DialogContent>
          </Dialog>
        </>
      </SecurityPath>
    );
  };
  const handleClick = (selected) => {
    setSelectedAnswer(selected);
  };

  const generateObservation = (values) => {
    const consultationData = location?.state?.data;
    let observation =
      consultationData.search === "cnae"
        ? consultationData.observation
        : `${values.cnae.code} - ${values.cnae.description}`;
    observation =
      consultationData.search !== "cnae" && values.cnae?.detailCode
        ? `${values.cnae.code} (${values.cnae?.detailCode}) - ${values.cnae.description}`
        : observation;
    return observation;
  };

  const generateSubitem = (values) => {
    const consultationData = location?.state?.data;
    let subItem = "";
    if (consultationData.search && consultationData.observation) {
      subItem +=
        consultationData.search === "cnae"
          ? `Relacionado ao subitem: ${values.cnae?.serviceCodes[0]?.code}`
          : `Relacionado ao CNAE: ${values.cnae?.code}`;
      subItem =
        consultationData.search !== "cnae" && values.cnae?.detailCode
          ? `${subItem} (${values.cnae?.detailCode})`
          : subItem;
    }
    return subItem;
  };

  const generateServiceCode = (values) => {
    const consultationData = location?.state?.data;
    let serviceCode =
      consultationData.search === "cnae" ? values.cnae.code : values.cnae?.serviceCodes[0]?.code;
    if (consultationData.search === "cnae" && values?.cnae?.detailCode) {
      serviceCode += ` (${values?.cnae?.detailCode})`;
    }
    return serviceCode;
  };

  const handleSubmitGenerateReport = async (values) => {
    const { inss, irrf, iss, csll, number } = values;
    const consultationData = location?.state?.data;

    let serviceCode = "";
    let subItem = "";
    let observation = "";
    let descriptionText = "";
    let serviceDescription = "";

    if (consultationData.search !== "freeSearch") {
      serviceCode = generateServiceCode(values);
      subItem = generateSubitem(values);
      observation = generateObservation(values);
      descriptionText =
        consultationData.search === "cnae"
          ? values.cnae.description
          : values.cnae?.serviceCodes[0]?.description;
      serviceDescription = `${
        descriptionText.endsWith(".") ? descriptionText : descriptionText + "."
      } ${subItem}`;
    }

    const data = {
      category: values.category,
      serviceCode,
      serviceDescription,
      observation: observation || "",
      contractType: hiredType.label,
      isSimples: queryParams.isSimples,
      inss: inss,
      irrf: irrf,
      iss: iss,
      csll: csll,
      number,
      cityContractor: cities[0]?.name ? cities[0]?.name : "",
      cityHire: cities[1]?.name ? cities[1]?.name : "",
      cityService: cities[2]?.name ? cities[2]?.name : "",
    };

    generateGtFacilReport(data);
  };

  const handleGenerateReport = () => {
    if (!answers) {
      return showError({}, "Não há respostas para gerar o relatório");
    }
    openDialogBox({
      // eslint-disable-next-line react/display-name
      customComponent: () => (
        <GenerateReportModal answer={answers} onSubmit={handleSubmitGenerateReport} />
      ),
      fullWidth: true,
      maxWidth: "sm",
    });
  };

  const handleSimulator = () => {
    openDialogBox({
      customComponent: () => (
        <Simulator
          cnae={data}
          answer={answers}
          hiredType={hiredType}
          contractorCategory={contractorCategory}
          isSimples={queryParams.isSimples}
          companyType={queryParams.companyType}
          handleClose={() => openDialogBox({ ...openDialogBox, open: false })}
        />
      ),
      fullWidth: true,
      maxWidth: "lg",
    });
  };

  useEffect(() => {
    if (!location?.state?.data) navigate("/gt-facil");
    getCnaeFromLocation();
  }, [location]);

  useEffect(() => {
    if (data.id) {
      getAnswers();
    }
  }, [data]);

  useEffect(() => {
    if (hiredType?.value === 2) {
      setDisabled({ ...disabled, csll: true });
    }
  }, [hiredType]);

  useEffect(() => {
    getContractorCategory();
    getHiredType();
    getCities();
    getUserCompany();
  }, []);

  const getItem = (item) => {
    if (item != null && item.label.includes("INSS") && queryParams.companyType === "1") {
      item.label = "INSS dos Contribuintes Individuais";
    }
    return item;
  };
  //comportamento de links popover
  const handlePopoverOpen = (event, content) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent(null);
  };

  const replacePopovers = (node, id) => {
    if (node.type === "tag" && node.name === "a" && node.attribs["data-role"] === "popover") {
      return (
        <span key={id} style={{ display: "inline" }}>
          <Typography
            component="span"
            sx={{
              color: "#0366d6",
              textDecoration: "none",
              cursor: "pointer",
            }}
            aria-owns={anchorEl ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={(e) => handlePopoverOpen(e, node.attribs["data-popover-text"])}
          >
            {node.children[0]?.data}
          </Typography>
        </span>
      );
    }
    return undefined;
  };

  return (
    <AnswerContext.Provider
      value={{
        gtFacilUseCases,
        companyUseCases,
        cityUseCases,
        valueVariableUseCases,
        dialogUseCases,
        contentUseCases,
      }}
    >
      <GTLayout title="Consulte a tributação de seus contratos com o GT-Fácil">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          {/* <SearchCard getSearch={getSearch} /> */}
          <Card
            title="Dados da operação consultada"

            // loading={isOperationDataLoading()}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {data.search === "freeSearch" ? (
                <Typography
                  sx={{
                    fontWeight: 300,
                    lineHeight: 1.5,
                    "> b": {
                      fontWeight: 500,
                    },
                  }}
                >
                  <b>Pesquisa específica:</b> {data.term}
                </Typography>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontWeight: 300,
                      lineHeight: 1.5,
                      "> b": {
                        fontWeight: 500,
                      },
                    }}
                  >
                    <b>{data.search === "cnae" ? "Código CNAE:" : "Código na LC 116/2003:"} </b>{" "}
                    {data?.code} {data?.detailCode && `(${data?.detailCode})`} - {data?.description}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 300,
                      lineHeight: 1.5,
                      "> b": {
                        fontWeight: 500,
                      },
                    }}
                  >
                    <b>
                      {data.search === "cnae" ? "Relacionado ao subitem:" : "Relacionado ao CNAE:"}
                    </b>{" "}
                    {data.observation}
                  </Typography>
                </>
              )}
              <Typography
                sx={{
                  fontWeight: 300,
                  lineHeight: 1.5,
                  "> b": {
                    fontWeight: 500,
                  },
                }}
              >
                <b>Natureza Jurídica do Contratante:</b> {contractorCategory?.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 300,
                  lineHeight: 1.5,
                  "> b": {
                    fontWeight: 500,
                  },
                }}
              >
                <b>Tipo do Contratado:</b> {hiredType?.label}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 300,
                  lineHeight: 1.5,
                  "> b": {
                    fontWeight: 500,
                  },
                }}
              >
                <b>Optante do Simples Nacional:</b> {renderIsSimples()}
              </Typography>
              {cities?.map(({ label, name }) => (
                <Typography
                  sx={{
                    fontWeight: 300,
                    lineHeight: 1.5,
                    "> b": {
                      fontWeight: 500,
                    },
                  }}
                  key={label + name}
                >
                  <b>{label}</b> {name}
                </Typography>
              ))}
              {data.annotation ? (
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <Typography
                    sx={{
                      marginRight: 2,
                      fontWeight: 300,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      "> b": {
                        fontWeight: 500,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        "& *": {
                          display: "inline",
                          fontWeight: 300,
                        },
                      }}
                    >
                      {" "}
                      <b style={{ fontWeight: 500 }}>Observação:&nbsp;</b>
                      <Typography
                        sx={{
                          wordBreak: "break-word",
                          "& *": {
                            display: "inline",
                            fontWeight: 300,
                            fontFamily: "Roboto",
                          },
                        }}
                      >
                        {parse(data.annotation, {
                          replace: (node, idx) => replacePopovers(node, idx),
                        })}
                      </Typography>
                    </Typography>
                  </Typography>
                </div>
              ) : null}
              {answers && renderAnswerItems()}
            </Box>
          </Card>

          <Card title="Confira abaixo a tributação do item pesquisado" loading={backdropLoading}>
            {answers && (
              <Box
                sx={{
                  display: "grid",
                  gridGap: "16px",
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "repeat(4, 1fr)",
                  "@media screen and (min-width: 600px)": {
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridTemplateRows: "repeat(2, 1fr)",
                  },
                  "@media screen and (min-width: 1000px)": {
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gridTemplateRows: "1fr",
                  },
                }}
              >
                {answerCategories.map((item, index) => {
                  const formattedItem = getItem(item);
                  formattedItem.color =
                    item.name === selectedAnswer ? item.color : lighten(item.color, 0.33);
                  return (
                    <AnswerCard
                      key={index}
                      disabled={item.disabled}
                      isPF={queryParams.companyType === "2"}
                      onClick={() => handleClick(item.name)}
                      answer={formattedItem}
                    />
                  );
                })}
              </Box>
            )}
            {selectedAnswer && (
              <AnswerAccordion answers={answers} selectedCategory={selectedAnswer} />
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "32px",
                gap: "16px",
                flexDirection: { xs: "column", md: "row" },
                marginRight: { xs: "0", md: "16px" },
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#1341a1",
                  color: "#fff",
                  gap: 1,
                  ":hover": {
                    backgroundColor: "#1341a1",
                  },
                  ":disabled": {
                    backgroundColor: "#c4c4c4",
                    color: "#fff",
                  },
                }}
                onClick={handleSimulator}
                disabled={!currentCompany?.plan?.isCalculationSimulator || !answers}
              >
                <DialpadIcon fontSize="small" />
                Simulador de Cálculo
              </Button>
              <Button
                sx={{
                  backgroundColor: "#1341a1",
                  color: "#fff",
                  gap: 1,
                  ":hover": {
                    backgroundColor: "#1341a1",
                  },
                  ":disabled": {
                    backgroundColor: "#c4c4c4",
                    color: "#fff",
                  },
                }}
                onClick={handleGenerateReport}
                disabled={!currentCompany?.plan?.isGenerateReport || !answers}
              >
                <DescriptionIcon fontSize="small" />
                Gerar Relatório
              </Button>
              <Button
                sx={{
                  backgroundColor: "#1341a1",
                  color: "#fff",
                  gap: 1,
                  ":hover": {
                    backgroundColor: "#1341a1",
                  },
                  ":disabled": {
                    backgroundColor: "#c4c4c4",
                    color: "#fff",
                  },
                }}
                onClick={() => {
                  navigate("/gt-facil");
                }}
              >
                <SearchIcon fontSize="small" />
                Nova Pesquisa
              </Button>
            </Box>
          </Card>
        </Box>
      </GTLayout>
      <Popover
        id="mouse-over-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: { xs: 2, lg: 3 },
            maxWidth: 800,
            maxHeight: 400,
            overflowY: "auto",
            lineHeight: "18px",
          }}
          dangerouslySetInnerHTML={{ __html: popoverContent }}
        />
      </Popover>
    </AnswerContext.Provider>
  );
};
