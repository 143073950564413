import React from "react";
import { Box, Typography } from "@mui/material";

export const SectionHeader = ({ text, subTitle }) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.h2}>{text}</Typography>
      {subTitle ? <Typography sx={styles.p}>{subTitle}</Typography> : null}
      <Box sx={styles.line} />
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    rowGap: "max(16px, 1vw)",
  },
  h2: {
    fontFamily: "Montserrat",
    fontSize: "max(32px, 2vw)",
    textAlign: "center",
  },
  p: {
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  line: {
    width: "60px",
    height: "3px",
    background: "#FE7F1E",
  },
};
