import React from "react";
import { Box, Button, Typography } from "@mui/material";

export const Card = ({ icon, title, text, onClick, buttonVariant, showButton = true }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.iconWrapper}>
        <Box sx={styles.iconWrapper.line} />
        <Box component="img" sx={styles.iconWrapper.img} alt="icon" src={icon} />
        <Box sx={styles.iconWrapper.line} />
      </Box>
      <Typography sx={styles.container.h3}>{title}</Typography>
      <Typography sx={styles.container.p}>{text}</Typography>
      {showButton && (
        <Button variant={buttonVariant} onClick={onClick} sx={styles.button}>
          Explore
        </Button>
      )}
    </Box>
  );
};

const styles = {
  container: {
    height: "200px",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    gap: "max(10px, 1vw)",

    h3: {
      fontFamily: '"Montserrat", sans-serif',
      textAlign: "center",
      fontSize: "max(20px, 1.5vw)",
      marginTop: "max(15px, 1vw)",
    },

    p: {
      fontFamily: '"Montserrat", sans-serif',
      textAlign: "center",
      fontSize: "max(16px, 1.2vw)",
      padding: 0,
      minHeight: "150px",
      maxHeight: "150px",
      "@media (min-width: 600px)": {
        padding: "0 10%",
      },
      "@media (max-width: 768px)": {
        minHeight: "auto",
        maxHeight: "auto",
      },
    },
  },

  iconWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    gridTemplateRows: "auto",
    alignItems: "center",
    line: {
      height: "2px",
      background: "#dedede",
    },
    img: {
      margin: "10px",
      height: "70px",
      objectFit: "contain",
      gridRow: "1",
      gridColumn: "2",
    },
  },

  button: {
    color: "#cf9455",
    borderColor: "#cf9455",
    textTransform: "capitalize",
    fontWeight: 500,
    width: "150px",
    padding: "8px", // equivalente a p: 1 no MUI
    fontSize: "15px",
    "&:hover": {
      color: "#fff",
      borderColor: "#cf9455",
      background: "#cf9455",
    },
  },
};
