import { apiURL } from "../../usecases/api";
export class ApiAdditionalCompanyPlanUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAdditionalCompanyPlans(companyId) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan/?companyId=${companyId}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getAdditionalCompanyPlan(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateAdditionalCompanyPlan(additionalCompanyPlan) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan`,
        method: "PUT",
        data: additionalCompanyPlan,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async deleteAdditionalCompanyPlan(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async createAdditionalCompanyPlan(additionalCompanyPlan) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan`,
        method: "POST",
        data: additionalCompanyPlan,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
