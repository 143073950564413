import * as Yup from "yup";

export default Yup.object({
  categoryId: Yup.number().typeError("Campo obrigatório!").required("Campo obrigatório!"),
  title: Yup.string().required("Campo Obrigatório!"),
  autor: Yup.string().required("Campo Obrigatório!"),
  publishDate: Yup.date().typeError("Selecione uma data!").required("Campo Obrigatório!"),
  image: Yup.mixed().required("Campo Obrigatório!"),
  text: Yup.string().required("Campo Obrigatório!"),
});
