import React from "react";
import { FileOpenOutlined, InfoOutlined, Update } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import {
  renderTableCNPJ,
  renderTableCurrency,
  renderTableDate,
} from "~/presentation/views/Reinf/Utils/utilsTableValues";
import { getDocType } from "~/presentation/views/Reinf/Utils/utilsReinf";

const renderFileIcon = (row) => {
  const event = row.hasEventSentWithSameCnpj;
  const changed = row.hadChanges;

  const TooltipTitle = changed
    ? "Existem dados por salvar."
    : event
      ? "Existe um evento com o mesmo par de CNPJ."
      : "Doc. Fiscal ainda não foi enviado.";

  const icon = changed ? (
    <InfoOutlined sx={{ color: "#DCAD23" }} />
  ) : event ? (
    <FileOpenOutlined sx={{ color: "gray" }} />
  ) : (
    <Update sx={{ color: "gray" }} />
  );

  return <Tooltip title={TooltipTitle}>{icon}</Tooltip>;
};

export const getColumns = ({ R2010, R4020AB, R4020C, R4020D }) => {
  const columns = [
    { field: "nFseNumber", headerName: "NºDoc", align: "center" },
    {
      field: "businessPartnerCNPJ",
      headerName: "CNPJ do Contratado",
      align: "center",
      renderCell: ({ value }) => renderTableCNPJ(value),
      hide: "lg",
    },
    { field: "businessPartnerName", headerName: "Nome do Contratado" },
    {
      field: R2010 ? "dateOfIssue" : "dateOfPayment",
      headerName: R2010 ? "Data de emissão" : "Data de pagamento",
      align: "center",
      renderCell: ({ value }) => renderTableDate(value),
      hide: "md",
    },
    {
      field: "amountBC",
      headerName: "Valor da Operação",
      align: "center",
      renderCell: ({ value }) => renderTableCurrency(value),
      hide: "lg",
    },
  ];

  if (R2010) {
    columns.push(
      {
        field: "bcINSS",
        headerName: "Base INSS",
        align: "center",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      },
      {
        field: "amountINSS",
        headerName: "Ret. INSS",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      }
    );
  }

  if (R4020AB) {
    columns.push(
      {
        field: "bcIRPJ",
        headerName: "Base IRPJ",
        align: "center",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      },
      {
        field: "amountAggregate",
        headerName: "Ret. Agregada",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      }
    );
  }

  if (R4020C) {
    columns.push(
      {
        field: "bcIR",
        headerName: "Base IR",
        align: "center",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      },
      {
        field: "amountIR",
        headerName: "Ret. IR",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      }
    );
  }

  if (R4020D) {
    columns.push(
      {
        field: "bcIR",
        headerName: "Base IR",
        align: "center",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      },
      {
        field: "amountIR",
        headerName: "Ret. IR",
        align: "center",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      },
      {
        field: "bcCSRF",
        headerName: "Base CSRF",
        align: "center",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      },
      {
        field: "amountCSRF",
        headerName: "Ret. CSRF",
        renderCell: ({ value }) => renderTableCurrency(value),
        hide: "xl",
      }
    );
  }

  columns.push(
    {
      field: "typeDoc",
      headerName: "Tipo de Doc.",
      align: "center",
      renderCell: ({ value }) => getDocType(value),
      hide: "lg",
    },
    {
      field: "contractorCNPJ",
      headerName: "CNPJ do Contratante",
      align: "center",
      renderCell: ({ value }) => renderTableCNPJ(value),
      hide: "lg",
    },
    {
      headerName: "Status",
      sortable: false,
      align: "center",
      renderCell: ({ row }) => renderFileIcon(row),
    }
  );

  return columns;
};
