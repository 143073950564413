import { reinfPerms, securityErrorMessage } from "~/presentation/security/SecurePaths";
import { apiURL } from "../../usecases/api";
import { hasPermission } from "~/presentation/security/SecurityPath";
export class ApiNfseUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async uploadNfse(file) {
    if (!hasPermission([reinfPerms.importXml])) {
      throw new Error(securityErrorMessage("importar arquivos .xml"));
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      let response = await this.httpClient.request({
        url: `${apiURL}/Nfse/UploadXml`,
        method: "POST",
        data: formData,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async uploadSIAFI({ fileXML, file, dateI, dateF, isDDF025, isDDF021 }) {
    if (!hasPermission([reinfPerms.importSiafi])) {
      throw new Error(securityErrorMessage("importar arquivos SIAFI"));
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileXML", fileXML);
      formData.append("dateI", dateI.toISOString());
      formData.append("dateF", dateF.toISOString());
      formData.append("isDDF025", isDDF025);
      formData.append("isDDF021", isDDF021);

      let response = await this.httpClient.request({
        url: `${apiURL}/Nfse/Upload/SIAFI`,
        method: "POST",
        data: formData,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async uploadSIAFIOB(file) {
    if (!hasPermission([reinfPerms.importSiafi])) {
      throw new Error(securityErrorMessage("importar arquivos SIAFI"));
    }
    try {
      const formData = new FormData();
      formData.append("file", file);

      let response = await this.httpClient.request({
        url: `${apiURL}/Nfse/Upload/OB`,
        method: "POST",
        data: formData,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async uploadSheet(file, isReplace) {
    if (!hasPermission([reinfPerms.importSpreadsheet])) {
      throw new Error(securityErrorMessage("importar planilhas"));
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      let response = await this.httpClient.request({
        url: `${apiURL}/Nfse/Upload/Sheet?isReplace=${isReplace}`,
        method: "POST",
        data: formData,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getNfse(data) {
    if (!hasPermission([reinfPerms.seeFiscalDocument])) {
      throw new Error(securityErrorMessage("visualizar documentos fiscais"));
    }

    try {
      // Monta a URL com ou sem query params
      const url = data
        ? `${apiURL}/Nfse?${new URLSearchParams(data).toString()}`
        : `${apiURL}/Nfse`;
      const response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getNfseById(id) {
    if (!hasPermission([reinfPerms.seeFiscalDocument])) {
      throw new Error(securityErrorMessage("visualizar documentos fiscais"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse/${id}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Erro: " + response.status);
      }
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async getNfseByProtocol(protocol) {
    if (!hasPermission([reinfPerms.seeFiscalDocument])) {
      throw new Error(securityErrorMessage("visualizar documentos fiscais"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse/reinf/${protocol}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Erro: " + response.status);
      }
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }

  async updateNfse(nfseObject) {
    if (!hasPermission([reinfPerms.editFiscalDocument])) {
      throw new Error(securityErrorMessage("editar documentos fiscais"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse`,
        method: "PUT",
        data: nfseObject, // Adicione o objeto Nfse no corpo da solicitação
      });

      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
  async createNfse(data) {
    if (!hasPermission([reinfPerms.addFiscalDocument])) {
      throw new Error(securityErrorMessage("adicionar documentos fiscais"));
    }

    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
  async deleteNfse(id) {
    if (!hasPermission([reinfPerms.deleteFiscalDocument])) {
      throw new Error(securityErrorMessage("excluir documentos fiscais"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
  //apaga todas as notas da empresa
  async deleteAllNfse(companyId) {
    if (!hasPermission([reinfPerms.deleteFiscalDocument])) {
      throw new Error(securityErrorMessage("excluir documentos fiscais"));
    }
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse/All/${companyId}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      throw new Error(error.response?.data || error.message);
    }
  }
}
