export function validarCPF(cpf) {
    cpf = cpf?.replace(/[^\d]+/g, "");

    if (cpf === "") return false;

    if (cpf?.length !== 11) return false;

    const invalidCPFs = [
        "00000000000",
        "11111111111",
        "22222222222",
        "33333333333",
        "44444444444",
        "55555555555",
        "66666666666",
        "77777777777",
        "88888888888",
        "99999999999"
    ];

    if (invalidCPFs.includes(cpf)) return false;

    let tempCPF = cpf.substring(0, 9);
    let soma = 0;
    let digito;
    let resto;

    for (let i = 0; i < 9; i++) {
        soma += parseInt(tempCPF.charAt(i)) * (10 - i);
    }

    resto = soma % 11;

    if (resto < 2) {
        resto = 0
    } else {
        resto = 11 - resto;
    }

    digito = resto.toString();
    tempCPF = tempCPF + digito;
    soma = 0;

    for (let i = 0; i < 10; i++) {
        soma += parseInt(tempCPF.charAt(i)) * (11 - i);
    }

    resto = soma % 11;

    if (resto < 2) {
        resto = 0;
    } else {
        resto = 11 - resto;
    }

    digito = digito + resto.toString();

    return cpf.endsWith(digito);
}