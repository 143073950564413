function buildQueryString(queryParams) {
  const uniqueParams = {};

  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key] !== null && !(key in uniqueParams)) {
      uniqueParams[key] = queryParams[key];
    }
  });

  return Object.keys(uniqueParams)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(uniqueParams[key])}`)
    .join("&");
}

export default buildQueryString;
