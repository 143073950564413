import React, { useState } from "react";
import RenderLayout from "~/presentation/views/Reinf/components/Layouts/nfseLayout/RenderLayout";
import { useSnackbar } from "~/presentation/providers/SnackbarProvider";
import ReinfTable from "~/presentation/views/Reinf/components/Table/ReinfTable";
import {
  makeBusinessPartnerUseCases,
  makeIncomeNatureUseCases,
  makeServiceClassUseCases,
} from "~/presentation/views/Reinf/Utils/utilsApiCalls";
import RenderPaymentLayout from "~/presentation/views/Reinf/components/Layouts/paymentLayout/RenderPaymentLayout";
import {
  legalPersonColumns,
  phisicalPersonColumns,
} from "~/presentation/views/Reinf/DocPanel/Table/Columns";
import { Tooltip } from "@mui/material";
import { Delete, DeleteForever } from "@mui/icons-material";
import { reinfPerms } from "~/presentation/security/SecurePaths";

function DocTable({
  legalPersonData = [],
  phisicalPersonData = [],
  isPhisicalPersonTable = false,
  onDelete = () => {},
  onChangeFieldValue = () => {},
  onChangeINSSfield = () => {},
  errors = {},
  setErrors = () => {},
  tablePage = 0,
  setTablePage = () => {},
  onUpdateStateDocs = () => {},
}) {
  const { showError } = useSnackbar();

  const apiServiceclass = makeServiceClassUseCases();
  const apiIncomeNature = makeIncomeNatureUseCases();
  const apiBusinessPartner = makeBusinessPartnerUseCases();

  const [serviceClassificationOptions, setServiceClassificationOptions] = useState([]); //select Classificação Serviço
  const [incomeNatureOptions, setIncomeNatureOptions] = useState([]); //select Natureza Rendimento
  const [businessPartnerOptions, setBusinessPartnerOptions] = useState([]); //lista de contratados

  const fetchDataOptions = async () => {
    try {
      const incomeNatureResponse = await apiIncomeNature.listIncomeNature();
      setIncomeNatureOptions(incomeNatureResponse);
    } catch (error) {
      showError(error, "Erro ao carregar opções de Natureza de Rend.");
    }

    try {
      const businessPartnerResponse = await apiBusinessPartner.listBusinessPartner();
      setBusinessPartnerOptions(businessPartnerResponse);
    } catch (error) {
      showError(error, "Erro ao carregar opções de contratado");
    }

    try {
      const serviceClassResponse = await apiServiceclass.listServiceClassification();
      setServiceClassificationOptions(serviceClassResponse);
    } catch (error) {
      showError(error, "Erro ao carregar opções de classificação de serviço");
    }
  };

  const renderDeleteIcon = (sent = false, id = null) => {
    if (sent) {
      return (
        <div>
          <Tooltip title="Doc. Fiscal vinculado a um evento enviado">
            <DeleteForever color="action" style={{ cursor: "auto" }} />
          </Tooltip>
        </div>
      );
    } else {
      return (
        <Tooltip title="Excluir Doc. fiscal">
          <Delete color="action" onClick={() => onDelete(id)} style={{ cursor: "pointer" }} />
        </Tooltip>
      );
    }
  };

  const renderCollapse = (row) => {
    if (isPhisicalPersonTable)
      return (
        <RenderPaymentLayout
          data={row}
          businessPartnerOptions={businessPartnerOptions}
          incomeNatureOptions={incomeNatureOptions}
        />
      );
    else {
      return (
        <RenderLayout
          nfseData={row}
          serviceClassificationOptions={serviceClassificationOptions}
          incomeNatureOptions={incomeNatureOptions}
          businessPartnerOptions={businessPartnerOptions}
          handleChangeFieldValue={onChangeFieldValue}
          handleChangeINSSfield={onChangeINSSfield}
          errors={errors}
          setErrors={setErrors}
          fetchOptions={fetchDataOptions}
          onUpdateStateDocs={onUpdateStateDocs}
        />
      );
    }
  };

  const deleteField = {
    headerName: <Delete />,
    sortable: false,
    align: "center",
    renderCell: ({ row }) => renderDeleteIcon(row.isR2010 || row.isR4020, row.id),
    securePath: [reinfPerms.deleteFiscalDocument],
  };

  const legalCols = [...legalPersonColumns, deleteField];
  const phisicalCols = [...phisicalPersonColumns, deleteField];

  const columnsAndDataProps = isPhisicalPersonTable
    ? { columns: phisicalCols, data: phisicalPersonData }
    : { columns: legalCols, data: legalPersonData };

  return (
    <ReinfTable
      {...columnsAndDataProps}
      collapse={renderCollapse}
      tablePage={tablePage}
      setTablePage={setTablePage}
    />
  );
}

export default DocTable;
