import React from "react";
import { List, ListItem } from "~/presentation/views/Admin/components/MessageList/styles";
import Checkbox from "~/presentation/components/Checkbox";
import parse from "html-react-parser";
import { CircularProgress } from "@mui/material";
/**
 * Component for list of messages, contains 4 fields for
 * displaying data, initial intended for Email lists with
 * selection checkbox, subject, excerpt and date.
 * Future customization are wellcome
 */

const MessageList = ({
  messages,
  selectedMessages,
  handleSelectMessage,
  disableGutters, // if true, disable the padding of ListItem components (used to free space on mobil views)
  onClick,
  // title = "subject",
  // subtitle = "excerpt",
  rightSmall = "date",
  noMessagesText = "Não há mensagens",
}) => {
  return (
    <List disableGutters={disableGutters} className="scrollbar-white">
      {!messages ? (
        <div className="absolute-center">
          <CircularProgress size="3vw" color="inherit" />
        </div>
      ) : !messages.length ? (
        <div className="absolute-center">{noMessagesText}</div>
      ) : (
        messages.map((message) => (
          <div className="listItemRelativeWrapper" key={"message-list-item-" + message.id}>
            <Checkbox
              disableGutters={disableGutters}
              className="checkbox"
              checked={selectedMessages.map((message) => message.id).includes(message.id)}
              onClick={() => handleSelectMessage(message)}
              size="small"
            />
            <ListItem
              onClick={() => onClick(message)}
              key={message.id}
              disableGutters={disableGutters}
              button
              sx={{ borderRadius: "12px", cursor: "pointer" }}
            >
              <div>
                <div className="checkbox-spacement" />
                <div className="titleAndSubtitle">
                  <div className="title">{parse(message.commentText)}</div>
                  <div className="subtitle">{message.userComment.email}</div>
                </div>
                <div className="flex column">
                  <small>{message[rightSmall]}</small>
                </div>
              </div>
            </ListItem>
          </div>
        ))
      )}
    </List>
  );
};

export default MessageList;
