import pdfMake from "pdfmake/build/pdfmake";
import { header } from "./imageBase64";

export default class Report {
  constructor(pageSize = "A4") {
    this.createDocDefinition(pageSize);
    this._content = [];
    this.line = {
      text: "____________________________________________________________________________________________________________",
      alignment: "center",
    };
  }

  setHeader(header) {
    this._header = header;
  }

  setFooter(footer) {
    this._footer = footer;
  }

  createDocDefinition(pageSize = "A4") {
    this._docDefinition = {
      pageSize,
      pageMargins: [30, 72], // left, top, right, bottom,
      header: this.headerHandler,
      content: [],
      footer: (currentPage, pageCount) => this.footerHandler(currentPage, pageCount),
      styles: {
        reportTitle: {
          fontSize: 15,
          bold: true,
          alignment: "center",
        },
        title: {
          fontSize: 12,
          bold: true,
        },
        footerText: {
          italics: true,
          alignment: "right",
          fontSize: 7,
        },
      },
    };
  }

  headerHandler() {
    return [
      {
        fit: [600, 100],
        margin: [0, 0, 0, 0],
        image: header,
      },
    ];
  }

  setContent(content) {
    this._content = content;
  }

  footerHandler(currentPage, pageCount) {
    return [
      this.line,
      {
        text: `Pag.: ${currentPage}/${pageCount}`,
        alignment: "right",
        bold: true,
        fontSize: 10,
        margin: [0, 0, 10, 0],
      },
      {
        columns: [
          // {
          //   image: footer,
          //   width: 100,
          //   margin: [3, 0],
          // },
          {
            text: `Cabe exclusivamente ao usuário a responsabilidade de avaliar a exatidão das informações apresentadas para aplicação às suas operações, sendo possível inclusive haver divergências entre o entendimento do Fisco e a opinião da equipe de consultores responsável pelo conteúdo. Também é vedado ao usuário, distribuir, publicar, transmitir, modificar, criar trabalhos derivativos ou explorar a qualquer título, no todo ou em parte, as informações ora apresentadas, independentemente da finalidade. Open Soluções Tributárias Ltda.`,
            alignment: "justify",
            style: "footerText",
            margin: [10, 10, 10, 10],
          },
        ],
      },
    ];
  }

  async generateReport() {
    this.createDocDefinition();
    this._docDefinition.content = [...this._content];
    pdfMake.createPdf(this._docDefinition).open();
  }
}
