import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { IMaskInput } from "react-imask";
import { errorColor, warningColor } from "../Common/Inputs";

export const MoneyField = ({
  styles = {},
  error = null,
  warning = null,
  prefix = "R$",
  value = 0,
  onChange = () => {},
  disabled = false,
  placeholder = "",
  percentageValue = null,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);
  return (
    <Box sx={containerStyles(styles, error, warning)}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, width: "100%" }}>
        <span style={{ color: disabled ? "#575757" : "#000", userSelect: "none" }}>{prefix}</span>
        <IMaskInput
          radix=","
          thousandsSeparator="."
          mask={Number}
          unmask={true}
          value={value?.toString()}
          onAccept={(value) => {
            if (!isMounted) return;
            const parsedValue = value === "" ? null : parseFloat(value);
            onChange(parsedValue);
          }}
          scale={2}
          signed={false}
          padFractionalZeros={true}
          lazy={false}
          disabled={disabled}
          placeholder={placeholder || "0,00"}
          style={amountInputStyles}
        />
      </Box>
      {percentageValue && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              borderLeft: "1px solid gray",
              paddingLeft: 8,
              userSelect: "none",
              overflow: "hidden",
              width: "7ch",
            }}
          >
            {percentageValue !== "NaN" ? percentageValue : "0.00"}
          </span>
          %
        </Box>
      )}
    </Box>
  );
};

const containerStyles = (styles, error, warning) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 1,
  fontSize: 14,
  fontFamily: "Montserrat",
  height: "38px",
  backgroundColor: "#fbfbfb",
  borderRadius: "3px",
  padding: "0px 10px",
  border: error || warning ? "solid" : "none",
  borderColor: warning ? warningColor : errorColor,
  borderWidth: "1px",
  ...styles,
});

const amountInputStyles = {
  width: "100%",
  backgroundColor: "transparent",
  color: "#000",
  fontFamily: "Montserrat",
  fontSize: 14,
  padding: 0,
  margin: 0,
  border: "none",
  outline: 0,
};
