import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { statsTableColumns } from "~/presentation/views/Admin/Stats/Display/statsTableColumns";
import MyPainelTable from "../../../components/MyPainelTable";
import { painelStyles } from "~/presentation/views/Client/MyPanel";
import {
  statsFilename,
  statsTableTitle,
} from "~/presentation/views/Admin/Stats/Display/StatsTable";

function Table({ tableRef, data = [], searchType = "" }) {
  const dataToTable = data.length > 0 ? data.map((item, index) => ({ ...item, id: index })) : [];
  const columns = statsTableColumns[searchType] || [];
  const title = statsTableTitle[searchType] || "";

  return (
    <>
      <Typography sx={painelStyles.subtitle}>{title}</Typography>
      <Box ref={tableRef}>
        <MyPainelTable
          columns={columns}
          data={dataToTable}
          tableExport
          exportFileName={statsFilename[searchType]}
        />
      </Box>
    </>
  );
}

export default Table;
