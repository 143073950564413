import React from "react";
import { Backdrop, Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function SimpleBackdrop({ open, message = "", handleClose }) {
  return (
    <Backdrop sx={backdropStyles.backdrop} open={open}>
      <Box sx={backdropStyles.content}>
        <CircularProgress size={50} sx={{ color: "#fff" }} />
        <Typography sx={backdropStyles.text}>{message || "Carregando"}...</Typography>
        <IconButton title="Fechar" sx={backdropStyles.close} onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
    </Backdrop>
  );
}

export const backdropStyles = {
  backdrop: {
    color: "#fff",
    flexDirection: "column",
    zIndex: 1301,
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 3,
  },
  text: { fontFamily: "Montserrat", lineHeight: "1rem", userSelect: "none" },
  close: { position: "absolute", top: 10, right: 10, color: "#fff" },
};
